/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavPointMetaDataView } from './NavPointMetaDataView';
import {
    NavPointMetaDataViewFromJSON,
    NavPointMetaDataViewFromJSONTyped,
    NavPointMetaDataViewToJSON,
} from './NavPointMetaDataView';
import type { StationDetailsView } from './StationDetailsView';
import {
    StationDetailsViewFromJSON,
    StationDetailsViewFromJSONTyped,
    StationDetailsViewToJSON,
} from './StationDetailsView';

/**
 * 
 * @export
 * @interface NavPointStationView
 */
export interface NavPointStationView {
    /**
     * 
     * @type {NavPointMetaDataView}
     * @memberof NavPointStationView
     */
    metaData?: NavPointMetaDataView;
    /**
     * 
     * @type {StationDetailsView}
     * @memberof NavPointStationView
     */
    details?: StationDetailsView;
}

/**
 * Check if a given object implements the NavPointStationView interface.
 */
export function instanceOfNavPointStationView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NavPointStationViewFromJSON(json: any): NavPointStationView {
    return NavPointStationViewFromJSONTyped(json, false);
}

export function NavPointStationViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavPointStationView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metaData': !exists(json, 'metaData') ? undefined : NavPointMetaDataViewFromJSON(json['metaData']),
        'details': !exists(json, 'details') ? undefined : StationDetailsViewFromJSON(json['details']),
    };
}

export function NavPointStationViewToJSON(value?: NavPointStationView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metaData': NavPointMetaDataViewToJSON(value.metaData),
        'details': StationDetailsViewToJSON(value.details),
    };
}

