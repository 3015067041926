import {ChangeEvent, MouseEvent, useEffect, useState} from 'react';
import Button from "../../common/input/button/Button";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {addToast} from "../../common/toast/toastUtil";
import Close from "../../common/ahreffun/Close";
import {TownEndpointApi} from "../../../api";

interface TownEditPanelProps {
    townId: string;
    onClose: (refresh: boolean) => void;
    mzkWorkspace: MzkWorkspace
}

export function TownEditPanel(props: TownEditPanelProps) {
    const [name, setName] = useState('');
    const [originalName, setOriginalName] = useState('');
    useEffect(() => {
        new TownEndpointApi().getTownDetails({
            townId: props.townId,
            companyId: props.mzkWorkspace.company.id
        }, {headers: prepareSecurityHeaders()})
            .then((r) => {
                setName(r.name);
                setOriginalName(r.name);
            })
            .catch(e => {
                verifyClientResponse(e, getErrorMap());
            });
    }, [props.townId]);
    const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (verifyForm()) {
            new TownEndpointApi().editTown({
                editTownInput: {
                    id: props.townId,
                    name: name,
                    companyId: props.mzkWorkspace.company.id
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie zaktualizowano miasto');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const getErrorMap = () => {
        const map = new Map();
        map.set("uniqueness", r => {
            if (r.data.name === 'name') {
                addToast('Nazwa miasta musi być unikalna na poziomie Data Space');
            }
        });
        return map;
    }
    const verifyForm = () => {
        if (!name.length) {
            addToast('Nazwa nie może być pusta');
            return false;
        }
        return true;
    }
    const onDelete = () => {
        const errorMap = new Map();
        errorMap.set("town_is_used", () => {
           addToast('Miasto pozostaje w użyciu, więc nie może być usunięte');
        });
        new TownEndpointApi().deleteTown({
            townId: props.townId,
            companyId: props.mzkWorkspace.company.id
        }, {headers: prepareSecurityHeaders()})
            .then(() => {
                addToast('Pomyślnie usunięto miasto');
                props.onClose(true);
            })
            .catch(e => {
                verifyClientResponse(e, errorMap);
            });
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        </div>
        <h2>Edycja {originalName}</h2>
        <form>
            <label>Nazwa*:</label>
            <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <Button onClick={onSubmit}>Zapisz</Button>
        </form>
        <h3>Inne opcje</h3>
        <Button onClick={onDelete}>Usuń</Button>
    </div>;
}
