/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionTimeView
 */
export interface SectionTimeView {
    /**
     * 
     * @type {string}
     * @memberof SectionTimeView
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof SectionTimeView
     */
    to: string;
    /**
     * 
     * @type {number}
     * @memberof SectionTimeView
     */
    travelSec: number;
}

/**
 * Check if a given object implements the SectionTimeView interface.
 */
export function instanceOfSectionTimeView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "travelSec" in value;

    return isInstance;
}

export function SectionTimeViewFromJSON(json: any): SectionTimeView {
    return SectionTimeViewFromJSONTyped(json, false);
}

export function SectionTimeViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionTimeView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': json['from'],
        'to': json['to'],
        'travelSec': json['travelSec'],
    };
}

export function SectionTimeViewToJSON(value?: SectionTimeView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
        'travelSec': value.travelSec,
    };
}

