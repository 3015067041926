import {ReactNode} from "react";
import Button from "../input/button/Button";

interface PopupProps {
    children: ReactNode;
    closePopup: () => void;
}

const Popup = (props: PopupProps) => {
    return (
        <div className="popup-container">
            <div className="popup-body">
                {props.children}
                <br/>
                <Button onClick={props.closePopup}>Zamknij X</Button>
            </div>
        </div>
    );
};

export default Popup;
