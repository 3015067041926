import {NavpointEndpointApi, ZoneEndpointApi} from "../../../api";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {addToast} from "../../common/toast/toastUtil";
import {verifyClientResponse} from "../../common/error/resolvers";
import Close from "../../common/ahreffun/Close";
import Button from "../../common/input/button/Button";
import {useEffect, useState} from "react";
import {prepareNameWithAncestor} from "../../common/navpoint/navpointUtil";

interface ZoneEditPanelProps {
    zoneId: string;
    mzkWorkspace: MzkWorkspace;
    onClose: (refresh: boolean) => void;
}

export function ZoneEditPanel(props: ZoneEditPanelProps) {
    const [name, setName] = useState('');
    const [originalName, setOriginalName] = useState<string>();
    useEffect(() => {
        new ZoneEndpointApi().getZone({
                zoneId: props.zoneId
            },
            {headers: prepareSecurityHeaders()})
            .then(z => {
                setName(z.name);
                setOriginalName(z.name);
            })
            .catch(e => verifyClientResponse(e));
    }, [props.zoneId]);
    const onSave = () => {
        if (verifyForm()) {
            new ZoneEndpointApi().updateZone({
                editZoneInput: {
                    id: props.zoneId,
                    name: name,
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie zaktualizowano strefę');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const getErrorMap = () => {
        const map = new Map<string, (r: any) => void>();
        map.set("uniqueness", r => {
            if (r.data.name === 'name') {
                addToast('Nazwa strefy musi być unikalna na poziomie Data Space i firmy');
            }
        });
        return map;
    }
    const verifyForm = () => {
        if (!name.length) {
            addToast('Nazwa nie może być pusta');
            return false;
        }
        return true;
    }
    const onRemove = () => {
        if (confirm('Czy na pewno chcesz usunąć strefę?')) {
            const errorMap = new Map<string, (r: any) => void>();
            errorMap.set('zone_is_used', r => {
                addToast('Strefa pozostaje w użyciu, więc nie może zostać usunięta');
                new NavpointEndpointApi().prepareAdoptedNavPoint({
                    adoptedNavPointRequest: {
                        id: r.data,
                        companyId: props.mzkWorkspace.company.id,
                    }
                }, {headers: prepareSecurityHeaders()})
                    .then(adoptedNavPoint => {
                        addToast(`Przykładowa użycia to navpoint ${prepareNameWithAncestor(adoptedNavPoint)}`);
                    }).catch(e => {
                    verifyClientResponse(e);
                });
            })
            new ZoneEndpointApi().deleteZone({zoneId: props.zoneId},
                {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie usunięto strefę');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, errorMap);
                });
        }
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        </div>
        <h3>Edycja {originalName}</h3>
        <form>
            <label>Nazwa*:</label>
            <input name='name' value={name} onChange={(e) => setName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <Button onClick={() => onSave()}>Zapisz</Button>
        </form>
        <h3>Inne opcje</h3>
        <Button onClick={onRemove}>Usuń strefę</Button>
    </div>;
}
