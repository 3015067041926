import {useEffect, useState} from 'react';
import Close from "../../common/ahreffun/Close";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import VariableTable from "../table/VariableTable";
import Button from "../../common/input/button/Button";
import {VariableEndpointApi, VariableTemplateView} from "../../../api";
import Af from "../../common/ahreffun/Af";

interface VariablePanelProps {
    closeFun: () => void;
    company: Company;
    changeVariableToViewValueFun: (v: any) => void;
    toggleShowNewVariableFormFun: () => void;
}

function VariablePanel(props: VariablePanelProps) {
    const [variables, setVariables] = useState<VariableTemplateView[]>([]);
    useEffect(() => {
        new VariableEndpointApi().getVariables({companyId: props.company.id}, {headers: prepareSecurityHeaders()})
            .then((r: VariableTemplateView[]) => {
                setVariables(r);
                return r;
            })
            .catch((e: any) => verifyClientResponse(e));
    }, []);
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={props.closeFun}/></span>
            </div>
            <h3>Zmienne środowiskowe</h3>
            <VariableTable
                variables={variables}
                actionProvider={(v: VariableTemplateView) => <Af
                    onClick={() => props.changeVariableToViewValueFun(v)}>Wartości</Af>}
            />
            {props.company.superUser &&
                <Button onClick={props.toggleShowNewVariableFormFun}>Nowa zmienna środowiskowa</Button>}
        </div>
    );
}

export default VariablePanel;
