import { addToast } from "../toast/toastUtil";
import { ResponseError } from "../../../api";

export function verifyClientResponse(error: Error, badRequestMappersMap: Map<string, Function> = new Map()): void {
    if (error instanceof ResponseError) {
        const response = error.response;
        if (response.status === 400) {
            response.json()
                .then((r: { code: string }) => {
                    const errorCode = r.code;
                    if (errorCode && badRequestMappersMap.has(errorCode)) {
                        return badRequestMappersMap.get(errorCode)(r);
                    } else {
                        onUnrecognizedError();
                    }
                });
        } else if (response.status === 401) {
            onUnlogged();
        } else if (response.status === 500) {
            onUnrecognizedError();
        } else {
            onUnrecognizedError();
        }
    } else {
        onUnrecognizedError();
        console.log(error);
    }
}

export function verifyResponse(response: Response, badRequestMappersMap: Map<string, Function> = new Map()): Response {
    if (response.ok) {
        return response;
    } else {
        if (response.status === 400) {
            response.json()
                .then((r: { code: string }) => {
                    const errorCode = r.code;
                    if (errorCode && badRequestMappersMap.has(errorCode)) {
                        return badRequestMappersMap.get(errorCode)(r);
                    } else {
                        throw new UnrecognizedError();
                    }
                });
        } else if (response.status === 401) {
            throw new UserNotLoggedError(response);
        } else if (response.status === 500) {
            throw new ServerError();
        } else {
            throw new UnrecognizedError();
        }
    }
}

function onUnrecognizedError(): void {
    addToast('Operacja nie może być obecnie zrealizowana. Spróbuj później.');
}

function onUnlogged(): void {
    addToast('Nie jesteś zalogowany. Zaloguj się aby móc wykonać operację.');
}

export function handleCommonError(error: Error): void {
    if (error instanceof UserNotLoggedError) {
        onUnlogged();
    } else if (error instanceof ServerError || error instanceof UnrecognizedError) {
        onUnrecognizedError();
    }
}

export class UserNotLoggedError extends Error {
    response: Response;

    constructor(response: Response, ...params: any[]) {
        super(...params);
        this.response = response;
    }
}

export class ServerError extends Error {
    constructor(...params: any[]) {
        super(...params);
    }
}

export class UnrecognizedError extends Error {
    constructor(...params: any[]) {
        super(...params);
    }
}
