import ReactDOM from "react-dom/client";
import React from "react";
import {App} from "./App";
import {determineFavicon} from "./common/favicon/favicon";
import './style/main.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);

determineFavicon();
