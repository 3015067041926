import {ChangeEvent, FormEvent, useState} from 'react';
import Close from "../../common/ahreffun/Close";
import Button from "../../common/input/button/Button";
import {nullIfBlank} from "../../common/string/stringUtil";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {addToast} from "../../common/toast/toastUtil";
import {TimetableEndpointApi} from "../../../api";
import {stringToDate} from "../../common/date/dateUtil";
import DateInput from "../../common/input/date/DateInput";

interface AddNewTimeTableFormProps {
    hideNewTimeTableFormFun: (refreshList: boolean) => void;
    company: Company
}

function AddNewTimetableForm(props: AddNewTimeTableFormProps) {
    const [name, setName] = useState('');
    const [preparationDate, setPreparationDate] = useState('');
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (verifyForm()) {
            new TimetableEndpointApi().saveTimeTable({
                    timetableInput: {
                        name: nullIfBlank(name),
                        preparationDate: stringToDate(preparationDate),
                        companyId: props.company.id,
                        editable: true
                    }
                }, {headers: prepareSecurityHeaders()}
            )
                .then(() => {
                    addToast('Poprawnie dodano nowy rozkład');
                    props.hideNewTimeTableFormFun(true);
                })
                .catch(e => {
                    verifyClientResponse(e, new Map());
                });
        }
    }
    const verifyForm = () => {
        if (name.length === 0) {
            addToast('Nazwa nie może być pusta');
            return false;
        } else if (preparationDate == '' || new Date(preparationDate) == null) {
            addToast('Uzupełnij datę');
            return false;
        }
        return true;
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.hideNewTimeTableFormFun(false)}/></span>
            </div>
            <h3>Dodawanie nowego rozkładu {name}</h3>
            <form>
                <label>Nazwa: </label>
                <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                       maxLength={50}/>
                <br/>
                Data przygotowania: <DateInput value={preparationDate} onChange={(f: string) => setPreparationDate(f)}
                                               disabled={false}/>
                <br/>
                <Button onClick={onSubmit}>Dodaj</Button>
            </form>
        </div>
    );
}

export default AddNewTimetableForm;
