import {ReactNode, useContext, useState} from "react";
import {StationTab} from "../station/tab/StationTab";
import {LoggedTabContextMainCategory} from "./LoggedTabContextMainCategory";
import {InitTab} from "../init/InitTab";
import {changeSubTitle} from "../common/sitetitle/siteTitleUtil";
import {StreetTab} from "../street/tab/StreetTab";
import {CurrentUser, CurrentUserContext} from "../CurrentUserContext";
import ConditionTab from "../condition/tab/ConditionTab";
import SectionTab from "../section/tab/SectionTab";
import {LoggedTabContext} from "./LoggedTabContext";
import NavbarMenu from "../navbar/NavbarMenu";
import SpaceTab from "../space/tab/SpaceTab";
import TimetableTab from "../timetable/TimetableTab";
import ZoneTab from "../zone/tab/ZoneTab";
import UserTab from "../user/UserTab";
import {CrewTab} from "../crew/tab/CrewTab";
import {AsyncTaskTab} from "../asynctask/tab/AsyncTaskTab";


export function UserLoggedApp() {
    const [previousTabContext, setPreviousTabContext] = useState<LoggedTabContext>();
    const [loggedTabContext, setLoggedTabContext] = useState<LoggedTabContext>();

    const prepareUserTabNavbarPosition = (currentUser: CurrentUser): ReactNode => {
        if (currentUser.user.defaultTimetable) {
            return (
                <>
                    {currentUser.user.defaultTimetable.name + " "}
                    ({currentUser.user.defaultCompany &&
                    currentUser.user.defaultCompany.name})
                    <br/>
                    {currentUser.user.nick}
                </>
            );
        } else {
            return (
                <>
                    {currentUser.user.defaultCompany &&
                        currentUser.user.defaultCompany.name}
                    <br/>
                    {currentUser.user.nick}
                </>
            );
        }
    }

    const changeTabContext = (newContext: LoggedTabContext) => {
        const oldContext = loggedTabContext;
        if (
            oldContext != null &&
            oldContext.isSelfDirected &&
            JSON.stringify(newContext) !== JSON.stringify(oldContext)
        ) {
            setPreviousTabContext(oldContext);
        }
        setLoggedTabContext(newContext);
    }

    const resolveTabContext = (): ReactNode => {
        const mainTabName =
            loggedTabContext == null
                ? LoggedTabContextMainCategory.INIT
                : loggedTabContext.mainTabName;
        switch (mainTabName) {
            case LoggedTabContextMainCategory.TIME_TABLE:
                changeSubTitle("Rozkład jazdy");
                return <TimetableTab/>;
            case LoggedTabContextMainCategory.STATION:
                changeSubTitle("Stacje");
                return <StationTab/>;
            case LoggedTabContextMainCategory.CONDITION:
                changeSubTitle("Warunki");
                return <ConditionTab/>;
            case LoggedTabContextMainCategory.CREW:
                changeSubTitle("Brygady");
                return <CrewTab/>;
            case LoggedTabContextMainCategory.ZONE:
                changeSubTitle("Strefy");
                return <ZoneTab/>;
            case LoggedTabContextMainCategory.USER:
                changeSubTitle("Użytkownik");
                return <UserTab/>;
            case LoggedTabContextMainCategory.SPACE:
                changeSubTitle("Space");
                return <SpaceTab/>;
            case LoggedTabContextMainCategory.STREET:
                changeSubTitle("Ulice");
                return <StreetTab/>;
            case LoggedTabContextMainCategory.SECTION:
                changeSubTitle("Odcinki");
                return <SectionTab/>;
            case LoggedTabContextMainCategory.ASYNC:
                changeSubTitle('Async');
                return <AsyncTaskTab/>;
            default:
                return <InitTab/>;
        }
    }

    const moveToPreviousTabContext = () => {
        setLoggedTabContext(previousTabContext);
        setPreviousTabContext(null);
    }

    const currentUser = useContext(CurrentUserContext);
    return (
        <>
            <NavbarMenu
                positions={[
                    {
                        e: "Rozkład jazdy",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.TIME_TABLE, true))
                    },
                    {
                        e: "Stacje",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.STATION, true))
                    },
                    {
                        e: "Odcinki",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.SECTION, true))
                    },
                    {
                        e: "Warunki",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.CONDITION, true))
                    },
                    {
                        e: "Brygady",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.CREW, true)),
                        disabled: false
                    },
                    {
                        e: "Strefy",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.ZONE, true))
                    },
                    {
                        e: "Ulice",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.STREET, true))
                    },
                    {
                        e: "Space",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.SPACE, true))
                    },
                    {
                        e: "Async",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.ASYNC, true)),
                    },
                    {
                        e: "Kompilacja",
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.COMPILATION, true)),
                        disabled: true
                    },
                    {
                        e: prepareUserTabNavbarPosition(currentUser),
                        onClick: () => changeTabContext(new LoggedTabContext(LoggedTabContextMainCategory.USER, true)),
                        disabled: false
                    }
                ]}
            />
            {resolveTabContext()}
        </>
    );
}
