/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConditionVisibilityRuleTypeView = {
    Section: 'SECTION',
    Crew: 'CREW',
    NavPoint: 'NAV_POINT'
} as const;
export type ConditionVisibilityRuleTypeView = typeof ConditionVisibilityRuleTypeView[keyof typeof ConditionVisibilityRuleTypeView];


export function ConditionVisibilityRuleTypeViewFromJSON(json: any): ConditionVisibilityRuleTypeView {
    return ConditionVisibilityRuleTypeViewFromJSONTyped(json, false);
}

export function ConditionVisibilityRuleTypeViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionVisibilityRuleTypeView {
    return json as ConditionVisibilityRuleTypeView;
}

export function ConditionVisibilityRuleTypeViewToJSON(value?: ConditionVisibilityRuleTypeView | null): any {
    return value as any;
}

