/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupInput
 */
export interface GroupInput {
    /**
     * 
     * @type {string}
     * @memberof GroupInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupInput
     */
    timetableId?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupInput
     */
    parentGroupId?: string;
}

/**
 * Check if a given object implements the GroupInput interface.
 */
export function instanceOfGroupInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupInputFromJSON(json: any): GroupInput {
    return GroupInputFromJSONTyped(json, false);
}

export function GroupInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'timetableId': !exists(json, 'timetableId') ? undefined : json['timetableId'],
        'parentGroupId': !exists(json, 'parentGroupId') ? undefined : json['parentGroupId'],
    };
}

export function GroupInputToJSON(value?: GroupInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'timetableId': value.timetableId,
        'parentGroupId': value.parentGroupId,
    };
}

