/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VariableTemplateView } from './VariableTemplateView';
import {
    VariableTemplateViewFromJSON,
    VariableTemplateViewFromJSONTyped,
    VariableTemplateViewToJSON,
} from './VariableTemplateView';

/**
 * 
 * @export
 * @interface VariableSelectionView
 */
export interface VariableSelectionView {
    /**
     * 
     * @type {VariableTemplateView}
     * @memberof VariableSelectionView
     */
    template: VariableTemplateView;
    /**
     * 
     * @type {string}
     * @memberof VariableSelectionView
     */
    currentValueId?: string;
}

/**
 * Check if a given object implements the VariableSelectionView interface.
 */
export function instanceOfVariableSelectionView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "template" in value;

    return isInstance;
}

export function VariableSelectionViewFromJSON(json: any): VariableSelectionView {
    return VariableSelectionViewFromJSONTyped(json, false);
}

export function VariableSelectionViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableSelectionView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'template': VariableTemplateViewFromJSON(json['template']),
        'currentValueId': !exists(json, 'currentValueId') ? undefined : json['currentValueId'],
    };
}

export function VariableSelectionViewToJSON(value?: VariableSelectionView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'template': VariableTemplateViewToJSON(value.template),
        'currentValueId': value.currentValueId,
    };
}

