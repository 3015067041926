/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrewView } from './CrewView';
import {
    CrewViewFromJSON,
    CrewViewFromJSONTyped,
    CrewViewToJSON,
} from './CrewView';

/**
 * 
 * @export
 * @interface CrewGroupOpenOpennessView
 */
export interface CrewGroupOpenOpennessView {
    /**
     * 
     * @type {Array<CrewView>}
     * @memberof CrewGroupOpenOpennessView
     */
    crew?: Array<CrewView>;
}

/**
 * Check if a given object implements the CrewGroupOpenOpennessView interface.
 */
export function instanceOfCrewGroupOpenOpennessView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CrewGroupOpenOpennessViewFromJSON(json: any): CrewGroupOpenOpennessView {
    return CrewGroupOpenOpennessViewFromJSONTyped(json, false);
}

export function CrewGroupOpenOpennessViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrewGroupOpenOpennessView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'crew': !exists(json, 'crew') ? undefined : ((json['crew'] as Array<any>).map(CrewViewFromJSON)),
    };
}

export function CrewGroupOpenOpennessViewToJSON(value?: CrewGroupOpenOpennessView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'crew': value.crew === undefined ? undefined : ((value.crew as Array<any>).map(CrewViewToJSON)),
    };
}

