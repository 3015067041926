import StreetTableRows from "./StreetTableRows";
import {StreetSearchView} from "../../../api";
import {ReactNode} from "react";

interface StreetTableProps {
    streetData: Array<StreetSearchView>;
    appliedLimit: boolean;
    actionProvider: (a0: StreetSearchView) => ReactNode;
}

const StreetTable = (props: StreetTableProps) => {
    return <div>
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Nazwa</th>
                <th>Nazwa skrócona</th>
                <th>Miasto</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            <StreetTableRows streetsWithTown={props.streetData}
                             availableActionsFun={props.actionProvider}/>
            {props.appliedLimit && <tr>
                <td colSpan={5}>Widok wyłącznie ostatnio użytych.</td>
            </tr>}
            </tbody>
        </table>
    </div>;
}

export default StreetTable;
