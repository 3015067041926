/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditStreetInput
 */
export interface EditStreetInput {
    /**
     * 
     * @type {string}
     * @memberof EditStreetInput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EditStreetInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EditStreetInput
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditStreetInput
     */
    townId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditStreetInput
     */
    companyId: string;
}

/**
 * Check if a given object implements the EditStreetInput interface.
 */
export function instanceOfEditStreetInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "companyId" in value;

    return isInstance;
}

export function EditStreetInputFromJSON(json: any): EditStreetInput {
    return EditStreetInputFromJSONTyped(json, false);
}

export function EditStreetInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditStreetInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'townId': !exists(json, 'townId') ? undefined : json['townId'],
        'companyId': json['companyId'],
    };
}

export function EditStreetInputToJSON(value?: EditStreetInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'shortName': value.shortName,
        'townId': value.townId,
        'companyId': value.companyId,
    };
}

