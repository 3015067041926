/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlatformDetailsView
 */
export interface PlatformDetailsView {
    /**
     * 
     * @type {boolean}
     * @memberof PlatformDetailsView
     */
    requestStop?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlatformDetailsView
     */
    capacity?: number;
}

/**
 * Check if a given object implements the PlatformDetailsView interface.
 */
export function instanceOfPlatformDetailsView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlatformDetailsViewFromJSON(json: any): PlatformDetailsView {
    return PlatformDetailsViewFromJSONTyped(json, false);
}

export function PlatformDetailsViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformDetailsView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestStop': !exists(json, 'requestStop') ? undefined : json['requestStop'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
    };
}

export function PlatformDetailsViewToJSON(value?: PlatformDetailsView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestStop': value.requestStop,
        'capacity': value.capacity,
    };
}

