import Close from "../../common/ahreffun/Close";
import Af from "../../common/ahreffun/Af";
import Tooltip from "../../common/tooltip/Tooltip";
import {StaticConditionDetailsSubtypeEnum} from "../../../api";
import {NewConditionPanelInConditionTabType} from "../tab/ConditionTabSectionConditionManagement";

interface NewConditionListPanelProps {
    closeFun: (refresh: boolean) => void;
    changeNewConditionTypeFun: (t: NewConditionPanelInConditionTabType) => void;
}

function NewConditionListPanel(props: NewConditionListPanelProps) {
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.closeFun(false)}/></span>
            </div>
            <h3>Nowy warunek</h3>
            <Af onClick={() => props.changeNewConditionTypeFun({type: 'WINTER_HOLIDAYS'})}>Ferie zimowe</Af><br/>
            <Af onClick={() => props.changeNewConditionTypeFun({
                type: 'STATIC',
                subtype: StaticConditionDetailsSubtypeEnum.SummerHolidays
            })}>Ferie letnie</Af><br/>
            <Af onClick={() => props.changeNewConditionTypeFun({
                type: 'STATIC',
                subtype: StaticConditionDetailsSubtypeEnum.PublicHolidays
            })}>Święta</Af><br/>
            <Af onClick={() => props.changeNewConditionTypeFun({
                type: 'STATIC',
                subtype: StaticConditionDetailsSubtypeEnum.SchoolDays
            })}>Dni szkolne</Af> <Tooltip
            content='Niezależne od województw'>(statyczne)</Tooltip><br/>
            <Af onClick={() => props.changeNewConditionTypeFun({
                type: 'STATIC',
                subtype: StaticConditionDetailsSubtypeEnum.NonSchoolDays
            })}>Dni wolne od szkoły</Af> <Tooltip
            content='Niezależne od województw'>(statyczne)</Tooltip><br/>
            <Af onClick={() => props.changeNewConditionTypeFun({
                type: 'STATIC',
                subtype: StaticConditionDetailsSubtypeEnum.Easter
            })}>Wielkanoc</Af><br/>
            <Af onClick={() => props.changeNewConditionTypeFun({type: 'DAY_OF_WEEK'})}>Wybrane dni tygodnia</Af><br/>
            <Af onClick={() => props.changeNewConditionTypeFun({type: 'DAY_OF_MONTH'})}>Wybrane dni miesiąca</Af><br/>
            <Af onClick={() => props.changeNewConditionTypeFun({type: 'VARIABLE'})}>Wartość warunku
                środowiskowego</Af><br/>
        </div>
    );
}

export default NewConditionListPanel;
