import Af from "../ahreffun/Af";
import {ReactNode} from "react";

interface RollerProps {
    children: ReactNode;
    title: ReactNode;
    open: boolean;
    onChangeVisibility: () => void;
}

function Roller(props: RollerProps) {
    const opened = (): ReactNode => {
        return (
            <>
                <p><Af onClick={props.onChangeVisibility}>↓ {props.title}</Af></p>
                {props.children}
            </>
        );
    }
    const closed = (): ReactNode => {
        return (
            <>
                <Af onClick={props.onChangeVisibility}>→ {props.title}</Af>
            </>
        );
    }
    return (
        <div className='diffArea'>
            {props.open ? opened() : closed()}
        </div>
    );
}

export default Roller;
