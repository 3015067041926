export function stringToDate(v: string){
    if (v == null || v === ''){
        return undefined;
    }
    return new Date(v);
}
export function stringDateFormatToDate(v: string){
    return v.split('T')[0];
}

export const DAYS_OF_WEEK = [
    'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'
]

export const MONTHS = [
    'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'
]

export interface DateOfMonth {
    day: number;
    month: number;
}

export function dateOfMonthIsTheSame(d1: DateOfMonth, d2: DateOfMonth) {
    return d1.day == d2.day && d1.month == d2.month;
}

export function groupAndSortSecondsByHours(secondsList: number[]) {
    const groupedByHours = new Map<number, number[]>();

    secondsList.forEach((seconds) => {
        const hours = Math.floor(seconds / 3600); // Convert seconds to hours
        if (!groupedByHours.has(hours)) {
            groupedByHours.set(hours, []);
        }
        groupedByHours.get(hours).push(seconds);
    });

    // Sort the Map by keys (hours) in ascending order
    const sortedHours = new Map([...groupedByHours.entries()].sort((a, b) => a[0] - b[0]));
return sortedHours;
}
