/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionMetaDataInput } from './ConditionMetaDataInput';
import {
    ConditionMetaDataInputFromJSON,
    ConditionMetaDataInputFromJSONTyped,
    ConditionMetaDataInputToJSON,
} from './ConditionMetaDataInput';
import type { VariableConditionDetails } from './VariableConditionDetails';
import {
    VariableConditionDetailsFromJSON,
    VariableConditionDetailsFromJSONTyped,
    VariableConditionDetailsToJSON,
} from './VariableConditionDetails';

/**
 * 
 * @export
 * @interface VariableConditionInput
 */
export interface VariableConditionInput {
    /**
     * 
     * @type {ConditionMetaDataInput}
     * @memberof VariableConditionInput
     */
    metaData?: ConditionMetaDataInput;
    /**
     * 
     * @type {VariableConditionDetails}
     * @memberof VariableConditionInput
     */
    details?: VariableConditionDetails;
}

/**
 * Check if a given object implements the VariableConditionInput interface.
 */
export function instanceOfVariableConditionInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VariableConditionInputFromJSON(json: any): VariableConditionInput {
    return VariableConditionInputFromJSONTyped(json, false);
}

export function VariableConditionInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariableConditionInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metaData': !exists(json, 'metaData') ? undefined : ConditionMetaDataInputFromJSON(json['metaData']),
        'details': !exists(json, 'details') ? undefined : VariableConditionDetailsFromJSON(json['details']),
    };
}

export function VariableConditionInputToJSON(value?: VariableConditionInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metaData': ConditionMetaDataInputToJSON(value.metaData),
        'details': VariableConditionDetailsToJSON(value.details),
    };
}

