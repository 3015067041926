import {ChangeEvent, useState} from 'react';
import Close from "../../common/ahreffun/Close";
import Af from "../../common/ahreffun/Af";
import Button from "../../common/input/button/Button";
import {StationPopupSelector} from "../../station/popup/StationPopupSelector";
import ConditionPopupSelector from "../../condition/popup/ConditionPopupSelector";
import Checkbox from "../../common/input/checkbox/Checkbox";
import {addToast} from "../../common/toast/toastUtil";
import {
    AdoptedNavPoint,
    ConditionMetaDataView,
    ConditionVisibilityRuleTypeView,
    NavpointEndpointApi,
    NavPointMetaDataView,
    SectionEndpointApi
} from "../../../api";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {nullIfBlank} from "../../common/string/stringUtil";
import {prepareNameWithAncestor} from "../../common/navpoint/navpointUtil";
import PlatformPopupSelector from "../../platform/popup/PlatformPopupSelector";
import {SectionSample, SectionSampleForm, validateSectionSample} from "../sample/form/SectionSampleForm";
import {stringToDate} from "../../common/date/dateUtil";
import {IntermediateNavPointForm} from "../intermediatenavpoint/table/IntermediateNavPointForm";

interface AddNewSectionFormProps {
    company: Company;
    initialFrom: AdoptedNavPoint;
    initialSampleDate: string
    onAddSection: () => void;
    onClose: () => void;
}

function AddSectionPanel(props: AddNewSectionFormProps) {
    const [from, setFrom] = useState(props.initialFrom);
    const [to, setTo] = useState<AdoptedNavPoint>();
    const [description, setDescription] = useState<string>('');
    const [condition, setCondition] = useState<ConditionMetaDataView>()
    const [distance, setDistance] = useState<number>();
    const [bidirectional, setBidirectional] = useState(false);
    const [selectingFrom, setSelectingFrom] = useState(true);
    const [showStationSelector, setShowStationSelector] = useState(false);
    const [showPlatformSelector, setShowPlatformSelector] = useState(false);
    const [showConditionSelector, setShowConditionSelector] = useState(false);
    const [sample, setSample] = useState<SectionSample>({
        defaultTravelSec: 0,
        defaultWaitingSec: null,
        times: [],
        sampleDate: props.initialSampleDate,
    });
    const [intermediateNavPoints, setIntermediateNavPoints] = useState<AdoptedNavPoint[]>([]);
    const reverseDir = () => {
        const lastFrom = from;
        setFrom(to);
        setTo(lastFrom);
    }
    const prepareDirName = (dir: AdoptedNavPoint) => {
        if (dir == null) {
            return 'nie ustawiono';
        }
        return prepareNameWithAncestor(dir);
    }
    const restartForm = () => {
        setFrom(null);
        setTo(null);
        setDescription('');
        setCondition(null);
        setDistance(null);
        setSample({
            defaultTravelSec: 0,
            defaultWaitingSec: null,
            times: [],
            sampleDate: props.initialSampleDate,
        });
        setIntermediateNavPoints([]);
    }
    const onAdd = () => {
        if (validate()) {
            new SectionEndpointApi().saveSection({
                createSectionInput: {
                    companyId: props.company.id,
                    part1: {navPointId: from.navPointMetaData.id},
                    part2: {navPointId: to.navPointMetaData.id},
                    description: nullIfBlank(description),
                    conditionId: condition ? condition.id : null,
                    distanceM: distance ? distance : null,
                    bidirectional: bidirectional,
                    intermediateNavPointIds: intermediateNavPoints.map(i => i.navPointMetaData.id),
                    initialSample: {
                        sampleDate: stringToDate(sample.sampleDate),
                        defaultWaitingSec: sample.defaultWaitingSec,
                        defaultTravelSec: sample.defaultTravelSec,
                        times: sample.times
                    }
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano odcinek');
                    restartForm();
                    props.onAddSection();
                })
                .catch(e => {
                    verifyClientResponse(e, prepareSectionErrorMap());
                })
        }
    }

    const validate = () => {
        if (from == null) {
            addToast('Nie wybrano miejsca początkowego');
            return false;
        } else if (to == null) {
            addToast('Nie wybrano miejsca docelowego');
            return false;
        } else if (distance < 1) {
            addToast('Dystans musi być większy niż 1');
            return false;
        } else if (!validateSectionSample(sample)) {
            return false;
        }
        return true;
    }
    const selectNavPoint = (navPoint: NavPointMetaDataView) => {
        new NavpointEndpointApi().prepareAdoptedNavPoint({
            adoptedNavPointRequest: {
                id: navPoint.id,
                companyId: props.company.id,
            }
        }, {headers: prepareSecurityHeaders()})
            .then(adoptedNavPoint => {
                if (selectingFrom) {
                    setFrom(adoptedNavPoint);
                    if (to == null) {
                        setSelectingFrom(false);
                    } else {
                        setShowPlatformSelector(false);
                        setShowStationSelector(false);
                    }
                } else {
                    setTo(adoptedNavPoint);
                    setShowPlatformSelector(false);
                    setShowStationSelector(false);
                }
            }).catch(e => {
            verifyClientResponse(e);
        });
    }
    return <>
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.onClose()}/></span>
            </div>
            <h3>Dodawanie nowego odcinka</h3>

            <span>Z*: </span> {prepareDirName(from)}
            <Af onClick={() => {
                setShowStationSelector(true);
                setSelectingFrom(true);
            }}>Stacja</Af>
            <Af onClick={() => {
                setShowPlatformSelector(true);
                setSelectingFrom(true);
            }}>Platforma</Af>
            <br/>

            <span>Do*: </span> {prepareDirName(to)}
            <Af onClick={() => {
                setShowStationSelector(true);
                setSelectingFrom(false);
            }}>Stacja</Af>
            <Af onClick={() => {
                setShowPlatformSelector(true);
                setSelectingFrom(false);
            }}>Platforma</Af>
            {(from || to) && <Af onClick={reverseDir}>Odwróć</Af>}
            <br/>
            <label>Opis:</label>
            <input name='description' value={description}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}/>
            <br/>

            <label>Odległość(m):</label>
            <input type='number' name='distance' step={1} min={1}
                   value={distance == null ? '' : distance}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setDistance(parseInt(e.target.value))}/>
            <br/>

            <span>Warunek: </span>
            {condition ? <>
                {condition.name}
                <Af onClick={() => setCondition(null)}>Usuń warunek</Af>
            </> : 'nie ustawiono'}
            <Af onClick={() => setShowConditionSelector(true)}>Warunek</Af>
            <br/>
            <Checkbox onChange={() => setBidirectional(s => !s)} value={bidirectional}>Odcinek
                dwukierunkowy</Checkbox><br/>
            <IntermediateNavPointForm navPoints={intermediateNavPoints}
                                      onChangeIntermediateNavPoint={(inp) => setIntermediateNavPoints(inp)}/>
            <div className='diffArea'>
                <SectionSampleForm sample={sample} onChangeSample={setSample}/>
            </div>
            <Button onClick={onAdd}>Dodaj</Button>
        </div>
            {showStationSelector && <StationPopupSelector selectNavPointFun={selectNavPoint}
                                                          onClosePopup={() => setShowStationSelector(false)}/>}
        {showPlatformSelector && <PlatformPopupSelector onSelectNavPoint={selectNavPoint}
                                                        onClose={() => setShowPlatformSelector(false)}/>}
        {showConditionSelector &&
            <ConditionPopupSelector
                company={props.company}
                visibilityRule={ConditionVisibilityRuleTypeView.Section}
                closePopup={() => setShowConditionSelector(false)}
                selectConditionFun={(c) => setCondition(c)}
            />}
    </>;
}

export const prepareSectionErrorMap = () => {
    const map = new Map();
    map.set("parts_equals", () => {
        addToast('Początek odcinka nie może być zarówno jego końcem.');
    });
    map.set("section_definition_already_exist", (r: any) => {
        if (r && r.data === 'bidirectional') {
            addToast('Dla zdefiniowanych parametrów odcinka istnieje już zapisany odcinek. Odcinki odwracalne z przeciwnymi kierunkami są także uważane za duplikaty.');
        } else {
            addToast('Dla zdefiniowanych parametrów odcinka istnieje już zapisany odcinek');
        }
    });
    map.set("section_union", () => {
        addToast('Wystąpiło zagnieżdżenie czasów.');
    });
    map.set('section_sample_definition_already_exist', () => {
        addToast('Dla wybranego dnia istnieje już zadeklarowany pomiar');
    })
    return map;
}

export default AddSectionPanel;
