import Close from "../../common/ahreffun/Close";
import {
    CoursesEndpointApi,
    CourseView,
    CrewEndpointApi,
    CrewGroupView,
    CrewView,
    LineSchemaMetaDataView,
    TimetableView
} from "../../../api";
import {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import Button from "../../common/input/button/Button";
import {CrewGroupPopup} from "../../crew/group/popup/CrewGroupPopup";
import {uxNull} from "../../common/string/stringUtil";
import Af from "../../common/ahreffun/Af";
import {minutesFromNearestHours} from "../../common/input/duration/durationUtil";
import Roller from "../../common/roller/Roller";
import {AddNewCourseForm} from "../new/AddNewCourseForm";
import {CurrentUserContext} from "../../CurrentUserContext";
import {sortByPropertyValue} from "../../common/sort/sortUtil";
import {logCrewGroupUsage} from "../../condition/ajax/ajaxUtils";

interface CourseDetailsPanelProps {
    onCloseFun: () => void;
    lineSchema: LineSchemaMetaDataView;
    timetable: TimetableView;
    onShowCourseDetails: (c: CourseView) => void;
    greedyCourseVersion: number;
}

function sort(courses: Array<CourseView>) {
    const groupedByHours = new Map<number, CourseView[]>();
    courses.forEach((c) => {
        const hours = Math.floor(c.startTimeSec / 3600); // Convert seconds to hours
        if (!groupedByHours.has(hours)) {
            groupedByHours.set(hours, []);
        }
        groupedByHours.get(hours).push(c);
    });
    return [...groupedByHours.entries()].sort((a, b) => a[0] - b[0]);
}

export function CourseListPanel(props: CourseDetailsPanelProps) {
    const [crewGroups, setCrewGroups] = useState<CrewGroupView[]>();
    const [selectedCrewGroupId, setSelectedCrewGroupId] = useState<string>();
    const [courses, setCourses] = useState<CourseView[]>();
    const [showAddNewCourseRoller, setShowAddNewCourseRoller] = useState(false);
    useEffect(() => {
        new CrewEndpointApi().findCrewGroupsAssociatedWithLine({lineSchemaId: props.lineSchema.id}, {headers: prepareSecurityHeaders()})
            .then(c => setCrewGroups(c))
            .catch(e => {
                verifyClientResponse(e)
            });
    }, [props.lineSchema.id]);
    const groupedCourses = useMemo(() => {
        if (courses == null) {
            return [];
        }
        return sort(courses);
    }, [courses]);
    const selectedGroup = useMemo(() => {
        if(crewGroups){
            let group = crewGroups.find(c => c.metaData.id == selectedCrewGroupId);
            if (!group && crewGroups.length > 0){
                setSelectedCrewGroupId(crewGroups[0].metaData.id);
            }else{
                return group;
            }
        }
    }, [crewGroups, selectedCrewGroupId, props.lineSchema.id]);
    const refreshCourses = () => {
        if (!selectedGroup) {
            return;
        }
        const liabilityPointIds = selectedGroup.openness.open ? selectedGroup.openness.open.crew.map(c => c.crewLiabilityPointId) : [selectedGroup.openness.close.crewLiabilityPointId];
        new CoursesEndpointApi().searchCourseInit({courseSearchInput: {lineId: props.lineSchema.id, crewLiabilityPointIds: liabilityPointIds}}, {headers: prepareSecurityHeaders()})
            .then(r => setCourses(r))
            .catch(e => {
                verifyClientResponse(e)
            });
    }
    useEffect(() => {
        refreshCourses();
    }, [selectedGroup, props.lineSchema.id, props.greedyCourseVersion]);
    const currentUser = useContext(CurrentUserContext);
    const editionIsPossible = currentUser.user.defaultCompany.superUser && props.timetable.editable;
    return <>
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={props.onCloseFun}/></span>
            </div>
            <h3>Podgląd kursów {props.lineSchema.name}</h3>
            {props.lineSchema.technicalName} {props.lineSchema.technicalDescription}<br />
            <MainCrewSelector crewGroups={crewGroups}
                              onAddCrewGroup={(cn) => setCrewGroups(c => [...c, cn])}
                              onChangeCrewGroup={c => {
                                  setSelectedCrewGroupId(c);
                                  logCrewGroupUsage(c);
                              }}
                              selectedCrewGroupId={selectedCrewGroupId}
            />
            <CourseTable groupedCourses={groupedCourses} selectedCrewGroup={selectedGroup}
                         onShowCourseDetails={props.onShowCourseDetails}/>
            {editionIsPossible && <AddCourseRoller
                lineSchema={props.lineSchema}
                onChangeVisibility={() => setShowAddNewCourseRoller(s => !s)}
                             open={showAddNewCourseRoller} selectedCrewGroup={selectedGroup}
                onAdd={()=>refreshCourses()}/>}
        </div>
    </>;
}

interface MainCrewSelectorProps {
    crewGroups: CrewGroupView[];
    onChangeCrewGroup: (c: string) => void;
    onAddCrewGroup: (c: CrewGroupView) => void;
    selectedCrewGroupId: string;
}

function MainCrewSelector(props: MainCrewSelectorProps) {
    const [showCrewGroupPopup, setShowCrewGroupPopup] = useState(false);
    return <>
        <label>Brygada: </label>
        <select value={props.selectedCrewGroupId} onChange={e => props.onChangeCrewGroup(e.target.value)}>
            {props.crewGroups && props.crewGroups.map(c => <option key={c.metaData.id}
                                                                   value={c.metaData.id}>{c.metaData.name}</option>)}
        </select>
        <Button onClick={() => setShowCrewGroupPopup(true)}>+</Button>
        {showCrewGroupPopup && <CrewGroupPopup onClose={() => setShowCrewGroupPopup(false)}
                                               actionProvider={c => {
                                                   if (props.crewGroups.some(c2 => c2.metaData.id == c.metaData.id)) {
                                                       return uxNull();
                                                   } else {
                                                       return <Af onClick={() => {
                                                           props.onAddCrewGroup(c);
                                                           setShowCrewGroupPopup(false);
                                                           logCrewGroupUsage(c.metaData.id);
                                                       }}>Dodaj</Af>;
                                                   }
                                               }}
        />}
    </>;
}

interface TimeRowProps {
    courses: CourseView[];
    crewLiabilityPointMap: Map<string, CrewView>
    onShowCourseDetails: (c: CourseView) => void;
}

function TimeRow(props: TimeRowProps) {
    const sortedCourses = useMemo(() => {
        const result = [...props.courses];
        result.sort(sortByPropertyValue('startTimeSec'));
        return result;
    }, [props.courses]);
    return <td>{sortedCourses.map(e => <Fragment key={e.id}><CourseElementInTable course={e}
                                                                                  onClick={props.onShowCourseDetails}
                                                                                  crewLiabilityPointMap={props.crewLiabilityPointMap}/>
    </Fragment>)}</td>;
}

interface CourseTableProps {
    groupedCourses: [number, CourseView[]][];
    selectedCrewGroup: CrewGroupView;
    onShowCourseDetails: (c: CourseView) => void;
}

function CourseTable(props: CourseTableProps) {
    const crewLiabilityPointMap = useMemo(() => {
        if (props.selectedCrewGroup) {
            if (props.selectedCrewGroup.openness.open) {
                const resultMap = new Map<string, CrewView>();
                props.selectedCrewGroup.openness.open.crew.forEach(c => resultMap.set(c.crewLiabilityPointId, c));
                return resultMap;
            }
        }
        return new Map<string, CrewView>();
    }, [props.selectedCrewGroup]);
    if (!props.groupedCourses) {
        return <p>Ładowanie...</p>;
    }
    return <table>
        <thead>
        <tr className='tableHeader'>
            <th colSpan={2}>Kursy</th>
        </tr>
        </thead>
        <tbody>
        {props.groupedCourses.map(g => <tr key={g[0]} className={'ignoreDefaultTableHover'}>
            <td className={'bgColor3'} style={{fontSize: '1.5em'}}>{g[0]}</td>
            <TimeRow courses={g[1]} crewLiabilityPointMap={crewLiabilityPointMap}
                     onShowCourseDetails={props.onShowCourseDetails}/>
        </tr>)}
        </tbody>
    </table>;
}

interface CourseElementInTableProps {
    course: CourseView;
    crewLiabilityPointMap: Map<string, CrewView>;
    onClick: (c: CourseView) => void;
}

function CourseElementInTable(props: CourseElementInTableProps) {
    const crew = props.crewLiabilityPointMap.get(props.course.crewLiabilityPointId);
    if (crew) {
        return <span onClick={() => props.onClick(props.course)} className={'courseElementInTable'}>
                        <span style={{fontSize: '1.2em'}}>{minutesFromNearestHours(props.course.startTimeSec)}</span>
                        <span style={{fontSize: '0.8em', textAlign: 'center'}}>{crew.name}</span>
                    </span>;
    } else {
        return <span onClick={() => props.onClick(props.course)} className={'courseElementInTable'}>
                        <span style={{fontSize: '1.3em'}}>{minutesFromNearestHours(props.course.startTimeSec)}</span>
        </span>;
    }
}

interface AddCourseRollerProps {
    selectedCrewGroup: CrewGroupView;
    lineSchema: LineSchemaMetaDataView;
    onChangeVisibility: () => void;
    open: boolean;
    onAdd: ()=>void;
}

function AddCourseRoller(props: AddCourseRollerProps) {
    if (!props.selectedCrewGroup){
        return null;
    }
    return <Roller title={'Dodawanie kursów'} open={props.open} onChangeVisibility={props.onChangeVisibility}>
        <AddNewCourseForm crewGroup={props.selectedCrewGroup} lineSchema={props.lineSchema} onAdd={props.onAdd}/>
    </Roller>
}
