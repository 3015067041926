/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionVisibilityRuleTypeView } from './ConditionVisibilityRuleTypeView';
import {
    ConditionVisibilityRuleTypeViewFromJSON,
    ConditionVisibilityRuleTypeViewFromJSONTyped,
    ConditionVisibilityRuleTypeViewToJSON,
} from './ConditionVisibilityRuleTypeView';

/**
 * 
 * @export
 * @interface ConditionVisibilityRuleView
 */
export interface ConditionVisibilityRuleView {
    /**
     * 
     * @type {Set<ConditionVisibilityRuleTypeView>}
     * @memberof ConditionVisibilityRuleView
     */
    rules?: Set<ConditionVisibilityRuleTypeView>;
}

/**
 * Check if a given object implements the ConditionVisibilityRuleView interface.
 */
export function instanceOfConditionVisibilityRuleView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConditionVisibilityRuleViewFromJSON(json: any): ConditionVisibilityRuleView {
    return ConditionVisibilityRuleViewFromJSONTyped(json, false);
}

export function ConditionVisibilityRuleViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionVisibilityRuleView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rules': !exists(json, 'rules') ? undefined : (new Set((json['rules'] as Array<any>).map(ConditionVisibilityRuleTypeViewFromJSON))),
    };
}

export function ConditionVisibilityRuleViewToJSON(value?: ConditionVisibilityRuleView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rules': value.rules === undefined ? undefined : (Array.from(value.rules as Set<any>).map(ConditionVisibilityRuleTypeViewToJSON)),
    };
}

