import {ReactNode, useEffect, useState} from "react";
import {PlatformTable} from "../../platform/table/PlatformTable";
import {NavPointMetaDataView, NavPointType} from "../../../api";
import Af from "../../common/ahreffun/Af";
import AddNewPlatformForm from "../../platform/new/AddNewPlatformForm";
import {hasSuperPermissionInSpace} from "../../common/superuser/permissionUtil";
import Button from "../../common/input/button/Button";
import {AddNewStationForm} from "../new/AddNewStationForm";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {PlatformTableCriteria} from "../../platform/table/PlatformTableCriteria";

interface StationTabSectionPlatformProps {
    mzkWorkspace: MzkWorkspace
    query: string;
    onChangeQuery: (query: string) => void;
    showNewStationForm: boolean;
    toggleShowNewStationFormFun: () => void;
    increaseGreedyPlatformVersion: () => void;
    greedyPlatformVersion: number;
    onShowNavPointUsage: (navPoint: NavPointMetaDataView) => void;
    onChangeNavPointIdForStationDetails: (navPointId: string) => void;
    onChangeNavPointIdForPlatformDetails: (navPointId: string) => void;
    onShowSection: (navPointId: string) => void;
}

export function StationTabSectionPlatform(props: StationTabSectionPlatformProps) {
    const [stationForNewPlatform, setStationForNewPlatform] = useState<NavPointMetaDataView>();
    useEffect(() => {
        setStationForNewPlatform(null);
    }, [props.mzkWorkspace]);
    const newPlatformForm = (): ReactNode => {
        return (
            stationForNewPlatform != null && (
                <AddNewPlatformForm
                    station={stationForNewPlatform}
                    mzkWorkspace={props.mzkWorkspace}
                    hideNewPlatformFormFun={(r) => {
                        setStationForNewPlatform(null);
                        r && props.increaseGreedyPlatformVersion();
                    }}
                />
            )
        );
    }
    const newStationForm = (): ReactNode => {
        return (
            props.showNewStationForm && (
                <AddNewStationForm
                    mzkWorkspace={props.mzkWorkspace}
                    onClose={(refresh) => {
                        props.toggleShowNewStationFormFun();
                        refresh && props.increaseGreedyPlatformVersion();
                    }}
                />
            )
        );
    }
    const actionProvider = (): (metaData: NavPointMetaDataView) => ReactNode => {
        const superPermissionInSpace = hasSuperPermissionInSpace(props.mzkWorkspace);
        return (metaData: NavPointMetaDataView): ReactNode => {
            const actions: ReactNode[] = [];
            if (metaData.type === NavPointType.Station) {
                if (superPermissionInSpace) {
                    actions.push(<Af key={'NEW_PLATFORM'} onClick={() => {
                        setStationForNewPlatform(metaData);
                    }}>Nowa platforma
                    </Af>);
                }
                actions.push(<Af key={'STATION_DETAILS'}
                                 onClick={() => props.onChangeNavPointIdForStationDetails(metaData.id)}>Szczegóły</Af>);
            } else {
                actions.push(<Af key={'PLATFORM_DETAILS'}
                                 onClick={() => props.onChangeNavPointIdForPlatformDetails(metaData.id)}>Szczegóły</Af>);
            }
            actions.push(<Af key={'SECTIONS'} onClick={() => props.onShowSection(metaData.id)}>Odcinki</Af>);
            actions.push(<Af key={'USAGES'} onClick={() => props.onShowNavPointUsage(metaData)}>Użycia</Af>);
            return actions;
        };
    }
    const newStationButton = (): ReactNode => {
        return (
            hasSuperPermissionInSpace(props.mzkWorkspace) && (
                <Button onClick={props.toggleShowNewStationFormFun}>
                    Stwórz nową stację
                </Button>
            )
        );
    }
    return (
        <>
            <div>
                <h3>Wyszukiwarka peronów</h3>
                <PlatformTableCriteria
                    onDataUpdated={(q: string) => props.onChangeQuery(q)}
                    query={props.query}
                />
                <PlatformTable
                    mzkWorkspace={props.mzkWorkspace}
                    query={props.query}
                    actionProviderFun={actionProvider()}
                    limit={6}
                    greedyPlatformVersion={props.greedyPlatformVersion}
                />
                {newStationButton()}
            </div>
            {newPlatformForm()}
            {newStationForm()}
        </>
    );
}
