/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CrewGroupMetaDataView
 */
export interface CrewGroupMetaDataView {
    /**
     * 
     * @type {string}
     * @memberof CrewGroupMetaDataView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CrewGroupMetaDataView
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CrewGroupMetaDataView
     */
    selectorType: CrewGroupMetaDataViewSelectorTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CrewGroupMetaDataView
     */
    open: boolean;
    /**
     * 
     * @type {string}
     * @memberof CrewGroupMetaDataView
     */
    companyId: string;
}


/**
 * @export
 */
export const CrewGroupMetaDataViewSelectorTypeEnum = {
    Date: 'DATE',
    Condition: 'CONDITION',
    None: 'NONE'
} as const;
export type CrewGroupMetaDataViewSelectorTypeEnum = typeof CrewGroupMetaDataViewSelectorTypeEnum[keyof typeof CrewGroupMetaDataViewSelectorTypeEnum];


/**
 * Check if a given object implements the CrewGroupMetaDataView interface.
 */
export function instanceOfCrewGroupMetaDataView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "selectorType" in value;
    isInstance = isInstance && "open" in value;
    isInstance = isInstance && "companyId" in value;

    return isInstance;
}

export function CrewGroupMetaDataViewFromJSON(json: any): CrewGroupMetaDataView {
    return CrewGroupMetaDataViewFromJSONTyped(json, false);
}

export function CrewGroupMetaDataViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrewGroupMetaDataView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'selectorType': json['selectorType'],
        'open': json['open'],
        'companyId': json['companyId'],
    };
}

export function CrewGroupMetaDataViewToJSON(value?: CrewGroupMetaDataView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'selectorType': value.selectorType,
        'open': value.open,
        'companyId': value.companyId,
    };
}

