import {FormEvent, useState} from 'react';
import Close from "../../common/ahreffun/Close";
import Checkbox from "../../common/input/checkbox/Checkbox";
import Button from "../../common/input/button/Button";
import {addToast} from "../../common/toast/toastUtil";
import Af from "../../common/ahreffun/Af";
import {CreateNewVariableValueInput, VariableEndpointApi} from "../../../api";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";

interface NewVariableFormProps {
    closeFun: () => void;
    company: Company;
}

function NewVariableForm(props: NewVariableFormProps) {
    const [name, setName] = useState('');
    const [values, setValues] = useState<CreateNewVariableValueInput[]>([]);
    const onSave = (e: FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            new VariableEndpointApi().createNewVariable({
                createNewVariableInput: {
                    name: name,
                    values: values,
                    companyId: props.company.id
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano nową zmienną');
                    props.closeFun();
                })
                .catch(e => verifyClientResponse(e, getErrorMap()))
        }
    }
    const validateForm = () => {
        if (name.length < 1) {
            addToast('Uzupełnij nazwę');
            return false;
        } else if (values.length < 1) {
            addToast('Zmienna powinna posiadać minimum jedną wartość')
            return false;
        }
        return true;
    }
    const changeDefaultFor = (v: CreateNewVariableValueInput) => {
        setValues(s => {
            const val = [...s];
            if (val.length === 1) {
                addToast('Wartość domyślna musi być zaznaczona na którymś z pól');
            } else if (v._default) {
                v._default = false;
                for (let i = 0; i < val.length; i++) {
                    const current = val[i];
                    if (current.description !== v.description) {
                        current._default = true;
                        break;
                    }
                }
            } else {
                val.forEach(v => v._default = false);
                v._default = true;
            }
            return val;
        })
    }
    const addNewValue = (e: FormEvent) => {
        e.preventDefault();
        const result = prompt('Wpisz nową wartość:');
        if (result) {
            setValues(s => {
                const val = [...s];
                if (!val.some(v => v.description === result)) {
                    if (val.length) {
                        val.push({description: result, _default: false});
                    } else {
                        val.push({description: result, _default: true});
                    }
                }
                return val;
            })
        }
    }
    const removeValue = (index: number) => {
        setValues(s => {
            const values = [...s];
            const removed = values.splice(index, 1)[0];
            if (removed._default && s.values.length > 0) {
                values[0] = {...values[0], _default: true}
            }
            return values;
        })
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={props.closeFun}/></span>
            </div>
            <h3>Dodawanie nowej zmiennej {name}</h3>
            <form>
                <label>Nazwa*:</label>
                <input name='name' value={name} onChange={e => setName(e.target.value)}
                       maxLength={50}/>
                <br/>
                <label>Wartości</label>
                <table>
                    <thead>
                    <tr className='tableHeader'>
                        <th>Nazwa</th>
                        <th>Domyślny</th>
                        <th>Akcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {values.map((v, index) => <tr key={v.description}>
                        <td>{v.description}</td>
                        <td><Checkbox value={v._default} onChange={() => changeDefaultFor(v)}><span/></Checkbox></td>
                        <td><Af onClick={() => removeValue(index)}>Usuń</Af></td>
                    </tr>)}
                    </tbody>
                </table>
                <Button onClick={addNewValue}>Nowa wartość</Button>
                <br/>
                <Button onClick={onSave}>Dodaj</Button>
            </form>
        </div>
    );
}

function getErrorMap() {
    const map = new Map<string, (r: any) => void>();
    map.set("value_description", () => {
        addToast('Zmienna musi posiadać <1, 10> unikalnych wartości.');
    });
    map.set("uniqueness", () => {
        addToast('Zmienna z taką nazwą już istnieje.');
    });
    map.set("default_value", () => {
        addToast('Zmienna powinna zawierać domyślną wartość.');
    });
    return map;
}

export default NewVariableForm;
