import * as React from 'react';
import {ChangeEvent, useEffect, useState} from 'react';
import Help from "../../common/tooltip/Help";
import StreetTable from "../table/StreetTable";
import Af from "../../common/ahreffun/Af";
import {getStreetPreview, getStreets} from "../ajax/streetAjax";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {StreetEndpointApi, StreetSearchView} from "../../../api";
import Popup from "../../common/popup/Popup";
import {verifyClientResponse} from "../../common/error/resolvers";

interface StreetPopupSelectorProps {
    closePopup: () => void;
    onSelectStreet: (street: StreetSearchView) => void;
    mzkWorkspace: MzkWorkspace
}

function StreetPopupSelector(props: StreetPopupSelectorProps) {
    const [query, setQuery] = useState('');
    const [streets, setStreets] = useState<StreetSearchView[]>([]);
    const [appliedLimit, setAppliedLimit] = useState(false);
    useEffect(() => {
        if (query.length > 1) {
            getStreets(query, props.mzkWorkspace, null, (r: StreetSearchView[]) => {
                setStreets(r);
                setAppliedLimit(false);
            });
        }else{
            getStreetPreview(props.mzkWorkspace, r => {
                setStreets(r.streets);
                setAppliedLimit(r.appliedLimit);
            })
        }
    }, [query]);
    return (
        <Popup closePopup={props.closePopup}>
            <h1>Wybór ulicy</h1>
            <label>Nazwa*:</label>
            <input onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                   value={query}
                   placeholder="nazwa"/>
            <label> <Help>Minimum dwa znaki</Help></label>
            <StreetTable streetData={streets}
                         appliedLimit={appliedLimit}
                         actionProvider={(s: StreetSearchView) => <Af
                onClick={() => {
                    props.onSelectStreet(s);
                    new StreetEndpointApi()
                        .logStreetUsage({usageInput: {id: s.street.id}},
                            {headers: prepareSecurityHeaders()})
                        .catch(e => verifyClientResponse(e));
                }}>Wybierz</Af>}/>
        </Popup>
    );
}

export default StreetPopupSelector;
