import {useRef, useState} from 'react';
import Af from "../../common/ahreffun/Af";
import {StationTable} from "../table/StationTable";
import SpaceForCurrentCompanySwitcher from "../../space/switch/SpaceForCurrentCompanySwitcher";
import Popup from "../../common/popup/Popup";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {NavPointMetaDataView} from "../../../api";
import {logNavPointUsage} from "../../navpoint/ajax/ajaxUtils";
import {StationTableCriteria} from "../table/StationTableCriteria";

interface StationPopupSelectorProps {
    onClosePopup: () => void;
    selectNavPointFun: (metaData: NavPointMetaDataView) => void;
}

export function StationPopupSelector(props: StationPopupSelectorProps) {
    const [mzkWorkspace, setMzkWorkspace] = useState<MzkWorkspace>();
    const [query, setQuery] = useState('');
    const [forceSearch, setForceSearch] = useState(false);
    const queryRef = useRef<HTMLInputElement>();
    return (
        <Popup closePopup={props.onClosePopup}>
            <h1>Wybór stacji</h1>
            <SpaceForCurrentCompanySwitcher
                mzkWorkspace={mzkWorkspace}
                onChangeCompanyAndSpaceFun={(c, s) => setMzkWorkspace({company: c, space: s})}
            />
            <br/>
            <StationTableCriteria
                query={query}
                onDataUpdated={(q, fs)=>{
                    setQuery(q);
                    setForceSearch(fs);
                }}
                ref={queryRef}
            />
            <StationTable mzkWorkspace={mzkWorkspace}
                          query={query}
                          onChangeQuery={q => setQuery(q)}
                          greedyStationVersion={0}
                          forceSearch={forceSearch}
                          limit={10}
                          actionProviderFun={(p) => <Af
                              onClick={() => {
                                  queryRef.current.focus();
                                  queryRef.current.select();
                                  props.selectNavPointFun(p.metaData);
                                  logNavPointUsage(p.metaData.id);
                              }}>Użyj</Af>}
            />
        </Popup>
    );
}
