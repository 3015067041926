/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StreetView } from './StreetView';
import {
    StreetViewFromJSON,
    StreetViewFromJSONTyped,
    StreetViewToJSON,
} from './StreetView';
import type { TownView } from './TownView';
import {
    TownViewFromJSON,
    TownViewFromJSONTyped,
    TownViewToJSON,
} from './TownView';

/**
 * 
 * @export
 * @interface StreetSearchView
 */
export interface StreetSearchView {
    /**
     * 
     * @type {StreetView}
     * @memberof StreetSearchView
     */
    street?: StreetView;
    /**
     * 
     * @type {TownView}
     * @memberof StreetSearchView
     */
    town?: TownView;
}

/**
 * Check if a given object implements the StreetSearchView interface.
 */
export function instanceOfStreetSearchView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StreetSearchViewFromJSON(json: any): StreetSearchView {
    return StreetSearchViewFromJSONTyped(json, false);
}

export function StreetSearchViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreetSearchView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'street': !exists(json, 'street') ? undefined : StreetViewFromJSON(json['street']),
        'town': !exists(json, 'town') ? undefined : TownViewFromJSON(json['town']),
    };
}

export function StreetSearchViewToJSON(value?: StreetSearchView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'street': StreetViewToJSON(value.street),
        'town': TownViewToJSON(value.town),
    };
}

