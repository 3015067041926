/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateNewVariableValueInput
 */
export interface CreateNewVariableValueInput {
    /**
     * 
     * @type {string}
     * @memberof CreateNewVariableValueInput
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNewVariableValueInput
     */
    _default?: boolean;
}

/**
 * Check if a given object implements the CreateNewVariableValueInput interface.
 */
export function instanceOfCreateNewVariableValueInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateNewVariableValueInputFromJSON(json: any): CreateNewVariableValueInput {
    return CreateNewVariableValueInputFromJSONTyped(json, false);
}

export function CreateNewVariableValueInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNewVariableValueInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
    };
}

export function CreateNewVariableValueInputToJSON(value?: CreateNewVariableValueInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'default': value._default,
    };
}

