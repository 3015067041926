import {useState} from 'react';
import Roller from "../../common/roller/Roller";
import SpaceForCurrentCompanySwitcher from "../../space/switch/SpaceForCurrentCompanySwitcher";
import {PlatformTable} from "../../platform/table/PlatformTable";
import Af from "../../common/ahreffun/Af";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {NavPointMetaDataView} from "../../../api";
import {logNavPointUsage} from "../../navpoint/ajax/ajaxUtils";
import {PlatformTableCriteria} from "../../platform/table/PlatformTableCriteria";

interface SectionPlatformActionSetterProps {
    open: boolean;
    onChangeVisibility: () => void;
    setSearchNavPointFun: (navPoint: NavPointMetaDataView) => void;
}

function SectionPlatformActionSetter(props: SectionPlatformActionSetterProps) {
    const [mzkWorkspace, setMzkWorkspace] = useState<MzkWorkspace>();
    const [query, setQuery] = useState('');
    return (
        <Roller
            open={props.open}
            onChangeVisibility={props.onChangeVisibility}
            title='Wybierz peron'>
            <SpaceForCurrentCompanySwitcher
                mzkWorkspace={mzkWorkspace}
                onChangeCompanyAndSpaceFun={(c, s) => setMzkWorkspace({space: s, company: c})}
            />
            <br/>
            <PlatformTableCriteria
                onDataUpdated={setQuery}
                query={query}
            />
            <PlatformTable
                mzkWorkspace={mzkWorkspace}
                query={query}
                greedyPlatformVersion={0}
                limit={6}
                actionProviderFun={(navPoint) => <Af
                    onClick={() => {
                        props.setSearchNavPointFun(navPoint);
                        logNavPointUsage(navPoint.id);
                    }
                }>Użyj</Af>}
            />
        </Roller>
    );
}

export default SectionPlatformActionSetter;
