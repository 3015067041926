/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddZoneInput,
  EditZoneInput,
  UsageInput,
  ZoneSearchInput,
  ZoneView,
} from '../models';
import {
    AddZoneInputFromJSON,
    AddZoneInputToJSON,
    EditZoneInputFromJSON,
    EditZoneInputToJSON,
    UsageInputFromJSON,
    UsageInputToJSON,
    ZoneSearchInputFromJSON,
    ZoneSearchInputToJSON,
    ZoneViewFromJSON,
    ZoneViewToJSON,
} from '../models';

export interface DeleteZoneRequest {
    zoneId: string;
}

export interface GetZoneRequest {
    zoneId: string;
}

export interface InitSearchRequest {
    zoneSearchInput: ZoneSearchInput;
}

export interface LogZoneUsageRequest {
    usageInput?: UsageInput;
}

export interface SaveZoneRequest {
    addZoneInput: AddZoneInput;
}

export interface UpdateZoneRequest {
    editZoneInput: EditZoneInput;
}

/**
 * 
 */
export class ZoneEndpointApi extends runtime.BaseAPI {

    /**
     */
    async deleteZoneRaw(requestParameters: DeleteZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.zoneId === null || requestParameters.zoneId === undefined) {
            throw new runtime.RequiredError('zoneId','Required parameter requestParameters.zoneId was null or undefined when calling deleteZone.');
        }

        const queryParameters: any = {};

        if (requestParameters.zoneId !== undefined) {
            queryParameters['zoneId'] = requestParameters.zoneId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zones`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteZone(requestParameters: DeleteZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteZoneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getZoneRaw(requestParameters: GetZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ZoneView>> {
        if (requestParameters.zoneId === null || requestParameters.zoneId === undefined) {
            throw new runtime.RequiredError('zoneId','Required parameter requestParameters.zoneId was null or undefined when calling getZone.');
        }

        const queryParameters: any = {};

        if (requestParameters.zoneId !== undefined) {
            queryParameters['zoneId'] = requestParameters.zoneId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/zones`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZoneViewFromJSON(jsonValue));
    }

    /**
     */
    async getZone(requestParameters: GetZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ZoneView> {
        const response = await this.getZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async initSearchRaw(requestParameters: InitSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ZoneView>>> {
        if (requestParameters.zoneSearchInput === null || requestParameters.zoneSearchInput === undefined) {
            throw new runtime.RequiredError('zoneSearchInput','Required parameter requestParameters.zoneSearchInput was null or undefined when calling initSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/zones/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ZoneSearchInputToJSON(requestParameters.zoneSearchInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ZoneViewFromJSON));
    }

    /**
     */
    async initSearch(requestParameters: InitSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ZoneView>> {
        const response = await this.initSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logZoneUsageRaw(requestParameters: LogZoneUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/zones/usages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsageInputToJSON(requestParameters.usageInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logZoneUsage(requestParameters: LogZoneUsageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logZoneUsageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveZoneRaw(requestParameters: SaveZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.addZoneInput === null || requestParameters.addZoneInput === undefined) {
            throw new runtime.RequiredError('addZoneInput','Required parameter requestParameters.addZoneInput was null or undefined when calling saveZone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/zones`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddZoneInputToJSON(requestParameters.addZoneInput),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async saveZone(requestParameters: SaveZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.saveZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateZoneRaw(requestParameters: UpdateZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.editZoneInput === null || requestParameters.editZoneInput === undefined) {
            throw new runtime.RequiredError('editZoneInput','Required parameter requestParameters.editZoneInput was null or undefined when calling updateZone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/zones`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditZoneInputToJSON(requestParameters.editZoneInput),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async updateZone(requestParameters: UpdateZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateZoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
