import {
    AdoptedNavPoint,
    CoursesEndpointApi,
    CourseTimeDefaultTimeView,
    CourseTimeOverriddenTimeView,
    CourseView,
    OverriddenTimesCoursesEndpointApi,
    TimetableView
} from "../../../api";
import Close from "../../common/ahreffun/Close";
import {secondsToFrontDuration, secondsToTime} from "../../common/input/duration/durationUtil";
import {useContext, useEffect, useState} from "react";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {CurrentUserContext} from "../../CurrentUserContext";
import Button from "../../common/input/button/Button";
import {prepareName} from "../../common/navpoint/navpointUtil";
import {uxNull} from "../../common/string/stringUtil";
import Help from "../../common/tooltip/Help";
import Checkbox from "../../common/input/checkbox/Checkbox";
import DurationPicker from "../../common/input/duration/DurationPicker";
import {addToast} from "../../common/toast/toastUtil";

interface CourseDetailsPanelProps {
    course: CourseView;
    onClose: () => void;
    onCourseRemoved: () => void;
    timetable: TimetableView;
}

export function CourseDetailsPanel(props: CourseDetailsPanelProps) {
    const [times, setTimes] = useState<CourseTime[]>(null);
    const [examinedDate] = useState(new Date());
    const currentUser = useContext(CurrentUserContext);
    const isEditable = currentUser.user.defaultCompany.superUser && props.timetable.editable;
    const refreshTimes = () =>{
        setTimes(null);
        new CoursesEndpointApi()
            .calculateTimesForCourse({
                courseSearchTimeInput: {
                    courseId: props.course.id,
                    examinedDate: examinedDate
                }
            }, {headers: prepareSecurityHeaders()})
            .then(r => {
                setTimes(r.map(t => new CourseTime(t.navPoint, t.defaultTime, t.overriddenTime, t.planPartId, false)));
            })
            .catch(e => verifyClientResponse(e));
    }
    useEffect(() => {
       refreshTimes();
    }, [props.course.id]);
    const onCourseRemove = () => {
        new CoursesEndpointApi().deleteCourse({courseId: props.course.id}, {headers: prepareSecurityHeaders()})
            .then(() => {
                addToast('Pomyślnie usunięto kurs');
                props.onCourseRemoved();
            }).catch(e => verifyClientResponse(e));
    }
    const onSaveTimeOverriding = () => {
        if (times.some(t => t.overriddenTime != null && (t.overriddenTime.waitingSec < 0 || t.overriddenTime.travelSec < 0))){
            addToast('Czasy nie mogą być ujemne');
        } else {
            new OverriddenTimesCoursesEndpointApi().saveOverriddenTimes({
                courseOverriddenTimesInput: {
                    courseId: props.course.id,
                    times: times.filter(t => t.isModified).map(t => ({
                        planPartId: t.planPartId,
                        travelSec: t.overriddenTime.travelSec,
                        waitingSec: t.overriddenTime.waitingSec
                    }))
                }}, {headers: prepareSecurityHeaders()})
                .then(()=>{
                    addToast('Pomyślnie zaktualizowano czasy');
                    refreshTimes();
                }).catch(e => verifyClientResponse(e));
        }
    };
    return <>
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={props.onClose}/></span>
            </div>
            <h3>Szczegóły kursu - {secondsToTime(props.course.startTimeSec)}</h3>
            <TimeTable times={times} examinedDate={examinedDate} course={props.course} isEditable={isEditable}
                       onChangeCourse={(i, t) => {
                           setTimes(ta => {
                               const results = [...ta];
                               results[i] = t;
                               return results;
                           })
                       }}/>
            {times && times.some(t => t.isModified) &&
                <Button onClick={() => onSaveTimeOverriding()}>Zapisz nadpisywania</Button>}
            {isEditable && <Button onClick={() => onCourseRemove()}>Usuń kurs</Button>}
        </div>
    </>;
}

interface TimeTableProps {
    times: CourseTime[];
    examinedDate: Date;
    course: CourseView;
    isEditable: boolean
    onChangeCourse: (index: number, t: CourseTime) => void;
}

function TimeTable(props: TimeTableProps) {
    if (!props.times) {
        return <p>Ładowanie odjazdów</p>;
    }
    return <table>
        <thead>
        <tr className='tableHeader'>
            <th rowSpan={2}>Lp</th>
            <th colSpan={2} rowSpan={2}>Węzły</th>
            <th colSpan={2}>{props.examinedDate.toLocaleDateString()}</th>
            <th rowSpan={2}>Nadp. czas oczekiwania</th>
            <th rowSpan={2}>Nadp. czas jazdy<Help>Nadpisywanie czasów jazdy powinno odbywać się przez warunki</Help>
            </th>
            <th rowSpan={2}>M</th>
        </tr>
        <tr className='tableHeader'>
            <th>Przyjazd</th>
            <th>Odjazd</th>
        </tr>
        </thead>
        <tbody>
        <TimeTableRows times={props.times} course={props.course} isEditable={props.isEditable}
                       onChangeCourse={props.onChangeCourse}/>
        </tbody>
    </table>;
}

interface TimeTableRowsProps {
    times: CourseTime[];
    course: CourseView;
    isEditable: boolean;
    onChangeCourse: (index: number, t: CourseTime) => void;
}

function TimeTableRows(props: TimeTableRowsProps) {
    const out = [];
    let currentSeconds = props.course.startTimeSec;
    for (let i = 0; i < props.times.length; i++) {
        let t = props.times[i];
        if (i == 0) {
            out.push(<tr key={i}>
                <NavPointMetaTd t={t} index={i}/>
                <td colSpan={2}>{secondsToTime(currentSeconds)}</td>
                <td colSpan={3}>{uxNull()}</td>
            </tr>);
        } else {
            const [travelTime, waitingTime] = resolveTime(t);
            const withTravelTime = currentSeconds + travelTime;
            const withWaitingTime = withTravelTime + waitingTime;
            out.push(<tr key={i}>
                <NavPointMetaTd t={t} index={i}/>
                <NavPointCollectedTimeTd withTravelTime={withTravelTime} withWaitingTime={withWaitingTime}/>
                <OverridingWaitingTimeTd t={t} isEditable={props.isEditable}
                                         onChange={(c) => props.onChangeCourse(i, c)}/>
                <td>{t.overriddenTime && t.overriddenTime.travelSec ? secondsToFrontDuration(t.overriddenTime.travelSec) : uxNull()}</td>
                <td>{t.isModified && 'M'}</td>
            </tr>);
            currentSeconds = withWaitingTime;
        }
    }
    return out;
}

function resolveTime(t: CourseTime) {
    let travelTime = 0;
    let waitingTime = 0;
    if (t.defaultTime) {
        travelTime = t.defaultTime.travelSec;
        if (t.defaultTime.waitingSec) {
            waitingTime = t.defaultTime.waitingSec;
        }
    }
    if (t.overriddenTime) {
        if (t.overriddenTime.travelSec) {
            travelTime = t.overriddenTime.travelSec;
        }
        if (t.overriddenTime.waitingSec) {
            waitingTime = t.overriddenTime.waitingSec;
        }
    }
    return [travelTime, waitingTime];
}

interface NavPointMetaTdProps {
    t: CourseTime;
    index: number;
}

function NavPointMetaTd(props: NavPointMetaTdProps) {
    return <>
        <td>{props.index}</td>
        {props.t.navPoint.navPointMetaDataBaseParent ? <>
                <td>{prepareName(props.t.navPoint.navPointMetaDataBaseParent)}</td>
                <td>{prepareName(props.t.navPoint.navPointMetaData)}</td>
            </> :
            <td colSpan={2}>{prepareName(props.t.navPoint.navPointMetaData)}</td>}
    </>;
}

interface NavPointCollectedTimeTdProps {
    withTravelTime: number;
    withWaitingTime: number;
}

function NavPointCollectedTimeTd(props: NavPointCollectedTimeTdProps) {
    if (props.withTravelTime == props.withWaitingTime) {
        return <td colSpan={2}>{secondsToTime(props.withWaitingTime)}</td>;
    }
    return <>
        <td>{secondsToTime(props.withTravelTime)}</td>
        <td>{secondsToTime(props.withWaitingTime)}</td>
    </>;
}

interface OverridingWaitingTimeTdProps {
    t: CourseTime;
    isEditable: boolean;
    onChange: (t: CourseTime) => void;
}

function OverridingWaitingTimeTd(props: OverridingWaitingTimeTdProps) {
    if (props.isEditable) {
        const overriddenTime = props.t.overriddenTime && props.t.overriddenTime.waitingSec;
        if (typeof overriddenTime === 'number') {
            return <td>
                <Checkbox onChange={() => props.onChange({
                    ...props.t,
                    overriddenTime: {waitingSec: null, travelSec: null},
                    isModified: true
                })}
                          value={true}>{}</Checkbox>
                <DurationPicker duration={overriddenTime} onDurationChange={(d) => {
                    props.onChange({
                        ...props.t,
                        overriddenTime: {...props.t.overriddenTime, waitingSec: d},
                        isModified: true
                    });
                }} negativeValueAllowed={false}/></td>
        } else {
            return <td><Checkbox onChange={() => {
                props.onChange({
                    ...props.t, overriddenTime: {
                        waitingSec: props.t.defaultTime.waitingSec ? props.t.defaultTime.waitingSec : 0,
                        travelSec: props.t.overriddenTime && props.t.overriddenTime.travelSec ? props.t.overriddenTime.travelSec : null
                    }, isModified: true
                })
            }} value={false}>{}</Checkbox></td>
        }
    } else {
        return <td>{props.t.overriddenTime && props.t.overriddenTime.waitingSec ? secondsToFrontDuration(props.t.overriddenTime.waitingSec) : uxNull()}</td>;
    }
}

class CourseTime {
    navPoint: AdoptedNavPoint;
    defaultTime?: CourseTimeDefaultTimeView;
    overriddenTime?: CourseTimeOverriddenTimeView;
    planPartId: string;
    isModified: boolean

    constructor(navPoint: AdoptedNavPoint, defaultTime: CourseTimeDefaultTimeView, overriddenTime: CourseTimeOverriddenTimeView, planPartId: string, isModified: boolean) {
        this.navPoint = navPoint;
        this.defaultTime = defaultTime;
        this.overriddenTime = overriddenTime;
        this.planPartId = planPartId;
        this.isModified = isModified;
    }
}
