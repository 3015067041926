import {CoursesEndpointApi, CrewGroupView, CrewView, LineSchemaMetaDataView} from "../../../api";
import {useEffect, useRef, useState} from "react";
import DurationPicker from "../../common/input/duration/DurationPicker";
import Button from "../../common/input/button/Button";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {addToast} from "../../common/toast/toastUtil";
import {verifyClientResponse} from "../../common/error/resolvers";

interface AddNewCourseFormProps {
    crewGroup: CrewGroupView;
    lineSchema: LineSchemaMetaDataView;
    onAdd: () => void;
}

export function AddNewCourseForm(props: AddNewCourseFormProps) {
    const [selectedCrew, setSelectedCrew] = useState<CrewView>();
    const [duration, setDuration] = useState<number>(0);
    const durationRef = useRef<HTMLInputElement>();
    useEffect(() => {
        if (props.crewGroup && props.crewGroup.openness.open) {
            setSelectedCrew(props.crewGroup.openness.open.crew[0]);
        } else {
            setSelectedCrew(null);
        }
    }, [props.crewGroup]);
    const verifyForm = () => {
        if (!props.crewGroup.openness.close && !selectedCrew){
            addToast('Wybierz brygadę');
            return false;
        }
        if (duration < -259200 || duration > 259200){ // 3 days
            addToast('Wpisz poprawny czas rozpoczęcia');
            return false;
        }
        return true;
    }
    const onAdd = () => {
        if (verifyForm()) {
            new CoursesEndpointApi().createNewCourse({
                courseInput: {
                    lineId: props.lineSchema.id,
                    startTimeSec: duration,
                    crewLiabilityPointId: props.crewGroup.openness.close ? props.crewGroup.openness.close.crewLiabilityPointId : selectedCrew.crewLiabilityPointId
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano nowy kurs');
                    props.onAdd();
                    durationRef.current.focus();
                    durationRef.current.select();
                }).catch(e => verifyClientResponse(e));
        }
    };
    return <form>
        <CrewSelector selectedCrew={selectedCrew} crewGroup={props.crewGroup} onSelect={(c) => setSelectedCrew(c)}/>
        <label>Godzina rozpoczęcia:</label>
        <DurationPicker duration={duration} onDurationChange={setDuration} negativeValueAllowed={true} ref={durationRef}/><br/>
        <Button onClick={onAdd}>Dodaj</Button>
    </form>;
}

interface CrewSelectorProps {
    selectedCrew: CrewView;
    crewGroup: CrewGroupView;
    onSelect: (c: CrewView) => void;
}

function CrewSelector(props: CrewSelectorProps) {
    if (!props.crewGroup.openness.open) {
        return;
    }
    return <>
        <label>Brygada: </label>
        <select value={props.selectedCrew && props.selectedCrew.id}
                onChange={e => props.onSelect(props.crewGroup.openness.open.crew.find(c => c.id == e.target.value))}>
            {props.crewGroup.openness.open.crew.map(c => <option key={c.id}>{c.name}</option>)}
        </select>
        <br/>
    </>
}
