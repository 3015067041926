import {ReactNode, useEffect, useState} from 'react';
import SectionStationActionSetter from "./SectionStationActionSetter";
import SectionPlatformActionSetter from "./SectionPlatformActionSetter";
import {
    AdoptedNavPoint, JammedSectionView,
    NavpointEndpointApi,
    NavPointMetaDataView,
    SectionEndpointApi, SectionSampleView,
    SectionView
} from "../../../api";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import SectionTable from "../table/SectionTable";
import Button from "../../common/input/button/Button";
import AddSectionPanel from "../new/AddSectionPanel";
import SectionTimeDetailsPopup from "../time/SectionTimeDetailsPopup";
import Af from "../../common/ahreffun/Af";
import {prepareNameWithAncestor} from "../../common/navpoint/navpointUtil";
import {stringDateFormatToDate, stringToDate} from "../../common/date/dateUtil";
import DateInput from "../../common/input/date/DateInput";
import {SectionSampleListPanel} from "../sample/list/SectionSampleListPanel";
import {ModifySectionPanel} from "../modify/ModifySectionPanel";
import {ModifySectionSamplePanel} from "../sample/edit/ModifySectionSamplePanel";

interface SectionSearchTabProps {
    company: Company
}

function SectionSearchTab(props: SectionSearchTabProps) {
    const [navPointPosition, setNavPointPosition] = useState(0);
    const [navPoint, setNavPoint] = useState<AdoptedNavPoint>();
    const [sections, setSections] = useState<JammedSectionView[]>([]);
    const [sampleForTimeDetailsPopup, setSampleForTimeDetailsPopup] = useState<SectionSampleView>();
    const [showStationSetter, setShowStationSetter] = useState(false);
    const [showPlatformSetter, setShowPlatformSetter] = useState(false);
    const [showNewSectionForm, setShowNewSectionForm] = useState(false);
    const [sectionForModifyPanel, setSectionForModifyPanel] = useState<SectionView>(null);
    const [sampleDate, setSampleDate] = useState(stringDateFormatToDate(new Date().toISOString()));
    const [sectionForSampleListPanel, setSectionForSampleListPanel] = useState<SectionView>();
    const [sectionSampleForModifyPanel, setSectionSampleForModifyPanel] = useState<SectionSampleView>();
    const [sectionGreedyVersion, setSectionGreedyVersion] = useState(0);
    const refreshSections = () => {
        setSectionGreedyVersion(v=>v+1);
        const validSampleDate = stringToDate(sampleDate);
        if (navPoint && validSampleDate) {
            new SectionEndpointApi().initSearchSections({
                sectionSearchInput: {
                    companyId: props.company.id,
                    navPointId: navPoint.navPointMetaData.id,
                    position: navPointPosition,
                    date: validSampleDate
                }
            }, {headers: prepareSecurityHeaders()})
                .then(r => {
                    setSections(r.sections);
                })
                .catch(e => verifyClientResponse(e))
        }
    }
    useEffect(() => {
        refreshSections();
    }, [navPoint, navPointPosition, sampleDate]);
    const getTitle = (): ReactNode => {
        if (navPoint) {
            return <h1>Przegląd odcinków - {prepareNameWithAncestor(navPoint)}</h1>;
        } else {
            return <h1>Przegląd odcinków</h1>;
        }
    }

    const setSearchNavPoint = (navPoint: NavPointMetaDataView): void => {
        new NavpointEndpointApi().prepareAdoptedNavPoint({
            adoptedNavPointRequest: {id: navPoint.id, companyId: props.company.id}
        }, {headers: prepareSecurityHeaders()})
            .then(adoptedNavPoint => {
                setNavPoint(adoptedNavPoint);
            }).catch(e => {
            verifyClientResponse(e);
        });
    }
    const actionProviderForSection = (jammedSection: JammedSectionView): ReactNode => {
        const actions = [];
        if (props.company.superUser) {
            actions.push(<Af key={1} onClick={() => setSectionForModifyPanel(jammedSection.section)}>Odcinek</Af>);
        }
        actions.push(<Af key={2} onClick={()=>setSectionForSampleListPanel(jammedSection.section)}>Pomiary</Af>);
        jammedSection.bestMatchedSample.times.length && actions.push(<Af key={0} onClick={() => setSampleForTimeDetailsPopup(jammedSection.bestMatchedSample)}>Czasy
            godzinowe</Af>);
        return actions;
    };
    return (
        <div id='simpleMainContentSplit'>
            <div>
                {getTitle()}
                <SectionStationActionSetter
                    open={showStationSetter}
                    onChangeVisibility={() => {
                        setShowStationSetter((s: boolean) => !s)
                    }}
                    setSearchNavPointFun={setSearchNavPoint}
                />
                <SectionPlatformActionSetter
                    open={showPlatformSetter}
                    onChangeVisibility={() => {
                        setShowPlatformSetter((s: boolean) => !s)
                    }}
                    setSearchNavPointFun={setSearchNavPoint}
                />
                <form>
                    <label>dla daty: </label>
                    <DateInput onChange={v => setSampleDate(v)}
                               value={sampleDate}/><br/>
                    <input type="radio" name="navPointPosition" value={0} checked={navPointPosition == 0}
                           onChange={e => setNavPointPosition(Number(e.target.value))}/>
                    <label>szukanie od</label><br/>

                    <input type="radio" name="navPointPosition" value={1} checked={navPointPosition == 1}
                           onChange={e => setNavPointPosition(Number(e.target.value))}/>
                    <label>szukanie do</label><br />
                </form>

                <SectionTable
                    sections={sections}
                    navPointPosition={navPointPosition}
                    baseNavPoint={navPoint}
                    actionProvider={actionProviderForSection}
                />
                {props.company.superUser &&
                    <Button onClick={() => setShowNewSectionForm((s: boolean) => !s)}>Nowy odcinek</Button>}
            </div>
            {showNewSectionForm && <AddSectionPanel
                company={props.company}
                onAddSection={refreshSections}
                onClose={() => setShowNewSectionForm(false)}
                initialFrom={null}
                initialSampleDate={sampleDate}
            />}
            {sampleForTimeDetailsPopup && <SectionTimeDetailsPopup
                sample={sampleForTimeDetailsPopup}
                onClose={() => setSampleForTimeDetailsPopup(null)}
            />}
            {sectionForModifyPanel && <ModifySectionPanel
                company={props.company}
                section={sectionForModifyPanel} onClose={(r) => {
                setSectionForModifyPanel(null);
                r && refreshSections();
            }}/>}
            {sectionForSampleListPanel && <SectionSampleListPanel
                onClose={()=>setSectionForSampleListPanel(null)}
                section={sectionForSampleListPanel}
                initialSampleDate={sampleDate}
                onEditSample={setSectionSampleForModifyPanel}
                sectionGreedyVersion={sectionGreedyVersion}
            />}
            {sectionSampleForModifyPanel && <ModifySectionSamplePanel
                sectionSample={sectionSampleForModifyPanel}
                onClose={(r)=>{
                    setSectionSampleForModifyPanel(null);
                    r && refreshSections();
                }}
            />}
        </div>);
}

export default SectionSearchTab;
