/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConditionTypeView = {
    And: 'AND',
    Or: 'OR',
    Not: 'NOT',
    Static: 'STATIC',
    WinterHolidays: 'WINTER_HOLIDAYS',
    DayOfWeek: 'DAY_OF_WEEK',
    DayOfMonth: 'DAY_OF_MONTH',
    Variable: 'VARIABLE'
} as const;
export type ConditionTypeView = typeof ConditionTypeView[keyof typeof ConditionTypeView];


export function ConditionTypeViewFromJSON(json: any): ConditionTypeView {
    return ConditionTypeViewFromJSONTyped(json, false);
}

export function ConditionTypeViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionTypeView {
    return json as ConditionTypeView;
}

export function ConditionTypeViewToJSON(value?: ConditionTypeView | null): any {
    return value as any;
}

