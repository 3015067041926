import {initStationSearch} from "../ajax/stationAjax";
import {prepareName} from "../../common/navpoint/navpointUtil";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {ReactNode, useEffect, useState} from "react";
import {NavPointStationView, StationEndpointApi} from "../../../api";
import {verifyClientResponse} from "../../common/error/resolvers";
import {stringCompare} from "../../common/string/stringUtil";

interface StationTableProps {
    mzkWorkspace: MzkWorkspace
    query: string;
    onChangeQuery: (query: string) => void;
    actionProviderFun: (s: NavPointStationView) => ReactNode;
    greedyStationVersion: number;
    forceSearch: boolean;
    limit: number;
}

interface StationInfo {
    data: NavPointStationView[];
    appliedLimit: boolean;
}

export function StationTable(props: StationTableProps) {
    const [stationInfo, setStationInfo] = useState<StationInfo>(null);
    useEffect(() => {
        if (props.mzkWorkspace && props.mzkWorkspace.space) {
            if (props.query || props.forceSearch) {
                let httpQuery = props.query;
                if (props.forceSearch) {
                    httpQuery = null;
                    props.query = "";
                }
                initStationSearch(
                    httpQuery,
                    [props.mzkWorkspace.space.id],
                    props.mzkWorkspace.company,
                    false,
                    (r: NavPointStationView[]) => setStationInfo({data: sort(r), appliedLimit: false})
                );
            } else {
                new StationEndpointApi().previewSearch({
                    companyId: props.mzkWorkspace.company.id,
                    spaceId: props.mzkWorkspace.space.id,
                    limit: props.limit
                }, {headers: prepareSecurityHeaders()})
                    .then(s => setStationInfo({data: s.stations, appliedLimit: s.appliedLimit}))
                    .catch(e => verifyClientResponse(e));
            }
        }
    }, [props.mzkWorkspace, props.greedyStationVersion, props.query, props.forceSearch]);

    function addStation() {
        if (stationInfo == null) {
            return <tr>
                <td colSpan={4}>Ładowanie danych. Zmień kryteria.</td>
            </tr>;
        } else if (!stationInfo.data.length) {
            return <tr>
                <td colSpan={4}>Brak danych. Zmień kryteria.</td>
            </tr>;
        }
        return <>
            {stationInfo.data.map((s, index) => (
                <tr key={s.metaData.id}>
                    <td>{index}</td>
                    <td>{prepareName(s.metaData)}</td>
                    <td>{props.actionProviderFun(s)}</td>
                </tr>
            ))}
            {stationInfo.appliedLimit && <tr>
                <td colSpan={4}>Dane skrócone. Zmień kryteria by wyświetlić całość.</td>
            </tr>}
        </>;
    }

    return <table>
        <thead>
        <tr className='tableHeader'>
            <th>Lp</th>
            <th>Nazwa</th>
            <th>Akcje</th>
        </tr>
        </thead>
        <tbody>
        {addStation()}
        </tbody>
    </table>;
}

function sort(navPoints: NavPointStationView[]) {
    if (navPoints == null) {
        return null;
    }
    const p = [...navPoints];
    p.sort((a, b) => {
        const name1 = prepareName(a.metaData);
        const name2 = prepareName(b.metaData);
        return stringCompare(name1, name2);
    });
    return p;
}
