import ConditionRows from "./ConditionRows";
import {Company} from "../../user/current/CurrentUserContext";
import {ConditionMetaDataView} from "../../../api";
import {ReactNode} from "react";

interface ConditionTableProps {
    conditions: ConditionMetaDataView[];
    company: Company;
    provideActionFun: (c: ConditionMetaDataView) => ReactNode;
}

function ConditionTable(props: ConditionTableProps) {

    return (
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Nazwa</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            <ConditionRows
                conditions={props.conditions}
                provideActionFun={props.provideActionFun}
            />
            </tbody>
        </table>
    );
}

export default ConditionTable;
