import {ChangeEvent} from 'react';
import Help from "../../common/tooltip/Help";
import Close from "../../common/ahreffun/Close";
import {TownView} from "../../../api";

interface StreetTableCriteriaProps {
    onQueryUpdatedFun: (query: string) => void;
    query: string;
    townToSearch: TownView;
    showStreetTableForTownFun: (town: TownView) => void;
}

function StreetTableCriteria(props: StreetTableCriteriaProps) {
    return <>
        {props.townToSearch && <p className='diffArea'>Miasto: {props.townToSearch.name} <Close
            onClick={() => props.showStreetTableForTownFun(null)}/></p>}
        <label>Nazwa*:</label>
        <input onChange={(e: ChangeEvent<HTMLInputElement>) => props.onQueryUpdatedFun(e.target.value)}
               value={props.query}
               placeholder="nazwa"/>
        <label> <Help>Minimum dwa znaki</Help></label>
    </>;
}

export default StreetTableCriteria;
