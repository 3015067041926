/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CourseOverriddenTimeInput
 */
export interface CourseOverriddenTimeInput {
    /**
     * 
     * @type {string}
     * @memberof CourseOverriddenTimeInput
     */
    planPartId: string;
    /**
     * 
     * @type {number}
     * @memberof CourseOverriddenTimeInput
     */
    travelSec?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseOverriddenTimeInput
     */
    waitingSec?: number;
}

/**
 * Check if a given object implements the CourseOverriddenTimeInput interface.
 */
export function instanceOfCourseOverriddenTimeInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "planPartId" in value;

    return isInstance;
}

export function CourseOverriddenTimeInputFromJSON(json: any): CourseOverriddenTimeInput {
    return CourseOverriddenTimeInputFromJSONTyped(json, false);
}

export function CourseOverriddenTimeInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CourseOverriddenTimeInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'planPartId': json['planPartId'],
        'travelSec': !exists(json, 'travelSec') ? undefined : json['travelSec'],
        'waitingSec': !exists(json, 'waitingSec') ? undefined : json['waitingSec'],
    };
}

export function CourseOverriddenTimeInputToJSON(value?: CourseOverriddenTimeInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'planPartId': value.planPartId,
        'travelSec': value.travelSec,
        'waitingSec': value.waitingSec,
    };
}

