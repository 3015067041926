import {ChangeEvent, useState} from 'react';
import Close from "../../../common/ahreffun/Close";
import Button from "../../../common/input/button/Button";
import {addToast} from "../../../common/toast/toastUtil";
import {ConditionEndpointApi, ConditionTypeView} from "../../../../api";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {Company, prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";

interface NewWinterHolidaysConditionFormProps {
    closeFun: (refresh: boolean) => void;
    company: Company;
}

function NewWinterHolidaysConditionForm(props: NewWinterHolidaysConditionFormProps) {
    const [name, setName] = useState('');
    const [winterHolidayGroup, setWinterHolidayGroup] = useState('0');
    const onSave = () => {
        if (validate()) {
            new ConditionEndpointApi().saveWinterHolidaysCondition({
                winterHolidaysConditionInput: {
                    metaData: {
                        name: name,
                        type: ConditionTypeView.WinterHolidays,
                        companyId: props.company.id
                    },
                    details: {
                        group: Number(winterHolidayGroup)
                    }
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano nowy warunek');
                    props.closeFun(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const validate = () => {
        if (!name) {
            addToast('Uzupełnij nazwę');
            return false;
        }
        return true;
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.closeFun(false)}/></span>
            </div>
            <h3>Ferie zimowe</h3>
            <label>Nazwa:</label>
            <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                   maxLength={50} required={true}/>
            <br/>
            Województwo:
            <select name="winterHolidayGroup" value={winterHolidayGroup}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => setWinterHolidayGroup(e.target.value)}>
                <option value={0}>Kujawsko-pomorskie, lubuskie, małopolskie, świętokrzyskie, wielkopolskie</option>
                <option value={1}>Dolnośląskie, mazowieckie, opolskie, zachodniopomorskie</option>
                <option value={2}>Podlaskie, warmińsko-mazurskie</option>
                <option value={3}>Lubelskie, łódzkie, podkarpackie, pomorskie, śląskie</option>
            </select>
            <br/>
            <Button onClick={onSave}>Stwórz</Button>
        </div>
    );
}

function getErrorMap() {
    const map = new Map<string, (r: any) => void>();
    map.set("uniqueness", r => {
        if (r.data.name === 'name') {
            addToast('Nazwa musi być unikalna na poziomie firmy');
        }
    });
    return map;
}

export default NewWinterHolidaysConditionForm;
