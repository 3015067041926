import Close from "../../common/ahreffun/Close";
import Button from "../../common/input/button/Button";
import Help from "../../common/tooltip/Help";
import {addToast} from "../../common/toast/toastUtil";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {LineSchemaEndpointApi, TimetableGroupView, TimetableView} from "../../../api";
import Checkbox from "../../common/input/checkbox/Checkbox";
import {ChangeEvent, FormEvent, useState} from "react";

interface AddNewTimetableLineFormProps {
    onClose: (refresh: boolean) => void;
    parentGroup: TimetableGroupView;
    timeTable: TimetableView;
}

function AddNewTimetableLineForm(props: AddNewTimetableLineFormProps) {

    const [name, setName] = useState<string>(null);
    const [description, setDescription] = useState<string>(null);
    const [technicalName, setTechnicalName] = useState('');
    const [technicalDescription, setTechnicalDescription] = useState('');

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (verifyForm()) {
            const parentGroupId = props.parentGroup ? props.parentGroup.id : null;
            new LineSchemaEndpointApi()
                .addLineSchema({
                    lineSchemaInput: {
                        name: name,
                        technicalName: technicalName,
                        description: description,
                        technicalDescription: technicalDescription,
                        timetableId: props.timeTable.id,
                        groupId: parentGroupId,
                    }
                }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e);
                });
        }
    }
    const verifyForm = () => {
        if (technicalName === '') {
            addToast('Nazwa techniczna nie może być pusta');
            return false;
        }
        return true;
    }

    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
            </div>
            <h3>Dodawanie nowej linii {technicalName} {technicalDescription}</h3>
            <form>
                <label>Id techniczne*:</label>
                <Help>Główny identyfikator</Help>
                <input name='technicalName' value={technicalName}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setTechnicalName(e.target.value)}
                       maxLength={50}/>
                <br/>
                <label>Opis techniczny:</label>
                <Help>Zalecany format: Cel(Początek) /Wariant</Help>
                <input name='technicalDescription' value={technicalDescription}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setTechnicalDescription(e.target.value)}
                       maxLength={250}/>
                <br/>
                <h4>Nadpisane nazwy dla pasażera:</h4>
                <Checkbox value={name != null} onChange={() => setName(name == null ? '' : null)}>Id:</Checkbox>
                <Help>Nazwa dla pasażerów. Zazwyczaj reprezentowana jako numer linii</Help>
                <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                       maxLength={50} disabled={name == null}/>
                <br/>
                <Checkbox value={description != null}
                          onChange={() => setDescription(description == null ? '' : null)}>Opis:</Checkbox>
                <Help>Opis dla pasażerów. Zazwyczaj przystanek docelowy</Help>
                <input name='description' value={description}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                       maxLength={250} disabled={description == null}/>
                <br/>
                <Button onClick={onSubmit}>Dodaj</Button>
            </form>
        </div>
    );
}

export default AddNewTimetableLineForm;
