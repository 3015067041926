import Close from "../../common/ahreffun/Close";
import {TimetableView, VariableSelectionsEndpointApi, VariableSelectionView} from "../../../api";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {useEffect, useState} from "react";
import {verifyClientResponse} from "../../common/error/resolvers";
import {OverriddenVariableTableRow} from "./OverriddenVariableTableRow";

interface OverriddenVariablesListProps {
    timetable: TimetableView;
    mzkWorkspace: MzkWorkspace;
    onClose: () => void;
}

export function OverriddenVariablesList(props: OverriddenVariablesListProps) {
    const [variables, setVariables] = useState<VariableSelectionView[]>();

    function updateVariableSelections() {
        new VariableSelectionsEndpointApi().getVariableSelections({companyId: props.mzkWorkspace.company.id, timeTableId: props.timetable.id}, {headers: prepareSecurityHeaders()})
            .then(r => setVariables(r))
            .catch(e => verifyClientResponse(e));
    }

    useEffect(() => {
        updateVariableSelections();
    }, [props.timetable, props.mzkWorkspace]);
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={props.onClose}/></span>
        </div>
        <h3>Nadpisywanie zmiennych środowiskowych</h3>
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Nazwa</th>
                <th>Wartości</th>
            </tr>
            </thead>
            <tbody>
            {variables && variables.map((v, index) => <OverriddenVariableTableRow
                key={v.template.id}
                variable={v}
                index={index}
                timetable={props.timetable}
                onUpdated={updateVariableSelections}
                shouldHaveAction={props.timetable.editable && props.mzkWorkspace.company.superUser}
            />)}
            </tbody>
        </table>
    </div>;
}
