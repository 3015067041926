import {useState} from 'react';
import Roller from "../../common/roller/Roller";
import SpaceForCurrentCompanySwitcher from "../../space/switch/SpaceForCurrentCompanySwitcher";
import {StationTable} from "../../station/table/StationTable";
import Af from "../../common/ahreffun/Af";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {NavPointMetaDataView} from "../../../api";
import {logNavPointUsage} from "../../navpoint/ajax/ajaxUtils";
import {StationTableCriteria} from "../../station/table/StationTableCriteria";

interface SectionStationActionSetterProps {
    open: boolean;
    onChangeVisibility: () => void;
    setSearchNavPointFun: (metaData: NavPointMetaDataView) => void;
}

function SectionStationActionSetter(props: SectionStationActionSetterProps) {
    const [mzkWorkspace, setMzkWorkspace] = useState<MzkWorkspace>();
    const [query, setQuery] = useState('');
    const [forceSearch, setForceSearch] = useState(false);
    return (
        <Roller title='Wybierz stacje'
                open={props.open}
                onChangeVisibility={props.onChangeVisibility}>
            <SpaceForCurrentCompanySwitcher
                mzkWorkspace={mzkWorkspace}
                onChangeCompanyAndSpaceFun={(c, s) => setMzkWorkspace({space: s, company: c})}
            />
            <br/>
            <StationTableCriteria
                query={query}
                onDataUpdated={(q, fs)=>{
                    setQuery(q);
                    setForceSearch(fs);
                }}
            />
            <StationTable mzkWorkspace={mzkWorkspace}
                          query={query}
                          onChangeQuery={(q) => setQuery(q)}
                          greedyStationVersion={0}
                          forceSearch={forceSearch}
                          limit={10}
                          actionProviderFun={(p) => <Af
                              onClick={() => {
                                  props.setSearchNavPointFun(p.metaData);
                                  logNavPointUsage(p.metaData.id);
                              }}>Użyj</Af>}
            />
        </Roller>
    );
}

export default SectionStationActionSetter;
