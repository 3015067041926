import {ReactNode} from 'react';
import {ConditionMetaDataView} from "../../../api";

interface ConditionRowsProps {
    conditions: ConditionMetaDataView[];
    provideActionFun: (condition: ConditionMetaDataView) => ReactNode;
}

function ConditionRows(props: ConditionRowsProps) {
    return props.conditions.map((c, index) => {
        return <tr key={c.id}>
            <td>{index}</td>
            <td>{c.name}</td>
            <td>
                {props.provideActionFun(c)}
            </td>
        </tr>;
    });
}

export default ConditionRows;
