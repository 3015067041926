/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdoptedNavPoint,
  AdoptedNavPointRequest,
  NavPointUsageReport,
  StructuredNavPointInitSearchRequest,
  StructuredNavPointPreviewInput,
  StructuredNavPoints,
  StructuredNavPointsPreviewResult,
  UsageInput,
} from '../models';
import {
    AdoptedNavPointFromJSON,
    AdoptedNavPointToJSON,
    AdoptedNavPointRequestFromJSON,
    AdoptedNavPointRequestToJSON,
    NavPointUsageReportFromJSON,
    NavPointUsageReportToJSON,
    StructuredNavPointInitSearchRequestFromJSON,
    StructuredNavPointInitSearchRequestToJSON,
    StructuredNavPointPreviewInputFromJSON,
    StructuredNavPointPreviewInputToJSON,
    StructuredNavPointsFromJSON,
    StructuredNavPointsToJSON,
    StructuredNavPointsPreviewResultFromJSON,
    StructuredNavPointsPreviewResultToJSON,
    UsageInputFromJSON,
    UsageInputToJSON,
} from '../models';

export interface DeleteNavPointRequest {
    navPointId: string;
    companyId?: string;
}

export interface InitiateNavPointStructureSearchRequest {
    structuredNavPointInitSearchRequest: StructuredNavPointInitSearchRequest;
}

export interface LogNavPointUsageRequest {
    usageInput?: UsageInput;
}

export interface NavPointStructurePreviewRequest {
    structuredNavPointPreviewInput: StructuredNavPointPreviewInput;
}

export interface PrepareAdoptedNavPointRequest {
    adoptedNavPointRequest: AdoptedNavPointRequest;
}

export interface PrepareNavPointUsageReportRequest {
    navPointId: string;
    companyId?: string;
}

/**
 * 
 */
export class NavpointEndpointApi extends runtime.BaseAPI {

    /**
     */
    async deleteNavPointRaw(requestParameters: DeleteNavPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.navPointId === null || requestParameters.navPointId === undefined) {
            throw new runtime.RequiredError('navPointId','Required parameter requestParameters.navPointId was null or undefined when calling deleteNavPoint.');
        }

        const queryParameters: any = {};

        if (requestParameters.navPointId !== undefined) {
            queryParameters['navPointId'] = requestParameters.navPointId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/navpoints`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNavPoint(requestParameters: DeleteNavPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNavPointRaw(requestParameters, initOverrides);
    }

    /**
     */
    async initiateNavPointStructureSearchRaw(requestParameters: InitiateNavPointStructureSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StructuredNavPoints>>> {
        if (requestParameters.structuredNavPointInitSearchRequest === null || requestParameters.structuredNavPointInitSearchRequest === undefined) {
            throw new runtime.RequiredError('structuredNavPointInitSearchRequest','Required parameter requestParameters.structuredNavPointInitSearchRequest was null or undefined when calling initiateNavPointStructureSearch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/navpoints/structures`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StructuredNavPointInitSearchRequestToJSON(requestParameters.structuredNavPointInitSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StructuredNavPointsFromJSON));
    }

    /**
     */
    async initiateNavPointStructureSearch(requestParameters: InitiateNavPointStructureSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StructuredNavPoints>> {
        const response = await this.initiateNavPointStructureSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logNavPointUsageRaw(requestParameters: LogNavPointUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/navpoints/usages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsageInputToJSON(requestParameters.usageInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logNavPointUsage(requestParameters: LogNavPointUsageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logNavPointUsageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async navPointStructurePreviewRaw(requestParameters: NavPointStructurePreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StructuredNavPointsPreviewResult>> {
        if (requestParameters.structuredNavPointPreviewInput === null || requestParameters.structuredNavPointPreviewInput === undefined) {
            throw new runtime.RequiredError('structuredNavPointPreviewInput','Required parameter requestParameters.structuredNavPointPreviewInput was null or undefined when calling navPointStructurePreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/navpoints/structures/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StructuredNavPointPreviewInputToJSON(requestParameters.structuredNavPointPreviewInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StructuredNavPointsPreviewResultFromJSON(jsonValue));
    }

    /**
     */
    async navPointStructurePreview(requestParameters: NavPointStructurePreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StructuredNavPointsPreviewResult> {
        const response = await this.navPointStructurePreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prepareAdoptedNavPointRaw(requestParameters: PrepareAdoptedNavPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdoptedNavPoint>> {
        if (requestParameters.adoptedNavPointRequest === null || requestParameters.adoptedNavPointRequest === undefined) {
            throw new runtime.RequiredError('adoptedNavPointRequest','Required parameter requestParameters.adoptedNavPointRequest was null or undefined when calling prepareAdoptedNavPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/navpoints/adoptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdoptedNavPointRequestToJSON(requestParameters.adoptedNavPointRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdoptedNavPointFromJSON(jsonValue));
    }

    /**
     */
    async prepareAdoptedNavPoint(requestParameters: PrepareAdoptedNavPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdoptedNavPoint> {
        const response = await this.prepareAdoptedNavPointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prepareNavPointUsageReportRaw(requestParameters: PrepareNavPointUsageReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NavPointUsageReport>> {
        if (requestParameters.navPointId === null || requestParameters.navPointId === undefined) {
            throw new runtime.RequiredError('navPointId','Required parameter requestParameters.navPointId was null or undefined when calling prepareNavPointUsageReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.navPointId !== undefined) {
            queryParameters['navPointId'] = requestParameters.navPointId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/navpoints/usages/reports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NavPointUsageReportFromJSON(jsonValue));
    }

    /**
     */
    async prepareNavPointUsageReport(requestParameters: PrepareNavPointUsageReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NavPointUsageReport> {
        const response = await this.prepareNavPointUsageReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
