import {ReactNode, useContext, useEffect, useState} from "react";
import {CrewEndpointApi, CrewGroupView} from "../../../../api";
import {CurrentUserContext} from "../../../CurrentUserContext";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {CREW_GROUP_OPEN_SELECTOR_NAMES, CREW_GROUP_SELECTOR_NAMES} from "../../crewUtil";

interface CrewGroupTableProps {
    crewGroupVersion: number;
    actionProvider: (c: CrewGroupView) => ReactNode;
}

export function CrewGroupTable(props: CrewGroupTableProps) {
    const user = useContext(CurrentUserContext).user;
    const [crewGroups, setCrewGroups] = useState<CrewGroupView[]>([]);
    useEffect(() => {
        new CrewEndpointApi().findCrewGroups({companyId: user.defaultCompany.id}, {headers: prepareSecurityHeaders()})
            .then(c => setCrewGroups(c))
            .catch(e => verifyClientResponse(e));
    }, [user.defaultCompany, props.crewGroupVersion]);
    return <table>
        <thead>
        <tr className='tableHeader'>
            <th>Lp</th>
            <th>Nazwa</th>
            <th>Selektor</th>
            <th>Widoczność</th>
            <th>Akcje</th>
        </tr>
        </thead>
        <tbody>
        {crewGroups.map((g, index) => <tr key={g.metaData.id}>
            <td>{index}</td>
            <td>{g.metaData.name}</td>
            <td>{CREW_GROUP_SELECTOR_NAMES.get(g.metaData.selectorType)}</td>
            <td>{CREW_GROUP_OPEN_SELECTOR_NAMES.get(g.metaData.open)}</td>
            <td>{props.actionProvider(g)}</td>
        </tr>)}
        </tbody>
    </table>;
}
