/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionTypeView } from './ConditionTypeView';
import {
    ConditionTypeViewFromJSON,
    ConditionTypeViewFromJSONTyped,
    ConditionTypeViewToJSON,
} from './ConditionTypeView';
import type { ConditionVisibilityRuleView } from './ConditionVisibilityRuleView';
import {
    ConditionVisibilityRuleViewFromJSON,
    ConditionVisibilityRuleViewFromJSONTyped,
    ConditionVisibilityRuleViewToJSON,
} from './ConditionVisibilityRuleView';

/**
 * 
 * @export
 * @interface ConditionMetaDataView
 */
export interface ConditionMetaDataView {
    /**
     * 
     * @type {string}
     * @memberof ConditionMetaDataView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionMetaDataView
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionMetaDataView
     */
    companyId: string;
    /**
     * 
     * @type {ConditionTypeView}
     * @memberof ConditionMetaDataView
     */
    type: ConditionTypeView;
    /**
     * 
     * @type {ConditionVisibilityRuleView}
     * @memberof ConditionMetaDataView
     */
    visibilityRule: ConditionVisibilityRuleView;
}

/**
 * Check if a given object implements the ConditionMetaDataView interface.
 */
export function instanceOfConditionMetaDataView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "visibilityRule" in value;

    return isInstance;
}

export function ConditionMetaDataViewFromJSON(json: any): ConditionMetaDataView {
    return ConditionMetaDataViewFromJSONTyped(json, false);
}

export function ConditionMetaDataViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionMetaDataView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'companyId': json['companyId'],
        'type': ConditionTypeViewFromJSON(json['type']),
        'visibilityRule': ConditionVisibilityRuleViewFromJSON(json['visibilityRule']),
    };
}

export function ConditionMetaDataViewToJSON(value?: ConditionMetaDataView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'companyId': value.companyId,
        'type': ConditionTypeViewToJSON(value.type),
        'visibilityRule': ConditionVisibilityRuleViewToJSON(value.visibilityRule),
    };
}

