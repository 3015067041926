import {useEffect, useState} from 'react';
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import Button from "../../common/input/button/Button";
import {TimetableEndpointApi, TimetableView} from "../../../api";
import TimetableTable from "./TimetableTable";
import AddNewTimetableForm from "../new/AddNewTimetableForm";
import {TimetableEditPanel} from "../edit/TimetableEditPanel";

interface TimeTableListProps {
    onSelect: (timetable: TimetableView) => void;
    company: Company;
}

function TimetableList(props: TimeTableListProps) {
    const [timetables, setTimetables] = useState<TimetableView[]>([]);
    const [showNewTimeTableForm, setShowNewTimeTableForm] = useState(false);
    const [timetableIdForEdit, setTimetableIdForEdit] = useState<string>(null);
    const refreshTimeTableList = (): void => {
        new TimetableEndpointApi().searchTimeTables({
            timetableSearchInput: {
                companyId: props.company.id
            }
        }, {headers: prepareSecurityHeaders()})
            .then((r) => {
                setTimetables(r);
            })
            .catch((e: any) => {
                verifyClientResponse(e);
            });
    }
    useEffect(() => {
        refreshTimeTableList()
    }, []);
    return <div id='simpleMainContentSplit'>
        <div>
            <h1>Wybór rozkładu</h1>
            <TimetableTable timeTables={timetables} onSelect={props.onSelect} onEdit={setTimetableIdForEdit}/>
            {props.company.superUser &&
                <Button onClick={() => setShowNewTimeTableForm(!showNewTimeTableForm)}>Stwórz nowy rozkład</Button>}
        </div>
        {showNewTimeTableForm && <AddNewTimetableForm company={props.company}
                                                      hideNewTimeTableFormFun={(refreshList: boolean) => {
                                                          setShowNewTimeTableForm(false);
                                                          refreshList && refreshTimeTableList();
                                                      }}/>}
        {timetableIdForEdit && <TimetableEditPanel timetableId={timetableIdForEdit} onClose={(r)=>{
            setTimetableIdForEdit(null);
            r && refreshTimeTableList();
        }} />
        }
    </div>;
}

export default TimetableList;
