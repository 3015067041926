/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavPointStationView } from './NavPointStationView';
import {
    NavPointStationViewFromJSON,
    NavPointStationViewFromJSONTyped,
    NavPointStationViewToJSON,
} from './NavPointStationView';

/**
 * 
 * @export
 * @interface StationPreviewView
 */
export interface StationPreviewView {
    /**
     * 
     * @type {Array<NavPointStationView>}
     * @memberof StationPreviewView
     */
    stations: Array<NavPointStationView>;
    /**
     * 
     * @type {boolean}
     * @memberof StationPreviewView
     */
    appliedLimit: boolean;
}

/**
 * Check if a given object implements the StationPreviewView interface.
 */
export function instanceOfStationPreviewView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stations" in value;
    isInstance = isInstance && "appliedLimit" in value;

    return isInstance;
}

export function StationPreviewViewFromJSON(json: any): StationPreviewView {
    return StationPreviewViewFromJSONTyped(json, false);
}

export function StationPreviewViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationPreviewView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stations': ((json['stations'] as Array<any>).map(NavPointStationViewFromJSON)),
        'appliedLimit': json['appliedLimit'],
    };
}

export function StationPreviewViewToJSON(value?: StationPreviewView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stations': ((value.stations as Array<any>).map(NavPointStationViewToJSON)),
        'appliedLimit': value.appliedLimit,
    };
}

