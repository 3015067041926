/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateSectionPartInput } from './CreateSectionPartInput';
import {
    CreateSectionPartInputFromJSON,
    CreateSectionPartInputFromJSONTyped,
    CreateSectionPartInputToJSON,
} from './CreateSectionPartInput';
import type { CreateSectionSampleInput } from './CreateSectionSampleInput';
import {
    CreateSectionSampleInputFromJSON,
    CreateSectionSampleInputFromJSONTyped,
    CreateSectionSampleInputToJSON,
} from './CreateSectionSampleInput';

/**
 * 
 * @export
 * @interface CreateSectionInput
 */
export interface CreateSectionInput {
    /**
     * 
     * @type {string}
     * @memberof CreateSectionInput
     */
    companyId: string;
    /**
     * 
     * @type {CreateSectionPartInput}
     * @memberof CreateSectionInput
     */
    part1: CreateSectionPartInput;
    /**
     * 
     * @type {CreateSectionPartInput}
     * @memberof CreateSectionInput
     */
    part2: CreateSectionPartInput;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionInput
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionInput
     */
    conditionId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSectionInput
     */
    distanceM?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSectionInput
     */
    intermediateNavPointIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSectionInput
     */
    bidirectional?: boolean;
    /**
     * 
     * @type {CreateSectionSampleInput}
     * @memberof CreateSectionInput
     */
    initialSample?: CreateSectionSampleInput;
}

/**
 * Check if a given object implements the CreateSectionInput interface.
 */
export function instanceOfCreateSectionInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "part1" in value;
    isInstance = isInstance && "part2" in value;
    isInstance = isInstance && "intermediateNavPointIds" in value;

    return isInstance;
}

export function CreateSectionInputFromJSON(json: any): CreateSectionInput {
    return CreateSectionInputFromJSONTyped(json, false);
}

export function CreateSectionInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSectionInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'part1': CreateSectionPartInputFromJSON(json['part1']),
        'part2': CreateSectionPartInputFromJSON(json['part2']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'conditionId': !exists(json, 'conditionId') ? undefined : json['conditionId'],
        'distanceM': !exists(json, 'distanceM') ? undefined : json['distanceM'],
        'intermediateNavPointIds': json['intermediateNavPointIds'],
        'bidirectional': !exists(json, 'bidirectional') ? undefined : json['bidirectional'],
        'initialSample': !exists(json, 'initialSample') ? undefined : CreateSectionSampleInputFromJSON(json['initialSample']),
    };
}

export function CreateSectionInputToJSON(value?: CreateSectionInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'part1': CreateSectionPartInputToJSON(value.part1),
        'part2': CreateSectionPartInputToJSON(value.part2),
        'description': value.description,
        'conditionId': value.conditionId,
        'distanceM': value.distanceM,
        'intermediateNavPointIds': value.intermediateNavPointIds,
        'bidirectional': value.bidirectional,
        'initialSample': CreateSectionSampleInputToJSON(value.initialSample),
    };
}

