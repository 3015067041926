import {CrewGroupMetaDataViewSelectorTypeEnum} from "../../api";
import {ReactNode} from "react";

export const CREW_GROUP_SELECTOR_NAMES = new Map<CrewGroupMetaDataViewSelectorTypeEnum, ReactNode>();
CREW_GROUP_SELECTOR_NAMES.set(CrewGroupMetaDataViewSelectorTypeEnum.None, 'Brak');
CREW_GROUP_SELECTOR_NAMES.set(CrewGroupMetaDataViewSelectorTypeEnum.Condition, 'Warunkowy');
CREW_GROUP_SELECTOR_NAMES.set(CrewGroupMetaDataViewSelectorTypeEnum.Date, 'Kalendarzowy');

export const CREW_GROUP_SELECTOR_DESCRIPTION = new Map<CrewGroupMetaDataViewSelectorTypeEnum, ReactNode>();
CREW_GROUP_SELECTOR_DESCRIPTION.set(CrewGroupMetaDataViewSelectorTypeEnum.None, 'Brygada pracuje w każdy dzień tygodnia.');
export const CREW_GROUP_OPEN_SELECTOR_NAMES = new Map<boolean, string>();
CREW_GROUP_OPEN_SELECTOR_NAMES.set(true, 'Otwarta');
CREW_GROUP_OPEN_SELECTOR_NAMES.set(false, 'Zamknięta');

export const CREW_GROUP_OPEN_SELECTOR_DESCRIPTION = new Map<boolean, string>();
CREW_GROUP_OPEN_SELECTOR_DESCRIPTION.set(true, 'Członkowie brygad są widoczni. Przydział kursów odbywa się do każdego z członków z osobna.');
CREW_GROUP_OPEN_SELECTOR_DESCRIPTION.set(false, 'Kursy przypisywane są do brygady. Ilość członków brygady wraz z przypisaniem członka do kursu odbywa się automatycznie.');
