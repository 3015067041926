/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavPointSettings } from './NavPointSettings';
import {
    NavPointSettingsFromJSON,
    NavPointSettingsFromJSONTyped,
    NavPointSettingsToJSON,
} from './NavPointSettings';

/**
 * 
 * @export
 * @interface NavPointMetaDataInput
 */
export interface NavPointMetaDataInput {
    /**
     * 
     * @type {string}
     * @memberof NavPointMetaDataInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NavPointMetaDataInput
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof NavPointMetaDataInput
     */
    technicalName?: string;
    /**
     * 
     * @type {string}
     * @memberof NavPointMetaDataInput
     */
    dataSpaceId: string;
    /**
     * 
     * @type {string}
     * @memberof NavPointMetaDataInput
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof NavPointMetaDataInput
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof NavPointMetaDataInput
     */
    conditionId?: string;
    /**
     * 
     * @type {number}
     * @memberof NavPointMetaDataInput
     */
    gpsX?: number;
    /**
     * 
     * @type {number}
     * @memberof NavPointMetaDataInput
     */
    gpsY?: number;
    /**
     * 
     * @type {string}
     * @memberof NavPointMetaDataInput
     */
    streetId?: string;
    /**
     * 
     * @type {NavPointSettings}
     * @memberof NavPointMetaDataInput
     */
    settings: NavPointSettings;
}

/**
 * Check if a given object implements the NavPointMetaDataInput interface.
 */
export function instanceOfNavPointMetaDataInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataSpaceId" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "settings" in value;

    return isInstance;
}

export function NavPointMetaDataInputFromJSON(json: any): NavPointMetaDataInput {
    return NavPointMetaDataInputFromJSONTyped(json, false);
}

export function NavPointMetaDataInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavPointMetaDataInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'technicalName': !exists(json, 'technicalName') ? undefined : json['technicalName'],
        'dataSpaceId': json['dataSpaceId'],
        'companyId': json['companyId'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'conditionId': !exists(json, 'conditionId') ? undefined : json['conditionId'],
        'gpsX': !exists(json, 'gpsX') ? undefined : json['gpsX'],
        'gpsY': !exists(json, 'gpsY') ? undefined : json['gpsY'],
        'streetId': !exists(json, 'streetId') ? undefined : json['streetId'],
        'settings': NavPointSettingsFromJSON(json['settings']),
    };
}

export function NavPointMetaDataInputToJSON(value?: NavPointMetaDataInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'shortName': value.shortName,
        'technicalName': value.technicalName,
        'dataSpaceId': value.dataSpaceId,
        'companyId': value.companyId,
        'parentId': value.parentId,
        'conditionId': value.conditionId,
        'gpsX': value.gpsX,
        'gpsY': value.gpsY,
        'streetId': value.streetId,
        'settings': NavPointSettingsToJSON(value.settings),
    };
}

