import {ChangeEvent, ClipboardEvent} from 'react';
import Help from "../common/tooltip/Help";

interface GpsInputProps {
    onChange: (gpsValues: string[]) => void;
    value: string[]
}

function GpsInput(props: GpsInputProps) {
    const onPasteVal = (e: ClipboardEvent<HTMLInputElement>, index: number) => {
        e.preventDefault();
        const value = e.clipboardData.getData('Text');
        const valueSingle = parseFloat(value);
        if (!isNaN(valueSingle)) {
            onChangeVal(index, valueSingle + '');
        }
        let stringValue = value.split(' ');
        if (value.length === 2) {
            stringValue = stringValue.map(v => v.replace(/\,$/, ''))
            let floatValue = stringValue.map(v => parseFloat(v));
            floatValue = floatValue.map(v => protectMinMax(v));
            if (floatValue.every(v => !isNaN(v))) {
                onChangeVal(0, stringValue[0]);
                onChangeVal(1, stringValue[1]);
            }
        }
    }
    const onChangeVal = (index: number, value: string) => {
        const gpsValues = [...props.value]
        gpsValues[index] = protectMinMax(parseFloat(value)) + '';
        props.onChange(gpsValues);
    }

    const protectMinMax = (value: number) => {
        if (value > 99) {
            return 0;
        } else if (value < -99) {
            return 0;
        } else {
            return value;
        }
    }
    return <>
        <label>GPS-x:</label>
        <input value={props.value[0]}
               onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeVal(0, e.target.value)}
               onPaste={(e: ClipboardEvent<HTMLInputElement>) => onPasteVal(e, 0)}
               type='number'
        />
        <span> </span>
        <Help>Wklejenie lokalizacji w formacie: "x.0, y.0" automatycznie wypełni oba pola</Help>
        <br/>
        <label>GPS-y:</label>
        <input value={props.value[1]}
               onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeVal(1, e.target.value)}
               onPaste={(e: ClipboardEvent<HTMLInputElement>) => onPasteVal(e, 1)}
               type='number'
        />
    </>;

}

export default GpsInput;
