import LinePlanTableRows from "./LinePlanTableRows";
import {LineSchemaPlanPart} from "../LinePlanPanel";
import {TimetableView} from "../../../../api";

interface LinePlanTableProps {
    examinedDate: Date;
    onChangePlan: (p: LineSchemaPlanPart[]) => void;
    onShowSectionListPanel: (navPointId: string) => void;
    plan: LineSchemaPlanPart[];
    timetable: TimetableView;
}

function LinePlanTable(props: LinePlanTableProps) {
    const changePartOrder = (index: number, destination: number) => {
        const parts = [...props.plan];
        const partToMove = parts[index];
        parts.splice(index, 1);
        parts.splice(destination, 0, partToMove);
        props.onChangePlan(parts);
    }
    const deletePart = (index: number) => {
        const parts = [...props.plan];
        parts.splice(index, 1);
        props.onChangePlan(parts);
    }
    return <table>
        <thead>
        <tr className='tableHeader'>
            <th>Lp</th>
            <th colSpan={2}>Węzły</th>
            <th>{props.examinedDate.toLocaleDateString()}</th>
            <th>Akcje</th>
        </tr>
        </thead>
        <tbody>
        {props.plan != null && <LinePlanTableRows
            plan={props.plan}
            onChangePartOrder={changePartOrder}
            onDeletePart={deletePart}
            onShowSectionListPanel={props.onShowSectionListPanel}
            timetable={props.timetable}
        />}
        </tbody>
    </table>;

}

export default LinePlanTable;
