import Button from "../../common/input/button/Button";
import {Component, ReactNode} from "react";
import {DataSpacePermissionCompanyView} from "../../../api/models/DataSpacePermissionCompanyView";

interface SpaceSwitcherProps {
    initSpace?: DataSpacePermissionCompanyView;
    space?: DataSpacePermissionCompanyView;
    spaces: DataSpacePermissionCompanyView[];
    onChangeSpace: (space: DataSpacePermissionCompanyView) => void;
}

class SpaceSwitcher extends Component<SpaceSwitcherProps> {

    constructor(props: SpaceSwitcherProps) {
        super(props);

        this.selectSpace = this.selectSpace.bind(this);
    }


    componentDidMount(): void {
        if (this.props.initSpace && !this.props.space) {
            this.selectSpace(this.props.initSpace);
        } else if (this.props.spaces.length === 1) {
            this.selectSpace(this.props.spaces[0]);
        }
    }

    render(): ReactNode {
        if (this.props.spaces.length) {
            return this.props.spaces.map(s => {
                return <Button onClick={() => this.selectSpace(s)}
                               backgroundColor={this.props.space && this.props.space.id === s.id ? 'Secondary' : undefined}
                               key={s.id}>{s.name}</Button>;
            })
        } else {
            return <span>-</span>;
        }
    }

    selectSpace(space: DataSpacePermissionCompanyView): void {
        this.props.onChangeSpace(space);
    }

}

export default SpaceSwitcher;
