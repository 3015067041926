import Close from "../../../common/ahreffun/Close";
import {useContext, useEffect, useState} from "react";
import {
    CrewEndpointApi,
    type CrewGroupMetaDataView,
    CrewGroupView,
    LineSchemaMetaDataView,
    TimetableEndpointApi,
    TimetableView
} from "../../../../api";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {CurrentUserContext} from "../../../CurrentUserContext";
import Button from "../../../common/input/button/Button";
import {CrewGroupPopup} from "../popup/CrewGroupPopup";
import {uxNull} from "../../../common/string/stringUtil";
import Af from "../../../common/ahreffun/Af";
import {addToast} from "../../../common/toast/toastUtil";

interface CrewGroupUsagePanelProps {
    groupMetaData: CrewGroupMetaDataView;
    onClose: (refresh: boolean) => void;
}

export function CrewGroupUsagePanel(props: CrewGroupUsagePanelProps) {
    const [lines, setLines] = useState<LineSchemaMetaDataView[]>();
    const [selectCrewGroupForReplace, setSelectCrewGroupForReplace] = useState(false);
    const refreshUsages = () => {
        new CrewEndpointApi().prepareCrewGroupUsageReport({crewGroupId: props.groupMetaData.id}, {headers: prepareSecurityHeaders()})
            .then((r) => {
                setLines(r);
            }).catch(e => verifyClientResponse(e, new Map()));
    }
    useEffect(() => {
        refreshUsages();
    }, [props.groupMetaData]);
    if (!lines) {
        return <p>Ładowanie...</p>;
    }
    const onReplaceUsages = (toGroup: CrewGroupView) => {
        new CrewEndpointApi().replaceCrewGroupUsages({
            fromCrewGroupId: props.groupMetaData.id,
            toCrewGroupId: toGroup.metaData.id
        }, {headers: prepareSecurityHeaders()})
            .then(() => {
                refreshUsages();
                addToast('Pomyślnie zastąpiono użycia');
            })
            .catch(e => verifyClientResponse(e, new Map()));
    };
    const onDelete = () => {
        if (confirm('Czy na pewno chcesz usunąć brygadę?')){
            new CrewEndpointApi().deleteCrewGroup({crewGroupId: props.groupMetaData.id}, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie usunięto grupę');
                    props.onClose(true);
                }).catch(e => verifyClientResponse(e, new Map()));
        }
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        </div>
        <h2>Użycia</h2>
        <LineUsageInfo lines={lines}/>
        <CrewGroupUsageAction lines={lines} groupMetaData={props.groupMetaData}
                              onStartReplaceUsages={() => setSelectCrewGroupForReplace(true)} onDelete={onDelete}/>
        {selectCrewGroupForReplace &&
            <CrewGroupPopup onClose={() => setSelectCrewGroupForReplace(false)} actionProvider={(g) => {
                if (g.metaData.id == props.groupMetaData.id || g.metaData.open) {
                    return uxNull();
                } else {
                    return <Af onClick={() => {
                        setSelectCrewGroupForReplace(false);
                        onReplaceUsages(g);
                    }}>Wybierz</Af>;
                }
            }}/>}
    </div>;
}

interface LineUsageInfoProps {
    lines: LineSchemaMetaDataView[];
}

export function LineUsageInfo(props: LineUsageInfoProps) {
    const [groupedLines, setGroupedLines] = useState<Map<string, LineSchemaMetaDataView[]>>();
    useEffect(() => {
        if (!props.lines) {
            setGroupedLines(null);
        } else {
            setGroupedLines(props.lines.reduce((result, item) => {
                const key = item.timeTableId;
                const group = result.get(key) || [];
                group.push(item);
                result.set(key, group);
                return result;
            }, new Map<string, LineSchemaMetaDataView[]>()));
        }
    }, [props.lines]);
    if (groupedLines) {
        return Array.from(groupedLines.entries()).map(e => <TimetableUsageInfo key={e[0]} timetableId={e[0]}
                                                                               lines={e[1]}/>);
    }
}

interface TimetableUsageInfoProps {
    timetableId: string;
    lines: LineSchemaMetaDataView[];
}

function TimetableUsageInfo(props: TimetableUsageInfoProps) {
    const [timetable, setTimetable] = useState<TimetableView>();
    useEffect(() => {
        new TimetableEndpointApi().getTimetableDetails({id: props.timetableId}, {headers: prepareSecurityHeaders()})
            .then(r => setTimetable(r))
            .catch(e => verifyClientResponse(e));
    }, []);
    if (!timetable) {
        return <p>Ładowanie...</p>;
    }
    return <><h4>{timetable.name}</h4>
        <ul>
            {props.lines.map(l => <ol key={l.id}>{l.technicalName} {l.technicalDescription}</ol>)}
        </ul>
    </>;
}

interface CrewGroupUsageActionProps {
    lines: LineSchemaMetaDataView[];
    groupMetaData: CrewGroupMetaDataView;
    onStartReplaceUsages: () => void;
    onDelete: () => void;
}

function CrewGroupUsageAction(props: CrewGroupUsageActionProps) {
    const currentUser = useContext(CurrentUserContext);

    if (!currentUser.user.defaultCompany.superUser) {
        return '';
    }
    return <><h3>Akcje</h3>
        {props.lines.length > 0 && !props.groupMetaData.open &&
            <Button onClick={props.onStartReplaceUsages}>Zastąp użycia</Button>}
        {props.lines.length == 0 && <Button onClick={props.onDelete}>Usuń</Button>}
    </>;
}
