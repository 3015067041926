/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavPointMetaDataView } from './NavPointMetaDataView';
import {
    NavPointMetaDataViewFromJSON,
    NavPointMetaDataViewFromJSONTyped,
    NavPointMetaDataViewToJSON,
} from './NavPointMetaDataView';

/**
 * 
 * @export
 * @interface AdoptedNavPoint
 */
export interface AdoptedNavPoint {
    /**
     * 
     * @type {NavPointMetaDataView}
     * @memberof AdoptedNavPoint
     */
    navPointMetaData: NavPointMetaDataView;
    /**
     * 
     * @type {NavPointMetaDataView}
     * @memberof AdoptedNavPoint
     */
    navPointMetaDataBaseParent?: NavPointMetaDataView;
}

/**
 * Check if a given object implements the AdoptedNavPoint interface.
 */
export function instanceOfAdoptedNavPoint(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "navPointMetaData" in value;

    return isInstance;
}

export function AdoptedNavPointFromJSON(json: any): AdoptedNavPoint {
    return AdoptedNavPointFromJSONTyped(json, false);
}

export function AdoptedNavPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdoptedNavPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'navPointMetaData': NavPointMetaDataViewFromJSON(json['navPointMetaData']),
        'navPointMetaDataBaseParent': !exists(json, 'navPointMetaDataBaseParent') ? undefined : NavPointMetaDataViewFromJSON(json['navPointMetaDataBaseParent']),
    };
}

export function AdoptedNavPointToJSON(value?: AdoptedNavPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'navPointMetaData': NavPointMetaDataViewToJSON(value.navPointMetaData),
        'navPointMetaDataBaseParent': NavPointMetaDataViewToJSON(value.navPointMetaDataBaseParent),
    };
}

