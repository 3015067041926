/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JammedSectionView } from './JammedSectionView';
import {
    JammedSectionViewFromJSON,
    JammedSectionViewFromJSONTyped,
    JammedSectionViewToJSON,
} from './JammedSectionView';

/**
 * 
 * @export
 * @interface SectionSearchResult
 */
export interface SectionSearchResult {
    /**
     * 
     * @type {Array<JammedSectionView>}
     * @memberof SectionSearchResult
     */
    sections: Array<JammedSectionView>;
}

/**
 * Check if a given object implements the SectionSearchResult interface.
 */
export function instanceOfSectionSearchResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sections" in value;

    return isInstance;
}

export function SectionSearchResultFromJSON(json: any): SectionSearchResult {
    return SectionSearchResultFromJSONTyped(json, false);
}

export function SectionSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': ((json['sections'] as Array<any>).map(JammedSectionViewFromJSON)),
    };
}

export function SectionSearchResultToJSON(value?: SectionSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': ((value.sections as Array<any>).map(JammedSectionViewToJSON)),
    };
}

