import Close from "../../../common/ahreffun/Close";
import Button from "../../../common/input/button/Button";
import {addToast} from "../../../common/toast/toastUtil";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {TimetableGroupEndpointApi} from "../../../../api";
import {TimetableGroupView} from "../../../../api";
import {TimetableView} from "../../../../api";
import {useState} from "react";

interface AddNewTimeTableGroupFormProps {
    onClose: (refresh: boolean)=>void;
    parentGroup: TimetableGroupView;
    timetable: TimetableView;
}

function getErrorMap(): Map<string, () => void> {
    const map = new Map<string, () => void>();
    map.set('uniqueness', () => addToast('Nazwa grupy powinna być unikalna'));
    return map;
}

function AddNewTimeTableGroupForm(props: AddNewTimeTableGroupFormProps) {
    const [name, setName] = useState('');
    const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (verifyForm()) {
            const parentGroupId = props.parentGroup ? props.parentGroup.id : null;
            new TimetableGroupEndpointApi()
                .addTimetableGroup({
                    groupInput: {
                        name: name,
                        timetableId: props.timetable.id,
                        parentGroupId: parentGroupId
                    }
                }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const verifyForm = (): boolean => {
        if (name.length === 0) {
            addToast('Nazwa jest wymagana');
            return false;
        }
        return true;
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={()=>props.onClose(false)}/></span>
            </div>
            <h3>Dodawanie nowej grupy {name}</h3>
            <form>
                <label>Nazwa*:</label>
                <input name='name' value={name} onChange={e => setName(e.target.value)} maxLength={50}/>
                <br/>
                <Button onClick={onSubmit}>Dodaj</Button>
            </form>
        </div>
    );
}

export default AddNewTimeTableGroupForm;
