import {ReactNode, useEffect, useMemo, useState} from 'react';
import ZoneAssigningZonesTableCriteria from "./ZoneAssigningZonesTableCriteria";
import {assignStationIdsToZone, getZones} from "../ajax/zoneAjax";
import {initStationSearch} from "../../station/ajax/stationAjax";
import Af from "../../common/ahreffun/Af";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {addToast} from "../../common/toast/toastUtil";
import {NavPointStationView, StationZoneEndpointApi, ZoneView} from "../../../api";
import {prepareName} from "../../common/navpoint/navpointUtil";

interface ZoneAssigningZonesTableProps {
    mzkWorkspace: MzkWorkspace
}

function ZoneAssigningZonesTable(props: ZoneAssigningZonesTableProps) {
    const [stationData, setStationData] = useState<NavPointStationView[]>([]);
    const [zones, setZones] = useState<ZoneView[]>([]);
    const [selectedZone, setSelectedZone] = useState<ZoneView>();
    const [query, setQuery] = useState('');
    const [showOnlyStationWithoutZones, setShowOnlyStationWithoutZones] = useState(true);
    useEffect(() => {
        refreshAll();
    }, [props.mzkWorkspace]);
    const mapAssignAction = (s: NavPointStationView): ReactNode => {
        if (props.mzkWorkspace.company.superUser && selectedZone && selectedZone.id !== s.details.zoneId) {
            return <Af
                onClick={() => assignStationIdsToZoneId([s.metaData.id])}>Przypisz {selectedZone.name}</Af>
        }
    }
    const mapUnpairAction = (s: NavPointStationView): ReactNode => {
        if (s.details.zoneId) {
            return <Af onClick={() => unpair(s.metaData.id)}>Usuń przypisaną strefę</Af>;
        }
    }
    const assignAllStationWithoutZone = (): void => {
        const stationIds = stationData.filter(s => !s.details.zoneId).map(s => s.metaData.id);
        if (stationIds.length) {
            if (confirm(`Czy na pewno chcesz przypisać wszystkim stacjom bez uzupełnionej strefy, strefę ${selectedZone.name}?`)) {
                assignStationIdsToZoneId(stationIds);
            }
        } else {
            addToast('Brak stacji do aktualizacji');
        }
    }
    const assignStationIdsToZoneId = (stationIds: string[]): void => {
        assignStationIdsToZone(stationIds, props.mzkWorkspace.company, selectedZone, getErrorMap(), () => {
            addToast('Zapisano pomyślnie');
            refreshStations(query, props.mzkWorkspace, showOnlyStationWithoutZones)
        })
    }
    const unpair = (stationId: string): void => {
        new StationZoneEndpointApi().deleteAssignedZone({
            stationId: stationId,
            companyId: props.mzkWorkspace.company.id
        }, {headers: prepareSecurityHeaders()})
            .then(() => {
                addToast('Usunięto powiązanie');
                refreshStations(query, props.mzkWorkspace, showOnlyStationWithoutZones)
            })
            .catch(e => {
                verifyClientResponse(e, getErrorMap());
            });
    }
    const getErrorMap = (): Map<string, Function> => {
        const map = new Map();
        map.set("not_exist", (r: any) => {
            if (r.data.name === 'station') {
                addToast('Jedna ze stacji, które próbujesz zaaktualizować nie istnieje. Odśwież stronę');
            } else if (r.data.name === 'zone') {
                addToast('Strefa nie istnieje. Odśwież stronę');
            }
        });
        return map;
    }
    const onChangeShowOnlyStationWithoutZones = (value: boolean): void => {
        setShowOnlyStationWithoutZones(value);
        refreshStations(query, props.mzkWorkspace, value);
    }
    const onQueryChanged = (query: string): void => {
        setQuery(query);
        refreshStations(query, props.mzkWorkspace, showOnlyStationWithoutZones);
    }
    const refreshAll = (): void => {
        refreshZones(() => refreshStations(query, props.mzkWorkspace, showOnlyStationWithoutZones));
    }

    const refreshZones = (callback: Function): void => {
        getZones(props.mzkWorkspace, (r: ZoneView[]) => {
            setZones(r);
            callback();
        });
    }
    const refreshStations = (query: string, mzkWorkspace: MzkWorkspace, showOnlyStationWithoutZones: boolean): void => {
        if (!query.length) {
            query = null;
        }
        initStationSearch(query, [mzkWorkspace.space.id], mzkWorkspace.company, showOnlyStationWithoutZones,
            (r) => setStationData(r));
    }
    const mapZone = (station: NavPointStationView, zonesMap: Map<string, ZoneView>): string => {
        const zoneId = station.details.zoneId;
        if (zoneId) {
            const zoneInfo = zonesMap.get(zoneId);
            if (zoneInfo) {
                return zoneInfo.name;
            }
        }
        return '-';
    }
    const zonesMap = useMemo(() => new Map(zones.map(z => [z.id, z])), [zones]);
    return (
        <div>
            <ZoneAssigningZonesTableCriteria
                zones={zones} selectedZone={selectedZone} onChangeZone={(z) => setSelectedZone(z)}
                showOnlyStationWithoutZones={showOnlyStationWithoutZones}
                onChangeShowOnlyStationWithoutZones={onChangeShowOnlyStationWithoutZones}
                assignAllStationWithoutZone={assignAllStationWithoutZone}
                query={query} onQueryChanged={onQueryChanged}
                company={props.mzkWorkspace.company}
            />
            <table>
                <thead>
                <tr className='tableHeader'>
                    <th>Lp</th>
                    <th>Nazwa</th>
                    <th>Aktualnie przypisana strefa</th>
                    <th>Akcje</th>
                </tr>
                </thead>
                <tbody>
                {stationData.map((s, index) =>
                    <tr key={s.metaData.id}>
                        <td>{index}</td>
                        <td>{prepareName(s.metaData)}</td>
                        <td>{mapZone(s, zonesMap)}</td>
                        <td>
                            {mapAssignAction(s)}
                            {mapUnpairAction(s)}
                        </td>
                    </tr>)}

                </tbody>
            </table>
        </div>
    );
}

export default ZoneAssigningZonesTable;
