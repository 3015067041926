import {CurrentUser, CurrentUserContext} from '../../CurrentUserContext';
import {Company, MzkWorkspace} from "../../user/current/CurrentUserContext";
import SpaceSwitcher from "./SpaceSwitcher";
import {DataSpacePermissionCompanyView} from "../../../api";

interface SpaceForCurrentCompanySwitcherProps {
    mzkWorkspace: MzkWorkspace
    onChangeCompanyAndSpaceFun: (company: Company, space: DataSpacePermissionCompanyView) => void;
}

function SpaceForCurrentCompanySwitcher(props: SpaceForCurrentCompanySwitcherProps) {
    return <CurrentUserContext.Consumer>
        {(currentUser: CurrentUser) => {
            return <>
                <span>Space:</span>
                <SpaceSwitcher
                    space={props.mzkWorkspace != null ? props.mzkWorkspace.space : null}
                    spaces={currentUser.user.defaultCompany.availableDataSpaces}
                    initSpace={currentUser.user.defaultSpace}
                    onChangeSpace={(s: object) => props.onChangeCompanyAndSpaceFun(currentUser.user.defaultCompany, s)}
                /></>
        }}
    </CurrentUserContext.Consumer>;
}

export default SpaceForCurrentCompanySwitcher;
