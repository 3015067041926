import Roller from "../../../../common/roller/Roller";
import SpaceForCurrentCompanySwitcher from "../../../../space/switch/SpaceForCurrentCompanySwitcher";
import Af from "../../../../common/ahreffun/Af";
import {StationTable} from "../../../../station/table/StationTable";
import Button from "../../../../common/input/button/Button";
import {hasSuperPermissionInSpace} from "../../../../common/superuser/permissionUtil";
import {useRef, useState} from "react";
import {MzkWorkspace} from "../../../../user/current/CurrentUserContext";
import {DataSpacePermissionCompanyView, NavPointMetaDataView, NavPointStationView} from "../../../../../api";
import {StationTableCriteria} from "../../../../station/table/StationTableCriteria";

interface AddNewStationPlanPartProps {
    open: boolean;
    onChangeVisibility: () => void;
    addPartFun: (metaData: NavPointMetaDataView) => void;
    showNewStationFormFun: (space: DataSpacePermissionCompanyView) => void;
    greedyStationVersion: number;
}

function AddNewStationPlanPart(props: AddNewStationPlanPartProps) {
    const [mzkWorkspace, setMzkWorkspace] = useState<MzkWorkspace>();
    const [query, setQuery] = useState('');
    const queryRef = useRef<HTMLInputElement>();
    const [forceSearch, setForceSearch] = useState(false);
    return (
        <Roller
            open={props.open}
            onChangeVisibility={props.onChangeVisibility}
            title='Dodawanie stacji'>
            <SpaceForCurrentCompanySwitcher
                mzkWorkspace={mzkWorkspace}
                onChangeCompanyAndSpaceFun={(c, s) => {
                    setMzkWorkspace({company: c, space: s});
                }}
            />
            <br/>
            <StationTableCriteria
                query={query}
                ref={queryRef}
                onDataUpdated={(q, fs)=>{
                    setQuery(q);
                    setForceSearch(fs);
                }}
            />
            <StationTable mzkWorkspace={mzkWorkspace}
                          query={query}
                          onChangeQuery={(q: string) => setQuery(q)}
                          forceSearch={forceSearch}
                          limit={4}
                          actionProviderFun={(navPoint: NavPointStationView) => <Af
                              onClick={() => {
                                  setQuery('');
                                  queryRef.current.focus()
                                  props.addPartFun(navPoint.metaData);
                              }}>Użyj</Af>}
                          greedyStationVersion={props.greedyStationVersion}
            />
            {hasSuperPermissionInSpace(mzkWorkspace) && <Button onClick={() => {
                props.showNewStationFormFun(mzkWorkspace.space)
            }}>Dodaj nową stację</Button>}
        </Roller>
    );
}

export default AddNewStationPlanPart;
