import {ReactNode} from 'react';
import {uxNull} from "../../common/string/stringUtil";
import {secondsToFrontDuration} from "../../common/input/duration/durationUtil";
import {prepareNameWithAncestor} from "../../common/navpoint/navpointUtil";
import {AdoptedNavPoint, JammedSectionView, SectionView} from "../../../api";
import {SectionStructure} from "./SectionTable";
import {toFrontMeters} from "../../common/number/numberUtil";
import {stringDateFormatToDate} from "../../common/date/dateUtil";

interface SectionGroupTableProps {
    sections: SectionStructure;
    actionProvider: (element: JammedSectionView) => ReactNode;
    baseNavPoint: AdoptedNavPoint;
    navPointPosition: number;
}

function SectionGroupTable(props: SectionGroupTableProps) {
    if (props.sections.elements.length) {
        const partForHeader = findUniquePart(props.sections.elements[0].section, props.baseNavPoint);
        return (
            <>
                <tr>
                    <td className='tableHeader' colSpan={9} style={{fontSize: 'larger'}}>
                        <b>
                            {props.navPointPosition == 0 ?
                                <>{prepareNameWithAncestor(props.baseNavPoint)} - {prepareNameWithAncestor(partForHeader)}</> :
                                <>{prepareNameWithAncestor(partForHeader)} - {prepareNameWithAncestor(props.baseNavPoint)}</>}
                        </b>
                    </td>
                </tr>
                <tr className='tableHeader'>
                    <th>Lp</th>
                    <th>Warunek</th>
                    <th>Opis</th>
                    <th>Czas przejazdu</th>
                    <th>Czas oczekiwania</th>
                    <th>Odwracalny</th>
                    <th>Odległość</th>
                    <th>Data pomiaru</th>
                    <th>Akcje</th>
                </tr>
                {props.sections.elements.map((e, index) => {
                    var section = e.section;
                    const bestMatchedSample = e.bestMatchedSample;
                    return (
                        <tr key={section.id}>
                            <td>{index}</td>
                            <td>{section.condition ? section.condition.name : uxNull()}</td>
                            <td>{uxNull(section.description)}</td>
                            <td>{secondsToFrontDuration(bestMatchedSample.defaultTravelSec)}</td>
                            <td>{bestMatchedSample.defaultWaitingSec ? secondsToFrontDuration(bestMatchedSample.defaultWaitingSec) : uxNull()}</td>
                            <td>{section.bidirectional ? '🔁' : uxNull()}</td>
                            <td>{section.distanceM ? toFrontMeters(section.distanceM) : uxNull()}</td>
                            <td>{stringDateFormatToDate(bestMatchedSample.sampleDate.toISOString())}</td>
                            <td>{props.actionProvider(e)}</td>
                        </tr>
                    );
                })}
            </>
        );
    }
}

function findUniquePart(s: SectionView, baseNavPoint: AdoptedNavPoint) {
    if (baseNavPoint.navPointMetaData.id == s.part1.navPointMetaData.id) {
        return s.part2;
    } else {
        return s.part1;
    }
}

export default SectionGroupTable;
