import {ReactNode, useEffect, useState} from 'react';
import {getZones} from "../ajax/zoneAjax";
import {ZoneView} from "../../../api";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";

interface ZoneTableProps {
    mzkWorkspace: MzkWorkspace;
    greedyVersion: number;
    actionProvider: (z: ZoneView) => ReactNode;
}

function ZoneTable(props: ZoneTableProps) {
    const [zones, setZones] = useState<ZoneView[]>(null)
    useEffect(() => {
        getZones(props.mzkWorkspace, (r: ZoneView[]) => setZones(r));
    }, [props.mzkWorkspace, props.greedyVersion]);
    return (
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Nazwa</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            {zones != null && zones.length ? (
                zones.map((z: ZoneView, index: number) => (
                    <tr key={index}>
                        <td>{index}</td>
                        <td>{z.name}</td>
                        <td>{props.actionProvider(z)}</td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan={4}>Brak danych</td>
                </tr>
            )}
            </tbody>
        </table>
    );
}

export default ZoneTable;
