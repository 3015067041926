import {Component, ReactNode} from 'react';
import Button from "../../common/input/button/Button";
import {Company} from "../../user/current/CurrentUserContext";

interface CompanySwitcherProps {
    initCompany?: Company;
    company?: Company;
    companies: Company[];
    onChangeCompanyFun: (company: Company) => void;
}

class CompanySwitcher extends Component<CompanySwitcherProps> {

    componentDidMount(): void {
        if (this.props.initCompany && !this.props.company) {
            this.props.onChangeCompanyFun(this.props.initCompany);
        } else if (this.props.companies.length === 1) {
            this.props.onChangeCompanyFun(this.props.companies[0]);
        }
    }

    render(): ReactNode {
        if (this.props.companies.length) {
            return this.props.companies.map(c => (
                <Button
                    key={c.id}
                    backgroundColor={this.props.company && this.props.company.id === c.id ? 'Secondary' : null}
                    onClick={() => this.props.onChangeCompanyFun(c)}
                >
                    {c.name}
                </Button>
            ));
        } else {
            return <span>-</span>;
        }
    }
}

export default CompanySwitcher;
