/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateSectionSampleInput,
  SectionSampleView,
} from '../models';
import {
    CreateSectionSampleInputFromJSON,
    CreateSectionSampleInputToJSON,
    SectionSampleViewFromJSON,
    SectionSampleViewToJSON,
} from '../models';

export interface DeleteSectionSampleRequest {
    sectionSampleId: string;
}

export interface GetSectionSamplesRequest {
    sectionId: string;
}

export interface SaveSectionSampleRequest {
    createSectionSampleInput?: CreateSectionSampleInput;
}

export interface UpdateSectionSampleRequest {
    createSectionSampleInput?: CreateSectionSampleInput;
}

/**
 * 
 */
export class SectionSampleEndpointApi extends runtime.BaseAPI {

    /**
     */
    async deleteSectionSampleRaw(requestParameters: DeleteSectionSampleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sectionSampleId === null || requestParameters.sectionSampleId === undefined) {
            throw new runtime.RequiredError('sectionSampleId','Required parameter requestParameters.sectionSampleId was null or undefined when calling deleteSectionSample.');
        }

        const queryParameters: any = {};

        if (requestParameters.sectionSampleId !== undefined) {
            queryParameters['sectionSampleId'] = requestParameters.sectionSampleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sections/samples`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSectionSample(requestParameters: DeleteSectionSampleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSectionSampleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getSectionSamplesRaw(requestParameters: GetSectionSamplesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SectionSampleView>>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling getSectionSamples.');
        }

        const queryParameters: any = {};

        if (requestParameters.sectionId !== undefined) {
            queryParameters['sectionId'] = requestParameters.sectionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sections/samples`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SectionSampleViewFromJSON));
    }

    /**
     */
    async getSectionSamples(requestParameters: GetSectionSamplesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SectionSampleView>> {
        const response = await this.getSectionSamplesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveSectionSampleRaw(requestParameters: SaveSectionSampleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sections/samples`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSectionSampleInputToJSON(requestParameters.createSectionSampleInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveSectionSample(requestParameters: SaveSectionSampleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveSectionSampleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateSectionSampleRaw(requestParameters: UpdateSectionSampleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sections/samples`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSectionSampleInputToJSON(requestParameters.createSectionSampleInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateSectionSample(requestParameters: UpdateSectionSampleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSectionSampleRaw(requestParameters, initOverrides);
    }

}
