/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrewGroupMetaDataView } from './CrewGroupMetaDataView';
import {
    CrewGroupMetaDataViewFromJSON,
    CrewGroupMetaDataViewFromJSONTyped,
    CrewGroupMetaDataViewToJSON,
} from './CrewGroupMetaDataView';
import type { CrewGroupOpennessView } from './CrewGroupOpennessView';
import {
    CrewGroupOpennessViewFromJSON,
    CrewGroupOpennessViewFromJSONTyped,
    CrewGroupOpennessViewToJSON,
} from './CrewGroupOpennessView';

/**
 * 
 * @export
 * @interface CrewGroupView
 */
export interface CrewGroupView {
    /**
     * 
     * @type {CrewGroupMetaDataView}
     * @memberof CrewGroupView
     */
    metaData?: CrewGroupMetaDataView;
    /**
     * 
     * @type {CrewGroupOpennessView}
     * @memberof CrewGroupView
     */
    openness?: CrewGroupOpennessView;
}

/**
 * Check if a given object implements the CrewGroupView interface.
 */
export function instanceOfCrewGroupView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CrewGroupViewFromJSON(json: any): CrewGroupView {
    return CrewGroupViewFromJSONTyped(json, false);
}

export function CrewGroupViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrewGroupView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metaData': !exists(json, 'metaData') ? undefined : CrewGroupMetaDataViewFromJSON(json['metaData']),
        'openness': !exists(json, 'openness') ? undefined : CrewGroupOpennessViewFromJSON(json['openness']),
    };
}

export function CrewGroupViewToJSON(value?: CrewGroupView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metaData': CrewGroupMetaDataViewToJSON(value.metaData),
        'openness': CrewGroupOpennessViewToJSON(value.openness),
    };
}

