/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataSpacePermissionCompanyView } from './DataSpacePermissionCompanyView';
import {
    DataSpacePermissionCompanyViewFromJSON,
    DataSpacePermissionCompanyViewFromJSONTyped,
    DataSpacePermissionCompanyViewToJSON,
} from './DataSpacePermissionCompanyView';

/**
 * 
 * @export
 * @interface CompanyPermissionUserView
 */
export interface CompanyPermissionUserView {
    /**
     * 
     * @type {string}
     * @memberof CompanyPermissionUserView
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPermissionUserView
     */
    companyName?: string;
    /**
     * 
     * @type {Array<DataSpacePermissionCompanyView>}
     * @memberof CompanyPermissionUserView
     */
    availableDataSpaces?: Array<DataSpacePermissionCompanyView>;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyPermissionUserView
     */
    superUser?: boolean;
}

/**
 * Check if a given object implements the CompanyPermissionUserView interface.
 */
export function instanceOfCompanyPermissionUserView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyPermissionUserViewFromJSON(json: any): CompanyPermissionUserView {
    return CompanyPermissionUserViewFromJSONTyped(json, false);
}

export function CompanyPermissionUserViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPermissionUserView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'availableDataSpaces': !exists(json, 'availableDataSpaces') ? undefined : ((json['availableDataSpaces'] as Array<any>).map(DataSpacePermissionCompanyViewFromJSON)),
        'superUser': !exists(json, 'superUser') ? undefined : json['superUser'],
    };
}

export function CompanyPermissionUserViewToJSON(value?: CompanyPermissionUserView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'companyName': value.companyName,
        'availableDataSpaces': value.availableDataSpaces === undefined ? undefined : ((value.availableDataSpaces as Array<any>).map(DataSpacePermissionCompanyViewToJSON)),
        'superUser': value.superUser,
    };
}

