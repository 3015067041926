import {verifyClientResponse} from "../../common/error/resolvers";
import {getCookie} from "../../common/cookie/cookieUtil";
import {choiceOne} from "../../common/random/randomUtil";
import {
    CompanyPermissionUserView,
    DataSpacePermissionCompanyView,
    TimeTableSearchView,
    UserEndpointApi
} from "../../../api";

export function prepareCurrentUser(callback: (u: User) => void) {
    new UserEndpointApi().getUserConfig({headers: prepareSecurityHeaders()})
        .then(r => {
            return new User(r.id, r.nick, mapAssociatedCompaniesToCompanies(r.associatedCompanies));
        })
        .then(u => {
            callback(u);
        })
        .catch(e => {
            callback(null);
            if (e.response.status !== 401) {
                verifyClientResponse(e);
            }
        });

}

export class Company {
    id: string;
    name: string;
    superUser: boolean;
    availableDataSpaces: DataSpacePermissionCompanyView[];

    constructor(id: string, name: string, superUser: boolean, availableDataSpaces: DataSpacePermissionCompanyView[]) {
        this.id = id;
        this.name = name;
        this.superUser = superUser;
        this.availableDataSpaces = availableDataSpaces;
    }
}

export interface MzkWorkspace {
    company: Company,
    space: DataSpacePermissionCompanyView
}

function mapAssociatedCompaniesToCompanies(associatedCompanies: Array<CompanyPermissionUserView>): Company[] {
    return associatedCompanies.map(ac => new Company(
        ac.companyId,
        ac.companyName,
        ac.superUser,
        ac.availableDataSpaces
    ))
}

export class User {
    id: string;
    nick: string;
    associatedCompanies: Company[];
    defaultCompany: Company;
    defaultSpace: DataSpacePermissionCompanyView;
    defaultTimetable: TimeTableSearchView;

    constructor(id: string, nick: string, associatedCompanies: Array<Company>) {
        const defCompany = calculateDefaultCompany(associatedCompanies);
        this.id = id;
        this.nick = nick;
        this.defaultCompany = defCompany;
        this.defaultSpace = calculateDefaultDataSpace(defCompany);
        this.defaultTimetable = null;
        this.associatedCompanies = associatedCompanies;
    }

    mzkWorkspace(): MzkWorkspace {
        return {company: this.defaultCompany, space: this.defaultSpace};
    }
}

export function prepareSecurityHeaders() {
    return {
        'Authorization': getCookie('Authorization'),
        'Content-Type': 'application/json'
    };
}

function calculateDefaultCompany(associatedCompanies: Array<Company>): Company {
    const companiesWithSuperUser = associatedCompanies.filter(c => c.superUser);
    if (companiesWithSuperUser.length > 0) {
        return choiceOne(companiesWithSuperUser);
    } else if (associatedCompanies.length > 0) {
        return choiceOne(associatedCompanies);
    } else {
        return null;
    }
}

function calculateDefaultDataSpace(defaultCompany: Company): DataSpacePermissionCompanyView{
    if (defaultCompany) {
        const defaultSpaces = defaultCompany.availableDataSpaces.filter(s => s.defaultSpace);
        if(defaultSpaces.length > 0){
            return defaultSpaces[0];
        }else {
            return null;
        }
    }
}
