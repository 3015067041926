import {ReactNode, useEffect, useState} from 'react';
import StreetTable from "../table/StreetTable";
import AddNewStreetPanel from "../new/AddNewStreetPanel";
import Button from "../../common/input/button/Button";
import StreetTableCriteria from "../table/StreetTableCriteria";
import {getStreetPreview, getStreets} from "../ajax/streetAjax";
import {uxNull} from "../../common/string/stringUtil";
import {hasSuperPermissionInSpace} from "../../common/superuser/permissionUtil";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {StreetSearchView, StreetView, TownView} from "../../../api";
import Af from "../../common/ahreffun/Af";
import EditStreetPanel from "../edit/EditStreetPanel";

interface StreetTabSectionStreetProps {
    townForStreetSearch: TownView;
    showStreetTableForTownFun: (town: TownView) => void;
    mzkWorkspace: MzkWorkspace
}

function StreetTabSectionStreet(props: StreetTabSectionStreetProps) {
    const [showNewStreetForm, setShowNewStreetForm] = useState(false);
    const [streets, setStreets] = useState<StreetSearchView[]>([]);
    const [query, setQuery] = useState('');
    const [appliedLimit, setAppliedLimit] = useState(false);
    const [streetIdToEdit, setStreetIdToEdit] = useState<string>();
    const tryUpdateStreetData = (): void => {
        if (props.mzkWorkspace) {
            if ((query && query.length > 1) || props.townForStreetSearch) {
                getStreets(query, props.mzkWorkspace, props.townForStreetSearch, (r: any) => {
                    setStreets(r);
                    setAppliedLimit(false);
                });
            } else {
                getStreetPreview(props.mzkWorkspace, r => {
                    setStreets(r.streets);
                    setAppliedLimit(r.appliedLimit);
                });
            }
        }
    }
    useEffect(() => {
        tryUpdateStreetData()
    }, [props.mzkWorkspace, query, props.townForStreetSearch]);
    const actionProvider = (): (s: StreetSearchView) => ReactNode => {
        if (hasSuperPermissionInSpace(props.mzkWorkspace)){
            return (s: StreetSearchView) => <Af onClick={()=>setStreetIdToEdit(s.street.id)}>Edytuj</Af>;
        }else{
            return (s: StreetSearchView) => uxNull();
        }
    }
    return <div id='simpleMainContentSplit'>
        <div>
            <h3>Wyszukiwarka ulic</h3>
            <StreetTableCriteria
                onQueryUpdatedFun={q => setQuery(q)}
                townToSearch={props.townForStreetSearch}
                showStreetTableForTownFun={props.showStreetTableForTownFun}
                query={query}/>
            <StreetTable streetData={streets} appliedLimit={appliedLimit} actionProvider={actionProvider()}/>
            {hasSuperPermissionInSpace(props.mzkWorkspace) &&
                <Button onClick={() => setShowNewStreetForm(s => !s)}>Stwórz nową
                    ulicę</Button>}
        </div>
        {showNewStreetForm &&
            <AddNewStreetPanel mzkWorkspace={props.mzkWorkspace}
                               hideNewStreetFormFun={(r) => {
                                   setShowNewStreetForm(false);
                                   r && tryUpdateStreetData();
                               }}/>}
        {streetIdToEdit && <EditStreetPanel streetId={streetIdToEdit}
                                            mzkWorkspace={props.mzkWorkspace}
                                            onClose={(r)=>{
                                                setStreetIdToEdit(null);
                                                r && tryUpdateStreetData();
                                            }}/>}
    </div>;
}

export default StreetTabSectionStreet;
