/* tslint:disable */
/* eslint-disable */
export * from './AsyncTaskEndpointApi';
export * from './CompanyEndpointApi';
export * from './ConditionEndpointApi';
export * from './CoursesEndpointApi';
export * from './CrewEndpointApi';
export * from './LineSchemaEndpointApi';
export * from './LineSchemaPlanEndpointApi';
export * from './NavpointEndpointApi';
export * from './OverriddenTimesCoursesEndpointApi';
export * from './PlatformEndpointApi';
export * from './SectionEndpointApi';
export * from './SectionSampleEndpointApi';
export * from './SpaceEndpointApi';
export * from './StationEndpointApi';
export * from './StationZoneEndpointApi';
export * from './StreetEndpointApi';
export * from './TestControllerApi';
export * from './TimetableEndpointApi';
export * from './TimetableGroupEndpointApi';
export * from './TownEndpointApi';
export * from './UserEndpointApi';
export * from './VariableEndpointApi';
export * from './VariableSelectionsEndpointApi';
export * from './ZoneEndpointApi';
