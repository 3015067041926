import {RequestStopInput} from "../../requeststop/RequestStopInput";
import GpsInput from "../../gps/GpsInput";
import Button from "../../common/input/button/Button";
import {addToast} from "../../common/toast/toastUtil";
import {nullIfBlank} from "../../common/string/stringUtil";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import Help from "../../common/tooltip/Help";
import Close from "../../common/ahreffun/Close";
import Af from "../../common/ahreffun/Af";
import StreetPopupSelector from "../../street/popup/StreetPopupSelector";
import {
    ConditionMetaDataView, ConditionVisibilityRuleTypeView,
    NavPointMetaDataView,
    NavPointSettings,
    PlatformEndpointApi,
    StreetSearchView
} from "../../../api";
import {prepareName} from "../../common/navpoint/navpointUtil";
import {ChangeEvent, FormEvent, useState} from "react";
import {NavPointSettingsRoller} from "../../common/navpoint/NavPointSettingsRoller";
import ConditionPopupSelector from "../../condition/popup/ConditionPopupSelector";

interface AddNewPlatformFormProps {
    mzkWorkspace: MzkWorkspace;
    hideNewPlatformFormFun: (refresh: boolean) => void;
    station: NavPointMetaDataView;
}

function AddNewPlatformForm(props: AddNewPlatformFormProps) {
    const [showStreetForm, setShowStreetForm] = useState(false);
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [technicalName, setTechnicalName] = useState('');
    const [street, setStreet] = useState<StreetSearchView>(null);
    const [requestStop, setRequestStop] = useState<boolean>(null);
    const [capacity, setCapacity] = useState<number>(1);
    const [gps, setGps] = useState<string[]>(['', '']);
    const [settings, setSettings] = useState<NavPointSettings>({
        passengersFrom: true, passengersTo: true, depot: false, technicalStop: false});
    const [showSettingsRoller, setShowSettingsRoller] = useState(false);
    const [showConditionSelector, setShowConditionSelector] = useState(false);
    const [condition, setCondition] = useState<ConditionMetaDataView>();
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (verifyForm()) {
            new PlatformEndpointApi().savePlatform({
                platformInputData: {
                    navPointMetaData: {
                        name: nullIfBlank(name),
                        shortName: nullIfBlank(shortName),
                        technicalName: nullIfBlank(technicalName),
                        dataSpaceId: props.mzkWorkspace.space.id,
                        parentId: props.station.id,
                        conditionId: condition ? condition.id : null,
                        streetId: street ? street.street.id : null,
                        gpsX: parseFloat(gps![0]),
                        gpsY: parseFloat(gps![1]),
                        settings: settings,
                        companyId: props.mzkWorkspace.company.id
                    },
                    requestStop: requestStop,
                    capacity: capacity,
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Poprawnie dodano nowy peron');
                    props.hideNewPlatformFormFun(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const verifyForm = () => {
        const gpsX = gps![0];
        const gpsY = gps![1];
        if ((gpsX.length > 0 && gpsY.length === 0) || (gpsX.length === 0 && gpsY.length > 0)) {
            addToast('Wypelnij oba pola GPS');
            return false;
        } else if (gpsX.length > 0 && gpsY.length > 0 && (isNaN(parseFloat(gpsX)) || isNaN(parseFloat(gpsY)))) {
            addToast('Wypelnij oba pola GPS poprawnie');
            return false;
        } else if (!name && !shortName && !technicalName) {
            addToast('Nie wypełniono nazwy');
            return false;
        }else if(capacity < 1 || capacity > 10){
            addToast('Pojemność musi być w zakresie <1, 10>');
            return false;
        }
        return true;
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={()=>props.hideNewPlatformFormFun(false)}/></span>
        </div>
        <h3>Dodawanie nowego peronu dla {prepareName(props.station)}</h3>
        <form>
            <label>Nazwa:</label>
            <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <label>Nazwa skrócona:</label>
            <input name='shortName' value={shortName}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setShortName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <label>Nazwa techniczna:</label>
            <input name='technicalName' value={technicalName}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setTechnicalName(e.target.value)} maxLength={50}/>
            <br/>
            <RequestStopInput value={requestStop} onChange={(v: boolean) => setRequestStop(v)}/>
            <br/>
            <span>Warunek: </span>
            {condition ? <>
                {condition.name}
                <Af onClick={() => setCondition(null)}>Usuń warunek</Af>
            </> : 'nie ustawiono'}
            <Af onClick={() => setShowConditionSelector(true)}>Warunek</Af>
            {showConditionSelector &&
                <ConditionPopupSelector
                    company={props.mzkWorkspace.company}
                    visibilityRule={ConditionVisibilityRuleTypeView.NavPoint}
                    closePopup={() => setShowConditionSelector(false)}
                    selectConditionFun={(c) => setCondition(c)}
                />}
            <br />
            <label>Ulica:</label>
            {street ?
                <span> {`${street.street.name}(${street.town.name})`} </span> :
                <span> nie wybrano ulicy </span>}
            <Af onClick={() => setShowStreetForm(true)}>Wybór ulicy</Af>
            {street && <Af onClick={() => setStreet(null)}>Usuń ulicę</Af>}
            {showStreetForm &&
                <StreetPopupSelector closePopup={() => setShowStreetForm(s => !s)}
                                     mzkWorkspace={props.mzkWorkspace}
                                     onSelectStreet={(s: StreetSearchView) => {
                                         setStreet(s);
                                         setShowStreetForm(false);
                                     }}/>}
            <br/>
            <GpsInput value={gps} onChange={(v: string[]) => setGps(v)}/>
            <br/>
            <label>Pojemność:</label>
            <input type='number' value={capacity} onChange={e=>setCapacity(parseInt(e.target.value))} min={1} max={10} step={1} />
            <Help>Mierzone w autobusach. Przyjęty przelicznik: 2 autobusy = 1 tramwaj</Help>
            <br/>
            <NavPointSettingsRoller onChangeVisibility={()=>setShowSettingsRoller(s=>!s)} isOpen={showSettingsRoller}
                                    settings={settings} onChangeSettings={(s)=>setSettings(s)} />
            <Button onClick={onSubmit}>Dodaj</Button>
        </form>
    </div>;
}

const getErrorMap = () => {
    const map = new Map();
    map.set("not_exist", (r: any) => {
        if (r.data.name === 'station') {
            addToast('Stacja nie istnieje');
        }
    });
    map.set("uniqueness", (r: any) => {
        if (r.data.name === 'technicalName') {
            addToast('Nazwa techniczna nie jest unikalna');
        } else if (r.data.name === 'name') {
            addToast('Nazwa nie jest unikalna');
        }
    });
    return map;
}
export default AddNewPlatformForm;
