/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavPointMetaDataView } from './NavPointMetaDataView';
import {
    NavPointMetaDataViewFromJSON,
    NavPointMetaDataViewFromJSONTyped,
    NavPointMetaDataViewToJSON,
} from './NavPointMetaDataView';
import type { PlatformDetailsView } from './PlatformDetailsView';
import {
    PlatformDetailsViewFromJSON,
    PlatformDetailsViewFromJSONTyped,
    PlatformDetailsViewToJSON,
} from './PlatformDetailsView';

/**
 * 
 * @export
 * @interface NavPointPlatformView
 */
export interface NavPointPlatformView {
    /**
     * 
     * @type {NavPointMetaDataView}
     * @memberof NavPointPlatformView
     */
    metaData?: NavPointMetaDataView;
    /**
     * 
     * @type {PlatformDetailsView}
     * @memberof NavPointPlatformView
     */
    details?: PlatformDetailsView;
}

/**
 * Check if a given object implements the NavPointPlatformView interface.
 */
export function instanceOfNavPointPlatformView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NavPointPlatformViewFromJSON(json: any): NavPointPlatformView {
    return NavPointPlatformViewFromJSONTyped(json, false);
}

export function NavPointPlatformViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavPointPlatformView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metaData': !exists(json, 'metaData') ? undefined : NavPointMetaDataViewFromJSON(json['metaData']),
        'details': !exists(json, 'details') ? undefined : PlatformDetailsViewFromJSON(json['details']),
    };
}

export function NavPointPlatformViewToJSON(value?: NavPointPlatformView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metaData': NavPointMetaDataViewToJSON(value.metaData),
        'details': PlatformDetailsViewToJSON(value.details),
    };
}

