/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SectionTimeView } from './SectionTimeView';
import {
    SectionTimeViewFromJSON,
    SectionTimeViewFromJSONTyped,
    SectionTimeViewToJSON,
} from './SectionTimeView';

/**
 * 
 * @export
 * @interface SectionSampleView
 */
export interface SectionSampleView {
    /**
     * 
     * @type {string}
     * @memberof SectionSampleView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SectionSampleView
     */
    sectionId: string;
    /**
     * 
     * @type {Date}
     * @memberof SectionSampleView
     */
    sampleDate: Date;
    /**
     * 
     * @type {number}
     * @memberof SectionSampleView
     */
    defaultTravelSec: number;
    /**
     * 
     * @type {number}
     * @memberof SectionSampleView
     */
    defaultWaitingSec?: number;
    /**
     * 
     * @type {Array<SectionTimeView>}
     * @memberof SectionSampleView
     */
    times: Array<SectionTimeView>;
}

/**
 * Check if a given object implements the SectionSampleView interface.
 */
export function instanceOfSectionSampleView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sectionId" in value;
    isInstance = isInstance && "sampleDate" in value;
    isInstance = isInstance && "defaultTravelSec" in value;
    isInstance = isInstance && "times" in value;

    return isInstance;
}

export function SectionSampleViewFromJSON(json: any): SectionSampleView {
    return SectionSampleViewFromJSONTyped(json, false);
}

export function SectionSampleViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionSampleView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sectionId': json['sectionId'],
        'sampleDate': (new Date(json['sampleDate'])),
        'defaultTravelSec': json['defaultTravelSec'],
        'defaultWaitingSec': !exists(json, 'defaultWaitingSec') ? undefined : json['defaultWaitingSec'],
        'times': ((json['times'] as Array<any>).map(SectionTimeViewFromJSON)),
    };
}

export function SectionSampleViewToJSON(value?: SectionSampleView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sectionId': value.sectionId,
        'sampleDate': (value.sampleDate.toISOString().substr(0,10)),
        'defaultTravelSec': value.defaultTravelSec,
        'defaultWaitingSec': value.defaultWaitingSec,
        'times': ((value.times as Array<any>).map(SectionTimeViewToJSON)),
    };
}

