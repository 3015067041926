import {ChangeEvent, MouseEvent, useState} from 'react';
import Close from "../../common/ahreffun/Close";
import Button from "../../common/input/button/Button";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {addToast} from "../../common/toast/toastUtil";
import {ZoneEndpointApi} from "../../../api";

interface AddNewZoneFormProps {
    hideNewZoneFormFun: () => void;
    mzkWorkspace: MzkWorkspace
}

function AddNewZoneForm(props: AddNewZoneFormProps) {
    const [name, setName] = useState('');
    const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (verifyForm()) {
            new ZoneEndpointApi().saveZone({
                addZoneInput: {
                    name: name,
                    companyId: props.mzkWorkspace.company.id,
                    spaceId: props.mzkWorkspace.space.id
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano nową strefę');
                    props.hideNewZoneFormFun();
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const getErrorMap = () => {
        const map = new Map<string, (r: any) => void>();
        map.set("uniqueness", r => {
            if (r.data.name === 'name') {
                addToast('Nazwa strefy musi być unikalna na poziomie Data Space i firmy');
            }
        });
        return map;
    }
    const verifyForm = () => {
        if (!name.length) {
            addToast('Nazwa nie może być pusta');
            return false;
        }
        return true;
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={props.hideNewZoneFormFun}/></span>
        </div>
        <h3>Dodawanie nowej strefy {name}</h3>
        <form>
            <label>Nazwa*:</label>
            <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <Button onClick={onSubmit}>Dodaj</Button>
        </form>
    </div>;
}

export default AddNewZoneForm;
