import {ChangeEvent, useState} from 'react';
import Close from "../../../common/ahreffun/Close";
import {ConditionEndpointApi, ConditionTypeView} from "../../../../api";
import Button from "../../../common/input/button/Button";
import {addToast} from "../../../common/toast/toastUtil";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {DAYS_OF_WEEK} from "../../../common/date/dateUtil";
import {Company, prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";

interface NewDayOfWeekConditionFormProps {
    closeFun: (refresh: boolean) => void;
    company: Company;
}

function NewDayOfWeekConditionForm(props: NewDayOfWeekConditionFormProps) {
    const [name, setName] = useState('');
    const [dayOfWeek, setDayOfWeek] = useState<number[]>([]);
    const toggleDay = (day: number) => {
        setDayOfWeek(d => {
            const index = d.indexOf(day);
            if (index !== -1) {
                return [...d].splice(index, 1);
            } else {
                return [...d, day];
            }
        })
    }
    const onSave = () => {
        if (validate()) {
            new ConditionEndpointApi().saveDayOfWeekCondition({
                dayOfWeekConditionInput: {
                    metaData: {
                        name: name,
                        type: ConditionTypeView.DayOfWeek,
                        companyId: props.company.id
                    },
                    details: {
                        days: dayOfWeek
                    }
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano nowy warunek');
                    props.closeFun(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const validate = () => {
        if (!name) {
            addToast('Uzupełnij nazwę');
            return false;
        } else if (dayOfWeek.length < 1) {
            addToast('Musisz wybrać przynajmniej jeden dzień');
            return false;
        }
        return true;
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.closeFun(false)}/></span>
            </div>
            <h3>Nowy warunek - dni tygodnia</h3>
            <label>Nazwa:</label>
            <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                   maxLength={50} required={true}/>
            <br/>
            {DAYS_OF_WEEK.map((dName, index) =>
                <Button key={index} backgroundColor={dayOfWeek.indexOf(index) > -1 ? 'Secondary' : 'Primary'}
                        onClick={() => toggleDay(index)}>{dName}</Button>)}
            <br/>
            <Button onClick={onSave}>Stwórz</Button>
        </div>
    );
}

function getErrorMap() {
    const map = new Map<string, (r: any) => void>();
    map.set("uniqueness", r => {
        if (r.data.name === 'name') {
            addToast('Nazwa musi być unikalna na poziomie firmy');
        }
    });
    return map;
}

export default NewDayOfWeekConditionForm;
