/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionSearchInput
 */
export interface SectionSearchInput {
    /**
     * 
     * @type {string}
     * @memberof SectionSearchInput
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof SectionSearchInput
     */
    navPointId: string;
    /**
     * 
     * @type {number}
     * @memberof SectionSearchInput
     */
    position?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionSearchInput
     */
    timeTableId?: string;
    /**
     * 
     * @type {Date}
     * @memberof SectionSearchInput
     */
    date: Date;
}

/**
 * Check if a given object implements the SectionSearchInput interface.
 */
export function instanceOfSectionSearchInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "navPointId" in value;
    isInstance = isInstance && "date" in value;

    return isInstance;
}

export function SectionSearchInputFromJSON(json: any): SectionSearchInput {
    return SectionSearchInputFromJSONTyped(json, false);
}

export function SectionSearchInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionSearchInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'navPointId': json['navPointId'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'timeTableId': !exists(json, 'timeTableId') ? undefined : json['timeTableId'],
        'date': (new Date(json['date'])),
    };
}

export function SectionSearchInputToJSON(value?: SectionSearchInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'navPointId': value.navPointId,
        'position': value.position,
        'timeTableId': value.timeTableId,
        'date': (value.date.toISOString().substr(0,10)),
    };
}

