import {AdoptedNavPoint} from "../../../api/models/AdoptedNavPoint";
import {NavPointMetaDataView} from "../../../api/models/NavPointMetaDataView";
import {ReactNode} from "react";

export function prepareName(navPoint: NavPointMetaDataView): string {
    if (navPoint.technicalName) {
        return navPoint.technicalName;
    }
    if (navPoint.name) {
        return navPoint.name;
    }
    return '-';
}

export function prepareNameWithAncestor(adoptedNavPoint: AdoptedNavPoint): ReactNode {
    if (adoptedNavPoint.navPointMetaDataBaseParent) {
        return `${prepareName(adoptedNavPoint.navPointMetaDataBaseParent)}(${prepareName(adoptedNavPoint.navPointMetaData)})`;
    }
    return prepareName(adoptedNavPoint.navPointMetaData);
}
