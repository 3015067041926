import {ReactNode} from 'react';
import {StreetSearchView} from "../../../api";
import {uxNull} from "../../common/string/stringUtil";

interface StreetTableRowsProps {
    streetsWithTown: StreetSearchView[];
    availableActionsFun: (s: StreetSearchView) => ReactNode;
}

function StreetTableRows(props: StreetTableRowsProps) {
    return props.streetsWithTown.length ? (
        props.streetsWithTown.map((s, index) => (
            <tr key={index}>
                <td>{index}</td>
                <td>{s.street.name}</td>
                <td>{uxNull(s.street.shortName)}</td>
                <td>{s.town.name}</td>
                <td>{props.availableActionsFun(s)}</td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={5}>Brak danych. Zmień kryteria</td>
        </tr>
    );
}

export default StreetTableRows;
