import Roller from "../../../common/roller/Roller";
import {useState} from "react";
import {SectionSample, SectionSampleForm, validateSectionSample} from "../form/SectionSampleForm";
import Button from "../../../common/input/button/Button";
import {SectionSampleEndpointApi, SectionView} from "../../../../api";
import {stringToDate} from "../../../common/date/dateUtil";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {addToast} from "../../../common/toast/toastUtil";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {prepareSectionErrorMap} from "../../new/AddSectionPanel";

interface AddNewSectionSampleRollerProps {
    initialSampleDate: string;
    section: SectionView;
    onAddSample: () => void;
}

export function AddNewSectionSampleRoller(props: AddNewSectionSampleRollerProps) {
    const [open, setOpen] = useState(false);
    const [sample, setSample] = useState<SectionSample>({
        defaultTravelSec: 0,
        defaultWaitingSec: null,
        times: [],
        sampleDate: props.initialSampleDate,
    });
    const onSave = () => {
        if (validateSectionSample(sample)) {
            new SectionSampleEndpointApi().saveSectionSample({
                createSectionSampleInput: {
                    sectionId: props.section.id,
                    sampleDate: stringToDate(sample.sampleDate),
                    defaultWaitingSec: sample.defaultWaitingSec,
                    defaultTravelSec: sample.defaultTravelSec,
                    times: sample.times
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano pomiar');
                    props.onAddSample();
                    setSample(s => {return {
                        defaultTravelSec: 0,
                        defaultWaitingSec: null,
                        times: [],
                        sampleDate: s.sampleDate,
                    };});
                    setOpen(false);
                }).catch(e => {
                verifyClientResponse(e, prepareSectionErrorMap());
            })
        }
    }
    return <Roller
        title={'Nowy pomiar'}
        open={open} onChangeVisibility={() => setOpen(s => !s)}>
        <SectionSampleForm sample={sample} onChangeSample={setSample}/>
        <Button onClick={onSave}>Zapisz</Button>
    </Roller>;
}
