import {NavpointEndpointApi} from "../../../api";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";

export function logNavPointUsage(id: string) {
    new NavpointEndpointApi()
        .logNavPointUsage({usageInput: {id: id}},
            {headers: prepareSecurityHeaders()})
        .catch(e => verifyClientResponse(e));
}
