import Tooltip from "../../../common/tooltip/Tooltip";
import Af from "../../../common/ahreffun/Af";
import {ReactNode} from "react";
import {TimetableView} from "../../../../api";

interface LinePlantTableActionProps {
    timeTable: TimetableView;
    index: number;
    totalLength: number;
    deletePartFun: (index: number) => void;
    changePartOrderFun: (currentIndex: number, destination: number) => void;
}

function LinePlantTableAction(props: LinePlantTableActionProps) {
    const deleteAction = (): ReactNode => {
        return <Af onClick={() => props.deletePartFun(props.index)}>Usuń</Af>;
    }
    const upperAction = (): ReactNode => {
        if (props.index !== 0) {
            const elements: ReactNode[] = [<Af key={1} onClick={prepareChangeOrderFun(0)}>Na górę</Af>];
            const indexAfterOneUp = props.index - 1;
            if (indexAfterOneUp !== 0) {
                elements.push(<Af key={2} onClick={prepareChangeOrderFun(indexAfterOneUp)}>Jedno wyżej</Af>);
            }
            return elements;
        }
    }
    const lowerAction = (): ReactNode => {
        if (props.index !== (props.totalLength - 1)) {
            const elements: ReactNode[] = [];
            const indexAfterOneLower = props.index + 1;
            if (indexAfterOneLower !== props.totalLength - 1) {
                elements.push(<Af key={1} onClick={prepareChangeOrderFun(props.index + 1)}>Jedno niżej</Af>);
            }
            elements.push(<Af key={2} onClick={prepareChangeOrderFun(props.totalLength - 1)}>Na dół</Af>);
            return elements;
        }
    }
    const prepareChangeOrderFun = (destination: number): () => void => {
        return () => {
            props.changePartOrderFun(props.index, destination);
        };
    }

    if (!props.timeTable.editable) {
        return <td>-</td>
    }
    return (
        <td>
            <Tooltip content={<>
                {upperAction()}
                {lowerAction()}
                {deleteAction()}
            </>}><Af>Edycja</Af></Tooltip>
        </td>
    );
}

export default LinePlantTableAction;
