/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateNewVariableValueInput } from './CreateNewVariableValueInput';
import {
    CreateNewVariableValueInputFromJSON,
    CreateNewVariableValueInputFromJSONTyped,
    CreateNewVariableValueInputToJSON,
} from './CreateNewVariableValueInput';

/**
 * 
 * @export
 * @interface CreateNewVariableInput
 */
export interface CreateNewVariableInput {
    /**
     * 
     * @type {string}
     * @memberof CreateNewVariableInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewVariableInput
     */
    companyId?: string;
    /**
     * 
     * @type {Array<CreateNewVariableValueInput>}
     * @memberof CreateNewVariableInput
     */
    values?: Array<CreateNewVariableValueInput>;
}

/**
 * Check if a given object implements the CreateNewVariableInput interface.
 */
export function instanceOfCreateNewVariableInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateNewVariableInputFromJSON(json: any): CreateNewVariableInput {
    return CreateNewVariableInputFromJSONTyped(json, false);
}

export function CreateNewVariableInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNewVariableInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(CreateNewVariableValueInputFromJSON)),
    };
}

export function CreateNewVariableInputToJSON(value?: CreateNewVariableInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'companyId': value.companyId,
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(CreateNewVariableValueInputToJSON)),
    };
}

