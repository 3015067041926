import {FC} from 'react';
import ZoneAssigningZonesTable from "../assigning/ZoneAssigningZonesTable";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";

interface ZoneTabSectionAssigningProps {
    mzkWorkspace: MzkWorkspace
}

const ZoneTabSectionAssigning: FC<ZoneTabSectionAssigningProps> = (props) => {
    return (
        <div>
            <ZoneAssigningZonesTable mzkWorkspace={props.mzkWorkspace}/>
        </div>
    );
}

export default ZoneTabSectionAssigning;
