import Af from "../../common/ahreffun/Af";

interface StreetSearchTypeSwitcherProps {
    searchStreet: boolean;
    onChangeSearchType: () => void;
}

const StreetSearchTypeSwitcher = (props: StreetSearchTypeSwitcherProps) => {
    if (props.searchStreet) {
        return <Af onClick={() => props.onChangeSearchType()}>Chcę szukać miasta</Af>;
    } else {
        return <Af onClick={() => props.onChangeSearchType()}>Chcę szukać ulic</Af>;
    }
}

export default StreetSearchTypeSwitcher;