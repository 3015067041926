import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import PlatformTableRows from "./PlatformTableRows";
import {NavpointEndpointApi, NavPointMetaDataView, StructuredNavPoints} from "../../../api";
import {ReactNode, useEffect, useState} from "react";

interface PlatformTableProps {
    mzkWorkspace: MzkWorkspace;
    query: string;
    actionProviderFun: (a0: NavPointMetaDataView) => ReactNode;
    greedyPlatformVersion: number;
    limit: number;
}

interface PlatformData {
    platforms: StructuredNavPoints[];
    maxDepth: number;
    appliedLimit: boolean;
}

export function PlatformTable(props: PlatformTableProps) {
    const [platformData, setPlatformData] = useState<PlatformData>({
        platforms: null,
        maxDepth: 2,
        appliedLimit: false,
    });
    const onDataUpdated = () => {
        if (props.mzkWorkspace && props.mzkWorkspace.space) {
            if (props.query && props.query.length >= 1) {
                new NavpointEndpointApi()
                    .initiateNavPointStructureSearch({
                        structuredNavPointInitSearchRequest: {
                            query: props.query,
                            spaceIds: [props.mzkWorkspace.space.id],
                            parentNavPointId: null,
                            companyId: props.mzkWorkspace.company.id
                        }
                    }, {headers: prepareSecurityHeaders()})
                    .then((r: StructuredNavPoints[]) => {
                        setPlatformData({
                            platforms: r,
                            maxDepth: calculateMaxDepth(r),
                            appliedLimit: false
                        })
                    })
                    .catch((e: Error) => {
                        verifyClientResponse(e);
                    });
            } else {
                new NavpointEndpointApi()
                    .navPointStructurePreview({
                        structuredNavPointPreviewInput: {
                            spaceIds: [props.mzkWorkspace.space.id],
                            companyId: props.mzkWorkspace.company.id,
                            limit: props.limit
                        }
                    }, {headers: prepareSecurityHeaders()})
                    .then((r) => {
                        setPlatformData({
                            platforms: r.navPoints,
                            maxDepth: calculateMaxDepth(r.navPoints),
                            appliedLimit: r.appliedLimit
                        })
                    })
                    .catch((e: Error) => {
                        verifyClientResponse(e);
                    });
            }
        } else {
            setPlatformData({platforms: [], maxDepth: 1, appliedLimit: false})
        }
    }
    useEffect(() => {
        onDataUpdated();
    }, [props.query, props.greedyPlatformVersion, props.mzkWorkspace]);
    return (
        <>
            <table>
                <thead>
                <tr className='tableHeader'>
                    <th colSpan={platformData.maxDepth}>Węzły</th>
                    <th>Akcje</th>
                </tr>
                </thead>
                <tbody>
                <PlatformTableRows
                    platformData={platformData.platforms}
                    actionProviderFun={props.actionProviderFun}
                    maxDepth={platformData.maxDepth}
                    query={props.query}
                />
                {platformData.appliedLimit && <tr>
                    <td colSpan={platformData.maxDepth + 1}>Prezentowano jedynie część danych.</td>
                </tr>}
                </tbody>
            </table>
        </>
    );
}

const calculateMaxDepth = (collection: StructuredNavPoints[]): number => {
    let maxDepth = 0;

    for (const node of collection) {
        const depth = findDepth(node);
        if (depth > maxDepth) {
            maxDepth = depth;
        }
    }

    return maxDepth;
}

const findDepth = (node: StructuredNavPoints): number => {
    if (node.children.length === 0) {
        return 1;
    } else {
        const childDepths = node.children.map(child => findDepth(child));
        return 1 + Math.max(...childDepths);
    }
}
