import Af from "../../common/ahreffun/Af";
import {mapBooleanToDescription} from "../../common/boolean/booleanUtil";
import {verifyClientResponse} from "../../common/error/resolvers";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {TimetableEndpointApi, TimetableView} from "../../../api";
import {stringDateFormatToDate} from "../../common/date/dateUtil";
import {useContext} from "react";
import {CurrentUserContext} from "../../CurrentUserContext";

interface TimeTableTableProps {
    timeTables: TimetableView[];
    onSelect: (timetable: TimetableView) => void;
    onEdit: (timetableId: string) => void;
}

function TimetableTable(props: TimeTableTableProps) {
    const currentUser = useContext(CurrentUserContext);
    const selectTimeTable = (id: string): void => {
        new TimetableEndpointApi().getTimetableDetails({id: id}, {headers: prepareSecurityHeaders()})
            .then(r => {
                props.onSelect(r);
            })
            .catch(e => verifyClientResponse(e));
        new TimetableEndpointApi()
            .logTimetableUsage({usageInput: {id: id}},
                {headers: prepareSecurityHeaders()})
            .catch(e => verifyClientResponse(e));
    }
    return (
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Nazwa</th>
                <th>Data przygotowania</th>
                <th>Edytowalny</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            {props.timeTables.map((s, index) => <tr key={index}>
                <td>{index}</td>
                <td>{s.name}</td>
                <td>{stringDateFormatToDate(s.preparationDate.toISOString())}</td>
                <td>{mapBooleanToDescription(s.editable)}</td>
                <td>
                    {currentUser.user.defaultCompany.superUser && s.editable && <Af onClick={()=> props.onEdit(s.id)}>Edycja</Af>}
                    <Af onClick={() => selectTimeTable(s.id)}>Wejdź</Af>
                </td>
            </tr>)}
            </tbody>
        </table>
    );
}

export default TimetableTable;
