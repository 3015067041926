import {DateOfMonth, MONTHS} from './dateUtil';
import Button from '../input/button/Button';
import {ChangeEvent, Component} from "react";
import {addToast} from "../toast/toastUtil";

interface DateOfMonthInputProps {
    onAddFun: (dateOfMonth: DateOfMonth) => void;
}

class DateOfMonthInput extends Component<DateOfMonthInputProps, { dateOfMonth: DateOfMonth }> {
    state = {
        dateOfMonth: {day: 0, month: 0}
    };

    handleDayChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedDay = parseInt(event.target.value, 10);
        this.setState({
            dateOfMonth: {
                ...this.state.dateOfMonth,
                day: selectedDay
            }
        });
    };

    handleMonthChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedMonth = parseInt(event.target.value, 10);
        this.setState({
            dateOfMonth: {
                ...this.state.dateOfMonth,
                month: selectedMonth
            }
        });
    };

    validateDate = (dateOfMonth: DateOfMonth): boolean => {
        const day = dateOfMonth.day + 1;
        const month = dateOfMonth.month + 1;
        return !(
            (month === 2 && day > 28) ||
            ([4, 6, 9, 11].includes(month) && day > 30) ||
            (![2, 4, 6, 9, 11].includes(month) && day > 31)
        );
    };

    onAdd = () => {
        if (this.validateDate(this.state.dateOfMonth)) {
            this.props.onAddFun(this.state.dateOfMonth);
            this.setState({
                dateOfMonth: {day: 0, month: 0}
            });
        } else {
            addToast('Wybrano niepoprawną datę');
        }
    };

    render() {
        return (
            <div>
                <select id="daySelect" onChange={this.handleDayChange} value={this.state.dateOfMonth.day}>
                    {Array.from({length: 31}, (_, i) => (
                        <option key={i} value={i}>
                            {i + 1}
                        </option>
                    ))}
                </select>
                <select id="monthSelect" onChange={this.handleMonthChange} value={this.state.dateOfMonth.month}>
                    {MONTHS.map((monthName: string, index: number) => (
                        <option key={index} value={index}>
                            {monthName}
                        </option>
                    ))}
                </select>
                <Button onClick={this.onAdd}>Dodaj</Button>
            </div>
        );
    }
}

export default DateOfMonthInput;
