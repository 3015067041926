import {CurrentUserContext} from "../../CurrentUserContext";
import {mapBooleanToDescription} from "../../common/boolean/booleanUtil";
import {useContext} from "react";

const SpaceTab = () => {
    const currentUser = useContext(CurrentUserContext);
    return (
        <>
            <h2>Space</h2>
            <table>
                <thead>
                <tr className='tableHeader'>
                    <th>Lp</th>
                    <th>Nazwa</th>
                    <th>Uprawnienia SuperUser</th>
                </tr>
                </thead>
                <tbody>
                {currentUser.user.defaultCompany.availableDataSpaces.map(
                    (s: any, index: number) => (
                        <tr key={s.id}>
                            <td>{index}</td>
                            <td>{s.name}</td>
                            <td>{mapBooleanToDescription(s.superUser)}</td>
                        </tr>
                    )
                )}
                </tbody>
            </table>
        </>
    );
};

export default SpaceTab;
