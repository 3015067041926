/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LineSchemaPlanPartView } from './LineSchemaPlanPartView';
import {
    LineSchemaPlanPartViewFromJSON,
    LineSchemaPlanPartViewFromJSONTyped,
    LineSchemaPlanPartViewToJSON,
} from './LineSchemaPlanPartView';

/**
 * 
 * @export
 * @interface LineSchemaPlanView
 */
export interface LineSchemaPlanView {
    /**
     * 
     * @type {Array<LineSchemaPlanPartView>}
     * @memberof LineSchemaPlanView
     */
    parts: Array<LineSchemaPlanPartView>;
}

/**
 * Check if a given object implements the LineSchemaPlanView interface.
 */
export function instanceOfLineSchemaPlanView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parts" in value;

    return isInstance;
}

export function LineSchemaPlanViewFromJSON(json: any): LineSchemaPlanView {
    return LineSchemaPlanViewFromJSONTyped(json, false);
}

export function LineSchemaPlanViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineSchemaPlanView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parts': ((json['parts'] as Array<any>).map(LineSchemaPlanPartViewFromJSON)),
    };
}

export function LineSchemaPlanViewToJSON(value?: LineSchemaPlanView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parts': ((value.parts as Array<any>).map(LineSchemaPlanPartViewToJSON)),
    };
}

