/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TownSearchInput
 */
export interface TownSearchInput {
    /**
     * 
     * @type {string}
     * @memberof TownSearchInput
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof TownSearchInput
     */
    spaceId?: string;
}

/**
 * Check if a given object implements the TownSearchInput interface.
 */
export function instanceOfTownSearchInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TownSearchInputFromJSON(json: any): TownSearchInput {
    return TownSearchInputFromJSONTyped(json, false);
}

export function TownSearchInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TownSearchInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'spaceId': !exists(json, 'spaceId') ? undefined : json['spaceId'],
    };
}

export function TownSearchInputToJSON(value?: TownSearchInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'spaceId': value.spaceId,
    };
}

