/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StationZoneAssignment,
} from '../models';
import {
    StationZoneAssignmentFromJSON,
    StationZoneAssignmentToJSON,
} from '../models';

export interface DeleteAssignedZoneRequest {
    stationId: string;
    companyId: string;
}

export interface SaveStationZonesRequest {
    stationZoneAssignment: StationZoneAssignment;
}

/**
 * 
 */
export class StationZoneEndpointApi extends runtime.BaseAPI {

    /**
     */
    async deleteAssignedZoneRaw(requestParameters: DeleteAssignedZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stationId === null || requestParameters.stationId === undefined) {
            throw new runtime.RequiredError('stationId','Required parameter requestParameters.stationId was null or undefined when calling deleteAssignedZone.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling deleteAssignedZone.');
        }

        const queryParameters: any = {};

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/stations/zones`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAssignedZone(requestParameters: DeleteAssignedZoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAssignedZoneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveStationZonesRaw(requestParameters: SaveStationZonesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stationZoneAssignment === null || requestParameters.stationZoneAssignment === undefined) {
            throw new runtime.RequiredError('stationZoneAssignment','Required parameter requestParameters.stationZoneAssignment was null or undefined when calling saveStationZones.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/stations/zones`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StationZoneAssignmentToJSON(requestParameters.stationZoneAssignment),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveStationZones(requestParameters: SaveStationZonesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveStationZonesRaw(requestParameters, initOverrides);
    }

}
