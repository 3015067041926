import {AdoptedNavPoint, LineSchemaPlanEndpointApi} from "../../../../api";
import {useContext, useEffect, useState} from "react";
import {CurrentUserContext} from "../../../CurrentUserContext";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {prepareNameWithAncestor} from "../../../common/navpoint/navpointUtil";
import {verifyClientResponse} from "../../../common/error/resolvers";
import Af from "../../../common/ahreffun/Af";
import {LineSchemaPlanPart} from "../LinePlanPanel";

interface LinePlanTablePartSuggestionsProps {
    plan: LineSchemaPlanPart[];
    onAdd: (a: AdoptedNavPoint) => void;
}

interface Suggestions {
    navPoints: AdoptedNavPoint[];
    dedicatedForNavPoint: string;
}

export function LinePlanTablePartSuggestions(props: LinePlanTablePartSuggestionsProps) {
    const [suggestions, setSuggestions] = useState<Suggestions>();
    const currentUser = useContext(CurrentUserContext);
    useEffect(() => {
        if (props.plan == null || props.plan.length == 0) {
            return;
        } else if (suggestions == null || suggestions.dedicatedForNavPoint != props.plan[props.plan.length - 1].adoptedNavPoint.navPointMetaData.id) {
            setSuggestions(null);
            const lastNavPointId = props.plan[props.plan.length - 1].adoptedNavPoint.navPointMetaData.id;
            new LineSchemaPlanEndpointApi().findNavPointRecommendations({
                    lastNavPointId: lastNavPointId,
                    companyId: currentUser.user.defaultCompany.id,
                    limit: 5
                },
                {headers: prepareSecurityHeaders()})
                .then(r => {
                    setSuggestions({navPoints: r, dedicatedForNavPoint: lastNavPointId})
                })
                .catch(e => {
                    verifyClientResponse(e);
                });
        }
    }, [props.plan]);
    if (suggestions == null || suggestions.navPoints.length == 0) {
        return null;
    }
    return <table>
        <thead>
        <tr className='tableHeader'>
            <th colSpan={3}>Sugestie</th>
        </tr>
        </thead>
        <tbody>
        {suggestions.navPoints.map((s, index) => <tr key={index}>
            <td>{index}</td>
            <td>{prepareNameWithAncestor(s)}</td>
            <td><Af onClick={() => props.onAdd(s)}>Użyj</Af></td>
        </tr>)}
        </tbody>
    </table>
}
