/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionVisibilityRuleTypeView } from './ConditionVisibilityRuleTypeView';
import {
    ConditionVisibilityRuleTypeViewFromJSON,
    ConditionVisibilityRuleTypeViewFromJSONTyped,
    ConditionVisibilityRuleTypeViewToJSON,
} from './ConditionVisibilityRuleTypeView';

/**
 * 
 * @export
 * @interface ConditionChangeVisibilityInput
 */
export interface ConditionChangeVisibilityInput {
    /**
     * 
     * @type {string}
     * @memberof ConditionChangeVisibilityInput
     */
    conditionId?: string;
    /**
     * 
     * @type {ConditionVisibilityRuleTypeView}
     * @memberof ConditionChangeVisibilityInput
     */
    visibilityType: ConditionVisibilityRuleTypeView;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionChangeVisibilityInput
     */
    visibilityValue: boolean;
}

/**
 * Check if a given object implements the ConditionChangeVisibilityInput interface.
 */
export function instanceOfConditionChangeVisibilityInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "visibilityType" in value;
    isInstance = isInstance && "visibilityValue" in value;

    return isInstance;
}

export function ConditionChangeVisibilityInputFromJSON(json: any): ConditionChangeVisibilityInput {
    return ConditionChangeVisibilityInputFromJSONTyped(json, false);
}

export function ConditionChangeVisibilityInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionChangeVisibilityInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditionId': !exists(json, 'conditionId') ? undefined : json['conditionId'],
        'visibilityType': ConditionVisibilityRuleTypeViewFromJSON(json['visibilityType']),
        'visibilityValue': json['visibilityValue'],
    };
}

export function ConditionChangeVisibilityInputToJSON(value?: ConditionChangeVisibilityInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditionId': value.conditionId,
        'visibilityType': ConditionVisibilityRuleTypeViewToJSON(value.visibilityType),
        'visibilityValue': value.visibilityValue,
    };
}

