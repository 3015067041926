import * as React from 'react';
import TimeTableGroupTable from "../group/list/TimeTableGroupTable";
import {TimetableGroupView, TimetableView} from "../../../api";

interface TimeTableExplorerGroupsProps {
    groupChildren: TimetableGroupView[];
    timetable: TimetableView;
    onChangeGroup: (arg0: TimetableGroupView) => void;
    onChangeGroupIdToEdit: (groupId: string) => void;
}

export function TimeTableExplorerGroups(props: TimeTableExplorerGroupsProps) {
    if (props.groupChildren == null) {
        return <h3>Ładowanie grup</h3>;
    } else if (props.groupChildren.length > 0) {
        return <>
            <h2>Grupy</h2>
            <TimeTableGroupTable groupChildren={props.groupChildren}
                                 timetable={props.timetable}
                                 onChangeGroup={props.onChangeGroup}
                                 onChangeGroupIdToEdit={props.onChangeGroupIdToEdit}
            />
        </>;
    }
};
