/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataSpaceView,
} from '../models';
import {
    DataSpaceViewFromJSON,
    DataSpaceViewToJSON,
} from '../models';

export interface GetDataSpaceRequest {
    companyId: string;
    dataSpaceId: string;
}

/**
 * 
 */
export class SpaceEndpointApi extends runtime.BaseAPI {

    /**
     */
    async getDataSpaceRaw(requestParameters: GetDataSpaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataSpaceView>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getDataSpace.');
        }

        if (requestParameters.dataSpaceId === null || requestParameters.dataSpaceId === undefined) {
            throw new runtime.RequiredError('dataSpaceId','Required parameter requestParameters.dataSpaceId was null or undefined when calling getDataSpace.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.dataSpaceId !== undefined) {
            queryParameters['dataSpaceId'] = requestParameters.dataSpaceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/space`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataSpaceViewFromJSON(jsonValue));
    }

    /**
     */
    async getDataSpace(requestParameters: GetDataSpaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataSpaceView> {
        const response = await this.getDataSpaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
