import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {StreetEndpointApi, StreetPreviewView, StreetSearchView, TownView} from "../../../api";

export function getStreets(query: string, mzkWorkspace: MzkWorkspace, townToSearch: TownView,
                           callback: (a0: StreetSearchView[]) => void) {
    new StreetEndpointApi()
        .searchStreets({
            streetSearchInput: {
                query: query,
                spaceId: mzkWorkspace.space.id,
                companyId: mzkWorkspace.company.id,
                townId: townToSearch ? townToSearch.id : null
            }
        }, {headers: prepareSecurityHeaders()})
        .then(r => {
            callback(r);
        })
        .catch(e => {
            verifyClientResponse(e);
        });
}

export function getStreetPreview(mzkWorkspace: MzkWorkspace,  callback: (a0: StreetPreviewView) => void){
    new StreetEndpointApi().previewStreetSearch(
        {companyId: mzkWorkspace.company.id, spaceId: mzkWorkspace.space.id}, {headers: prepareSecurityHeaders()})
        .then(r => {
            callback(r);
        })
        .catch(e => {
            verifyClientResponse(e);
        });
}
