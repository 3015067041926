import {LoggedTabContextMainCategory} from "./LoggedTabContextMainCategory";

export class LoggedTabContext {
    constructor(
        public mainTabName: LoggedTabContextMainCategory,
        public isSelfDirected: boolean,
        public data: any = {}
    ) {
    }
}
