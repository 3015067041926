import {useContext, useState} from "react";
import {CurrentUserContext} from "../../CurrentUserContext";
import {CrewGroupTable} from "../group/table/CrewGroupTable";
import Button from "../../common/input/button/Button";
import {NewCrewGroupPanel} from "../group/new/NewCrewGroupPanel";
import Af from "../../common/ahreffun/Af";
import {type CrewGroupMetaDataView, CrewGroupView} from "../../../api";
import {CrewGroupUsagePanel} from "../group/usage/CrewGroupUsagePanel";

export function CrewTab() {
    const user = useContext(CurrentUserContext).user;
    const [showNewCrewGroupPanel, setShowNewCrewGroupPanel] = useState(false);
    const [crewGroupVersion, setCrewGroupVersion] = useState(0);
    const [crewGroupMetaDataForUsagePanel, setCrewGroupMetaDataForUsagePanel] = useState<CrewGroupMetaDataView>();
    const actionProvider = (g: CrewGroupView) => {
        return <Af
            onClick={() => setCrewGroupMetaDataForUsagePanel(g.metaData)}>Użycia</Af>;
    }
    return <div id='simpleMainContentSplit'>
        <div>
            <h1>Brygady</h1>
            <CrewGroupTable crewGroupVersion={crewGroupVersion} actionProvider={actionProvider}/>
            {user.defaultCompany.superUser &&
                <Button onClick={() => setShowNewCrewGroupPanel(s => !s)}>Nowa grupa brygad</Button>}
        </div>
        {showNewCrewGroupPanel && <NewCrewGroupPanel onClose={(r) => {
            setShowNewCrewGroupPanel(false);
            r && setCrewGroupVersion(v => v + 1);
        }}/>}
        {crewGroupMetaDataForUsagePanel && <CrewGroupUsagePanel
            groupMetaData={crewGroupMetaDataForUsagePanel}
            onClose={(r) => {
                setCrewGroupMetaDataForUsagePanel(null);
                r && setCrewGroupVersion(v => v + 1);
            }}/>}
    </div>;
}
