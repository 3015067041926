import Close from "../../../common/ahreffun/Close";
import {SectionSampleEndpointApi, SectionSampleView, SectionView} from "../../../../api";
import {prepareNameWithAncestor} from "../../../common/navpoint/navpointUtil";
import {Fragment, useContext, useEffect, useState} from "react";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {prepareSectionErrorMap} from "../../new/AddSectionPanel";
import {stringDateFormatToDate} from "../../../common/date/dateUtil";
import {secondsToFrontDuration} from "../../../common/input/duration/durationUtil";
import {uxNull} from "../../../common/string/stringUtil";
import {CurrentUserContext} from "../../../CurrentUserContext";
import Af from "../../../common/ahreffun/Af";
import Roller from "../../../common/roller/Roller";
import {AddNewSectionSampleRoller} from "../new/AddNewSectionSampleRoller";

interface SectionSampleListPanelProps {
    onClose: () => void;
    section: SectionView;
    initialSampleDate: string
    onEditSample: (s: SectionSampleView)=>void;
    sectionGreedyVersion: number;
}

export function SectionSampleListPanel(props: SectionSampleListPanelProps) {
    const [samples, setSamples] = useState<SectionSampleView[]>();
    const currentUser = useContext(CurrentUserContext);
    const refreshSections = ()=>{
        new SectionSampleEndpointApi().getSectionSamples({sectionId: props.section.id}, {headers: prepareSecurityHeaders()})
            .then((s) => {
                setSamples(s);
            })
            .catch(e => {
                verifyClientResponse(e);
            })
    }
    useEffect(() => {
        refreshSections();
    }, [props.section.id, props.sectionGreedyVersion]);
    if (!samples) {
        return 'ładowanie...';
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose()}/></span>
        </div>
        <h3>Pomiary
            odcinka {prepareNameWithAncestor(props.section.part1)} - {prepareNameWithAncestor(props.section.part2)}</h3>
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Data próbki</th>
                <th>Czas przejazdu</th>
                <th>Czas oczekiwania</th>
                <th>Wykluczenia</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            {samples.map((s, index) => <tr key={s.id}>
                <td>{index}</td>
                <td>{stringDateFormatToDate(s.sampleDate.toISOString())}</td>
                <td>{secondsToFrontDuration(s.defaultTravelSec)}</td>
                <td>{s.defaultWaitingSec ? secondsToFrontDuration(s.defaultWaitingSec) : uxNull()}</td>
                <td>
                    {s.times.map((t, ti) => <Fragment key={t.from}>
                        {ti > 0 && <br/>}<b>od {t.from} do {t.to}</b>: {secondsToFrontDuration(t.travelSec)}
                    </Fragment>)}
                </td>
                <td>{currentUser.user.defaultCompany.superUser ? <Af onClick={()=>props.onEditSample(s)}>Edycja</Af> : uxNull()}</td>
            </tr>)}
            </tbody>
        </table>
        {currentUser.user.defaultCompany.superUser && <AddNewSectionSampleRoller
            section={props.section}
            onAddSample={refreshSections}
            initialSampleDate={props.initialSampleDate}
        />}
    </div>;
}
