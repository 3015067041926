import {ReactNode, useEffect, useState} from 'react';
import {handleCommonError} from "../../common/error/resolvers";
import TownRows from "./TownRows";
import {findAllTowns} from "../ajax/townRequests";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {TownView} from "../../../api";

interface TownTableProps {
    mzkWorkspace: MzkWorkspace;
    greedyVersion: number;
    actionProvider: (town: TownView) => ReactNode;
}

function TownTable(props: TownTableProps) {
    const [towns, setTowns] = useState<TownView[]>([]);
    useEffect(() => {
        if (props.mzkWorkspace) {
            findAllTowns(props.mzkWorkspace)
                .then((r: any) => {
                    setTowns(r);
                })
                .catch((e: any) => {
                    handleCommonError(e);
                });
        }
    }, [props.mzkWorkspace, props.greedyVersion]);
    return (
            <table>
                <thead>
                <tr className='tableHeader'>
                    <th>Lp</th>
                    <th>Nazwa</th>
                    <th>Akcje</th>
                </tr>
                </thead>
                <tbody>
                <TownRows towns={towns} actionProvider={props.actionProvider}/>
                </tbody>
            </table>
    );
}

export default TownTable;
