import Af from "../../common/ahreffun/Af";

import { ChangeEvent, forwardRef } from 'react';

interface StationTableCriteriaProps {
    query: string;
    onDataUpdated: (q: string, forceSearch: boolean) => void;
}
export const StationTableCriteria = forwardRef<HTMLInputElement, StationTableCriteriaProps>(
    (props, ref) => {
        return (
            <>
                <label>Nazwa*:</label>
                <input
                    ref={ref}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.onDataUpdated(e.target.value, false)}
                    value={props.query}
                    placeholder="nazwa"
                />
                <span> </span>
                <Af onClick={() => props.onDataUpdated('', true)}>Pokaż wszystkie</Af>
            </>
        );
    }
);
