import {ReactNode} from "react";

interface CheckboxProps {
    onChange: (value: boolean) => void;
    children: ReactNode;
    value: boolean;
    disabled?: boolean;
}

function Checkbox(props: CheckboxProps) {
    const disabled = props.disabled === undefined ? false : props.disabled;
    const onChange = disabled ? () => {
    } : props.onChange;
    return (
        <>
            <input disabled={props.disabled} onChange={() => onChange(!props.value)}
                   checked={props.value} type="checkbox"/>
            <label onClick={() => onChange(!props.value)}>{props.children}</label>
        </>
    );
}

export default Checkbox;
