/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LineSchemaPlanInput
 */
export interface LineSchemaPlanInput {
    /**
     * 
     * @type {string}
     * @memberof LineSchemaPlanInput
     */
    lineId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LineSchemaPlanInput
     */
    parts?: Array<string>;
}

/**
 * Check if a given object implements the LineSchemaPlanInput interface.
 */
export function instanceOfLineSchemaPlanInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LineSchemaPlanInputFromJSON(json: any): LineSchemaPlanInput {
    return LineSchemaPlanInputFromJSONTyped(json, false);
}

export function LineSchemaPlanInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineSchemaPlanInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineId': !exists(json, 'lineId') ? undefined : json['lineId'],
        'parts': !exists(json, 'parts') ? undefined : json['parts'],
    };
}

export function LineSchemaPlanInputToJSON(value?: LineSchemaPlanInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineId': value.lineId,
        'parts': value.parts,
    };
}

