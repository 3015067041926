import Close from "../../common/ahreffun/Close";
import Button from "../../common/input/button/Button";
import {
    NavpointEndpointApi,
    NavPointMetaDataView,
    NavPointUsageReport,
    TimetableEndpointApi,
    TimetableView
} from "../../../api";
import {prepareName} from "../../common/navpoint/navpointUtil";
import {useContext, useEffect, useState} from "react";
import {CurrentUserContext} from "../../CurrentUserContext";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {LineSchemaMetaData} from "../../../api/models/LineSchemaMetaData";
import {addToast} from "../../common/toast/toastUtil";
import {hasSuperPermissionInSpace} from "../../common/superuser/permissionUtil";

interface NavPointUsagePanelProps {
    navPoint: NavPointMetaDataView;

    onClose(refresh: boolean): void;
}

export function NavPointUsagePanel(props: NavPointUsagePanelProps) {

    const [usageInfo, setUsageInfo] = useState<NavPointUsageReport>();
    const currentUser = useContext(CurrentUserContext);
    useEffect(() => {
        new NavpointEndpointApi().prepareNavPointUsageReport({
                navPointId: props.navPoint.id,
                companyId: currentUser.user.defaultCompany.id
            },
            {headers: prepareSecurityHeaders()})
            .then(r => setUsageInfo(r))
            .catch(e => verifyClientResponse(e));
    }, [props.navPoint]);
    const groupedLines = usageInfo?.lines.reduce((map, line) => {
        const timeTableId = line.timeTableId;
        if (!map.has(timeTableId)) {
            map.set(timeTableId, []);
        }
        map.get(timeTableId).push(line);
        return map;
    }, new Map());
    const onDelete = () => {
        if (confirm('Czy na pewno chcesz usunąć ten obiekt?')) {
            new NavpointEndpointApi().deleteNavPoint({
                    navPointId: props.navPoint.id,
                    companyId: currentUser.user.defaultCompany.id
                },
                {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie usunięto obiekt');
                    props.onClose(true);
                }).catch(e => verifyClientResponse(e, getErrorMap()));
        }
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        </div>
        <h3>Użycia - {prepareName(props.navPoint)}</h3>
        {groupedLines && Array.from(groupedLines.keys()).map(timeTableId => (
            <TimetableUsage key={timeTableId} lines={groupedLines.get(timeTableId)}/>))}
        {usageInfo?.lines?.length == 0 && hasSuperPermissionInSpace({
            company: currentUser.user.defaultCompany,
            space: currentUser.user.defaultCompany.availableDataSpaces.filter(ds => ds.id == props.navPoint.dataSpaceId)[0]
        }) ? <Button onClick={onDelete}>Usuń obiekt</Button> :
            <p>Nie możesz usunąć tego obiektu, ponieważ jest on używany.</p>}
    </div>
}

interface TimetableUsageProps {
    lines: LineSchemaMetaData[]
}

function TimetableUsage(props: TimetableUsageProps) {

    const [timetable, setTimetable] = useState<TimetableView>();
    useEffect(() => {
        new TimetableEndpointApi().getTimetableDetails({id: props.lines[0].timeTableId}, {headers: prepareSecurityHeaders()})
            .then(r => setTimetable(r))
            .catch(e => verifyClientResponse(e));
    }, []);
    if (!timetable) {
        return;
    }
    return <><h4>{timetable.name}</h4>
        <ul>
            {props.lines.map(l => <li key={l.id}>{l.technicalName} {l.technicalDescription}</li>)}
        </ul>
    </>;
}

function getErrorMap() {
    const map = new Map<string, (r: any) => void>();
    map.set("children_exist", r => {
        addToast('Usuń obiekty dzieci przed kontynuowaniem');
    });
    return map;
}
