import Close from "../../common/ahreffun/Close";
import DateInput from "../../common/input/date/DateInput";
import Button from "../../common/input/button/Button";
import {ChangeEvent, FormEvent, useContext, useEffect, useState} from "react";
import {TimetableEndpointApi} from "../../../api";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {stringDateFormatToDate, stringToDate} from "../../common/date/dateUtil";
import {addToast} from "../../common/toast/toastUtil";
import {nullIfBlank} from "../../common/string/stringUtil";
import {CurrentUserContext} from "../../CurrentUserContext";

interface TimetableEditPanelProps {
    timetableId: string;
    onClose: (refresh: boolean) => void;
}

export function TimetableEditPanel(props: TimetableEditPanelProps) {
    const [name, setName] = useState('');
    const [preparationDate, setPreparationDate] = useState('');
    const [originalName, setOriginalName] = useState<string>();
    const currentUser = useContext(CurrentUserContext);
    useEffect(() => {
        new TimetableEndpointApi().getTimetableDetails({id: props.timetableId}, {headers: prepareSecurityHeaders()})
            .then(r => {
                setName(r.name);
                setOriginalName(r.name);
                setPreparationDate(stringDateFormatToDate(r.preparationDate.toISOString()));
            })
            .catch(e => verifyClientResponse(e));
    }, [props.timetableId]);
    const verifyForm = () => {
        if (name.length === 0) {
            addToast('Nazwa nie może być pusta');
            return false;
        } else if (preparationDate == '' || new Date(preparationDate) == null) {
            addToast('Uzupełnij datę');
            return false;
        }
        return true;
    }
    const onUpdate = (e: FormEvent) => {
        e.preventDefault();
        if (verifyForm()) {
            new TimetableEndpointApi().saveTimeTable({
                    timetableInput: {
                        id: props.timetableId,
                        name: nullIfBlank(name),
                        preparationDate: stringToDate(preparationDate),
                        companyId: currentUser.user.defaultCompany.id,
                        editable: true
                    }
                }, {headers: prepareSecurityHeaders()}
            )
                .then(() => {
                    addToast('Poprawnie zaktualizowano rozkład');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, new Map());
                });
        }
    };
    const onDelete = () => {
        if (confirm(`Czy na pewno chcesz usunąć rozkład ${originalName}?`)) {
            new TimetableEndpointApi().deleteTimetable({timetableId: props.timetableId}, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Poprawnie usunięto rozkład');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getDeleteErrorMap());
                });
        }
    }
    return <div className='diffArea'>
        <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        <h2>Edycja {originalName}</h2>
        <form>
            <label>Nazwa: </label>
            <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                   maxLength={50}/>
            <br/>
            Data przygotowania: <DateInput value={preparationDate} onChange={(f: string) => setPreparationDate(f)}
                                           disabled={false}/>
            <br/>
            <Button onClick={onUpdate}>Zapisz</Button>
        </form>
        <h3>Inne opcje</h3>
        <Button onClick={onDelete}>Usuń rozkład</Button>
    </div>;
}

const getDeleteErrorMap = () => {
    const map = new Map<string, (r: any) => void>();
    map.set("timetable_recently_used", () => {
        addToast('Ten rozkład nie może być jeszcze usunięty');
    });
    return map;
}
