import {useContext} from 'react';
import TimetableList from "./list/TimetableList";
import {CurrentUserContext} from "../CurrentUserContext";
import TimeTableExplorer from "./explorer/TimeTableExplorer";

const TimetableTab = () => {
    const currentUser = useContext(CurrentUserContext);

    const selectTimeTable = (timeTable: any) => {
        currentUser.user.defaultTimetable = timeTable;
        currentUser.changeUserFun(currentUser);
    };

    return (
        <>
            {currentUser.user.defaultTimetable ?
                <TimeTableExplorer timetable={currentUser.user.defaultTimetable}
                                   mzkWorkspace={currentUser.user.mzkWorkspace()}
                                   selectTimeTable={selectTimeTable}/> :
                <TimetableList company={currentUser.user.defaultCompany}
                               onSelect={selectTimeTable}/>}
        </>
    );
}

export default TimetableTab;
