import Roller from "../roller/Roller";
import {NavPointSettings} from "../../../api";
import Checkbox from "../input/checkbox/Checkbox";

interface NavPointSettingsRollerProps{
    onChangeVisibility: () => void;
    isOpen: boolean;
    settings: NavPointSettings;
    onChangeSettings: (s: NavPointSettings)=>void;
}
export function NavPointSettingsRoller(props: NavPointSettingsRollerProps){
    return <Roller title={'Akcje przystankowe'} open={props.isOpen} onChangeVisibility={props.onChangeVisibility}>
        <Checkbox onChange={(v)=>props.onChangeSettings({...props.settings, passengersFrom: v})} value={props.settings.passengersFrom}>Wsiadanie pasażerów</Checkbox><br />
        <Checkbox onChange={(v)=>props.onChangeSettings({...props.settings, passengersTo: v})} value={props.settings.passengersTo}>Wysiadanie pasażerów</Checkbox><br />
        <Checkbox onChange={(v)=>props.onChangeSettings({...props.settings, technicalStop: v})} value={props.settings.technicalStop}>Miejsce postojów technicznych</Checkbox><br />
        <Checkbox onChange={(v)=>props.onChangeSettings({...props.settings, depot: v})} value={props.settings.depot}>Zajezdnia</Checkbox><br />
    </Roller>
}
