import SectionTimeTable from "./SectionTimeTable";
import {uxNull} from "../../common/string/stringUtil";
import {secondsToFrontDuration} from "../../common/input/duration/durationUtil";
import Popup from "../../common/popup/Popup";
import {SectionSampleView, SectionView} from "../../../api";
import {SectionSample} from "../sample/form/SectionSampleForm";

interface SectionTimeDetailsPopupProps {
    onClose: () => void;
    sample: SectionSampleView;
}

const SectionTimeDetailsPopup = (props: SectionTimeDetailsPopupProps) => {
    return (
        <Popup closePopup={props.onClose}>
            <h1>Szczegóły czasów godzinowych</h1>
            Domyślny czas przejazdu to {secondsToFrontDuration(props.sample.defaultTravelSec)}
            <SectionTimeTable
                times={props.sample.times}
                actionProvider={() => uxNull()}
            />
        </Popup>
    );
}

export default SectionTimeDetailsPopup;
