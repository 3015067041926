/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AsyncTaskProgressionView } from './AsyncTaskProgressionView';
import {
    AsyncTaskProgressionViewFromJSON,
    AsyncTaskProgressionViewFromJSONTyped,
    AsyncTaskProgressionViewToJSON,
} from './AsyncTaskProgressionView';

/**
 * 
 * @export
 * @interface AsyncTaskView
 */
export interface AsyncTaskView {
    /**
     * 
     * @type {string}
     * @memberof AsyncTaskView
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AsyncTaskView
     */
    taskType?: AsyncTaskViewTaskTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AsyncTaskView
     */
    taskStatus?: AsyncTaskViewTaskStatusEnum;
    /**
     * 
     * @type {AsyncTaskProgressionView}
     * @memberof AsyncTaskView
     */
    progression?: AsyncTaskProgressionView;
    /**
     * 
     * @type {string}
     * @memberof AsyncTaskView
     */
    errorDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AsyncTaskView
     */
    addTime?: string;
    /**
     * 
     * @type {string}
     * @memberof AsyncTaskView
     */
    endTime?: string;
}


/**
 * @export
 */
export const AsyncTaskViewTaskTypeEnum = {
    Todo: 'TODO'
} as const;
export type AsyncTaskViewTaskTypeEnum = typeof AsyncTaskViewTaskTypeEnum[keyof typeof AsyncTaskViewTaskTypeEnum];

/**
 * @export
 */
export const AsyncTaskViewTaskStatusEnum = {
    Ready: 'READY',
    Processing: 'PROCESSING',
    Finished: 'FINISHED',
    Error: 'ERROR'
} as const;
export type AsyncTaskViewTaskStatusEnum = typeof AsyncTaskViewTaskStatusEnum[keyof typeof AsyncTaskViewTaskStatusEnum];


/**
 * Check if a given object implements the AsyncTaskView interface.
 */
export function instanceOfAsyncTaskView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AsyncTaskViewFromJSON(json: any): AsyncTaskView {
    return AsyncTaskViewFromJSONTyped(json, false);
}

export function AsyncTaskViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): AsyncTaskView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'taskType': !exists(json, 'taskType') ? undefined : json['taskType'],
        'taskStatus': !exists(json, 'taskStatus') ? undefined : json['taskStatus'],
        'progression': !exists(json, 'progression') ? undefined : AsyncTaskProgressionViewFromJSON(json['progression']),
        'errorDescription': !exists(json, 'errorDescription') ? undefined : json['errorDescription'],
        'addTime': !exists(json, 'addTime') ? undefined : json['addTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
    };
}

export function AsyncTaskViewToJSON(value?: AsyncTaskView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'taskType': value.taskType,
        'taskStatus': value.taskStatus,
        'progression': AsyncTaskProgressionViewToJSON(value.progression),
        'errorDescription': value.errorDescription,
        'addTime': value.addTime,
        'endTime': value.endTime,
    };
}

