import {AdoptedNavPoint, NavpointEndpointApi, NavPointMetaDataView} from "../../../../api";
import Af from "../../../common/ahreffun/Af";
import {useContext, useState} from "react";
import {StationPopupSelector} from "../../../station/popup/StationPopupSelector";
import PlatformPopupSelector from "../../../platform/popup/PlatformPopupSelector";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {CurrentUserContext} from "../../../CurrentUserContext";
import {prepareNameWithAncestor} from "../../../common/navpoint/navpointUtil";
import {addToast} from "../../../common/toast/toastUtil";
import Help from "../../../common/tooltip/Help";

interface IntermediateNavPointTableProps {
    navPoints: AdoptedNavPoint[];
    onChangeIntermediateNavPoint: (n: AdoptedNavPoint[]) => void;
}

export function IntermediateNavPointForm(props: IntermediateNavPointTableProps) {
    const [showStationSelector, setShowStationSelector] = useState(false);
    const [showPlatformSelector, setShowPlatformSelector] = useState(false);
    const currentUser = useContext(CurrentUserContext);
    const  selectNavPoint = (navPoint: NavPointMetaDataView)=>{
        if (props.navPoints.some(n => n.navPointMetaData.id == navPoint.id)){
            addToast('Wybrany element został już dodany');
        }
        new NavpointEndpointApi().prepareAdoptedNavPoint({adoptedNavPointRequest: {id: navPoint.id, companyId: currentUser.user.defaultCompany.id}},
            {headers: prepareSecurityHeaders()})
            .then(r => props.onChangeIntermediateNavPoint([...props.navPoints, r]))
            .catch(e => verifyClientResponse(e));
        setShowStationSelector(false);
        setShowPlatformSelector(false);
    }
    const removeElementByIndex = (index: number) => {
        const navPoints = [...props.navPoints];
        navPoints.splice(index, 1);
        props.onChangeIntermediateNavPoint(navPoints);
    };
    return <div className='diffArea'>
        <strong>Punkty pośrednie</strong><Help>Przydatne podczas budowania schematu. Sugerują z jakich innych odcinków można skorzystać by reprezentować ten odcinek.</Help>
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Węzeł</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            {props.navPoints.map((n, index)=> <tr>
                <td>{index}</td>
                <td>{prepareNameWithAncestor(n)}</td>
                <td><Af onClick={()=>removeElementByIndex(index)}>Usuń</Af></td>
            </tr>)}
            </tbody>
        </table>
        {props.navPoints.length < 10 && <><Af onClick={()=>setShowStationSelector(true)}>Dodawanie stacji</Af>
        <Af onClick={()=>setShowPlatformSelector(true)}>Dodawanie przystanku</Af></>}
        {showStationSelector && <StationPopupSelector selectNavPointFun={selectNavPoint}
                                                      onClosePopup={() => setShowStationSelector(false)}/>}
        {showPlatformSelector && <PlatformPopupSelector onSelectNavPoint={selectNavPoint}
                                                        onClose={() => setShowPlatformSelector(false)}/>}
    </div>;
}
