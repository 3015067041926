/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginInputData,
  UserConfigView,
} from '../models';
import {
    LoginInputDataFromJSON,
    LoginInputDataToJSON,
    UserConfigViewFromJSON,
    UserConfigViewToJSON,
} from '../models';

export interface LoginRequest {
    loginInputData: LoginInputData;
}

/**
 * 
 */
export class UserEndpointApi extends runtime.BaseAPI {

    /**
     */
    async getUserConfigRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserConfigView>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConfigViewFromJSON(jsonValue));
    }

    /**
     */
    async getUserConfig(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserConfigView> {
        const response = await this.getUserConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.loginInputData === null || requestParameters.loginInputData === undefined) {
            throw new runtime.RequiredError('loginInputData','Required parameter requestParameters.loginInputData was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginInputDataToJSON(requestParameters.loginInputData),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
