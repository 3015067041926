import {ReactNode} from "react";

interface ButtonProps {
    onClick?: (a0: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    backgroundColor?: 'Primary' | 'Secondary';
    children: ReactNode;
}

const Button = (props: ButtonProps) => {
    const backgroundColorStyle = props.backgroundColor === 'Secondary' ? 'bgColorSecondary' : 'bgColorPrimary';
    return <button className={backgroundColorStyle + ' clickable'}
                   onClick={e => { e.preventDefault(); props.onClick(e);}}>{props.children}</button>;
}

export default Button;
