import {useContext} from "react";
import {deleteCookie} from "../common/cookie/cookieUtil";
import {CurrentUserContext} from "../CurrentUserContext";
import {addToast} from "../common/toast/toastUtil";
import Button from "../common/input/button/Button";

const LogoutBox = () => {
    const currentUser = useContext(CurrentUserContext);

    const logout = (refreshUserInfoFun: () => void) => {
        deleteCookie("Authorization");
        refreshUserInfoFun();
        addToast("Pomyślnie wylogowano");
    };

    return (
        <Button onClick={() => logout(currentUser.refreshUserInfoFun)}>
            Wyloguj mnie
        </Button>
    );
};

export default LogoutBox;