/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StreetSearchView } from './StreetSearchView';
import {
    StreetSearchViewFromJSON,
    StreetSearchViewFromJSONTyped,
    StreetSearchViewToJSON,
} from './StreetSearchView';

/**
 * 
 * @export
 * @interface StreetPreviewView
 */
export interface StreetPreviewView {
    /**
     * 
     * @type {Array<StreetSearchView>}
     * @memberof StreetPreviewView
     */
    streets: Array<StreetSearchView>;
    /**
     * 
     * @type {boolean}
     * @memberof StreetPreviewView
     */
    appliedLimit: boolean;
}

/**
 * Check if a given object implements the StreetPreviewView interface.
 */
export function instanceOfStreetPreviewView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "streets" in value;
    isInstance = isInstance && "appliedLimit" in value;

    return isInstance;
}

export function StreetPreviewViewFromJSON(json: any): StreetPreviewView {
    return StreetPreviewViewFromJSONTyped(json, false);
}

export function StreetPreviewViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreetPreviewView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'streets': ((json['streets'] as Array<any>).map(StreetSearchViewFromJSON)),
        'appliedLimit': json['appliedLimit'],
    };
}

export function StreetPreviewViewToJSON(value?: StreetPreviewView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'streets': ((value.streets as Array<any>).map(StreetSearchViewToJSON)),
        'appliedLimit': value.appliedLimit,
    };
}

