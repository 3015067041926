import {ChangeEvent, useRef, useState} from 'react';
import DurationPicker from "../../common/input/duration/DurationPicker";
import Button from "../../common/input/button/Button";
import Roller from "../../common/roller/Roller";
import {addToast} from "../../common/toast/toastUtil";
import {timeStringAsDate} from "../../common/input/duration/durationUtil";

interface AddNewSectionTimePartProps {
    showAddTime: boolean;
    onChangeVisibility: () => void;
    addNewSectionTimeFun: (data: {
        from: string;
        to: string;
        travelSec: number
    }) => void;
}

function AddNewSectionTimePart(props: AddNewSectionTimePartProps) {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [travelSec, setTravelSec] = useState(0);
    const fromRef = useRef<HTMLInputElement>();

    function addOneMinute(to: string) {
        const currentTime = new Date(`1970-01-01T${to}:00Z`);
        currentTime.setMinutes(currentTime.getMinutes() + 1);
        currentTime.setMinutes(currentTime.getMinutes() + currentTime.getTimezoneOffset());
        return currentTime.toTimeString().slice(0, 5);
    }

    const onAdd = () => {
        if (validate()) {
            props.addNewSectionTimeFun({
                from: from,
                to: to,
                travelSec: travelSec
            })
            setFrom(addOneMinute(to));
            setTo('');
            setTravelSec(0);
            fromRef.current.focus();
        }

    }
    const validate = () => {
        if (!from || !to || travelSec === 0) {
            addToast('Wypełnij wszystkie pola');
            return false;
        }
        if (timeStringAsDate(from) >= timeStringAsDate(to)) {
            addToast('Od musi być mniejsze niż do');
            return false;
        }
        return true;
    }

    return (
        <Roller title={"Dodawanie zależności godzinowej"}
                open={props.showAddTime}
                onChangeVisibility={props.onChangeVisibility}>
            <label>Od:</label>
            <input type="time" value={from} name='from'
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setFrom(e.target.value)}
            ref={fromRef}
            />
            <br/>
            <label>Do:</label>
            <input type="time" value={to} name='to'
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setTo(e.target.value)}/>
            <br/>
            <label>Czas (h/m/s)</label>
            <DurationPicker
                duration={travelSec}
                onDurationChange={(ts: number) => {
                    setTravelSec(ts)
                }}
                negativeValueAllowed={false}
            />
            <br/>
            <Button onClick={onAdd}>Dodaj</Button>
        </Roller>
    );
}

export default AddNewSectionTimePart;
