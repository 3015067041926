import {useEffect, useState} from 'react';
import ConditionTable from "../table/ConditionTable";
import Af from "../../common/ahreffun/Af";
import Popup from "../../common/popup/Popup";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {ConditionEndpointApi, ConditionMetaDataView, ConditionVisibilityRuleTypeView} from "../../../api";
import {searchConditions} from "../ajax/ajaxUtils";
import {verifyClientResponse} from "../../common/error/resolvers";

interface ConditionPopupSelectorProps {
    company: Company;
    visibilityRule: ConditionVisibilityRuleTypeView
    closePopup: () => void;
    selectConditionFun: (condition: ConditionMetaDataView) => void;
}

function ConditionPopupSelector(props: ConditionPopupSelectorProps) {
    const [conditions, setConditions] = useState<ConditionMetaDataView[]>([]);
    useEffect(() => {
        if (props.company == null) {
            return;
        }
        searchConditions(props.company.id, props.visibilityRule)
            .then((r) => {
                if (r !== void 0) {
                    setConditions(r as ConditionMetaDataView[]);
                }
            });
    }, [props.company]);
    return (
        <Popup closePopup={props.closePopup}>
            <h1>Wybór warunku</h1>
            <br/>
            <ConditionTable
                company={props.company}
                conditions={conditions}
                provideActionFun={(condition: ConditionMetaDataView) => <Af onClick={() => {
                    props.selectConditionFun(condition);
                    props.closePopup();
                    new ConditionEndpointApi()
                        .logConditionUsage({usageInput: {id: condition.id}},
                            {headers: prepareSecurityHeaders()})
                        .catch(e => verifyClientResponse(e));
                }}>Wybierz</Af>}
            />
        </Popup>
    );
}

export default ConditionPopupSelector;
