import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {NavpointEndpointApi, StreetEndpointApi, TownEndpointApi, TownView, ZoneEndpointApi} from "../../../api";
import {nullIfBlank} from "../../common/string/stringUtil";
import {addToast} from "../../common/toast/toastUtil";
import {verifyClientResponse} from "../../common/error/resolvers";
import Close from "../../common/ahreffun/Close";
import Af from "../../common/ahreffun/Af";
import Button from "../../common/input/button/Button";
import TownPopupSelector from "../../town/popup/TownPopupSelector";
import {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {prepareNameWithAncestor} from "../../common/navpoint/navpointUtil";

interface AddNewStreetFormProps {
    streetId: string;
    mzkWorkspace: MzkWorkspace;
    onClose: (refresh: boolean) => void;
}

function AddNewStreetPanel(props: AddNewStreetFormProps) {
    const [name, setName] = useState('');
    const [originalName, setOriginalName] = useState<string>();
    const [shortName, setShortName] = useState('');
    const [town, setTown] = useState<TownView>();
    const [showTownSelector, setShowTownSelector] = useState(false);
    useEffect(() => {
        new StreetEndpointApi().getStreet({
            streetId: props.streetId,
            companyId: props.mzkWorkspace.company.id
        }, {headers: prepareSecurityHeaders()})
            .then(r => {
                setName(r.name);
                setOriginalName(r.shortName ? r.shortName : r.name);
                setShortName(r.shortName);
                new TownEndpointApi().getTownDetails({
                    townId: r.townId,
                    companyId: props.mzkWorkspace.company.id
                }, {headers: prepareSecurityHeaders()})
                    .then(r => {
                        setTown(r);
                    })
                    .catch(e => {
                        verifyClientResponse(e);
                    });
            })
            .catch(e => {
                verifyClientResponse(e);
            });
    }, [props.streetId]);
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (verifyForm()) {
            new StreetEndpointApi()
                .editStreet({
                    editStreetInput: {
                        id: props.streetId,
                        name: name,
                        shortName: nullIfBlank(shortName),
                        townId: town!.id,
                        companyId: props.mzkWorkspace.company.id
                    }
                }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie zaktualizowano ulicę');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const verifyForm = () => {
        if (!name.length) {
            addToast('Nazwa nie może być pusta');
            return false;
        }
        if (!town) {
            addToast('Nie wybrano miasta');
            return false;
        }
        return true;
    }
    const onRemove = ()=>{
        if (confirm('Czy na pewno chcesz usunąc ulicę?')){
            const errorMap = new Map<string, (r: any) => void>();
            errorMap.set('street_is_used', r => {
                addToast('Ulica pozostaje w użyciu, więc nie może zostać usunięta');
                new NavpointEndpointApi().prepareAdoptedNavPoint({
                    adoptedNavPointRequest: {
                        id: r.data,
                        companyId: props.mzkWorkspace.company.id,
                    }
                }, {headers: prepareSecurityHeaders()})
                    .then(adoptedNavPoint => {
                        addToast(`Przykładowa użycia to navpoint ${prepareNameWithAncestor(adoptedNavPoint)}`);
                    }).catch(e => {
                    verifyClientResponse(e);
                });
            })
            new StreetEndpointApi().deleteStreet({streetId: props.streetId, companyId: props.mzkWorkspace.company.id},
                {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie usunięto ulicę');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, errorMap);
                });
        }
    }

    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        </div>
        <h2>Edycja {originalName}</h2>
        <form>
            <label>Nazwa*:</label>
            <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <label>Nazwa skrócona:</label>
            <input name='shortName' value={shortName}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setShortName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <label>Miasto: {town ? town.name : 'nie wybrano'}</label>
            <Af onClick={() => setShowTownSelector(true)}>Wybór miasta</Af>
            <br/>

            <Button onClick={onSubmit}>Zapisz</Button>
        </form>
        {showTownSelector && <TownPopupSelector
            closePopup={() => setShowTownSelector(false)}
            onSelect={(t) => {
                setTown(t);
                setShowTownSelector(false);
            }}
            mzkWorkspace={props.mzkWorkspace}/>}
        <h3>Inne opcje</h3>
        <Button onClick={onRemove}>Usuń</Button>
    </div>;
}

function getErrorMap() {
    const map = new Map<string, (r: any) => void>();
    map.set("uniqueness", r => {
        if (r.data.name === 'name') {
            addToast('Nazwa ulicy musi być unikalna na poziomie miasta');
        }
    });
    return map;
}

export default AddNewStreetPanel;
