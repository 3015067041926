/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavPointMetaDataView } from './NavPointMetaDataView';
import {
    NavPointMetaDataViewFromJSON,
    NavPointMetaDataViewFromJSONTyped,
    NavPointMetaDataViewToJSON,
} from './NavPointMetaDataView';

/**
 * 
 * @export
 * @interface StructuredNavPoints
 */
export interface StructuredNavPoints {
    /**
     * 
     * @type {NavPointMetaDataView}
     * @memberof StructuredNavPoints
     */
    metaData?: NavPointMetaDataView;
    /**
     * 
     * @type {Array<StructuredNavPoints>}
     * @memberof StructuredNavPoints
     */
    children?: Array<StructuredNavPoints>;
}

/**
 * Check if a given object implements the StructuredNavPoints interface.
 */
export function instanceOfStructuredNavPoints(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StructuredNavPointsFromJSON(json: any): StructuredNavPoints {
    return StructuredNavPointsFromJSONTyped(json, false);
}

export function StructuredNavPointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StructuredNavPoints {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metaData': !exists(json, 'metaData') ? undefined : NavPointMetaDataViewFromJSON(json['metaData']),
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(StructuredNavPointsFromJSON)),
    };
}

export function StructuredNavPointsToJSON(value?: StructuredNavPoints | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metaData': NavPointMetaDataViewToJSON(value.metaData),
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(StructuredNavPointsToJSON)),
    };
}

