export enum LoggedTabContextMainCategory{
    INIT,
    TIME_TABLE,
    STATION,
    SECTION,
    CONDITION,
    CREW,
    ZONE,
    SPACE,
    STREET,
    ASYNC,
    COMPILATION,
    USER,
}
