import {useContext, useEffect, useState} from "react";
import {CurrentUserContext} from "../../CurrentUserContext";
import {
    AdoptedNavPoint,
    ConditionEndpointApi,
    ConditionMetaDataView,
    DataSpaceView,
    NavpointEndpointApi,
    NavPointPlatformView,
    PlatformEndpointApi,
    SpaceEndpointApi,
    StreetEndpointApi,
    StreetView
} from "../../../api";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import Close from "../../common/ahreffun/Close";
import {prepareNameWithAncestor} from "../../common/navpoint/navpointUtil";
import {NavPointSettingsViewDisplay} from "../../navpoint/setting/NavPointSettingsViewDisplay";
import {mapBooleanToDescription} from "../../common/boolean/booleanUtil";
import {uxNull} from "../../common/string/stringUtil";
import {hasSuperPermissionInSpace} from "../../common/superuser/permissionUtil";
import Button from "../../common/input/button/Button";

interface PlatformDetailsPanelProps {
    platformId: string;
    onClose: () => void;
    onModify: (platformId: string) => void;
}

export function PlatformDetailsPanel(props: PlatformDetailsPanelProps) {
    const currentUser = useContext(CurrentUserContext);
    const [platformData, setPlatformData] = useState<NavPointPlatformView>();
    const [conditionData, setConditionData] = useState<ConditionMetaDataView>();
    const [adoptedNavPoint, setAdoptedNavPoint] = useState<AdoptedNavPoint>();
    const [street, setStreet] = useState<StreetView>();
    const [space, setSpace] = useState<DataSpaceView>();
    useEffect(() => {
        new PlatformEndpointApi().getPlatformDetails({platformId: props.platformId, companyId: currentUser.user.defaultCompany.id},
            {headers: prepareSecurityHeaders()})
            .then(s => setPlatformData(s))
            .catch(e => verifyClientResponse(e));
    }, [props.platformId]);
    useEffect(() => {
        const id = platformData?.metaData.conditionId;
        if (id) {
            new ConditionEndpointApi().getConditionMetaData({conditionId: id},
                {headers: prepareSecurityHeaders()})
                .then(c => setConditionData(c))
                .catch(e => verifyClientResponse(e));
        } else {
            setConditionData(null);
        }
    }, [platformData?.metaData.conditionId]);
    useEffect(() => {
        setAdoptedNavPoint(null);
        new NavpointEndpointApi().prepareAdoptedNavPoint({
            adoptedNavPointRequest: {
                id: props.platformId,
                companyId: currentUser.user.defaultCompany.id,
            }
        }, {headers: prepareSecurityHeaders()})
            .then(adoptedNavPoint => {
                setAdoptedNavPoint(adoptedNavPoint);
            }).catch(e => {
            verifyClientResponse(e);
        });
    }, [props.platformId]);
    useEffect(() => {
        const id = platformData?.metaData.streetId;
        if (id) {
            new StreetEndpointApi().getStreet({
                    companyId: currentUser.user.defaultCompany.id,
                    streetId: id
                },
                {headers: prepareSecurityHeaders()})
                .then(s => setStreet(s))
                .catch(e => verifyClientResponse(e));
        } else {
            setStreet(null);
        }
    }, [platformData?.metaData.streetId]);
    useEffect(() => {
        const id = platformData?.metaData.dataSpaceId;
        if (id) {
            new SpaceEndpointApi().getDataSpace({dataSpaceId: id, companyId: currentUser.user.defaultCompany.id},
                {headers: prepareSecurityHeaders()})
                .then(s => setSpace(s))
                .catch(e => verifyClientResponse(e));
        } else {
            setSpace(null);
        }
    }, [platformData?.metaData.dataSpaceId]);
    if (platformData == null) {
        return <div className="diffArea">Ładowanie...</div>;
    }
    return <div className="diffArea">
        <div>
      <span className="windowButton">
        <Close onClick={props.onClose}/>
      </span>
        </div>
        <h3>{adoptedNavPoint ? <>{prepareNameWithAncestor(adoptedNavPoint)} - szczegóły</> : 'Szczegóły platformy'}</h3>
        Nazwa: {uxNull(platformData.metaData.name)} <br/>
        Nazwa skrócona: {uxNull(platformData.metaData.shortName)} <br/>
        Nazwa techniczna: {uxNull(platformData.metaData.technicalName)} <br/>
        IncId: {uxNull(platformData.metaData.incrementalId)} <br/>
        DataSpace: {uxNull(space?.name)} <br/>
        Warunek: {conditionData ? conditionData.name : uxNull()} <br/>
        Przystanek na żądanie: {mapBooleanToDescription(platformData.details.requestStop)} <br/>
        Pojemność: {platformData.details.capacity} <br />
        Gps: {uxNull(platformData.metaData.gpsX)} {platformData.metaData.gpsY}<br/>
        Ulica: {uxNull(street?.name)} <br/>
        <NavPointSettingsViewDisplay settings={platformData.metaData.settings}/><br/>
        {hasSuperPermissionInSpace({
            company: currentUser.user.defaultCompany,
            space: currentUser.user.defaultCompany.availableDataSpaces.filter(ds => ds.id == platformData.metaData.dataSpaceId)[0]
        }) && <Button onClick={() => props.onModify(props.platformId)}>Modyfikuj</Button>}
    </div>;
}
