import {ReactNode} from 'react';
import {VariableTemplateView} from "../../../api";

interface VariableTableProps {
    variables: VariableTemplateView[];
    actionProvider: (variable: VariableTemplateView) => ReactNode;
}

const VariableTable = (props: VariableTableProps) => {
    return (
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Nazwa</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            {props.variables.map((v, index) => <tr key={v.id}>
                <td>{index}</td>
                <td>{v.name}</td>
                <td>{props.actionProvider(v)}</td>
            </tr>)}
            </tbody>
        </table>
    );
}

export default VariableTable;
