import {ReactNode, useContext} from 'react';
import CompanySwitcher from "./CompanySwitcher";
import {CurrentUserContext} from "../../CurrentUserContext";
import {Company} from "../../user/current/CurrentUserContext";

interface UserAssociatedCompanySwitcherProps {
    company: Company;
    onChangeCompanyFun: (company: Company) => void;
}

function UserAssociatedCompanySwitcher(props: UserAssociatedCompanySwitcherProps): ReactNode {
    const currentUser = useContext(CurrentUserContext);
    return <CompanySwitcher
        company={props.company}
        initCompany={currentUser.user.defaultCompany}
        companies={currentUser.user.associatedCompanies}
        onChangeCompanyFun={props.onChangeCompanyFun}/>;
}

export default UserAssociatedCompanySwitcher;
