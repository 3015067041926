import {ChangeEvent, forwardRef, Ref} from "react";

interface DurationPickerProps {
    duration: number;
    onDurationChange: (duration: number) => void;
    negativeValueAllowed: boolean;
    disabled?: boolean;
}

const DurationPicker = forwardRef(
    (props: DurationPickerProps, ref: Ref<HTMLInputElement>) => {
    const duration = props.duration;
    const hours = Math.floor(duration / 3600);
    const remainingSeconds = duration % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    const handleDurationChange = (type: string, value: string) => {
        if (props.disabled) {
            return;
        }

        let newHours = hours;
        let newMinutes = minutes;
        let newSeconds = seconds;

        value = value === '' ? '0' : value;

        if (type === 'hours') {
            newHours = parseInt(value, 10);
        } else if (type === 'minutes') {
            newMinutes = parseInt(value, 10);
        } else if (type === 'seconds') {
            newSeconds = parseInt(value, 10);
        }

        const newDuration = newHours * 3600 + newMinutes * 60 + newSeconds;

        props.onDurationChange(newDuration);
    };

    return (
        <>
            <input
                type="number"
                id="hours"
                min={props.negativeValueAllowed ? -99 : 0}
                max={99}
                value={hours}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleDurationChange('hours', e.target.value)}
                disabled={props.disabled}
                style={{width: '3em'}}
                ref={ref}
            />
            <input
                type="number"
                id="minutes"
                min={props.negativeValueAllowed ? -59 : 0}
                max={59}
                value={minutes}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleDurationChange('minutes', e.target.value)}
                disabled={props.disabled}
                style={{width: '3em'}}
            />
            <input
                type="number"
                id="seconds"
                min={props.negativeValueAllowed ? -59 : 0}
                max={59}
                value={seconds}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleDurationChange('seconds', e.target.value)}
                disabled={props.disabled}
                style={{width: '3em'}}
            />
        </>
    );
});

export default DurationPicker;
