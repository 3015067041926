import {Company, MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {StationZoneEndpointApi, ZoneEndpointApi, ZoneView} from "../../../api";

export function getZones(mzkWorkspace: MzkWorkspace, callback: (a0: ZoneView[]) => void) {
    new ZoneEndpointApi()
        .initSearch({zoneSearchInput: {companyId: mzkWorkspace.company.id, spaceId: mzkWorkspace.space.id}}, {headers: prepareSecurityHeaders()})
        .then(r => callback(r))
        .catch(e => {
            verifyClientResponse(e);
        });
}

export function assignStationIdsToZone(stationIds: string[], company: Company, zone: ZoneView, errorMap: Map<string, Function>, callback: Function) {
    new StationZoneEndpointApi()
        .saveStationZones({
            stationZoneAssignment: {
                companyId: company.id,
                stationIds: stationIds,
                zoneId: zone.id
            }
        }, {headers: prepareSecurityHeaders()})
        .then(() => {
            callback();
        })
        .catch(e => {
            verifyClientResponse(e, errorMap);
        });
}
