/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LineSchemaMetaDataView } from './LineSchemaMetaDataView';
import {
    LineSchemaMetaDataViewFromJSON,
    LineSchemaMetaDataViewFromJSONTyped,
    LineSchemaMetaDataViewToJSON,
} from './LineSchemaMetaDataView';

/**
 * 
 * @export
 * @interface NavPointUsageReport
 */
export interface NavPointUsageReport {
    /**
     * 
     * @type {Array<LineSchemaMetaDataView>}
     * @memberof NavPointUsageReport
     */
    lines?: Array<LineSchemaMetaDataView>;
}

/**
 * Check if a given object implements the NavPointUsageReport interface.
 */
export function instanceOfNavPointUsageReport(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NavPointUsageReportFromJSON(json: any): NavPointUsageReport {
    return NavPointUsageReportFromJSONTyped(json, false);
}

export function NavPointUsageReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavPointUsageReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lines': !exists(json, 'lines') ? undefined : ((json['lines'] as Array<any>).map(LineSchemaMetaDataViewFromJSON)),
    };
}

export function NavPointUsageReportToJSON(value?: NavPointUsageReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lines': value.lines === undefined ? undefined : ((value.lines as Array<any>).map(LineSchemaMetaDataViewToJSON)),
    };
}

