import {TownView} from "../../../api";
import {ReactNode} from "react";

interface TownRowsProps {
    towns: TownView[];
    actionProvider: (town: TownView) => ReactNode;
}

const TownRows = (props: TownRowsProps) => {
    return props.towns.length ?
        props.towns.map((t, index) =>
            <tr key={index}>
                <td>{index}</td>
                <td>{t.name}</td>
                <td>{props.actionProvider(t)}</td>
            </tr>) :
        <tr>
            <td colSpan={5}>Brak danych</td>
        </tr>
}

export default TownRows;
