import {ReactNode} from "react";

interface AfProps {
    onClick?: () => void;
    children?: ReactNode;
}

function Af(props: AfProps) {
    const onClick = props.onClick == null ? () => {
    } : props.onClick;
    return (
        <a className='af' onClick={onClick}>
            [ {props.children} ]
        </a>
    );
}

export default Af;
