import {SectionSampleEndpointApi, SectionSampleView} from "../../../../api";
import {useEffect, useState} from "react";
import {SectionSample, SectionSampleForm, validateSectionSample} from "../form/SectionSampleForm";
import Close from "../../../common/ahreffun/Close";
import {stringDateFormatToDate, stringToDate} from "../../../common/date/dateUtil";
import Button from "../../../common/input/button/Button";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {addToast} from "../../../common/toast/toastUtil";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {prepareSectionErrorMap} from "../../new/AddSectionPanel";

interface ModifySectionSamplePanelProps {
    sectionSample: SectionSampleView;
    onClose: (refresh: boolean) => void;
}

export function ModifySectionSamplePanel(props: ModifySectionSamplePanelProps) {
    const calculateSample = (): SectionSample => {
        return {
            defaultTravelSec: props.sectionSample.defaultTravelSec,
            defaultWaitingSec: props.sectionSample.defaultWaitingSec,
            times: props.sectionSample.times,
            sampleDate: stringDateFormatToDate(props.sectionSample.sampleDate.toISOString())
        };
    }
    const [sample, setSample] = useState<SectionSample>(calculateSample());
    useEffect(() => {
        setSample(calculateSample());
    }, [props.sectionSample]);
    const onSave = () => {
        if (validateSectionSample(sample)) {
            new SectionSampleEndpointApi().updateSectionSample({
                createSectionSampleInput: {
                    id: props.sectionSample.id,
                    sectionId: props.sectionSample.sectionId,
                    sampleDate: stringToDate(sample.sampleDate),
                    defaultTravelSec: sample.defaultTravelSec,
                    defaultWaitingSec: sample.defaultWaitingSec,
                    times: sample.times
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie zaktualizowano');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, prepareSectionErrorMap());
                })
        }
    }
    const onDelete = () =>{
        if (confirm('Czy na pewno chcesz usunąć tą próbkę?')){
            new SectionSampleEndpointApi().deleteSectionSample({sectionSampleId: props.sectionSample.id},{headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie usunięto próbkę');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, prepareErrorMap());
                })
        }
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        </div>
        <h3>Modyfikacja pomiaru</h3>
        <SectionSampleForm sample={sample} onChangeSample={setSample}/>
        <Button onClick={onSave}>Zapisz</Button>

        <h4>Inne opcje</h4>
        <Button onClick={onDelete}>Usuń próbkę</Button>
    </div>;
}

function prepareErrorMap() {
    const map = new Map();
    map.set("last_sample", () => {
        addToast('Nie można usunąć ostatniej próbki odcinka');
    });
    return map;
}
