import {ReactNode} from "react";

interface TooltipProps {
    content: ReactNode;
    children: ReactNode;
}

function Tooltip(props: TooltipProps) {
    return (
        <span className='tooltip'>
                {props.children}
            <span className='tooltip-content'>{props.content}</span>
            </span>
    );
}

export default Tooltip;
