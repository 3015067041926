import {Component, ReactNode} from "react";
import {prepareCurrentUser, User} from "./user/current/CurrentUserContext";
import {UserLoggedApp} from "./app/UserLoggedApp";
import {UserNotLoggedApp} from "./app/UserNotLoggedApp";
import {CurrentUser, CurrentUserContext as CurrentUserContext} from "./CurrentUserContext";

interface AppState {
    currentUser: CurrentUser
    currentUserInitialized: boolean;
}

export class App extends Component<{}, AppState> {
    constructor(props: {}) {
        super(props);
        this.updateUser = this.updateUser.bind(this);
        this.state = {
            currentUser: {
                user: null,
                isLogged: false,
                refreshUserInfoFun: () => prepareCurrentUser(this.updateUser),
                changeUserFun: (u) => {
                    this.setState({currentUser: u});
                },
            },
            currentUserInitialized: false,
        };
        prepareCurrentUser(this.updateUser);
    }

    updateUser(u: User) {
        const thiz = this;
        this.setState({
            currentUser: {
                user: u,
                isLogged: u != null,
                refreshUserInfoFun: () => prepareCurrentUser(this.updateUser),
                changeUserFun: (u) => {
                    thiz.setState({currentUser: u});
                },
            },
            currentUserInitialized: true,
        });
    }

    render(): ReactNode {
        return (
            <>
                {this.state.currentUserInitialized
                    ? this.getAppContent()
                    : this.getLoadingAppContent()}
            </>
        );
    }

    getAppContent(): ReactNode {
        return (
            <CurrentUserContext.Provider value={this.state.currentUser}>
                {this.state.currentUser.isLogged ? <UserLoggedApp/> : <UserNotLoggedApp/>}
            </CurrentUserContext.Provider>
        );
    }

    getLoadingAppContent(): ReactNode {
        return <p>Ładowanie...</p>;
    }
}
