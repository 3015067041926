import Popup from "../../common/popup/Popup";
import {VariableTemplateView} from "../../../api";
import {ReactNode} from "react";
import VariableTable from "../table/VariableTable.js";
interface VariablePopupProps{
    onClose: ()=>void;
    actionProvider: (v:VariableTemplateView)=>ReactNode;
    variables: VariableTemplateView[];
}
export function VariablePopup(props: VariablePopupProps){
    return <Popup closePopup={props.onClose}>
        <h1>Wybór zmiennej</h1>
        <VariableTable variables={props.variables}  actionProvider={props.actionProvider}/>
    </Popup>
}
