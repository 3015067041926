import {ReactNode, useState} from "react";
import {StationTable} from "../table/StationTable";
import {AddNewStationForm} from "../new/AddNewStationForm";
import AddNewPlatformForm from "../../platform/new/AddNewPlatformForm";
import Button from "../../common/input/button/Button";
import Af from "../../common/ahreffun/Af";
import {hasSuperPermissionInSpace} from "../../common/superuser/permissionUtil";
import {uxNull} from "../../common/string/stringUtil";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {NavPointMetaDataView, NavPointStationView} from "../../../api";
import {StationTableCriteria} from "../table/StationTableCriteria";

interface StationTabSectionStationProps {
    mzkWorkspace: MzkWorkspace;
    query: string;
    onChangeQuery: (query: string) => void;
    showNewStationForm: boolean;
    toggleShowNewStationFormFun: () => void;
    increaseGreedyStationVersion: ()=>void;
    greedyStationVersion: number;
    onShowNavPointUsage: (navPoint: NavPointMetaDataView)=>void;
    onShowSection: (navPointId: string) => void;
    onChangeNavPointIdForStationDetails: (navPointId: string)=>void;
}

export function StationTabSectionStation(props: StationTabSectionStationProps) {
    const hasSuperUserPermissionInSpaceResult: boolean = hasSuperPermissionInSpace(props.mzkWorkspace);
    const [stationForNewPlatform, setStationForNewPlatform] = useState<NavPointStationView>();
    const [forceSearch, setForceSearch] = useState(false);
    const actionProvider = (hasSuperUserPermissionInSpaceResult: boolean) => {
        return (s: NavPointStationView): ReactNode => {
            const actions: ReactNode[] = [];
            if (hasSuperUserPermissionInSpaceResult){
                actions.push(<Af key={'CREATE_PLATFORM'} onClick={() => {setStationForNewPlatform(s)}}>
                                Stwórz peron
                            </Af>);
            }
            actions.push(<Af key={'USAGES'} onClick={()=>props.onShowNavPointUsage(s.metaData)}>Użycia</Af>);
            actions.push(<Af key={'SECTIONS'} onClick={() => props.onShowSection(s.metaData.id)}>Odcinki</Af>);
            actions.push(<Af key={'DETAILS'} onClick={()=>props.onChangeNavPointIdForStationDetails(s.metaData.id)}>Szczegóły</Af>)
            return actions;
        }
    }
    const newStationForm = (): ReactNode => {
        return (
            props.showNewStationForm && (
                <AddNewStationForm
                    mzkWorkspace={props.mzkWorkspace}
                    onClose={(r)=>{
                        props.toggleShowNewStationFormFun();
                        r && props.increaseGreedyStationVersion();
                    }}
                />
            )
        );
    }
    const newPlatformForm = (): ReactNode => {
        return (
            stationForNewPlatform != null && (
                <AddNewPlatformForm
                    station={stationForNewPlatform.metaData}
                    mzkWorkspace={props.mzkWorkspace}
                    hideNewPlatformFormFun={() => setStationForNewPlatform(null)}
                />
            )
        );
    }
    const newStationButton = (): ReactNode => {
        return hasSuperPermissionInSpace(props.mzkWorkspace) ? (
            <Button onClick={props.toggleShowNewStationFormFun}>Stwórz nową stację</Button>
        ) : (uxNull());
    }

    return (
     <>
            <div>
                <h3>Wyszukiwarka stacji</h3>
                <StationTableCriteria
                    query={props.query}
                    onDataUpdated={(q, fs)=>{
                        props.onChangeQuery(q);
                        setForceSearch(fs);
                    }}
                />
                <StationTable
                    mzkWorkspace={props.mzkWorkspace}
                    query={props.query}
                    onChangeQuery={props.onChangeQuery}
                    limit={5}
                    forceSearch={forceSearch}
                    actionProviderFun={actionProvider(hasSuperUserPermissionInSpaceResult)}
                    greedyStationVersion={props.greedyStationVersion}
                />
                {newStationButton()}
            </div>
            {newStationForm()}
            {newPlatformForm()}
        </>
    );
}
