/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavPointMetaDataInput } from './NavPointMetaDataInput';
import {
    NavPointMetaDataInputFromJSON,
    NavPointMetaDataInputFromJSONTyped,
    NavPointMetaDataInputToJSON,
} from './NavPointMetaDataInput';

/**
 * 
 * @export
 * @interface PlatformInputData
 */
export interface PlatformInputData {
    /**
     * 
     * @type {NavPointMetaDataInput}
     * @memberof PlatformInputData
     */
    navPointMetaData: NavPointMetaDataInput;
    /**
     * 
     * @type {boolean}
     * @memberof PlatformInputData
     */
    requestStop?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlatformInputData
     */
    capacity: number;
}

/**
 * Check if a given object implements the PlatformInputData interface.
 */
export function instanceOfPlatformInputData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "navPointMetaData" in value;
    isInstance = isInstance && "capacity" in value;

    return isInstance;
}

export function PlatformInputDataFromJSON(json: any): PlatformInputData {
    return PlatformInputDataFromJSONTyped(json, false);
}

export function PlatformInputDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformInputData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'navPointMetaData': NavPointMetaDataInputFromJSON(json['navPointMetaData']),
        'requestStop': !exists(json, 'requestStop') ? undefined : json['requestStop'],
        'capacity': json['capacity'],
    };
}

export function PlatformInputDataToJSON(value?: PlatformInputData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'navPointMetaData': NavPointMetaDataInputToJSON(value.navPointMetaData),
        'requestStop': value.requestStop,
        'capacity': value.capacity,
    };
}

