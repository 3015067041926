/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdoptedNavPoint } from './AdoptedNavPoint';
import {
    AdoptedNavPointFromJSON,
    AdoptedNavPointFromJSONTyped,
    AdoptedNavPointToJSON,
} from './AdoptedNavPoint';
import type { CourseTimeDefaultTimeView } from './CourseTimeDefaultTimeView';
import {
    CourseTimeDefaultTimeViewFromJSON,
    CourseTimeDefaultTimeViewFromJSONTyped,
    CourseTimeDefaultTimeViewToJSON,
} from './CourseTimeDefaultTimeView';
import type { CourseTimeOverriddenTimeView } from './CourseTimeOverriddenTimeView';
import {
    CourseTimeOverriddenTimeViewFromJSON,
    CourseTimeOverriddenTimeViewFromJSONTyped,
    CourseTimeOverriddenTimeViewToJSON,
} from './CourseTimeOverriddenTimeView';

/**
 * 
 * @export
 * @interface CourseTimeView
 */
export interface CourseTimeView {
    /**
     * 
     * @type {AdoptedNavPoint}
     * @memberof CourseTimeView
     */
    navPoint: AdoptedNavPoint;
    /**
     * 
     * @type {CourseTimeDefaultTimeView}
     * @memberof CourseTimeView
     */
    defaultTime?: CourseTimeDefaultTimeView;
    /**
     * 
     * @type {CourseTimeOverriddenTimeView}
     * @memberof CourseTimeView
     */
    overriddenTime?: CourseTimeOverriddenTimeView;
    /**
     * 
     * @type {string}
     * @memberof CourseTimeView
     */
    planPartId: string;
}

/**
 * Check if a given object implements the CourseTimeView interface.
 */
export function instanceOfCourseTimeView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "navPoint" in value;
    isInstance = isInstance && "planPartId" in value;

    return isInstance;
}

export function CourseTimeViewFromJSON(json: any): CourseTimeView {
    return CourseTimeViewFromJSONTyped(json, false);
}

export function CourseTimeViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CourseTimeView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'navPoint': AdoptedNavPointFromJSON(json['navPoint']),
        'defaultTime': !exists(json, 'defaultTime') ? undefined : CourseTimeDefaultTimeViewFromJSON(json['defaultTime']),
        'overriddenTime': !exists(json, 'overriddenTime') ? undefined : CourseTimeOverriddenTimeViewFromJSON(json['overriddenTime']),
        'planPartId': json['planPartId'],
    };
}

export function CourseTimeViewToJSON(value?: CourseTimeView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'navPoint': AdoptedNavPointToJSON(value.navPoint),
        'defaultTime': CourseTimeDefaultTimeViewToJSON(value.defaultTime),
        'overriddenTime': CourseTimeOverriddenTimeViewToJSON(value.overriddenTime),
        'planPartId': value.planPartId,
    };
}

