import Af from "../../common/ahreffun/Af";
import SpaceForCurrentCompanySwitcher from "../../space/switch/SpaceForCurrentCompanySwitcher";
import {PlatformTable} from "../table/PlatformTable";
import Popup from "../../common/popup/Popup";
import {useRef, useState} from "react";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {NavPointMetaDataView} from "../../../api";
import {logNavPointUsage} from "../../navpoint/ajax/ajaxUtils";
import {PlatformTableCriteria} from "../table/PlatformTableCriteria";

interface PlatformPopupSelectorProps {
    onSelectNavPoint: (a1: NavPointMetaDataView) => void;
    onClose: () => void;
}

function PlatformPopupSelector(props: PlatformPopupSelectorProps) {
    const [mzkWorkspace, setMzkWorkspace] = useState<MzkWorkspace>();
    const [query, setQuery] = useState('');
    const queryRef = useRef<HTMLInputElement>();
    return (
        <Popup closePopup={props.onClose}>
            <h1>Wybór platformy</h1>
            <SpaceForCurrentCompanySwitcher
                mzkWorkspace={mzkWorkspace}
                onChangeCompanyAndSpaceFun={(c, s) => setMzkWorkspace({company: c, space: s})}
            />
            <br/>
            <PlatformTableCriteria
                onDataUpdated={setQuery}
                query={query}
                inputRef={queryRef}
            />
            <PlatformTable
                mzkWorkspace={mzkWorkspace}
                query={query}
                greedyPlatformVersion={0}
                limit={6}
                actionProviderFun={(p: NavPointMetaDataView) => <Af
                    onClick={() => {
                        queryRef.current.focus();
                        queryRef.current.select();
                        props.onSelectNavPoint(p);
                        logNavPointUsage(p.id);
                    }}>Użyj</Af>}
            />
        </Popup>
    );
}

export default PlatformPopupSelector;
