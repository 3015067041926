/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConditionChangeVisibilityInput,
  ConditionMetaDataView,
  ConditionSearchInput,
  DayOfMonthConditionInput,
  DayOfWeekConditionInput,
  LogicalConditionInput,
  StaticConditionInput,
  UsageInput,
  VariableConditionInput,
  WinterHolidaysConditionInput,
} from '../models';
import {
    ConditionChangeVisibilityInputFromJSON,
    ConditionChangeVisibilityInputToJSON,
    ConditionMetaDataViewFromJSON,
    ConditionMetaDataViewToJSON,
    ConditionSearchInputFromJSON,
    ConditionSearchInputToJSON,
    DayOfMonthConditionInputFromJSON,
    DayOfMonthConditionInputToJSON,
    DayOfWeekConditionInputFromJSON,
    DayOfWeekConditionInputToJSON,
    LogicalConditionInputFromJSON,
    LogicalConditionInputToJSON,
    StaticConditionInputFromJSON,
    StaticConditionInputToJSON,
    UsageInputFromJSON,
    UsageInputToJSON,
    VariableConditionInputFromJSON,
    VariableConditionInputToJSON,
    WinterHolidaysConditionInputFromJSON,
    WinterHolidaysConditionInputToJSON,
} from '../models';

export interface ChangeVisibilityRuleRequest {
    conditionChangeVisibilityInput?: ConditionChangeVisibilityInput;
}

export interface GetConditionMetaDataRequest {
    conditionId: string;
}

export interface InitSearchConditionsRequest {
    conditionSearchInput: ConditionSearchInput;
}

export interface LogConditionUsageRequest {
    usageInput?: UsageInput;
}

export interface SaveDayOfMonthConditionRequest {
    dayOfMonthConditionInput: DayOfMonthConditionInput;
}

export interface SaveDayOfWeekConditionRequest {
    dayOfWeekConditionInput: DayOfWeekConditionInput;
}

export interface SaveLogicalConditionRequest {
    logicalConditionInput: LogicalConditionInput;
}

export interface SaveStaticConditionRequest {
    staticConditionInput: StaticConditionInput;
}

export interface SaveVariableConditionRequest {
    variableConditionInput: VariableConditionInput;
}

export interface SaveWinterHolidaysConditionRequest {
    winterHolidaysConditionInput: WinterHolidaysConditionInput;
}

/**
 * 
 */
export class ConditionEndpointApi extends runtime.BaseAPI {

    /**
     */
    async changeVisibilityRuleRaw(requestParameters: ChangeVisibilityRuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conditions/visibilityrules`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConditionChangeVisibilityInputToJSON(requestParameters.conditionChangeVisibilityInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeVisibilityRule(requestParameters: ChangeVisibilityRuleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeVisibilityRuleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getConditionMetaDataRaw(requestParameters: GetConditionMetaDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConditionMetaDataView>> {
        if (requestParameters.conditionId === null || requestParameters.conditionId === undefined) {
            throw new runtime.RequiredError('conditionId','Required parameter requestParameters.conditionId was null or undefined when calling getConditionMetaData.');
        }

        const queryParameters: any = {};

        if (requestParameters.conditionId !== undefined) {
            queryParameters['conditionId'] = requestParameters.conditionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/conditions/metadata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConditionMetaDataViewFromJSON(jsonValue));
    }

    /**
     */
    async getConditionMetaData(requestParameters: GetConditionMetaDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConditionMetaDataView> {
        const response = await this.getConditionMetaDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async initSearchConditionsRaw(requestParameters: InitSearchConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConditionMetaDataView>>> {
        if (requestParameters.conditionSearchInput === null || requestParameters.conditionSearchInput === undefined) {
            throw new runtime.RequiredError('conditionSearchInput','Required parameter requestParameters.conditionSearchInput was null or undefined when calling initSearchConditions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conditions/metadata/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConditionSearchInputToJSON(requestParameters.conditionSearchInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConditionMetaDataViewFromJSON));
    }

    /**
     */
    async initSearchConditions(requestParameters: InitSearchConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConditionMetaDataView>> {
        const response = await this.initSearchConditionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logConditionUsageRaw(requestParameters: LogConditionUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conditions/usages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsageInputToJSON(requestParameters.usageInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logConditionUsage(requestParameters: LogConditionUsageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logConditionUsageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveDayOfMonthConditionRaw(requestParameters: SaveDayOfMonthConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dayOfMonthConditionInput === null || requestParameters.dayOfMonthConditionInput === undefined) {
            throw new runtime.RequiredError('dayOfMonthConditionInput','Required parameter requestParameters.dayOfMonthConditionInput was null or undefined when calling saveDayOfMonthCondition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conditions/dayofmonth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DayOfMonthConditionInputToJSON(requestParameters.dayOfMonthConditionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveDayOfMonthCondition(requestParameters: SaveDayOfMonthConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveDayOfMonthConditionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveDayOfWeekConditionRaw(requestParameters: SaveDayOfWeekConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dayOfWeekConditionInput === null || requestParameters.dayOfWeekConditionInput === undefined) {
            throw new runtime.RequiredError('dayOfWeekConditionInput','Required parameter requestParameters.dayOfWeekConditionInput was null or undefined when calling saveDayOfWeekCondition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conditions/dayofweek`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DayOfWeekConditionInputToJSON(requestParameters.dayOfWeekConditionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveDayOfWeekCondition(requestParameters: SaveDayOfWeekConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveDayOfWeekConditionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveLogicalConditionRaw(requestParameters: SaveLogicalConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.logicalConditionInput === null || requestParameters.logicalConditionInput === undefined) {
            throw new runtime.RequiredError('logicalConditionInput','Required parameter requestParameters.logicalConditionInput was null or undefined when calling saveLogicalCondition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conditions/logical`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogicalConditionInputToJSON(requestParameters.logicalConditionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveLogicalCondition(requestParameters: SaveLogicalConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveLogicalConditionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveStaticConditionRaw(requestParameters: SaveStaticConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.staticConditionInput === null || requestParameters.staticConditionInput === undefined) {
            throw new runtime.RequiredError('staticConditionInput','Required parameter requestParameters.staticConditionInput was null or undefined when calling saveStaticCondition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conditions/static`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaticConditionInputToJSON(requestParameters.staticConditionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveStaticCondition(requestParameters: SaveStaticConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveStaticConditionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveVariableConditionRaw(requestParameters: SaveVariableConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.variableConditionInput === null || requestParameters.variableConditionInput === undefined) {
            throw new runtime.RequiredError('variableConditionInput','Required parameter requestParameters.variableConditionInput was null or undefined when calling saveVariableCondition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conditions/variables`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VariableConditionInputToJSON(requestParameters.variableConditionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveVariableCondition(requestParameters: SaveVariableConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveVariableConditionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveWinterHolidaysConditionRaw(requestParameters: SaveWinterHolidaysConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.winterHolidaysConditionInput === null || requestParameters.winterHolidaysConditionInput === undefined) {
            throw new runtime.RequiredError('winterHolidaysConditionInput','Required parameter requestParameters.winterHolidaysConditionInput was null or undefined when calling saveWinterHolidaysCondition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/conditions/winterholidays`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WinterHolidaysConditionInputToJSON(requestParameters.winterHolidaysConditionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveWinterHolidaysCondition(requestParameters: SaveWinterHolidaysConditionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveWinterHolidaysConditionRaw(requestParameters, initOverrides);
    }

}
