/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdoptedNavPoint } from './AdoptedNavPoint';
import {
    AdoptedNavPointFromJSON,
    AdoptedNavPointFromJSONTyped,
    AdoptedNavPointToJSON,
} from './AdoptedNavPoint';
import type { ConditionMetaDataView } from './ConditionMetaDataView';
import {
    ConditionMetaDataViewFromJSON,
    ConditionMetaDataViewFromJSONTyped,
    ConditionMetaDataViewToJSON,
} from './ConditionMetaDataView';
import type { SectionPartView } from './SectionPartView';
import {
    SectionPartViewFromJSON,
    SectionPartViewFromJSONTyped,
    SectionPartViewToJSON,
} from './SectionPartView';

/**
 * 
 * @export
 * @interface SectionView
 */
export interface SectionView {
    /**
     * 
     * @type {string}
     * @memberof SectionView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SectionView
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionView
     */
    description?: string;
    /**
     * 
     * @type {SectionPartView}
     * @memberof SectionView
     */
    part1: SectionPartView;
    /**
     * 
     * @type {SectionPartView}
     * @memberof SectionView
     */
    part2: SectionPartView;
    /**
     * 
     * @type {ConditionMetaDataView}
     * @memberof SectionView
     */
    condition?: ConditionMetaDataView;
    /**
     * 
     * @type {number}
     * @memberof SectionView
     */
    distanceM?: number;
    /**
     * 
     * @type {Array<AdoptedNavPoint>}
     * @memberof SectionView
     */
    intermediateNavPoints?: Array<AdoptedNavPoint>;
    /**
     * 
     * @type {boolean}
     * @memberof SectionView
     */
    bidirectional: boolean;
}

/**
 * Check if a given object implements the SectionView interface.
 */
export function instanceOfSectionView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "part1" in value;
    isInstance = isInstance && "part2" in value;
    isInstance = isInstance && "bidirectional" in value;

    return isInstance;
}

export function SectionViewFromJSON(json: any): SectionView {
    return SectionViewFromJSONTyped(json, false);
}

export function SectionViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'part1': SectionPartViewFromJSON(json['part1']),
        'part2': SectionPartViewFromJSON(json['part2']),
        'condition': !exists(json, 'condition') ? undefined : ConditionMetaDataViewFromJSON(json['condition']),
        'distanceM': !exists(json, 'distanceM') ? undefined : json['distanceM'],
        'intermediateNavPoints': !exists(json, 'intermediateNavPoints') ? undefined : ((json['intermediateNavPoints'] as Array<any>).map(AdoptedNavPointFromJSON)),
        'bidirectional': json['bidirectional'],
    };
}

export function SectionViewToJSON(value?: SectionView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyId': value.companyId,
        'description': value.description,
        'part1': SectionPartViewToJSON(value.part1),
        'part2': SectionPartViewToJSON(value.part2),
        'condition': ConditionMetaDataViewToJSON(value.condition),
        'distanceM': value.distanceM,
        'intermediateNavPoints': value.intermediateNavPoints === undefined ? undefined : ((value.intermediateNavPoints as Array<any>).map(AdoptedNavPointToJSON)),
        'bidirectional': value.bidirectional,
    };
}

