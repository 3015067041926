import {LineSchemaMetaDataView, TimetableView} from "../../../api";
import TimetableLineTable from "../../line/table/TimetableLineTable";
import {ReactNode} from "react";

interface TimeTableExplorerLinesProps {
    lineChildren: LineSchemaMetaDataView[];
    timetable: TimetableView;
    onChangeLineForLinePlan: (a0: LineSchemaMetaDataView) => void;
    onChangeLineForCoursePanel: (line: LineSchemaMetaDataView) => void;
    onChangeLineForEditPanel: (lineId: string) => void;
}

export function TimeTableExplorerLines(props: TimeTableExplorerLinesProps): ReactNode {
    if (props.lineChildren == null) {
        return <h3>Ładowanie linii</h3>;
    } else if (props.lineChildren.length > 0) {
        return <>
            <h2>Linie</h2>
            <TimetableLineTable lines={props.lineChildren}
                                timetable={props.timetable}
                                onChangeLineForLinePlan={props.onChangeLineForLinePlan}
                                onChangeLineForCoursePanel={props.onChangeLineForCoursePanel}
                                onChangeLineForEditPanel={props.onChangeLineForEditPanel}
            />
        </>
    }
}
