import Roller from "../../../../common/roller/Roller";
import {PlatformTable} from "../../../../platform/table/PlatformTable";
import SpaceForCurrentCompanySwitcher from "../../../../space/switch/SpaceForCurrentCompanySwitcher";
import Af from "../../../../common/ahreffun/Af";
import {hasSuperPermissionInSpace} from "../../../../common/superuser/permissionUtil";
import {DataSpacePermissionCompanyView, NavPointMetaDataView, NavPointType} from "../../../../../api";
import {ReactNode, useRef, useState} from "react";
import {Company, MzkWorkspace} from "../../../../user/current/CurrentUserContext";
import Button from "../../../../common/input/button/Button";
import {PlatformTableCriteria} from "../../../../platform/table/PlatformTableCriteria";

interface AddNewPlatformPlanPartProps {
    open: boolean;
    onChangeVisibility: () => void;
    addPartFun: (navPoint: NavPointMetaDataView) => void;
    showNewPlatformFormFun: (navPoint: NavPointMetaDataView, space: DataSpacePermissionCompanyView) => void;
    showNewStationFormFun: (space: DataSpacePermissionCompanyView) => void;
    greedyVersion: number;
}

function AddNewPlatformPlanPart(props: AddNewPlatformPlanPartProps) {
    const [mzkWorkspace, setMzkWorkspace] = useState<MzkWorkspace>();
    const [query, setQuery] = useState('');
    const queryRef = useRef<HTMLInputElement>();
    return (
        <Roller
            open={props.open}
            onChangeVisibility={props.onChangeVisibility}
            title='Dodawanie platformy'>
            <SpaceForCurrentCompanySwitcher
                mzkWorkspace={mzkWorkspace}
                onChangeCompanyAndSpaceFun={(c: Company, s: DataSpacePermissionCompanyView) => {
                    setMzkWorkspace({company: c, space: s})
                }}
            />
            <br/>
            <PlatformTableCriteria
                onDataUpdated={setQuery}
                query={query}
                inputRef={queryRef}
            />
            <PlatformTable
                mzkWorkspace={mzkWorkspace}
                query={query}
                limit={3}
                actionProviderFun={(navPoint: NavPointMetaDataView): ReactNode => <>
                    <Af onClick={() => {
                        setQuery('');
                        props.addPartFun(navPoint);
                        queryRef.current.focus();
                    }}>Użyj</Af>
                    {hasSuperPermissionInSpace(mzkWorkspace) && navPoint.type === NavPointType.Station &&
                        <Af onClick={() => props.showNewPlatformFormFun(navPoint, mzkWorkspace.space)}>Dodaj nową
                            platformę</Af>}
                </>}
                greedyPlatformVersion={props.greedyVersion}
            />
            {hasSuperPermissionInSpace(mzkWorkspace) && <Button onClick={() => {
                props.showNewStationFormFun(mzkWorkspace.space)
            }}>Dodaj nową stację</Button>}
        </Roller>
    );
}

export default AddNewPlatformPlanPart;
