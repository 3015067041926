/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CourseTimeOverriddenTimeView
 */
export interface CourseTimeOverriddenTimeView {
    /**
     * 
     * @type {number}
     * @memberof CourseTimeOverriddenTimeView
     */
    travelSec?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseTimeOverriddenTimeView
     */
    waitingSec?: number;
}

/**
 * Check if a given object implements the CourseTimeOverriddenTimeView interface.
 */
export function instanceOfCourseTimeOverriddenTimeView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CourseTimeOverriddenTimeViewFromJSON(json: any): CourseTimeOverriddenTimeView {
    return CourseTimeOverriddenTimeViewFromJSONTyped(json, false);
}

export function CourseTimeOverriddenTimeViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CourseTimeOverriddenTimeView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'travelSec': !exists(json, 'travelSec') ? undefined : json['travelSec'],
        'waitingSec': !exists(json, 'waitingSec') ? undefined : json['waitingSec'],
    };
}

export function CourseTimeOverriddenTimeViewToJSON(value?: CourseTimeOverriddenTimeView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'travelSec': value.travelSec,
        'waitingSec': value.waitingSec,
    };
}

