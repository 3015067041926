import * as React from 'react';
import Af from "../../common/ahreffun/Af";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {TownView} from "../../../api";
import Popup from "../../common/popup/Popup";
import TownTable from "../table/TownTable";

interface StreetPopupSelectorProps {
    closePopup: () => void;
    onSelect: (town: TownView) => void;
    mzkWorkspace: MzkWorkspace
}

function StreetPopupSelector(props: StreetPopupSelectorProps) {
    return (
        <Popup closePopup={props.closePopup}>
            <h1>Wybór miasta</h1>
            <TownTable mzkWorkspace={props.mzkWorkspace}
                       actionProvider={(t) => <Af onClick={() => props.onSelect(t)}>Wybierz</Af>}/>
        </Popup>
    );
}

export default StreetPopupSelector;
