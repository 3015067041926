/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CourseSearchTimeInput
 */
export interface CourseSearchTimeInput {
    /**
     * 
     * @type {string}
     * @memberof CourseSearchTimeInput
     */
    courseId: string;
    /**
     * 
     * @type {Date}
     * @memberof CourseSearchTimeInput
     */
    examinedDate: Date;
}

/**
 * Check if a given object implements the CourseSearchTimeInput interface.
 */
export function instanceOfCourseSearchTimeInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "courseId" in value;
    isInstance = isInstance && "examinedDate" in value;

    return isInstance;
}

export function CourseSearchTimeInputFromJSON(json: any): CourseSearchTimeInput {
    return CourseSearchTimeInputFromJSONTyped(json, false);
}

export function CourseSearchTimeInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CourseSearchTimeInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'courseId': json['courseId'],
        'examinedDate': (new Date(json['examinedDate'])),
    };
}

export function CourseSearchTimeInputToJSON(value?: CourseSearchTimeInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'courseId': value.courseId,
        'examinedDate': (value.examinedDate.toISOString().substr(0,10)),
    };
}

