export function sortByPropertyValue(property: string, sortAsc: boolean = true) {
    return sortByFunctionValue(obj => obj[property], sortAsc);
}

export function sortByFunctionValue(fun: (obj: any) => any, sortAsc = true) {
    const sortOrder = sortAsc ? 1 : -1;
    return function (a: any, b: any) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        const aValue = fun(a);
        const bValue = fun(b);
        const result = (aValue < bValue) ? -1 : (aValue > bValue) ? 1 : 0;
        return result * sortOrder;
    }
}
