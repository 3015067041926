/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CourseInput
 */
export interface CourseInput {
    /**
     * 
     * @type {string}
     * @memberof CourseInput
     */
    lineId: string;
    /**
     * 
     * @type {string}
     * @memberof CourseInput
     */
    crewLiabilityPointId: string;
    /**
     * 
     * @type {number}
     * @memberof CourseInput
     */
    startTimeSec: number;
}

/**
 * Check if a given object implements the CourseInput interface.
 */
export function instanceOfCourseInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lineId" in value;
    isInstance = isInstance && "crewLiabilityPointId" in value;
    isInstance = isInstance && "startTimeSec" in value;

    return isInstance;
}

export function CourseInputFromJSON(json: any): CourseInput {
    return CourseInputFromJSONTyped(json, false);
}

export function CourseInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CourseInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineId': json['lineId'],
        'crewLiabilityPointId': json['crewLiabilityPointId'],
        'startTimeSec': json['startTimeSec'],
    };
}

export function CourseInputToJSON(value?: CourseInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineId': value.lineId,
        'crewLiabilityPointId': value.crewLiabilityPointId,
        'startTimeSec': value.startTimeSec,
    };
}

