import {useContext, useState} from 'react';
import {addToast} from '../common/toast/toastUtil';
import {CurrentUserContext} from '../CurrentUserContext';
import CompanyContextAssociatedSpaceSwitcher from '../space/switch/CompanyContextAssociatedSpaceSwitcher';
import {Company} from "./current/CurrentUserContext";
import {DataSpacePermissionCompanyView} from "../../api";
import LogoutBox from "../login/LogoutBox";

function UserTab() {
    const [space, setSpace] = useState<DataSpacePermissionCompanyView>();
    const [company, setCompany] = useState<Company>();
    const currentUser = useContext(CurrentUserContext);
    return (
        <>
            <h2>Zarządzanie użytkownikiem</h2>
            <h3>Domyślny kontekst</h3>
            <CompanyContextAssociatedSpaceSwitcher
                company={company}
                space={space}
                onChangeCompanyAndSpaceFun={(c: Company, s: DataSpacePermissionCompanyView) => {
                    if (space || company) {
                        currentUser.user.defaultCompany = c;
                        currentUser.user.defaultSpace = s;
                        currentUser.user.defaultTimetable = null;
                        currentUser.changeUserFun(currentUser);
                        addToast('Domyślny kontekst został zmieniony');
                    }
                    setCompany(c);
                    setSpace(s);
                }}
            />
            <br/>
            <LogoutBox/>
        </>
    );
}

export default UserTab;