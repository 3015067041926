import {ConditionVisibilityRuleTypeView, SectionEndpointApi, SectionView} from "../../../api";
import Close from "../../common/ahreffun/Close";
import {ChangeEvent, useEffect, useState} from "react";
import Af from "../../common/ahreffun/Af";
import {prepareNameWithAncestor} from "../../common/navpoint/navpointUtil";
import Checkbox from "../../common/input/checkbox/Checkbox";
import ConditionPopupSelector from "../../condition/popup/ConditionPopupSelector";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {nullIfBlank} from "../../common/string/stringUtil";
import {addToast} from "../../common/toast/toastUtil";
import {verifyClientResponse} from "../../common/error/resolvers";
import Button from "../../common/input/button/Button";
import {prepareSectionErrorMap} from "../new/AddSectionPanel";
import {IntermediateNavPointForm} from "../intermediatenavpoint/table/IntermediateNavPointForm";

interface ModifySectionPanelProps {
    section: SectionView;
    company: Company
    onClose: (refresh: boolean) => void;
}

export function ModifySectionPanel(props: ModifySectionPanelProps) {
    const [section, setSection] = useState<SectionView>();
    const [showConditionSelector, setShowConditionSelector] = useState(false);
    useEffect(() => {
        setSection({...props.section});
    }, []);
    if (section == null) {
        return;
    }
    const onSave = () => {
        if (validate()) {
            new SectionEndpointApi().updateSection({
                updateSectionInput: {
                    id: section.id,
                    description: nullIfBlank(section.description),
                    conditionId: section.condition ? section.condition.id : null,
                    distanceM: section.distanceM ? section.distanceM : null,
                    bidirectional: section.bidirectional,
                    intermediateNavPointIds: section.intermediateNavPoints.map(i => i.navPointMetaData.id)
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie zaktualizowano odcinek');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, prepareSectionErrorMap());
                })
        }
    }
    const validate = () => {
        if(section.distanceM < 1){
            addToast('Dystans musi być większy niż 1');
            return false;
        }
        return true;
    }
    const onDelete = () => {
        if (confirm('Czy na pewno chcesz usunąć ten odcinek?')){
            new SectionEndpointApi().removeSection({sectionId: section.id}, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Poprawnie usunięto odcinek');
                    props.onClose(true);
                }).catch(e => {
                verifyClientResponse(e, prepareSectionErrorMap());
            })
        }
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        </div>
        <h3>Modyfikacja odcinka</h3>
        <span>Z: </span> <b>{prepareNameWithAncestor(section.part1)}</b>
        <br/>

        <span>Do: </span> <b>{prepareNameWithAncestor(section.part2)}</b>
        <br/>
        <label>Opis:</label>
        <input name='description' value={section.description}
               onChange={(e: ChangeEvent<HTMLInputElement>) => setSection(s => {
                   return {...s, description: e.target.value}
               })}/>
        <br/>

        <label>Odległość(m):</label>
        <input type='number' name='distance' step={1} min={1}
               value={section.distanceM ? section.distanceM : ''}
               onChange={(e: ChangeEvent<HTMLInputElement>) => setSection({
                   ...section,
                   distanceM: parseInt(e.target.value)
               })}/>
        <br/>

        <span>Warunek: </span>
        {section.condition ? <>
            {section.condition.name}
            <Af onClick={() => setSection({...section, condition: null})}>Usuń warunek</Af>
        </> : 'nie ustawiono'}
        <Af onClick={() => setShowConditionSelector(true)}>Warunek</Af>
        <br/>
        <Checkbox onChange={() => setSection({...section, bidirectional: !section.bidirectional})}
                  value={section.bidirectional}>Odcinek dwukierunkowy</Checkbox>
        <br />
        <IntermediateNavPointForm navPoints={section.intermediateNavPoints} onChangeIntermediateNavPoint={(inp)=>setSection({...section, intermediateNavPoints: inp})}/>
        <Button onClick={onSave}>Zapisz</Button>
        {showConditionSelector &&
            <ConditionPopupSelector
                company={props.company}
                visibilityRule={ConditionVisibilityRuleTypeView.Section}
                closePopup={() => setShowConditionSelector(false)}
                selectConditionFun={(c) => setSection(s => ({...s, condition: c}))}
            />}
        <h3>Inne opcje</h3>
        <Button onClick={onDelete}>Usuń odcinek</Button>
    </div>;
}
