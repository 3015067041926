import Close from "../../common/ahreffun/Close";
import {uxNull} from "../../common/string/stringUtil";
import {VariableValueTable} from "./VariableValueTable";
import {VariableTemplateView} from "../../../api";

interface VariableValuePopupProps {
    close: () => void;
    variable: VariableTemplateView;
}

const VariableValuePopup = (props: VariableValuePopupProps) => {
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={props.close}/></span>
        </div>
        <h3>{props.variable.name} - wartości</h3>
        <VariableValueTable values={props.variable.values} actionProvider={() => uxNull()}/>
    </div>
}

export default VariableValuePopup;
