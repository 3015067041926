/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionMetaDataInput } from './ConditionMetaDataInput';
import {
    ConditionMetaDataInputFromJSON,
    ConditionMetaDataInputFromJSONTyped,
    ConditionMetaDataInputToJSON,
} from './ConditionMetaDataInput';
import type { LogicalConditionDetails } from './LogicalConditionDetails';
import {
    LogicalConditionDetailsFromJSON,
    LogicalConditionDetailsFromJSONTyped,
    LogicalConditionDetailsToJSON,
} from './LogicalConditionDetails';

/**
 * 
 * @export
 * @interface LogicalConditionInput
 */
export interface LogicalConditionInput {
    /**
     * 
     * @type {ConditionMetaDataInput}
     * @memberof LogicalConditionInput
     */
    metaData?: ConditionMetaDataInput;
    /**
     * 
     * @type {LogicalConditionDetails}
     * @memberof LogicalConditionInput
     */
    details?: LogicalConditionDetails;
}

/**
 * Check if a given object implements the LogicalConditionInput interface.
 */
export function instanceOfLogicalConditionInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LogicalConditionInputFromJSON(json: any): LogicalConditionInput {
    return LogicalConditionInputFromJSONTyped(json, false);
}

export function LogicalConditionInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogicalConditionInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metaData': !exists(json, 'metaData') ? undefined : ConditionMetaDataInputFromJSON(json['metaData']),
        'details': !exists(json, 'details') ? undefined : LogicalConditionDetailsFromJSON(json['details']),
    };
}

export function LogicalConditionInputToJSON(value?: LogicalConditionInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metaData': ConditionMetaDataInputToJSON(value.metaData),
        'details': LogicalConditionDetailsToJSON(value.details),
    };
}

