import {useContext, useEffect, useState} from "react";
import {CurrentUserContext} from "../../CurrentUserContext";
import {
    ConditionEndpointApi,
    ConditionMetaDataView, DataSpaceView,
    NavPointStationView, SpaceEndpointApi,
    StationEndpointApi, StreetEndpointApi, StreetView,
    ZoneEndpointApi, ZoneView
} from "../../../api";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import Close from "../../common/ahreffun/Close";
import {prepareName} from "../../common/navpoint/navpointUtil";
import {NavPointSettingsViewDisplay} from "../../navpoint/setting/NavPointSettingsViewDisplay";
import {mapBooleanToDescription} from "../../common/boolean/booleanUtil";
import {uxNull} from "../../common/string/stringUtil";
import {hasSuperPermissionInSpace} from "../../common/superuser/permissionUtil";
import Button from "../../common/input/button/Button";

interface StationDetailsPanelProps {
    stationId: string;
    onClose: () => void;
    onModify: (stationId: string) => void;
}

export function StationDetailsPanel(props: StationDetailsPanelProps) {
    const currentUser = useContext(CurrentUserContext);
    const [stationData, setStationData] = useState<NavPointStationView>();
    const [conditionData, setConditionData] = useState<ConditionMetaDataView>();
    const [zoneData, setZoneData] = useState<ZoneView>();
    const [street, setStreet] = useState<StreetView>();
    const [space, setSpace] = useState<DataSpaceView>();
    useEffect(() => {
        new StationEndpointApi().getStationDetails({stationId: props.stationId, companyId: currentUser.user.defaultCompany.id},
            {headers: prepareSecurityHeaders()})
            .then(s => setStationData(s))
            .catch(e => verifyClientResponse(e));
    }, [props.stationId]);
    useEffect(() => {
        const id = stationData?.metaData.conditionId;
        if (id) {
            new ConditionEndpointApi().getConditionMetaData({conditionId: id},
                {headers: prepareSecurityHeaders()})
                .then(c => setConditionData(c))
                .catch(e => verifyClientResponse(e));
        } else {
            setConditionData(null);
        }
    }, [stationData?.metaData.conditionId]);
    useEffect(() => {
        const id = stationData?.details.zoneId;
        if (id){
            new ZoneEndpointApi().getZone({
                    zoneId: id
                },
                {headers: prepareSecurityHeaders()})
                .then(z => setZoneData(z))
                .catch(e => verifyClientResponse(e));
        }else{
            setZoneData(null);
        }
    }, [stationData?.details.zoneId]);
    useEffect(() => {
        const id = stationData?.metaData.dataSpaceId;
        if (id){
            new SpaceEndpointApi().getDataSpace({dataSpaceId: id, companyId: currentUser.user.defaultCompany.id},
                {headers: prepareSecurityHeaders()})
                .then(s => setSpace(s))
                .catch(e => verifyClientResponse(e));
        }else{
            setSpace(null);
        }
    }, [stationData?.metaData.dataSpaceId]);
    useEffect(() => {
        const id = stationData?.metaData.streetId;
        if (id) {
            new StreetEndpointApi().getStreet({
                    companyId: currentUser.user.defaultCompany.id,
                    streetId: id
                },
                {headers: prepareSecurityHeaders()})
                .then(s => setStreet(s))
                .catch(e => verifyClientResponse(e));
        } else {
            setStreet(null);
        }
    }, [stationData?.metaData.streetId]);
    if (stationData == null) {
        return <div className="diffArea">Ładowanie...</div>;
    }
    return <div className="diffArea">
        <div>
      <span className="windowButton">
        <Close onClick={props.onClose}/>
      </span>
        </div>
        <h3>{prepareName(stationData.metaData)} - szczegóły</h3>
        Nazwa: {uxNull(stationData.metaData.name)} <br/>
        Nazwa skrócona: {uxNull(stationData.metaData.shortName)} <br/>
        Nazwa techniczna: {uxNull(stationData.metaData.technicalName)} <br/>
        IncId: {uxNull(stationData.metaData.incrementalId)} <br/>
        DataSpace: {uxNull(space?.name)} <br/>
        Rodzic: {uxNull(stationData.metaData.parentId)} <br/>
        Warunek: {conditionData ? conditionData.name : uxNull()} <br/>
        <NavPointSettingsViewDisplay settings={stationData.metaData.settings}/><br/>
        Przystanek na żądanie: {mapBooleanToDescription(stationData.details.requestStop)} <br/>
        Ulica: {uxNull(street?.name)} <br/>
        Gps: {uxNull(stationData.metaData.gpsX)} {stationData.metaData.gpsY}<br/>
        Strefa: {zoneData ? zoneData.name : uxNull()}<br/>
        {hasSuperPermissionInSpace({
            company: currentUser.user.defaultCompany,
            space: currentUser.user.defaultCompany.availableDataSpaces.filter(ds => ds.id == stationData.metaData.dataSpaceId)[0]
        }) && <Button onClick={() => props.onModify(props.stationId)}>Modyfikuj</Button>}
    </div>;
}
