import {VariableValueSelection} from "./NewVariableConditionForm";
import {VariableValueTable} from "../../../variable/value/VariableValueTable";
import Checkbox from "../../../common/input/checkbox/Checkbox";

interface NewVariableConditionValueSelectorProps {
    value: VariableValueSelection;
    onSelect: (id: string) => void;
}

export function NewVariableConditionValueSelector(props: NewVariableConditionValueSelectorProps) {
    if (props.value == null) {
        return null;
    }
    return <VariableValueTable values={props.value.variable.values}
                               actionProvider={(v) => <Checkbox
                                   onChange={() => props.onSelect(v.id)}
                                   value={props.value.selectedValueIds.includes(v.id)}>Wybierz</Checkbox>}
    />
}
