import {NavPointSettings} from "../../../api";
import {mapBooleanToDescription} from "../../common/boolean/booleanUtil";

interface NavPointSettingsViewDisplayProps{
    settings: NavPointSettings;
}
export function NavPointSettingsViewDisplay(props: NavPointSettingsViewDisplayProps){
    return <>
        Wsiadanie pasażerów: {mapBooleanToDescription(props.settings.passengersFrom)} <br />
        Wysiadanie pasażerów: {mapBooleanToDescription(props.settings.passengersTo)} <br />
        Miejsce postojów technicznych: {mapBooleanToDescription(props.settings.technicalStop)} <br />
        Zajezdnia: {mapBooleanToDescription(props.settings.depot)}
    </>;
}
