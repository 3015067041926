/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NavPointSettings
 */
export interface NavPointSettings {
    /**
     * 
     * @type {boolean}
     * @memberof NavPointSettings
     */
    passengersFrom: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NavPointSettings
     */
    passengersTo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NavPointSettings
     */
    technicalStop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NavPointSettings
     */
    depot: boolean;
}

/**
 * Check if a given object implements the NavPointSettings interface.
 */
export function instanceOfNavPointSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "passengersFrom" in value;
    isInstance = isInstance && "passengersTo" in value;
    isInstance = isInstance && "technicalStop" in value;
    isInstance = isInstance && "depot" in value;

    return isInstance;
}

export function NavPointSettingsFromJSON(json: any): NavPointSettings {
    return NavPointSettingsFromJSONTyped(json, false);
}

export function NavPointSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavPointSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'passengersFrom': json['passengersFrom'],
        'passengersTo': json['passengersTo'],
        'technicalStop': json['technicalStop'],
        'depot': json['depot'],
    };
}

export function NavPointSettingsToJSON(value?: NavPointSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'passengersFrom': value.passengersFrom,
        'passengersTo': value.passengersTo,
        'technicalStop': value.technicalStop,
        'depot': value.depot,
    };
}

