/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AsyncTaskSearchInput,
  AsyncTaskView,
} from '../models';
import {
    AsyncTaskSearchInputFromJSON,
    AsyncTaskSearchInputToJSON,
    AsyncTaskViewFromJSON,
    AsyncTaskViewToJSON,
} from '../models';

export interface InitSearchAsyncTaskRequest {
    asyncTaskSearchInput: AsyncTaskSearchInput;
}

/**
 * 
 */
export class AsyncTaskEndpointApi extends runtime.BaseAPI {

    /**
     */
    async initSearchAsyncTaskRaw(requestParameters: InitSearchAsyncTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AsyncTaskView>>> {
        if (requestParameters.asyncTaskSearchInput === null || requestParameters.asyncTaskSearchInput === undefined) {
            throw new runtime.RequiredError('asyncTaskSearchInput','Required parameter requestParameters.asyncTaskSearchInput was null or undefined when calling initSearchAsyncTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/asyncTask/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AsyncTaskSearchInputToJSON(requestParameters.asyncTaskSearchInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AsyncTaskViewFromJSON));
    }

    /**
     */
    async initSearchAsyncTask(requestParameters: InitSearchAsyncTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AsyncTaskView>> {
        const response = await this.initSearchAsyncTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
