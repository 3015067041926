import {useState} from 'react';
import AddNewZoneForm from "../new/AddNewZoneForm";
import Button from "../../common/input/button/Button";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import ZoneTable from "../table/ZoneTable";
import {uxNull} from "../../common/string/stringUtil";
import Af from "../../common/ahreffun/Af";
import {ZoneEditPanel} from "../edit/ZoneEditPanel";
import {ZoneView} from "../../../api";

interface ZoneTabSectionAvailableZonesProps {
    mzkWorkspace: MzkWorkspace
}

function ZoneTabSectionAvailableZones(props: ZoneTabSectionAvailableZonesProps) {
    const [showNewZoneForm, setShowNewZoneForm] = useState(false);
    const [zoneIdForZoneEditPanel, setZoneIdForZoneEditPanel] = useState<string>();
    const [zoneGreedyVersion, setZoneGreedyVersion] = useState(0);
    const actionProvider = (z: ZoneView) => {
        return props.mzkWorkspace.company.superUser ?
            <Af onClick={() => setZoneIdForZoneEditPanel(z.id)}>Edycja</Af> : uxNull();
    }
    return (
        <div id='simpleMainContentSplit'>
            <div>
                <ZoneTable mzkWorkspace={props.mzkWorkspace}
                           greedyVersion={zoneGreedyVersion}
                           actionProvider={actionProvider}/>
                {props.mzkWorkspace && props.mzkWorkspace.company.superUser &&
                    <Button onClick={() => setShowNewZoneForm(!showNewZoneForm)}>Dodaj nową strefę</Button>}
            </div>
            {showNewZoneForm && <AddNewZoneForm mzkWorkspace={props.mzkWorkspace}
                                                hideNewZoneFormFun={() => setShowNewZoneForm(false)}/>}
            {zoneIdForZoneEditPanel &&
                <ZoneEditPanel
                    zoneId={zoneIdForZoneEditPanel}
                    mzkWorkspace={props.mzkWorkspace}
                    onClose={(r) => {
                        setZoneIdForZoneEditPanel(null);
                        r && setZoneGreedyVersion(v => v + 1);
                    }}/>}
        </div>
    );
}

export default ZoneTabSectionAvailableZones;
