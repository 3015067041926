/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StationDetailsView
 */
export interface StationDetailsView {
    /**
     * 
     * @type {boolean}
     * @memberof StationDetailsView
     */
    requestStop?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StationDetailsView
     */
    zoneId?: string;
}

/**
 * Check if a given object implements the StationDetailsView interface.
 */
export function instanceOfStationDetailsView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StationDetailsViewFromJSON(json: any): StationDetailsView {
    return StationDetailsViewFromJSONTyped(json, false);
}

export function StationDetailsViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationDetailsView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestStop': !exists(json, 'requestStop') ? undefined : json['requestStop'],
        'zoneId': !exists(json, 'zoneId') ? undefined : json['zoneId'],
    };
}

export function StationDetailsViewToJSON(value?: StationDetailsView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestStop': value.requestStop,
        'zoneId': value.zoneId,
    };
}

