import DurationPicker from "../../../common/input/duration/DurationPicker";
import Checkbox from "../../../common/input/checkbox/Checkbox";
import Help from "../../../common/tooltip/Help";
import SectionTimeTable from "../../time/SectionTimeTable";
import {SectionTimeView} from "../../../../api";
import Af from "../../../common/ahreffun/Af";
import AddNewSectionTimePart from "../../time/AddNewSectionTimePart";
import {useState} from "react";
import {sortByPropertyValue} from "../../../common/sort/sortUtil";
import DateInput from "../../../common/input/date/DateInput";
import {addToast} from "../../../common/toast/toastUtil";
import {stringToDate} from "../../../common/date/dateUtil";

interface SectionSampleFormProps {
    sample: SectionSample;
    onChangeSample: (s: SectionSample) => void;
}

export function SectionSampleForm(props: SectionSampleFormProps) {
    const [showAddTimePart, setShowAddTimePart] = useState(false);
    const removeSectionTime = (index: number) => {
        const times = [...props.sample.times];
        times.splice(index, 1);
        props.onChangeSample({...props.sample, times: times});
    }
    const addNewSectionTime = (time: SectionTimeView) => {
        const times = [...props.sample.times];
        times.push(time);
        times.sort(sortByPropertyValue('from'));
        props.onChangeSample({...props.sample, times: times});
    }
    return <form>
        <label>Czas pomiaru:</label>
        <DateInput onChange={v => props.onChangeSample({...props.sample, sampleDate: v})}
                   value={props.sample.sampleDate}/><br/>

        <label>Czas przejazdu(h/m/s)*:</label>
        <DurationPicker duration={props.sample.defaultTravelSec}
                        negativeValueAllowed={false}
                        onDurationChange={(d: number) => {
                            props.onChangeSample({...props.sample, defaultTravelSec: d});
                        }}/><br/>


        <Checkbox value={props.sample.defaultWaitingSec != null}
                  children={<label>Czas oczekiwania:<Help>dotyczy miejsca docelowego</Help></label>}
                  onChange={() => {
                      const defaultWaitingSec = props.sample.defaultWaitingSec == null ? 0 : null
                      props.onChangeSample({...props.sample, defaultWaitingSec: defaultWaitingSec});
                  }}/>
        <DurationPicker duration={props.sample.defaultWaitingSec}
                        onDurationChange={(d: number) => props.onChangeSample({...props.sample, defaultWaitingSec: d})}
                        negativeValueAllowed={false}
                        disabled={props.sample.defaultWaitingSec == null}/>
        <br/>
        <SectionTimeTable times={props.sample.times}
                          actionProvider={(t, index: number) => <Af
                              onClick={() => removeSectionTime(index)}>Usuń</Af>}
        />
        <AddNewSectionTimePart showAddTime={showAddTimePart}
                               addNewSectionTimeFun={addNewSectionTime}
                               onChangeVisibility={() => setShowAddTimePart(s => !s)}/>
    </form>
}

export interface SectionSample {
    sampleDate: string;
    defaultTravelSec: number;
    defaultWaitingSec: number;
    times: SectionTimeView[];
}

export function validateSectionSample(s: SectionSample): boolean {
    if (s.defaultTravelSec < 1) {
        addToast('Brak czasu przejazdu');
        return false;
    } else if (s.defaultWaitingSec != null && s.defaultWaitingSec < 1) {
        addToast('Niepoprawny czas oczekiwania');
        return false;
    }else if (stringToDate(s.sampleDate) == null){
        addToast('Uzupełnij datę próbki');
        return false;
    }
    return true;
}
