/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSectionInput
 */
export interface UpdateSectionInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionInput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionInput
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionInput
     */
    conditionId?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSectionInput
     */
    distanceM?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSectionInput
     */
    intermediateNavPointIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSectionInput
     */
    bidirectional?: boolean;
}

/**
 * Check if a given object implements the UpdateSectionInput interface.
 */
export function instanceOfUpdateSectionInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "intermediateNavPointIds" in value;

    return isInstance;
}

export function UpdateSectionInputFromJSON(json: any): UpdateSectionInput {
    return UpdateSectionInputFromJSONTyped(json, false);
}

export function UpdateSectionInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSectionInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'conditionId': !exists(json, 'conditionId') ? undefined : json['conditionId'],
        'distanceM': !exists(json, 'distanceM') ? undefined : json['distanceM'],
        'intermediateNavPointIds': json['intermediateNavPointIds'],
        'bidirectional': !exists(json, 'bidirectional') ? undefined : json['bidirectional'],
    };
}

export function UpdateSectionInputToJSON(value?: UpdateSectionInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'conditionId': value.conditionId,
        'distanceM': value.distanceM,
        'intermediateNavPointIds': value.intermediateNavPointIds,
        'bidirectional': value.bidirectional,
    };
}

