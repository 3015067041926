import Button from "../../../common/input/button/Button";
import Close from "../../../common/ahreffun/Close";
import {useState} from "react";
import {addToast} from "../../../common/toast/toastUtil";
import {ConditionEndpointApi, ConditionTypeView} from "../../../../api";
import {DateOfMonth, dateOfMonthIsTheSame, MONTHS} from "../../../common/date/dateUtil";
import Af from "../../../common/ahreffun/Af";
import DateOfMonthInput from "../../../common/date/DateOfMonthInput";
import {Company, prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../../common/error/resolvers";

interface NewDayOfMonthConditionInputProps {
    onClose: (refresh: boolean) => void,
    company: Company
}

function getErrorMap() {
    const map = new Map();
    map.set("uniqueness", (r: any) => {
        if (r.data.name === 'name') {
            addToast('Nazwa musi być unikalna na poziomie firmy');
        }
    });
    return map;
}

function NewDayOfMonthConditionInput(props: NewDayOfMonthConditionInputProps) {
    const [name, setName] = useState('');
    const [dates, setDates] = useState<DateOfMonth[]>([]);
    const validate = () => {
        if (!name) {
            addToast('Uzupełnij nazwę');
            return false;
        } else if (dates.length < 1) {
            addToast('Musisz wybrać przynajmniej jeden dzień');
            return false;
        }
        return true;
    }
    const onSave = () => {
        if (validate()) {
            new ConditionEndpointApi()
                .saveDayOfMonthCondition({
                    dayOfMonthConditionInput: {
                        metaData: {name: name, companyId: props.company.id, type: ConditionTypeView.DayOfMonth},
                        details: {days: dates}}}, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano nowy warunek');
                    props.onClose(true);
                }).catch(e => {
                verifyClientResponse(e, getErrorMap());
            });
        }
    }
    const onAdd = (date: DateOfMonth) => {
        if (dates.filter(d => dateOfMonthIsTheSame(d, date)).length) {
            addToast('Wybrana data jest już dodana');
        } else {
            setDates([...dates, date]);
        }
    }
    const onRemove = (date: DateOfMonth) => {
        setDates(dates.filter(d => !dateOfMonthIsTheSame(d, date)));
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
            </div>
            <h3>Nowy warunek - dni miesiąca</h3>
            <label>Nazwa:</label>
            <input name='name' value={name} onChange={e => setName(e.target.value)} maxLength={50} required={true}/>
            <br/>
            <table>
                <thead>
                <tr className='tableHeader'>
                    <th>Lp</th>
                    <th>Data</th>
                    <th>Akcje</th>
                </tr>
                </thead>
                <tbody>
                {dates.map((d, index) => <tr key={index}>
                    <td>{index}</td>
                    <td>{d.day + 1} {MONTHS[d.month]}</td>
                    <td><Af onClick={() => onRemove(d)}>Usuń</Af></td>
                </tr>)}
                </tbody>
            </table>
            <DateOfMonthInput onAddFun={onAdd}/>
            <Button onClick={onSave}>Stwórz</Button>
        </div>
    );
}

export default NewDayOfMonthConditionInput;
