/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SectionSampleView } from './SectionSampleView';
import {
    SectionSampleViewFromJSON,
    SectionSampleViewFromJSONTyped,
    SectionSampleViewToJSON,
} from './SectionSampleView';
import type { SectionView } from './SectionView';
import {
    SectionViewFromJSON,
    SectionViewFromJSONTyped,
    SectionViewToJSON,
} from './SectionView';

/**
 * 
 * @export
 * @interface JammedSectionView
 */
export interface JammedSectionView {
    /**
     * 
     * @type {SectionView}
     * @memberof JammedSectionView
     */
    section?: SectionView;
    /**
     * 
     * @type {SectionSampleView}
     * @memberof JammedSectionView
     */
    bestMatchedSample?: SectionSampleView;
}

/**
 * Check if a given object implements the JammedSectionView interface.
 */
export function instanceOfJammedSectionView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JammedSectionViewFromJSON(json: any): JammedSectionView {
    return JammedSectionViewFromJSONTyped(json, false);
}

export function JammedSectionViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): JammedSectionView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'section': !exists(json, 'section') ? undefined : SectionViewFromJSON(json['section']),
        'bestMatchedSample': !exists(json, 'bestMatchedSample') ? undefined : SectionSampleViewFromJSON(json['bestMatchedSample']),
    };
}

export function JammedSectionViewToJSON(value?: JammedSectionView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section': SectionViewToJSON(value.section),
        'bestMatchedSample': SectionSampleViewToJSON(value.bestMatchedSample),
    };
}

