import {createContext} from "react";
import {User} from "./user/current/CurrentUserContext";

export const CurrentUserContext = createContext<CurrentUser>(null);

export interface CurrentUser {
    user: User | null;
    isLogged: boolean;
    refreshUserInfoFun: () => void;
    changeUserFun: (u: CurrentUser) => void;
}
