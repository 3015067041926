/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavPointMetaDataModifyInput } from './NavPointMetaDataModifyInput';
import {
    NavPointMetaDataModifyInputFromJSON,
    NavPointMetaDataModifyInputFromJSONTyped,
    NavPointMetaDataModifyInputToJSON,
} from './NavPointMetaDataModifyInput';

/**
 * 
 * @export
 * @interface StationModifyInputData
 */
export interface StationModifyInputData {
    /**
     * 
     * @type {NavPointMetaDataModifyInput}
     * @memberof StationModifyInputData
     */
    navPointMetaData?: NavPointMetaDataModifyInput;
    /**
     * 
     * @type {boolean}
     * @memberof StationModifyInputData
     */
    requestStop?: boolean;
}

/**
 * Check if a given object implements the StationModifyInputData interface.
 */
export function instanceOfStationModifyInputData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StationModifyInputDataFromJSON(json: any): StationModifyInputData {
    return StationModifyInputDataFromJSONTyped(json, false);
}

export function StationModifyInputDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationModifyInputData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'navPointMetaData': !exists(json, 'navPointMetaData') ? undefined : NavPointMetaDataModifyInputFromJSON(json['navPointMetaData']),
        'requestStop': !exists(json, 'requestStop') ? undefined : json['requestStop'],
    };
}

export function StationModifyInputDataToJSON(value?: StationModifyInputData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'navPointMetaData': NavPointMetaDataModifyInputToJSON(value.navPointMetaData),
        'requestStop': value.requestStop,
    };
}

