import {useEffect, useState} from "react";
import {TimetableGroupEndpointApi,} from "../../../../api";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {addToast} from "../../../common/toast/toastUtil";
import Close from "../../../common/ahreffun/Close";
import Button from "../../../common/input/button/Button";

interface EditTimetableGroupPanelProps {
    groupId: string;
    onClose: (r: boolean) => void;
}

export function EditTimetableGroupPanel(props: EditTimetableGroupPanelProps) {
    const [name, setName] = useState('');
    const [originalName, setOriginalName] = useState<string>();
    useEffect(() => {
        new TimetableGroupEndpointApi().getTimetableGroupDetails({groupId: props.groupId}, {headers: prepareSecurityHeaders()}
        )
            .then((r) => {
                setName(r.name);
                setOriginalName(r.name);
            })
            .catch(e => {
                verifyClientResponse(e, new Map());
            });
    }, [props.groupId]);
    const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (verifyForm()) {
            new TimetableGroupEndpointApi()
                .updateTimetableGroup({
                    editGroupInput: {
                        id: props.groupId,
                        name: name,
                    }
                }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const verifyForm = (): boolean => {
        if (name.length === 0) {
            addToast('Nazwa jest wymagana');
            return false;
        }
        return true;
    }
    const onRemove = () => {
        if (confirm(`Czy na pewno chcesz usunąć grupę ${originalName}?`)) {
            new TimetableGroupEndpointApi().deleteTimetableGroup({groupId: props.groupId}, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Poprawnie usunięto grupę');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, new Map());
                });
        }
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
            </div>
            <h2>Modyfikacja grupy {originalName}</h2>
            <form>
                <label>Nazwa*:</label>
                <input name='name' value={name} onChange={e => setName(e.target.value)} maxLength={50}/>
                <br/>
                <Button onClick={onSubmit}>Zapisz</Button>
            </form>
            <h3>Inne opcje</h3>
            <Button onClick={onRemove}>Usuń grupę</Button>
        </div>
    );
}

function getErrorMap(): Map<string, () => void> {
    const map = new Map<string, () => void>();
    map.set('uniqueness', () => addToast('Nazwa grupy powinna być unikalna'));
    return map;
}
