import {ReactNode, useState} from 'react';
import TownTable from "../../town/table/TownTable";
import Button from "../../common/input/button/Button";
import AddNewTownForm from "../../town/new/AddNewTownForm";
import {hasSuperPermissionInSpace} from "../../common/superuser/permissionUtil";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {TownView} from "../../../api";
import Af from "../../common/ahreffun/Af";
import {TownEditPanel} from "../../town/edit/TownEditPanel";

interface StreetTabSectionTownProps {
    mzkWorkspace: MzkWorkspace
    showStreetTableForTownFun: (t: TownView) => void;
}

function StreetTabSectionTown(props: StreetTabSectionTownProps) {
    const [showNewTownForm, setShowNewTownForm] = useState(false);
    const [townIdForEditPanel, setTownIdForEditPanel] = useState<string>(null);
    const [greedyVersion, setGreedyVersion] = useState(0);
    const superPermissionInSpaceValue = hasSuperPermissionInSpace(props.mzkWorkspace);
    const newTownButton = (): ReactNode => {
        return superPermissionInSpaceValue &&
            <Button onClick={() => setShowNewTownForm(!showNewTownForm)}>Stwórz nowe miasto</Button>;
    }
    return <div id='simpleMainContentSplit'>
        <div>
            <h3>Wyszukiwarka miast</h3>
            <TownTable
                mzkWorkspace={props.mzkWorkspace}
                greedyVersion={greedyVersion}
                actionProvider={t => <>
                    {superPermissionInSpaceValue && <Af onClick={() => setTownIdForEditPanel(t.id)}>Edycja</Af>}
                    <Af onClick={() => props.showStreetTableForTownFun(t)}>Pozwiązane ulice</Af>
                </>
                }/>
            {newTownButton()}
        </div>
        {showNewTownForm &&
            <AddNewTownForm
                mzkWorkspace={props.mzkWorkspace}
                onClose={(r) => {
                    setShowNewTownForm(false);
                    r && setGreedyVersion(v => v + 1);
                }}/>}
        {townIdForEditPanel && <TownEditPanel townId={townIdForEditPanel} onClose={(r) => {
            setTownIdForEditPanel(null);
            r && setGreedyVersion(v => v + 1);
        }} mzkWorkspace={props.mzkWorkspace}/>}
    </div>
}

export default StreetTabSectionTown;
