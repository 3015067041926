import {useState} from 'react';
import Close from "../../../common/ahreffun/Close";
import Button from "../../../common/input/button/Button";
import {ConditionEndpointApi, ConditionTypeView, StaticConditionDetailsSubtypeEnum} from "../../../../api";
import {addToast} from "../../../common/toast/toastUtil";
import {verifyClientResponse} from "../../../common/error/resolvers";
import {Company, prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";

interface NewStaticConditionFormProps {
    closeFun: (refresh: boolean) => void;
    company: Company;
    subtype: StaticConditionDetailsSubtypeEnum;
}

function NewStaticConditionForm(props: NewStaticConditionFormProps) {
    const [name, setName] = useState('');
    const [subtype, setSubtype] = useState(props.subtype);
    const onSave = () => {
        if (validate()) {
            new ConditionEndpointApi().saveStaticCondition({
                staticConditionInput: {
                    metaData: {
                        name: name,
                        type: ConditionTypeView.Static,
                        companyId: props.company.id
                    },
                    details: {
                        subtype: subtype
                    }
                }
            }, {headers: prepareSecurityHeaders()}).then(() => {
                addToast('Pomyślnie dodano nowy warunek');
                props.closeFun(true);
            })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const validate = () => {
        if (!name) {
            addToast('Uzupełnij nazwę');
            return false;
        }
        return true;
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.closeFun(false)}/></span>
            </div>
            <h3>Nowy warunek statyczny</h3>
            <label>Nazwa:</label>
            <input name='name' value={name} onChange={e => setName(e.target.value)}
                   maxLength={50} required={true}/>
            <br/>
            Typ warunku:
            <select name="subtype" value={subtype}
                    onChange={e => setSubtype(StaticConditionDetailsSubtypeEnum[e.target.value])}>
                <option value={StaticConditionDetailsSubtypeEnum.SummerHolidays}>Ferie letnie</option>
                <option value={StaticConditionDetailsSubtypeEnum.PublicHolidays}>Święta</option>
                <option value={StaticConditionDetailsSubtypeEnum.SchoolDays}>Dni szkolne</option>
                <option value={StaticConditionDetailsSubtypeEnum.NonSchoolDays}>Dni wolne od szkoły</option>
                <option value={StaticConditionDetailsSubtypeEnum.Easter}>Wielkanoc</option>
            </select>
            <br/>
            <Button onClick={onSave}>Stwórz</Button>
        </div>
    );
}

function getErrorMap() {
    const map = new Map<string, (r: any) => void>();
    map.set("uniqueness", r => {
        if (r.data.name === 'name') {
            addToast('Nazwa musi być unikalna na poziomie firmy');
        }
    });
    return map;
}

export default NewStaticConditionForm;
