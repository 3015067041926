export function choiceOne<T>(arr: T[]): T {
    return arr[Math.floor(Math.random()*arr.length)];
}

export function randInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
}

export function randBool(){
    const value = randInt(0, 2);
    return value === 0;
}
