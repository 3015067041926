/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionVisibilityRuleTypeView } from './ConditionVisibilityRuleTypeView';
import {
    ConditionVisibilityRuleTypeViewFromJSON,
    ConditionVisibilityRuleTypeViewFromJSONTyped,
    ConditionVisibilityRuleTypeViewToJSON,
} from './ConditionVisibilityRuleTypeView';

/**
 * 
 * @export
 * @interface ConditionSearchInput
 */
export interface ConditionSearchInput {
    /**
     * 
     * @type {string}
     * @memberof ConditionSearchInput
     */
    appliedForCompanyId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConditionSearchInput
     */
    withGlobal?: boolean;
    /**
     * 
     * @type {ConditionVisibilityRuleTypeView}
     * @memberof ConditionSearchInput
     */
    visibilityRuleType?: ConditionVisibilityRuleTypeView;
}

/**
 * Check if a given object implements the ConditionSearchInput interface.
 */
export function instanceOfConditionSearchInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConditionSearchInputFromJSON(json: any): ConditionSearchInput {
    return ConditionSearchInputFromJSONTyped(json, false);
}

export function ConditionSearchInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionSearchInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appliedForCompanyId': !exists(json, 'appliedForCompanyId') ? undefined : json['appliedForCompanyId'],
        'withGlobal': !exists(json, 'withGlobal') ? undefined : json['withGlobal'],
        'visibilityRuleType': !exists(json, 'visibilityRuleType') ? undefined : ConditionVisibilityRuleTypeViewFromJSON(json['visibilityRuleType']),
    };
}

export function ConditionSearchInputToJSON(value?: ConditionSearchInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appliedForCompanyId': value.appliedForCompanyId,
        'withGlobal': value.withGlobal,
        'visibilityRuleType': ConditionVisibilityRuleTypeViewToJSON(value.visibilityRuleType),
    };
}

