import {prepareName} from "../../common/navpoint/navpointUtil";
import {ReactNode} from "react";
import {StructuredNavPoints} from "../../../api/models/StructuredNavPoints";
import {NavPointMetaDataView} from "../../../api/models/NavPointMetaDataView";

interface PlatformRowProps {
    nodeLvl: number;
    structuredNavPoint: StructuredNavPoints;
    maxDepth: number;
    actionProviderFun: (metaData: NavPointMetaDataView) => ReactNode;
}

function PlatformRow(props: PlatformRowProps) {
    return (
        <>
            {props.nodeLvl === 0 ? (
                <tr className='bgColor3'>
                    <td colSpan={props.maxDepth - props.nodeLvl}><b>{prepareName(props.structuredNavPoint.metaData)}</b>
                    </td>
                    <td>{props.actionProviderFun(props.structuredNavPoint.metaData)}</td>
                </tr>
            ) : (
                <tr>
                    {props.nodeLvl > 0 && <td colSpan={props.nodeLvl}>{Array(props.nodeLvl).fill('----') + '>'}</td>}
                    <td colSpan={props.maxDepth - props.nodeLvl}>{prepareName(props.structuredNavPoint.metaData)}</td>
                    <td>{props.actionProviderFun(props.structuredNavPoint.metaData)}</td>
                </tr>
            )}
        </>
    );
}

export default PlatformRow;
