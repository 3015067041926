import {ChangeEvent, forwardRef, Ref} from "react";

interface PlatformTableCriteriaProps {
    query: string;
    onDataUpdated: (q: string) => void;
}

interface PlatformTableCriteriaProps {
    onDataUpdated: (data: string) => void;
    query: string;
    inputRef?: Ref<HTMLInputElement>;
}

export const PlatformTableCriteria = forwardRef(
    (props: PlatformTableCriteriaProps, ref: Ref<HTMLInputElement>) => {
        return (
            <>
                <label>Nazwa:</label>
                <input
                    ref={(ref as Ref<HTMLInputElement>) || props.inputRef}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.onDataUpdated(e.target.value)}
                    value={props.query}
                    placeholder="nazwa"
                />
            </>
        );
    }
);
