/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionTypeView } from './ConditionTypeView';
import {
    ConditionTypeViewFromJSON,
    ConditionTypeViewFromJSONTyped,
    ConditionTypeViewToJSON,
} from './ConditionTypeView';

/**
 * 
 * @export
 * @interface ConditionMetaDataInput
 */
export interface ConditionMetaDataInput {
    /**
     * 
     * @type {string}
     * @memberof ConditionMetaDataInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionMetaDataInput
     */
    companyId: string;
    /**
     * 
     * @type {ConditionTypeView}
     * @memberof ConditionMetaDataInput
     */
    type: ConditionTypeView;
}

/**
 * Check if a given object implements the ConditionMetaDataInput interface.
 */
export function instanceOfConditionMetaDataInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ConditionMetaDataInputFromJSON(json: any): ConditionMetaDataInput {
    return ConditionMetaDataInputFromJSONTyped(json, false);
}

export function ConditionMetaDataInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionMetaDataInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'companyId': json['companyId'],
        'type': ConditionTypeViewFromJSON(json['type']),
    };
}

export function ConditionMetaDataInputToJSON(value?: ConditionMetaDataInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'companyId': value.companyId,
        'type': ConditionTypeViewToJSON(value.type),
    };
}

