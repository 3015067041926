import Af from "../../../common/ahreffun/Af";
import {stringCompare} from "../../../common/string/stringUtil";
import {useContext, useMemo} from "react";
import {CurrentUserContext} from "../../../CurrentUserContext";
import {TimetableView} from "../../../../api";

interface Group {
    id: string;
    name: string;
}

interface TimeTableGroupTableProps {
    groupChildren: Group[];
    timetable: TimetableView;
    onChangeGroup: (group: Group) => void;
    onChangeGroupIdToEdit: (groupId: string) => void;
}

function sort(group: Group[]): void {
    group.sort((a, b) => {
        const splitNameA = a.name.split(/(\d+)/).filter(part => part !== "");
        const splitNameB = b.name.split(/(\d+)/).filter(part => part !== "");
        for (let i = 0; i < splitNameA.length && splitNameB.length; i++) {
            const aNumber = Number(splitNameA[i]);
            const bNumber = Number(splitNameB[i]);
            let result = aNumber - bNumber;
            if (isNaN(result)) {
                result = stringCompare(splitNameA[i], splitNameB[i]);
            }
            if (result !== 0) {
                return result;
            }
        }
        return 0;
    });
}

function TimeTableGroupTable(props: TimeTableGroupTableProps) {
    const currentUser = useContext(CurrentUserContext);
    const sortedGroups = useMemo(() => {
        const groups = [...props.groupChildren];
        sort(groups);
        return groups;
    }, [props.groupChildren])

    return (
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Nazwa</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            {sortedGroups.map((group, index) => {
                return (
                    <tr key={group.id}>
                        <td>{index}</td>
                        <td>{group.name}</td>
                        <td>
                            {currentUser.user.defaultCompany.superUser && props.timetable.editable && <Af onClick={()=>props.onChangeGroupIdToEdit(group.id)}>Edycja</Af>}
                            <Af onClick={() => props.onChangeGroup(group)}>Wejdź</Af>
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
}

export default TimeTableGroupTable;
