/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TimetableInput,
  TimetableSearchInput,
  TimetableView,
  UsageInput,
} from '../models';
import {
    TimetableInputFromJSON,
    TimetableInputToJSON,
    TimetableSearchInputFromJSON,
    TimetableSearchInputToJSON,
    TimetableViewFromJSON,
    TimetableViewToJSON,
    UsageInputFromJSON,
    UsageInputToJSON,
} from '../models';

export interface DeleteTimetableRequest {
    timetableId: string;
}

export interface GetTimetableDetailsRequest {
    id: string;
}

export interface LogTimetableUsageRequest {
    usageInput?: UsageInput;
}

export interface SaveTimeTableRequest {
    timetableInput: TimetableInput;
}

export interface SearchTimeTablesRequest {
    timetableSearchInput: TimetableSearchInput;
}

/**
 * 
 */
export class TimetableEndpointApi extends runtime.BaseAPI {

    /**
     */
    async deleteTimetableRaw(requestParameters: DeleteTimetableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.timetableId === null || requestParameters.timetableId === undefined) {
            throw new runtime.RequiredError('timetableId','Required parameter requestParameters.timetableId was null or undefined when calling deleteTimetable.');
        }

        const queryParameters: any = {};

        if (requestParameters.timetableId !== undefined) {
            queryParameters['timetableId'] = requestParameters.timetableId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/timetables`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTimetable(requestParameters: DeleteTimetableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTimetableRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getTimetableDetailsRaw(requestParameters: GetTimetableDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TimetableView>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTimetableDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/timetables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimetableViewFromJSON(jsonValue));
    }

    /**
     */
    async getTimetableDetails(requestParameters: GetTimetableDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TimetableView> {
        const response = await this.getTimetableDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logTimetableUsageRaw(requestParameters: LogTimetableUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timetables/usages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsageInputToJSON(requestParameters.usageInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logTimetableUsage(requestParameters: LogTimetableUsageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logTimetableUsageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveTimeTableRaw(requestParameters: SaveTimeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.timetableInput === null || requestParameters.timetableInput === undefined) {
            throw new runtime.RequiredError('timetableInput','Required parameter requestParameters.timetableInput was null or undefined when calling saveTimeTable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timetables`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimetableInputToJSON(requestParameters.timetableInput),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async saveTimeTable(requestParameters: SaveTimeTableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.saveTimeTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchTimeTablesRaw(requestParameters: SearchTimeTablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TimetableView>>> {
        if (requestParameters.timetableSearchInput === null || requestParameters.timetableSearchInput === undefined) {
            throw new runtime.RequiredError('timetableSearchInput','Required parameter requestParameters.timetableSearchInput was null or undefined when calling searchTimeTables.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timetables/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimetableSearchInputToJSON(requestParameters.timetableSearchInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimetableViewFromJSON));
    }

    /**
     */
    async searchTimeTables(requestParameters: SearchTimeTablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TimetableView>> {
        const response = await this.searchTimeTablesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
