import {ChangeEvent, MouseEvent, useState} from 'react';
import Close from "../../common/ahreffun/Close";
import Button from "../../common/input/button/Button";
import {addToast} from "../../common/toast/toastUtil";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {ConditionEndpointApi, ConditionMetaDataView, ConditionTypeView} from "../../../api";

interface NewDependentConditionFormProps {
    close: (refresh: boolean) => void;
    proposedConditions: ConditionMetaDataView[];
    removeSelectedLogicalConditionFun: (index: number) => void;
    company: Company;
    type: ConditionTypeView;
}

function NewLogicConditionForm(props: NewDependentConditionFormProps) {
    const [name, setName] = useState('');
    const mapCondition = () => {
        return props.proposedConditions.map((c, i) => <Button key={c.id}
                                                              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                                                  e.preventDefault();
                                                                  props.removeSelectedLogicalConditionFun(i);
                                                              }
                                                              }>{c.name}</Button>);
    }

    const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (validateForm()) {
            new ConditionEndpointApi().saveLogicalCondition({
                logicalConditionInput: {
                    details: {
                        childrenConditionIds: props.proposedConditions.map(c => {
                            return c.id
                        })
                    }, metaData: {
                        name: name,
                        companyId: props.company.id,
                        type: props.type
                    }
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Poprawnie dodano nowy warunek');
                    props.close(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap())
                });
        }
    }
    const validateForm = () => {
        if (name === '') {
            addToast('Uzupełnij nazwę');
            return false;
        }
        if (props.type === ConditionTypeView.Not) {
            if (props.proposedConditions.length === 0) {
                addToast('Brak warunku dziecka');
                return false;
            }
            if (props.proposedConditions.length > 1) {
                addToast('Za dużo wybranych warunków');
                return false;
            }
        } else {
            if (props.proposedConditions.length < 2) {
                addToast('Wybierz warunki');
                return false;
            }
            if (props.proposedConditions.length > 10) {
                addToast('Za dużo wybranych warunków');
                return false;
            }
        }
        return true;
    }
    const prepareTypeText = () => {
        if (props.type === ConditionTypeView.And) {
            return 'Oraz';
        } else if (props.type === ConditionTypeView.Or) {
            return 'Lub';
        } else {
            return 'Nie';
        }
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.close(false)}/></span>
            </div>
            <h3>Nowy warunek zależny {name}</h3>
            <form>
                <label>Nazwa*:</label>
                <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                       maxLength={100}/>
                <br/>
                <label>Typ warunku: </label>{prepareTypeText()}<br/>
                <label>Warunki:</label>
                <br/>
                {mapCondition()}
                <br/>
                <Button onClick={onSubmit}>Dodaj</Button>
            </form>
        </div>
    );
}

function getErrorMap() {
    const map = new Map<string, (r: any) => void>();
    map.set("condition_too_similar", () => {
        addToast('Za dużo duplikatów w warunkach potomków.');
    });
    map.set("condition_too_big", () => {
        addToast('Za duża ilość zagnieżdżeń.');
    });
    return map;
}

export default NewLogicConditionForm;
