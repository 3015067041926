import LinePlantTableAction from "./LinePlantTableAction";
import Tooltip from "../../../common/tooltip/Tooltip";
import {secondsToFrontDuration} from "../../../common/input/duration/durationUtil";
import {prepareName} from "../../../common/navpoint/navpointUtil";
import {ReactNode} from "react";
import {NavPointMetaDataView, TimetableView} from "../../../../api";
import {LineSchemaPlanPart} from "../LinePlanPanel";

interface LinePlanTableRowsProps {
    plan: LineSchemaPlanPart[];
    onChangePartOrder: (index: number, destination: number) => void;
    onDeletePart: (index: number) => void;
    onShowSectionListPanel: (navPointId: string) => void;
    timetable: TimetableView;
}

function LinePlanTableRows(props: LinePlanTableRowsProps) {
    const part = (p: LineSchemaPlanPart, index: number, partSize: number) => {
        return <tr key={index}>
            <td>{index}</td>
            {p.adoptedNavPoint.navPointMetaDataBaseParent ? <>
                <td>{formatMainNavPointName(p.adoptedNavPoint.navPointMetaDataBaseParent, p?.validationData?.result?.parentNavPointNotAvailable)}</td>
                <td>{formatMainNavPointName(p.adoptedNavPoint.navPointMetaData, p?.validationData?.result?.navPointNotAvailable)}</td>
            </> : <td colSpan={2}>{formatMainNavPointName(p.adoptedNavPoint.navPointMetaData, p?.validationData?.result?.navPointNotAvailable)}</td>}
            <td>{conditionRow(p, index + 1 == partSize)}</td>
            <LinePlantTableAction index={index} totalLength={props.plan.length}
                                  changePartOrderFun={props.onChangePartOrder}
                                  deletePartFun={props.onDeletePart}
                                  timeTable={props.timetable}
            />
        </tr>;
    }
    const formatMainNavPointName = (n: NavPointMetaDataView, isNotAvailable: boolean) => {
        if (isNotAvailable) {
            return <s>{prepareName(n)}</s>;
        } else {
            return prepareName(n);
        }
    }
    const conditionRow = (p: LineSchemaPlanPart, isLast: boolean) => {
        const onClick = () => props.onShowSectionListPanel(p.adoptedNavPoint.navPointMetaData.id);
        if (!p.validationData?.result) {
            return <Tooltip content='Brak informacji'>
                <span onClick={onClick}>⟳</span>
            </Tooltip>;
        }
        if (isLast) {
            return <Tooltip content='Przystanek końcowy'>🏁</Tooltip>;
        }
        const sections = p.validationData.result.sections;
        if (sections) {
            const totalTravelSec = sections.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.bestMatchedSample.defaultTravelSec;
            }, 0);
            return <Tooltip
                content={`~${secondsToFrontDuration(totalTravelSec)}`}>
                <span onClick={onClick}> {sections.length == 1 ? '✓' : '🩹'}</span>
            </Tooltip>
        } else {
            return <Tooltip content='Niezdefiniowany odcinek'>
                <span onClick={onClick}>❌</span>
            </Tooltip>;
        }
    }
    let results: ReactNode[] = [];
    for (let i = 0; i < props.plan.length; i++) {
        let current = props.plan[i];
        results.push(part(current, i, props.plan.length));
    }
    return results;
}

export default LinePlanTableRows;
