/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NavPointType = {
    Station: 'STATION',
    Platform: 'PLATFORM'
} as const;
export type NavPointType = typeof NavPointType[keyof typeof NavPointType];


export function NavPointTypeFromJSON(json: any): NavPointType {
    return NavPointTypeFromJSONTyped(json, false);
}

export function NavPointTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavPointType {
    return json as NavPointType;
}

export function NavPointTypeToJSON(value?: NavPointType | null): any {
    return value as any;
}

