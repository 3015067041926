/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JammedSectionView } from './JammedSectionView';
import {
    JammedSectionViewFromJSON,
    JammedSectionViewFromJSONTyped,
    JammedSectionViewToJSON,
} from './JammedSectionView';

/**
 * 
 * @export
 * @interface LineSchemaPlanValidationResult
 */
export interface LineSchemaPlanValidationResult {
    /**
     * 
     * @type {string}
     * @memberof LineSchemaPlanValidationResult
     */
    sampleId: string;
    /**
     * 
     * @type {Array<JammedSectionView>}
     * @memberof LineSchemaPlanValidationResult
     */
    sections?: Array<JammedSectionView>;
    /**
     * 
     * @type {boolean}
     * @memberof LineSchemaPlanValidationResult
     */
    navPointNotAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LineSchemaPlanValidationResult
     */
    parentNavPointNotAvailable: boolean;
}

/**
 * Check if a given object implements the LineSchemaPlanValidationResult interface.
 */
export function instanceOfLineSchemaPlanValidationResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sampleId" in value;
    isInstance = isInstance && "navPointNotAvailable" in value;
    isInstance = isInstance && "parentNavPointNotAvailable" in value;

    return isInstance;
}

export function LineSchemaPlanValidationResultFromJSON(json: any): LineSchemaPlanValidationResult {
    return LineSchemaPlanValidationResultFromJSONTyped(json, false);
}

export function LineSchemaPlanValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineSchemaPlanValidationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sampleId': json['sampleId'],
        'sections': !exists(json, 'sections') ? undefined : ((json['sections'] as Array<any>).map(JammedSectionViewFromJSON)),
        'navPointNotAvailable': json['navPointNotAvailable'],
        'parentNavPointNotAvailable': json['parentNavPointNotAvailable'],
    };
}

export function LineSchemaPlanValidationResultToJSON(value?: LineSchemaPlanValidationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sampleId': value.sampleId,
        'sections': value.sections === undefined ? undefined : ((value.sections as Array<any>).map(JammedSectionViewToJSON)),
        'navPointNotAvailable': value.navPointNotAvailable,
        'parentNavPointNotAvailable': value.parentNavPointNotAvailable,
    };
}

