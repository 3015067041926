import NavbarLogo from './NavbarLogo';
import {ReactNode} from "react";
import {NavbarMenuPosition} from "./NavbarMenuPosition";

interface NavbarMenuProps {
    positions: {
        disabled?: boolean;
        onClick: () => void;
        e: ReactNode;
    }[];
}

const NavbarMenu = (props: NavbarMenuProps) => {
    return (
        <div id='navbarMenu'>
            <NavbarLogo/>
            {props.positions.map((p, index) => (
                <NavbarMenuPosition
                    key={index}
                    disabled={p.disabled}
                    onClick={!p.disabled ? p.onClick : () => {
                    }}>
                    {p.e}
                </NavbarMenuPosition>
            ))}
        </div>
    );
}

export default NavbarMenu;