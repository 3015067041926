/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateNewVariableInput,
  VariableTemplateView,
} from '../models';
import {
    CreateNewVariableInputFromJSON,
    CreateNewVariableInputToJSON,
    VariableTemplateViewFromJSON,
    VariableTemplateViewToJSON,
} from '../models';

export interface CreateNewVariableRequest {
    createNewVariableInput: CreateNewVariableInput;
}

export interface GetVariablesRequest {
    companyId: string;
}

/**
 * 
 */
export class VariableEndpointApi extends runtime.BaseAPI {

    /**
     */
    async createNewVariableRaw(requestParameters: CreateNewVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createNewVariableInput === null || requestParameters.createNewVariableInput === undefined) {
            throw new runtime.RequiredError('createNewVariableInput','Required parameter requestParameters.createNewVariableInput was null or undefined when calling createNewVariable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/variables`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNewVariableInputToJSON(requestParameters.createNewVariableInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createNewVariable(requestParameters: CreateNewVariableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNewVariableRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getVariablesRaw(requestParameters: GetVariablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VariableTemplateView>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getVariables.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/variables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VariableTemplateViewFromJSON));
    }

    /**
     */
    async getVariables(requestParameters: GetVariablesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VariableTemplateView>> {
        const response = await this.getVariablesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
