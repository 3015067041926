import Af from "../../common/ahreffun/Af";
import {StationTabContentType} from "../tab/StationTab";

interface StationSearchTypeSwitcherProps {
    stationTabContentType: StationTabContentType;
    onChangeSearchType: (a0: StationTabContentType) => void;
}

export function StationSearchTypeSwitcher(props: StationSearchTypeSwitcherProps) {
    const searchStation = props.stationTabContentType === StationTabContentType.STATION;

    return searchStation ? searchPeronCheckbox(props.onChangeSearchType) : searchStationCheckbox(props.onChangeSearchType);
}

function searchPeronCheckbox(onChangeSearchType: (a0: StationTabContentType) => void) {
    return <Af onClick={() => onChangeSearchType(StationTabContentType.PLATFORM)}>Chcę szukać peronów</Af>;
}

function searchStationCheckbox(onChangeSearchType: (a0: StationTabContentType) => void) {
    return <Af onClick={() => onChangeSearchType(StationTabContentType.STATION)}>Chcę szukać stacji</Af>;
}
