import {FormEvent, useContext, useState} from "react";
import {addToast} from "../common/toast/toastUtil";
import {prepareSecurityHeaders} from "../user/current/CurrentUserContext";
import {handleCommonError} from "../common/error/resolvers";
import {setCookie} from "../common/cookie/cookieUtil";
import Button from "../common/input/button/Button";
import {CurrentUserContext} from "../CurrentUserContext";
import {UserEndpointApi} from "../../api";

function LoginForm() {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const currentUser = useContext(CurrentUserContext);
    const onLogin = (e: FormEvent) => {
        e.preventDefault();
        new UserEndpointApi()
            .login(
                {
                    loginInputData: {
                        login: login,
                        password: password
                    }
                },
                {headers: prepareSecurityHeaders()}
            )
            .then(authorizationId => setCookie("Authorization", authorizationId, 10))
            .then(_ => addToast("Zalogowano pomyślnie"))
            .then(_ => currentUser.refreshUserInfoFun())
            .catch(e => {
                if (e.response.status === 401) {
                    e.response
                        .json()
                        .then((r: any) => {
                            if (r.code === "USER_INACTIVE") {
                                addToast("Użytkownik zablokowany");
                            } else {
                                addToast("Podano nieprawidłowe dane logowania");
                            }
                        });
                } else {
                    handleCommonError(e);
                }
            });
    }
    return (
        <>
            <form method="post">
                <label>
                    Login:
                    <input
                        name="login"
                        type="text"
                        value={login}
                        onChange={e => setLogin(e.target.value)}
                    />
                </label>
                <label>
                    Hasło:
                    <input
                        name="password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </label>
                <Button onClick={(e) => onLogin(e)}>Zaloguj</Button><br />
                <span>Ta strona wykorzystuje ciasteczka.</span>
            </form>
        </>
    );
}

export default LoginForm;
