import Af from "../../common/ahreffun/Af";
import {LineSchemaMetaDataView, TimetableView} from "../../../api";
import {stringCompare} from "../../common/string/stringUtil";
import {useContext} from "react";
import {CurrentUserContext} from "../../CurrentUserContext";

interface TimetableLineTableProps {
    lines: LineSchemaMetaDataView[];
    timetable: TimetableView;
    onChangeLineForLinePlan: (line: LineSchemaMetaDataView) => void;
    onChangeLineForCoursePanel: (line: LineSchemaMetaDataView) => void;
    onChangeLineForEditPanel: (lineId: string) => void;
}

function TimetableLineTable(props: TimetableLineTableProps) {
    const currentUser = useContext(CurrentUserContext);
    const sort = (lines: LineSchemaMetaDataView[])=> {
        lines.sort((a, b) => {
            const splittedTechnicalNameA = a.technicalName.split(/(\d+)/).filter(part => part !== "");
            const splittedTechnicalNameB = b.technicalName.split(/(\d+)/).filter(part => part !== "");
            for (let i = 0; i < splittedTechnicalNameA.length && splittedTechnicalNameB.length; i++) {
                const aNumber = Number(splittedTechnicalNameA[i]);
                const bNumber = Number(splittedTechnicalNameB[i]);
                let result = aNumber - bNumber;
                if (isNaN(result)) {
                    result = stringCompare(splittedTechnicalNameA[i], splittedTechnicalNameB[i]);
                }
                if (result !== 0) {
                    return result;
                }
            }
            return stringCompare(a.technicalDescription, b.technicalDescription);
        })
    }
    sort(props.lines);
    return (
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Id</th>
                <th>Opis</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            {props.lines.map((line) => {
                return <tr key={line.id}>
                    <td>{line.technicalName}</td>
                    <td>{line.technicalDescription}</td>
                    <td>
                        {currentUser.user.defaultCompany.superUser && props.timetable.editable && <Af onClick={()=>props.onChangeLineForEditPanel(line.id)}>Edycja</Af>}
                        <Af onClick={() => props.onChangeLineForLinePlan(line)}>Przebieg trasy</Af>
                        <Af onClick={() => props.onChangeLineForCoursePanel(line)}>Kursy</Af>
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    );
}

export default TimetableLineTable;
