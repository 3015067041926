/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConditionMetaDataInput } from './ConditionMetaDataInput';
import {
    ConditionMetaDataInputFromJSON,
    ConditionMetaDataInputFromJSONTyped,
    ConditionMetaDataInputToJSON,
} from './ConditionMetaDataInput';
import type { DayOfMonthConditionDetails } from './DayOfMonthConditionDetails';
import {
    DayOfMonthConditionDetailsFromJSON,
    DayOfMonthConditionDetailsFromJSONTyped,
    DayOfMonthConditionDetailsToJSON,
} from './DayOfMonthConditionDetails';

/**
 * 
 * @export
 * @interface DayOfMonthConditionInput
 */
export interface DayOfMonthConditionInput {
    /**
     * 
     * @type {ConditionMetaDataInput}
     * @memberof DayOfMonthConditionInput
     */
    metaData?: ConditionMetaDataInput;
    /**
     * 
     * @type {DayOfMonthConditionDetails}
     * @memberof DayOfMonthConditionInput
     */
    details?: DayOfMonthConditionDetails;
}

/**
 * Check if a given object implements the DayOfMonthConditionInput interface.
 */
export function instanceOfDayOfMonthConditionInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DayOfMonthConditionInputFromJSON(json: any): DayOfMonthConditionInput {
    return DayOfMonthConditionInputFromJSONTyped(json, false);
}

export function DayOfMonthConditionInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayOfMonthConditionInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metaData': !exists(json, 'metaData') ? undefined : ConditionMetaDataInputFromJSON(json['metaData']),
        'details': !exists(json, 'details') ? undefined : DayOfMonthConditionDetailsFromJSON(json['details']),
    };
}

export function DayOfMonthConditionInputToJSON(value?: DayOfMonthConditionInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metaData': ConditionMetaDataInputToJSON(value.metaData),
        'details': DayOfMonthConditionDetailsToJSON(value.details),
    };
}

