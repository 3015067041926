import Popup from "../../../common/popup/Popup";
import {CrewGroupView} from "../../../../api";
import {ReactNode} from "react";
import {CrewGroupTable} from "../table/CrewGroupTable";

interface CrewGroupPopupProps{
    onClose: ()=>void;
    actionProvider: (c: CrewGroupView)=>ReactNode;
}
export function CrewGroupPopup(props: CrewGroupPopupProps){
    return <Popup closePopup={props.onClose}>
        <h1>Wybór grupy brygad</h1>
        <CrewGroupTable actionProvider={props.actionProvider} crewGroupVersion={0} />
    </Popup>
}
