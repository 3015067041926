import {ChangeEvent, FormEvent, useState} from 'react';
import Button from "../../common/input/button/Button";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {addToast} from "../../common/toast/toastUtil";
import Close from "../../common/ahreffun/Close";
import {StreetEndpointApi, TownView} from "../../../api";
import Af from "../../common/ahreffun/Af";
import TownPopupSelector from "../../town/popup/TownPopupSelector";
import {nullIfBlank} from "../../common/string/stringUtil";

interface AddNewStreetFormProps {
    mzkWorkspace: MzkWorkspace;
    hideNewStreetFormFun: (refresh: boolean) => void;
}

function AddNewStreetPanel(props: AddNewStreetFormProps) {
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [town, setTown] = useState<TownView>();
    const [showTownSelector, setShowTownSelector] = useState(false);
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (verifyForm()) {
            new StreetEndpointApi()
                .saveStreet({
                    streetInput: {
                        name: name,
                        shortName: nullIfBlank(shortName),
                        townId: town!.id,
                        companyId: props.mzkWorkspace.company.id
                    }
                }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano nową ulicę');
                    props.hideNewStreetFormFun(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    const verifyForm = () => {
        if (!name.length) {
            addToast('Nazwa nie może być pusta');
            return false;
        }
        if (!town) {
            addToast('Nie wybrano miasta');
            return false;
        }
        return true;
    }

    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={()=>props.hideNewStreetFormFun(false)}/></span>
        </div>
        <h3>Dodawanie nowej ulicy {name}</h3>
        <form>
            <label>Nazwa*:</label>
            <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <label>Nazwa skrócona:</label>
            <input name='shortName' value={shortName}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setShortName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <label>Miasto: {town ? town.name : 'nie wybrano'}</label>
            <Af onClick={() => setShowTownSelector(true)}>Wybór miasta</Af>
            <br/>

            <Button onClick={onSubmit}>Dodaj</Button>
        </form>
        {showTownSelector && <TownPopupSelector
            closePopup={() => setShowTownSelector(false)}
            onSelect={(t)=>{
                setTown(t);
                setShowTownSelector(false);
            }}
            mzkWorkspace={props.mzkWorkspace}/>}
    </div>;
}

function getErrorMap() {
    const map = new Map<string, (r: any) => void>();
    map.set("uniqueness", r => {
        if (r.data.name === 'name') {
            addToast('Nazwa ulicy musi być unikalna na poziomie miasta');
        }
    });
    return map;
}

export default AddNewStreetPanel;
