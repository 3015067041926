import Tooltip from "../../common/tooltip/Tooltip";
import {Company, MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import Button from "../../common/input/button/Button";
import AddNewTimeTableGroupForm from "../group/new/AddNewTimeTableGroupForm";
import AddNewTimetableLineForm from "../../line/new/AddNewTimetableLineForm";
import AddNewPlatformForm from "../../platform/new/AddNewPlatformForm";
import {AddNewStationForm} from "../../station/new/AddNewStationForm";
import {
    CourseView,
    DataSpacePermissionCompanyView,
    LineSchemaEndpointApi,
    LineSchemaMetaDataView,
    NavPointMetaDataView,
    TimetableGroupView,
    TimetableGroupEndpointApi,
    TimetableView
} from "../../../api";
import {ReactNode, useEffect, useState} from "react";
import {TimeTableExplorerLines} from "./TimeTableExplorerLines";
import {TimeTableExplorerGroups} from "./TimeTableExplorerGroups";
import {OverriddenVariablesList} from "../../variable/overridden/OverriddenVariablesList";
import {CourseListPanel} from "../../course/list/CourseListPanel";
import {CourseDetailsPanel} from "../../course/details/CourseDetailsPanel";
import {LinePlanPanel} from "../../line/plan/LinePlanPanel";
import {SectionListPanel} from "../../section/list/SectionListPanel";
import {EditTimetableGroupPanel} from "../group/edit/EditTimetableGroupPanel";
import EditTimetableLineForm from "../../line/edit/EditTimeTableLineForm";

interface TimeTableExplorerProps {
    timetable: TimetableView;
    mzkWorkspace: MzkWorkspace
    selectTimeTable: (timeTable: TimetableView) => void;
}

interface NewPlatformFormData {
    station: NavPointMetaDataView,
    space: DataSpacePermissionCompanyView
}

interface NewStationFormData {
    space: DataSpacePermissionCompanyView
}

function modifyButtons(timetable: TimetableView, company: Company, group: TimetableGroupView,
                       toggleVisibilityAddNewGroupForm: () => void,
                       toggleVisibilityAddNewLineForm: () => void
): ReactNode {
    if (!timetable.editable || !company.superUser) {
        return;
    }
    const results: Array<ReactNode> = [];
    if (group == null) {
        results.push(<Button key='addGroup' onClick={toggleVisibilityAddNewGroupForm}>Dodaj grupę</Button>);
    } else {
        results.push(<Button key='addLine' onClick={toggleVisibilityAddNewLineForm}>Dodaj linię</Button>);
    }
    return results;
}

function nonModifyButtons(
    group: TimetableGroupView,
    onChangeGroup: (a0: TimetableGroupView) => void,
    selectTimeTable: (timeTable: TimetableView) => void,
    toggleVisibilityOverriddenVariableList: () => void) {
    const results: Array<ReactNode> = [];
    results.push(<Button key={'OVERRIDDEN_VARIABLES_LIST'} onClick={toggleVisibilityOverriddenVariableList}>Nadpisane
        zmienne środowiskowe</Button>);
    results.push(<br key={'NEW_LINE_GROUP'}/>);
    if (group) {
        results.push(<Button key={'BACK_BASE_GROUP'} onClick={() => onChangeGroup(null)}>Powrót do grupy
            bazowej</Button>);
    }
    results.push(<Button key={'BACK_TIMETABLE_LIST'} onClick={() => selectTimeTable(null)}>Wróć do listy
        rozkładów</Button>);
    return results;
}

function TimeTableExplorer(props: TimeTableExplorerProps) {
    const [group, setGroup] = useState<TimetableGroupView>(null);
    const [groupChildren, setGroupChildren] = useState<TimetableGroupView[]>(null);
    const [lineChildren, setLineChildren] = useState<LineSchemaMetaDataView[]>(null);
    const [lineForLinePlan, setLineForLinePlan] = useState<LineSchemaMetaDataView>(null);
    const [lineForCoursePanel, setLineForCoursePanel] = useState<LineSchemaMetaDataView>(null);
    const [newPlatformFormData, setNewPlatformFormData] = useState<NewPlatformFormData>(null);
    const [newStationFormData, setNewStationFormData] = useState<NewStationFormData>(null);
    const [navpointIdForSectionList, setNavpointIdForSectionList] = useState<string>();
    const [showNewGroupForm, setShowNewGroupForm] = useState(false);
    const [showNewLineForm, setShowNewLineForm] = useState(false);
    const [showOverriddenVariableList, setShowOverriddenVariableList] = useState(false);
    const [courseForCourseDetailsPanel, setCourseForCourseDetailsPanel] = useState<CourseView>();
    const [greedyCourseVersion, setGreedyCourseVersion] = useState(0);
    const [greedyStationVersion, setGreedyStationVersion] = useState(0);
    const [greedyPlatformVersion, setGreedyPlatformVersion] = useState(0);
    const [groupIdToEdit, setGroupIdToEdit] = useState<string>();
    const [lineIdToEdit, setLineIdToEdit] = useState<string>();
    const refreshGroup = () => {
        const groupId = group == null ? undefined : group.id;
        new TimetableGroupEndpointApi()
            .findGroupsForParent({groupId: groupId, timetableId: props.timetable.id},
                {headers: prepareSecurityHeaders()})
            .then(r => {
                setGroupChildren(r);
            })
            .catch(e => {
                verifyClientResponse(e);
            });
    }

    function refreshLine() {
        const groupId = group == null ? undefined : group.id;
        new LineSchemaEndpointApi()
            .findLinesForParent({
                groupId: groupId,
                timetableId: props.timetable.id
            }, {headers: prepareSecurityHeaders()})
            .then(r => {
                setLineChildren(r);
            })
            .catch(e => {
                verifyClientResponse(e);
            });
    }

    useEffect(() => {
        refreshGroup();
        refreshLine();
    }, [group, props.timetable.id])

    return (
        <div id='simpleMainContentSplit'>
            <div>
                <p>
            <span id='timeTableHeader_timeTable'>
              {props.timetable.name}
                {!props.timetable.editable && <Tooltip content={'Zablokowany'}>🔒</Tooltip>}
            </span>
                    {group && <span id='timeTableHeader_group'> {group.name}</span>}
                </p>
                <TimeTableExplorerGroups groupChildren={groupChildren} onChangeGroup={setGroup} timetable={props.timetable} onChangeGroupIdToEdit={setGroupIdToEdit}/>
                <TimeTableExplorerLines lineChildren={lineChildren}
                                        timetable={props.timetable}
                                        onChangeLineForLinePlan={setLineForLinePlan}
                                        onChangeLineForCoursePanel={setLineForCoursePanel}
                                        onChangeLineForEditPanel={setLineIdToEdit}
                />
                {modifyButtons(props.timetable,
                    props.mzkWorkspace.company,
                    group,
                    () => setShowNewGroupForm(!showNewGroupForm),
                    () => setShowNewLineForm(!showNewLineForm)
                )}
                {nonModifyButtons(group,
                    (g) => setGroup(g), props.selectTimeTable,
                    () => setShowOverriddenVariableList(!showOverriddenVariableList))}
            </div>
            {showNewGroupForm && props.timetable.editable &&
                <AddNewTimeTableGroupForm parentGroup={group} timetable={props.timetable}
                                          onClose={(r) => {
                                              setShowNewGroupForm(false);
                                              r && refreshGroup();
                                          }}/>}
            {showNewLineForm && props.timetable.editable &&
                <AddNewTimetableLineForm parentGroup={group} timeTable={props.timetable}
                                         onClose={(r) => {
                                             setShowNewLineForm(false);
                                             r && refreshLine();
                                         }}/>}
            {lineForLinePlan &&
                <LinePlanPanel
                    line={lineForLinePlan}
                    timeTable={props.timetable}
                    close={() => setLineForLinePlan(null)}
                    onShowNewPlatformForm={(station: NavPointMetaDataView, space: DataSpacePermissionCompanyView) => setNewPlatformFormData({
                        station: station,
                        space: space
                    })}
                    onShowNewStationForm={(space: DataSpacePermissionCompanyView) => setNewStationFormData({space: space})}
                    onShowSectionListPanel={n => setNavpointIdForSectionList(n)}
                    onShowCoursePanel={setLineForCoursePanel}
                    company={props.mzkWorkspace.company}
                    greedyStationVersion={greedyStationVersion}
                    greedyPlatformVersion={greedyPlatformVersion}
                />}
            {newPlatformFormData &&
                <AddNewPlatformForm station={newPlatformFormData.station}
                                    mzkWorkspace={props.mzkWorkspace}
                                    hideNewPlatformFormFun={(r) => {
                                        setNewPlatformFormData(null);
                                        r && setGreedyPlatformVersion(v => v + 1);
                                    }}/>}

            {newStationFormData &&
                <AddNewStationForm mzkWorkspace={props.mzkWorkspace}
                                   onClose={(r) => {
                                       setNewStationFormData(null);
                                       r && setGreedyStationVersion(v => v + 1);
                                   }}/>}
            {showOverriddenVariableList &&
                <OverriddenVariablesList timetable={props.timetable} mzkWorkspace={props.mzkWorkspace}
                                         onClose={() => setShowOverriddenVariableList(false)}/>}
            {lineForCoursePanel &&
                <CourseListPanel onCloseFun={() => setLineForCoursePanel(null)} lineSchema={lineForCoursePanel}
                                 timetable={props.timetable}
                                 onShowCourseDetails={(c) => setCourseForCourseDetailsPanel(c)}
                                 greedyCourseVersion={greedyCourseVersion}/>}
            {courseForCourseDetailsPanel && <CourseDetailsPanel
                timetable={props.timetable}
                onClose={() => setCourseForCourseDetailsPanel(null)}
                course={courseForCourseDetailsPanel}
                onCourseRemoved={() => {
                    setCourseForCourseDetailsPanel(null);
                    setGreedyCourseVersion(v => v + 1);
                }}
            />}
            {navpointIdForSectionList && <SectionListPanel navPointId={navpointIdForSectionList}
                                                           onClose={() => setNavpointIdForSectionList(null)}/>}
            {groupIdToEdit && <EditTimetableGroupPanel groupId={groupIdToEdit} onClose={(r)=>{
                setGroupIdToEdit(null);
                r && refreshGroup();
            }} />}
            {lineIdToEdit && <EditTimetableLineForm onClose={(r)=>{
                setLineIdToEdit(null);
                r && refreshLine();
            }} lineId={lineIdToEdit} />}
        </div>
    );
}

export default TimeTableExplorer;
