import Close from "../../common/ahreffun/Close";
import {AdoptedNavPoint, JammedSectionView, NavpointEndpointApi, SectionEndpointApi} from "../../../api";
import SectionTable from "../table/SectionTable";
import {uxNull} from "../../common/string/stringUtil";
import {useContext, useEffect, useState} from "react";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {CurrentUserContext} from "../../CurrentUserContext";
import {stringToDate} from "../../common/date/dateUtil";

interface SectionListPanelProps {
    navPointId: string;
    onClose: () => void;
}

export function SectionListPanel(props: SectionListPanelProps) {
    const [sections, setSections] = useState<JammedSectionView[]>();
    const [adoptedNavPoint, setAdoptedNavPoint] = useState<AdoptedNavPoint>();
    const currentUser = useContext(CurrentUserContext);
    useEffect(() => {
        const defaultCompany = currentUser.user.defaultCompany;
        new SectionEndpointApi().initSearchSections({
            sectionSearchInput: {
                companyId: defaultCompany.id,
                navPointId: props.navPointId,
                position: 0,
                date: stringToDate(new Date().toISOString())
            }
        }, {headers: prepareSecurityHeaders()})
            .then(r => {
                setSections(r.sections);
            })
            .catch(e => verifyClientResponse(e));
        new NavpointEndpointApi().prepareAdoptedNavPoint({
            adoptedNavPointRequest: {
                id: props.navPointId,
                companyId: defaultCompany.id,
            }
        }, {headers: prepareSecurityHeaders()})
            .then(adoptedNavPoint => {
                setAdoptedNavPoint(adoptedNavPoint);
            }).catch(e => {
            verifyClientResponse(e);
        });
    }, [props.navPointId]);
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose()}/></span>
        </div>
        <h3>Powiązane odcinki</h3>
        {sections && adoptedNavPoint && <SectionTable
            sections={sections}
            navPointPosition={0}
            baseNavPoint={adoptedNavPoint}
            actionProvider={() => uxNull()}
        />}
    </div>;
}
