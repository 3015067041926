import Tooltip from "./Tooltip";
import {ReactNode} from "react";

interface HelpProps {
    children: ReactNode;
}

function Help(props: HelpProps) {
    return <span className='help'><Tooltip content={props.children}>[?]</Tooltip></span>
}

export default Help;
