import {CurrentUserContext} from "../../CurrentUserContext";
import ConditionTabSectionConditionManagement from "./ConditionTabSectionConditionManagement";
import {Company} from "../../user/current/CurrentUserContext";
import {useContext} from "react";

const ConditionTab = () => {
    const currentUser = useContext(CurrentUserContext);
    const company: Company = currentUser.user.defaultCompany;
    return <ConditionTabSectionConditionManagement company={company}/>;
}

export default ConditionTab;