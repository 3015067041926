/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateVariableSelectionInput,
  VariableSelectionView,
} from '../models';
import {
    CreateVariableSelectionInputFromJSON,
    CreateVariableSelectionInputToJSON,
    VariableSelectionViewFromJSON,
    VariableSelectionViewToJSON,
} from '../models';

export interface GetVariableSelectionsRequest {
    timeTableId: string;
    companyId: string;
}

export interface SaveSelectionRequest {
    createVariableSelectionInput?: CreateVariableSelectionInput;
}

/**
 * 
 */
export class VariableSelectionsEndpointApi extends runtime.BaseAPI {

    /**
     */
    async getVariableSelectionsRaw(requestParameters: GetVariableSelectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VariableSelectionView>>> {
        if (requestParameters.timeTableId === null || requestParameters.timeTableId === undefined) {
            throw new runtime.RequiredError('timeTableId','Required parameter requestParameters.timeTableId was null or undefined when calling getVariableSelections.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getVariableSelections.');
        }

        const queryParameters: any = {};

        if (requestParameters.timeTableId !== undefined) {
            queryParameters['timeTableId'] = requestParameters.timeTableId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/variables/selections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VariableSelectionViewFromJSON));
    }

    /**
     */
    async getVariableSelections(requestParameters: GetVariableSelectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VariableSelectionView>> {
        const response = await this.getVariableSelectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveSelectionRaw(requestParameters: SaveSelectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/variables/selections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateVariableSelectionInputToJSON(requestParameters.createVariableSelectionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveSelection(requestParameters: SaveSelectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveSelectionRaw(requestParameters, initOverrides);
    }

}
