import {ConditionEndpointApi, ConditionVisibilityRuleTypeView, CrewEndpointApi} from "../../../api";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";

export function searchConditions(companyId: string, visibilityRule: ConditionVisibilityRuleTypeView) {
    return new ConditionEndpointApi()
        .initSearchConditions({
            conditionSearchInput: {
                appliedForCompanyId: companyId,
                withGlobal: true,
                visibilityRuleType: visibilityRule
            }
        }, {headers: prepareSecurityHeaders()})
        .catch(e => verifyClientResponse(e));
}

export function logCrewGroupUsage(id: string){
    new CrewEndpointApi()
        .logCrewGroupUsage({usageInput: {id: id}},
            {headers: prepareSecurityHeaders()})
        .catch(e => verifyClientResponse(e));
}
