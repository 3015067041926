import {ReactNode, useState} from "react";
import ZoneTabSectionAssigning from "./ZoneTabSectionAssigning";
import Af from "../../common/ahreffun/Af";
import SpaceForCurrentCompanySwitcher from "../../space/switch/SpaceForCurrentCompanySwitcher";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import ZoneTabSectionAvailableZones from "./ZoneTabSectionAvailableZones";

enum ZoneTabCategory {
    AVAILABLE_ZONE,
    ASSIGNING_ZONE
}

function ZoneTab() {
    const [category, setCategory] = useState(ZoneTabCategory.AVAILABLE_ZONE);
    const [mzkWorkspace, setMzkWorkspace] = useState<MzkWorkspace>();
    const mainContent = (): ReactNode => {
        return category === ZoneTabCategory.AVAILABLE_ZONE ?
            <ZoneTabSectionAvailableZones mzkWorkspace={mzkWorkspace}/> :
            <ZoneTabSectionAssigning mzkWorkspace={mzkWorkspace}/>;
    }
    const initContent = (): ReactNode => {
        return <div className='diffArea'>
            Wybierz firmę oraz space aby kontynuować.
        </div>;
    }
    const sectionLink = (): ReactNode => {
        if (category === ZoneTabCategory.AVAILABLE_ZONE) {
            return <p><Af onClick={() => setCategory(ZoneTabCategory.ASSIGNING_ZONE)}>Przejdź do przyznawania stref</Af>
            </p>;
        } else {
            return <p><Af onClick={() => setCategory(ZoneTabCategory.AVAILABLE_ZONE)}>Przejdź do dostępnych stref</Af>
            </p>;
        }
    }

    return (
        <div>
            <h2>Strefy</h2>
            <SpaceForCurrentCompanySwitcher
                mzkWorkspace={mzkWorkspace}
                onChangeCompanyAndSpaceFun={(c, s) => setMzkWorkspace({company: c, space: s})}
            />
            <br/>
            {sectionLink()}
            {mzkWorkspace ? mainContent() : initContent()}
        </div>
    );
}

export default ZoneTab;
