/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyPermissionUserView } from './CompanyPermissionUserView';
import {
    CompanyPermissionUserViewFromJSON,
    CompanyPermissionUserViewFromJSONTyped,
    CompanyPermissionUserViewToJSON,
} from './CompanyPermissionUserView';
import type { DataSpacePermissionUserView } from './DataSpacePermissionUserView';
import {
    DataSpacePermissionUserViewFromJSON,
    DataSpacePermissionUserViewFromJSONTyped,
    DataSpacePermissionUserViewToJSON,
} from './DataSpacePermissionUserView';

/**
 * 
 * @export
 * @interface UserConfigView
 */
export interface UserConfigView {
    /**
     * 
     * @type {string}
     * @memberof UserConfigView
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserConfigView
     */
    nick?: string;
    /**
     * 
     * @type {Array<DataSpacePermissionUserView>}
     * @memberof UserConfigView
     */
    associatedDataSpaces?: Array<DataSpacePermissionUserView>;
    /**
     * 
     * @type {Array<CompanyPermissionUserView>}
     * @memberof UserConfigView
     */
    associatedCompanies?: Array<CompanyPermissionUserView>;
    /**
     * 
     * @type {Set<string>}
     * @memberof UserConfigView
     */
    administrationPrivileges?: Set<UserConfigViewAdministrationPrivilegesEnum>;
}


/**
 * @export
 */
export const UserConfigViewAdministrationPrivilegesEnum = {
    Import: 'IMPORT',
    Space: 'SPACE',
    Company: 'COMPANY'
} as const;
export type UserConfigViewAdministrationPrivilegesEnum = typeof UserConfigViewAdministrationPrivilegesEnum[keyof typeof UserConfigViewAdministrationPrivilegesEnum];


/**
 * Check if a given object implements the UserConfigView interface.
 */
export function instanceOfUserConfigView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserConfigViewFromJSON(json: any): UserConfigView {
    return UserConfigViewFromJSONTyped(json, false);
}

export function UserConfigViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserConfigView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nick': !exists(json, 'nick') ? undefined : json['nick'],
        'associatedDataSpaces': !exists(json, 'associatedDataSpaces') ? undefined : ((json['associatedDataSpaces'] as Array<any>).map(DataSpacePermissionUserViewFromJSON)),
        'associatedCompanies': !exists(json, 'associatedCompanies') ? undefined : ((json['associatedCompanies'] as Array<any>).map(CompanyPermissionUserViewFromJSON)),
        'administrationPrivileges': !exists(json, 'administrationPrivileges') ? undefined : json['administrationPrivileges'],
    };
}

export function UserConfigViewToJSON(value?: UserConfigView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nick': value.nick,
        'associatedDataSpaces': value.associatedDataSpaces === undefined ? undefined : ((value.associatedDataSpaces as Array<any>).map(DataSpacePermissionUserViewToJSON)),
        'associatedCompanies': value.associatedCompanies === undefined ? undefined : ((value.associatedCompanies as Array<any>).map(CompanyPermissionUserViewToJSON)),
        'administrationPrivileges': value.administrationPrivileges === undefined ? undefined : Array.from(value.administrationPrivileges as Set<any>),
    };
}

