interface DateInputProps {
    onChange: (value: string) => void;
    value: string;
    disabled?: boolean;
}

function DateInput(props: DateInputProps) {
    return (
        <input type="date" disabled={props.disabled} value={props.value}
               onChange={(e) => props.onChange(e.target.value)}/>
    );
}

export default DateInput;
