/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CrewGroupInput,
  CrewGroupView,
  LineSchemaMetaDataView,
  UsageInput,
} from '../models';
import {
    CrewGroupInputFromJSON,
    CrewGroupInputToJSON,
    CrewGroupViewFromJSON,
    CrewGroupViewToJSON,
    LineSchemaMetaDataViewFromJSON,
    LineSchemaMetaDataViewToJSON,
    UsageInputFromJSON,
    UsageInputToJSON,
} from '../models';

export interface DeleteCrewGroupRequest {
    crewGroupId: string;
}

export interface FindCrewGroupsRequest {
    companyId: string;
}

export interface FindCrewGroupsAssociatedWithLineRequest {
    lineSchemaId: string;
}

export interface LogCrewGroupUsageRequest {
    usageInput?: UsageInput;
}

export interface PrepareCrewGroupUsageReportRequest {
    crewGroupId: string;
}

export interface ReplaceCrewGroupUsagesRequest {
    fromCrewGroupId: string;
    toCrewGroupId: string;
}

export interface SaveCrewGroupsRequest {
    crewGroupInput?: CrewGroupInput;
}

/**
 * 
 */
export class CrewEndpointApi extends runtime.BaseAPI {

    /**
     */
    async deleteCrewGroupRaw(requestParameters: DeleteCrewGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.crewGroupId === null || requestParameters.crewGroupId === undefined) {
            throw new runtime.RequiredError('crewGroupId','Required parameter requestParameters.crewGroupId was null or undefined when calling deleteCrewGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.crewGroupId !== undefined) {
            queryParameters['crewGroupId'] = requestParameters.crewGroupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/crew/groups`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCrewGroup(requestParameters: DeleteCrewGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCrewGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findCrewGroupsRaw(requestParameters: FindCrewGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CrewGroupView>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling findCrewGroups.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/crew/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CrewGroupViewFromJSON));
    }

    /**
     */
    async findCrewGroups(requestParameters: FindCrewGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CrewGroupView>> {
        const response = await this.findCrewGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findCrewGroupsAssociatedWithLineRaw(requestParameters: FindCrewGroupsAssociatedWithLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CrewGroupView>>> {
        if (requestParameters.lineSchemaId === null || requestParameters.lineSchemaId === undefined) {
            throw new runtime.RequiredError('lineSchemaId','Required parameter requestParameters.lineSchemaId was null or undefined when calling findCrewGroupsAssociatedWithLine.');
        }

        const queryParameters: any = {};

        if (requestParameters.lineSchemaId !== undefined) {
            queryParameters['lineSchemaId'] = requestParameters.lineSchemaId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/crew/groups/associatedWithLine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CrewGroupViewFromJSON));
    }

    /**
     */
    async findCrewGroupsAssociatedWithLine(requestParameters: FindCrewGroupsAssociatedWithLineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CrewGroupView>> {
        const response = await this.findCrewGroupsAssociatedWithLineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logCrewGroupUsageRaw(requestParameters: LogCrewGroupUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/crew/groups/usages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsageInputToJSON(requestParameters.usageInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logCrewGroupUsage(requestParameters: LogCrewGroupUsageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logCrewGroupUsageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async prepareCrewGroupUsageReportRaw(requestParameters: PrepareCrewGroupUsageReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LineSchemaMetaDataView>>> {
        if (requestParameters.crewGroupId === null || requestParameters.crewGroupId === undefined) {
            throw new runtime.RequiredError('crewGroupId','Required parameter requestParameters.crewGroupId was null or undefined when calling prepareCrewGroupUsageReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.crewGroupId !== undefined) {
            queryParameters['crewGroupId'] = requestParameters.crewGroupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/crew/groups/usages/report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LineSchemaMetaDataViewFromJSON));
    }

    /**
     */
    async prepareCrewGroupUsageReport(requestParameters: PrepareCrewGroupUsageReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LineSchemaMetaDataView>> {
        const response = await this.prepareCrewGroupUsageReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async replaceCrewGroupUsagesRaw(requestParameters: ReplaceCrewGroupUsagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fromCrewGroupId === null || requestParameters.fromCrewGroupId === undefined) {
            throw new runtime.RequiredError('fromCrewGroupId','Required parameter requestParameters.fromCrewGroupId was null or undefined when calling replaceCrewGroupUsages.');
        }

        if (requestParameters.toCrewGroupId === null || requestParameters.toCrewGroupId === undefined) {
            throw new runtime.RequiredError('toCrewGroupId','Required parameter requestParameters.toCrewGroupId was null or undefined when calling replaceCrewGroupUsages.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromCrewGroupId !== undefined) {
            queryParameters['fromCrewGroupId'] = requestParameters.fromCrewGroupId;
        }

        if (requestParameters.toCrewGroupId !== undefined) {
            queryParameters['toCrewGroupId'] = requestParameters.toCrewGroupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/crew/groups/usages`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async replaceCrewGroupUsages(requestParameters: ReplaceCrewGroupUsagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.replaceCrewGroupUsagesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveCrewGroupsRaw(requestParameters: SaveCrewGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/crew/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CrewGroupInputToJSON(requestParameters.crewGroupInput),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async saveCrewGroups(requestParameters: SaveCrewGroupsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.saveCrewGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
