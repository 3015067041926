import {
    AdoptedNavPoint,
    DataSpacePermissionCompanyView,
    LineSchemaMetaDataView,
    LineSchemaPlanEndpointApi,
    LineSchemaPlanValidationInputSample,
    LineSchemaPlanValidationResult,
    NavpointEndpointApi,
    NavPointMetaDataView,
    TimetableView
} from "../../../api";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import AddNewPlatformPlanPart from "./table/part/AddNewPlatformPlanPart";
import AddNewStationPlanPart from "./table/part/AddNewStationPlanPart";
import {addToast} from "../../common/toast/toastUtil";
import Close from "../../common/ahreffun/Close";
import {ModifiedElement} from "../../common/modified/ModifiedElement";
import {LinePlanTablePartSuggestions} from "./table/LinePlanTablePartSuggestions";
import Button from "../../common/input/button/Button";
import LinePlanTable from "./table/LinePlanTable";
import {useEffect, useState} from "react";
import {logNavPointUsage} from "../../navpoint/ajax/ajaxUtils";

interface LinePlanPanelProps {
    line: LineSchemaMetaDataView;
    timeTable: TimetableView;
    close: () => void;
    onShowNewPlatformForm: (station: NavPointMetaDataView, space: DataSpacePermissionCompanyView) => void;
    onShowNewStationForm: (space: DataSpacePermissionCompanyView) => void;
    onShowSectionListPanel: (navPointId: string) => void;
    onShowCoursePanel: (lineSchema: LineSchemaMetaDataView)=>void;
    company: Company
    greedyPlatformVersion: number;
    greedyStationVersion: number;
}

export function LinePlanPanel(props: LinePlanPanelProps) {
    const [plan, setPlan] = useState<LineSchemaPlanPart[]>(null);
    const [examinedDate] = useState(new Date());
    const [modified, setModified] = useState(false);
    const [showNewPlatformRollerContent, setShowNewPlatformRollerContent] = useState(false);
    const [showNewStationRollerContent, setShowNewStationRollerContent] = useState(false);
    useEffect(() => {
        new LineSchemaPlanEndpointApi()
            .findSchemaPlan({lineId: props.line.id}, {headers: prepareSecurityHeaders()})
            .then(r => {
                setPlan(r.parts.map(p => {
                    return {id: p.id, adoptedNavPoint: p.adoptedNavPoint, validationData: null}
                }));
            })
            .catch(e => verifyClientResponse(e));
    }, [props.line.id]);
    useEffect(() => {
        if (plan == null) {
            return;
        }
        const samples: LineSchemaPlanValidationInputSample[] = [];
        for (let i = 0; i < plan.length; i++) {
            let current = plan[i];
            const nextNavPointId = i + 1 < plan.length ? plan[i + 1].adoptedNavPoint.navPointMetaData.id : null;
            if (current.validationData == null || current.validationData.shouldBeRefreshed(nextNavPointId)) {
                const sample: LineSchemaPlanValidationInputSample = {
                    sampleId: self.crypto.randomUUID(),
                    navPointId: current.adoptedNavPoint.navPointMetaData.id,
                    nextNavPointId: nextNavPointId
                };
                current.validationData = new LineSchemaPlanPartValidationData(sample, null);
                samples.push(sample)
            }
        }
        if (samples.length) {
            new LineSchemaPlanEndpointApi().validatePlan({
                lineSchemaPlanValidationInput: {
                    samples: samples,
                    examinedDate: examinedDate,
                    timetableId: props.timeTable.id
                },
            }, {headers: prepareSecurityHeaders()})
                .then(r => {
                    setPlan(p => {
                        const newPlan = [...p];
                        r.forEach(result => {
                            for (let i = 0; i < newPlan.length; i++) {
                                const el = newPlan[i];
                                if (el.validationData?.sample?.sampleId == result.sampleId) {
                                    newPlan[i] = {
                                        adoptedNavPoint: el.adoptedNavPoint,
                                        id: el.id,
                                        validationData: new LineSchemaPlanPartValidationData(el.validationData.sample, result)
                                    };
                                    break;
                                }
                            }
                        })
                        return newPlan;
                    })
                })
                .catch(e => verifyClientResponse(e));
        }
    }, [plan, examinedDate])
    const addPart = (navPoint: NavPointMetaDataView) => {
        new NavpointEndpointApi().prepareAdoptedNavPoint({
            adoptedNavPointRequest: {
                id: navPoint.id,
                companyId: props.company.id,
            }
        }, {headers: prepareSecurityHeaders()})
            .then(adoptedNavPoint => {
                addAdoptedNavPointPart(adoptedNavPoint);
            }).catch(e => {
            verifyClientResponse(e);
        });
        logNavPointUsage(navPoint.id);
    }
    const addAdoptedNavPointPart = (adoptedNavPoint: AdoptedNavPoint) => {
        setModified(true);
        setPlan(s => {
            return [...s, {id: null, adoptedNavPoint: adoptedNavPoint, validationData: null}];
        });
    }
    const onSave = () => {
        const parts = plan.map(p => p.adoptedNavPoint.navPointMetaData.id);
        new LineSchemaPlanEndpointApi()
            .saveLineSchemaPlan({
                lineSchemaPlanInput: {
                    lineId: props.line.id,
                    parts: parts
                }
            }, {headers: prepareSecurityHeaders()})
            .then(() => {
                setModified(false);
                addToast('Pomyślnie zaktualizowano plan');
            })
            .catch(e => verifyClientResponse(e, getErrorMap()));
    }
    const getErrorMap = () => {
        const map = new Map();
        map.set('following_parts_the_same', () => addToast('Następujące po sobie przystanki nie mogą być takie same'));
        return map;
    }

    return (<div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={props.close}/></span>
            </div>
            <h3>Przebieg trasy{modified && <ModifiedElement/>}</h3>
            {props.line.technicalName} {props.line.technicalDescription}
            <LinePlanTable
                plan={plan}
                examinedDate={examinedDate}
                timetable={props.timeTable}
                onChangePlan={(p) => {
                    setPlan(p);
                    setModified(true);
                }}
                onShowSectionListPanel={props.onShowSectionListPanel}
            />
            <LinePlanTablePartSuggestions plan={plan} onAdd={(a) => addAdoptedNavPointPart(a)}/>
            {modified && <Button onClick={onSave}>Zapisz</Button>}
            <Button onClick={()=>props.onShowCoursePanel(props.line)}>Kursy</Button>
            {props.timeTable.editable && props.company.superUser && <>
                <br/>
                <AddNewPlatformPlanPart
                    open={showNewPlatformRollerContent}
                    onChangeVisibility={() => setShowNewPlatformRollerContent(s => !s)}
                    showNewPlatformFormFun={props.onShowNewPlatformForm}
                    showNewStationFormFun={props.onShowNewStationForm}
                    addPartFun={addPart}
                    greedyVersion={props.greedyPlatformVersion + props.greedyStationVersion}
                />
                <br/>
                <AddNewStationPlanPart
                    open={showNewStationRollerContent}
                    onChangeVisibility={() => setShowNewStationRollerContent(s => !s)}
                    showNewStationFormFun={props.onShowNewStationForm}
                    addPartFun={addPart}
                    greedyStationVersion={props.greedyStationVersion}
                />
            </>}
        </div>
    );
}

export interface LineSchemaPlanPart {
    id: string;
    adoptedNavPoint: AdoptedNavPoint;
    validationData: LineSchemaPlanPartValidationData;
}

class LineSchemaPlanPartValidationData {
    sample: LineSchemaPlanValidationInputSample;
    result: LineSchemaPlanValidationResult;

    constructor(sample: LineSchemaPlanValidationInputSample, result: LineSchemaPlanValidationResult) {
        this.sample = sample;
        this.result = result;
    }

    public shouldBeRefreshed(nextNavPointId: string) {
        return this.sample == null || this.sample.nextNavPointId != nextNavPointId;
    }
}
