/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataSpacePermissionCompanyView
 */
export interface DataSpacePermissionCompanyView {
    /**
     * 
     * @type {string}
     * @memberof DataSpacePermissionCompanyView
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSpacePermissionCompanyView
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataSpacePermissionCompanyView
     */
    defaultSpace?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSpacePermissionCompanyView
     */
    superUser?: boolean;
}

/**
 * Check if a given object implements the DataSpacePermissionCompanyView interface.
 */
export function instanceOfDataSpacePermissionCompanyView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataSpacePermissionCompanyViewFromJSON(json: any): DataSpacePermissionCompanyView {
    return DataSpacePermissionCompanyViewFromJSONTyped(json, false);
}

export function DataSpacePermissionCompanyViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSpacePermissionCompanyView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'defaultSpace': !exists(json, 'defaultSpace') ? undefined : json['defaultSpace'],
        'superUser': !exists(json, 'superUser') ? undefined : json['superUser'],
    };
}

export function DataSpacePermissionCompanyViewToJSON(value?: DataSpacePermissionCompanyView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'defaultSpace': value.defaultSpace,
        'superUser': value.superUser,
    };
}

