/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EditLineSchemaInput,
  LineSchemaInput,
  LineSchemaMetaDataView,
} from '../models';
import {
    EditLineSchemaInputFromJSON,
    EditLineSchemaInputToJSON,
    LineSchemaInputFromJSON,
    LineSchemaInputToJSON,
    LineSchemaMetaDataViewFromJSON,
    LineSchemaMetaDataViewToJSON,
} from '../models';

export interface AddLineSchemaRequest {
    lineSchemaInput: LineSchemaInput;
}

export interface DeleteLineSchemaRequest {
    lineId: string;
}

export interface FindLinesForParentRequest {
    timetableId: string;
    groupId?: string;
}

export interface GetLineSchemaDetailsRequest {
    lineId?: string;
}

export interface UpdateLineSchemaRequest {
    editLineSchemaInput: EditLineSchemaInput;
}

/**
 * 
 */
export class LineSchemaEndpointApi extends runtime.BaseAPI {

    /**
     */
    async addLineSchemaRaw(requestParameters: AddLineSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.lineSchemaInput === null || requestParameters.lineSchemaInput === undefined) {
            throw new runtime.RequiredError('lineSchemaInput','Required parameter requestParameters.lineSchemaInput was null or undefined when calling addLineSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lines/schemas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LineSchemaInputToJSON(requestParameters.lineSchemaInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addLineSchema(requestParameters: AddLineSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addLineSchemaRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteLineSchemaRaw(requestParameters: DeleteLineSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.lineId === null || requestParameters.lineId === undefined) {
            throw new runtime.RequiredError('lineId','Required parameter requestParameters.lineId was null or undefined when calling deleteLineSchema.');
        }

        const queryParameters: any = {};

        if (requestParameters.lineId !== undefined) {
            queryParameters['lineId'] = requestParameters.lineId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lines/schemas`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLineSchema(requestParameters: DeleteLineSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLineSchemaRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findLinesForParentRaw(requestParameters: FindLinesForParentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LineSchemaMetaDataView>>> {
        if (requestParameters.timetableId === null || requestParameters.timetableId === undefined) {
            throw new runtime.RequiredError('timetableId','Required parameter requestParameters.timetableId was null or undefined when calling findLinesForParent.');
        }

        const queryParameters: any = {};

        if (requestParameters.timetableId !== undefined) {
            queryParameters['timetableId'] = requestParameters.timetableId;
        }

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lines/schemas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LineSchemaMetaDataViewFromJSON));
    }

    /**
     */
    async findLinesForParent(requestParameters: FindLinesForParentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LineSchemaMetaDataView>> {
        const response = await this.findLinesForParentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLineSchemaDetailsRaw(requestParameters: GetLineSchemaDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LineSchemaMetaDataView>> {
        const queryParameters: any = {};

        if (requestParameters.lineId !== undefined) {
            queryParameters['lineId'] = requestParameters.lineId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lines/schemas/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineSchemaMetaDataViewFromJSON(jsonValue));
    }

    /**
     */
    async getLineSchemaDetails(requestParameters: GetLineSchemaDetailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LineSchemaMetaDataView> {
        const response = await this.getLineSchemaDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateLineSchemaRaw(requestParameters: UpdateLineSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.editLineSchemaInput === null || requestParameters.editLineSchemaInput === undefined) {
            throw new runtime.RequiredError('editLineSchemaInput','Required parameter requestParameters.editLineSchemaInput was null or undefined when calling updateLineSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lines/schemas`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditLineSchemaInputToJSON(requestParameters.editLineSchemaInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateLineSchema(requestParameters: UpdateLineSchemaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLineSchemaRaw(requestParameters, initOverrides);
    }

}
