import {mapBooleanToDescription} from "../../common/boolean/booleanUtil";
import {VariableTemplateValueView} from "../../../api";
import {ReactNode} from "react";

interface VariableValueTableProps {
    values: VariableTemplateValueView[];
    actionProvider: (v: VariableTemplateValueView) => ReactNode;
}

export function VariableValueTable(props: VariableValueTableProps) {
    return <table>
        <thead>
        <tr className='tableHeader'>
            <th>Lp</th>
            <th>Nazwa</th>
            <th>Domyślna</th>
            <th>Akcje</th>
        </tr>
        </thead>
        <tbody>
        {props.values.map((v, index) => <tr key={v.id}>
            <td>{index}</td>
            <td>{v.description}</td>
            <td>{mapBooleanToDescription(v._default)}</td>
            <td>{props.actionProvider(v)}</td>
        </tr>)}
        </tbody>
    </table>;
}
