import Checkbox from "../common/input/checkbox/Checkbox";

interface RequestStopInputProps {
    onChange: (value: boolean) => void;
    value: boolean;
}

export function RequestStopInput(props: RequestStopInputProps) {
    const currentValue = props.value !== null && props.value !== undefined;
    return (
        <>
            <Checkbox onChange={() => props.onChange(currentValue ? null : true)} value={currentValue}>
                Na żądanie:
            </Checkbox>
            <input
                type="radio"
                checked={props.value}
                onChange={() => props.onChange(true)}
                disabled={props.value == null}
            />
            Tak
            <input
                type="radio"
                checked={props.value === false}
                onChange={() => props.onChange(false)}
                disabled={props.value == null}
            />
            Nie
        </>
    );
}
