import Close from "../../common/ahreffun/Close";
import {
    AdoptedNavPoint,
    ConditionEndpointApi,
    ConditionMetaDataView, ConditionVisibilityRuleTypeView,
    DataSpacePermissionCompanyView,
    NavpointEndpointApi,
    NavPointSettings,
    PlatformEndpointApi,
    StreetEndpointApi,
    StreetView
} from "../../../api";
import {nullIfBlank} from "../../common/string/stringUtil";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {addToast} from "../../common/toast/toastUtil";
import {verifyClientResponse} from "../../common/error/resolvers";
import {prepareNameWithAncestor} from "../../common/navpoint/navpointUtil";
import {RequestStopInput} from "../../requeststop/RequestStopInput";
import Af from "../../common/ahreffun/Af";
import ConditionPopupSelector from "../../condition/popup/ConditionPopupSelector";
import StreetPopupSelector from "../../street/popup/StreetPopupSelector";
import GpsInput from "../../gps/GpsInput";
import Help from "../../common/tooltip/Help";
import {NavPointSettingsRoller} from "../../common/navpoint/NavPointSettingsRoller";
import Button from "../../common/input/button/Button";
import {ChangeEvent, FormEvent, useContext, useEffect, useState} from "react";
import {CurrentUserContext} from "../../CurrentUserContext";

interface PlatformModificationPanelProps {
    platformId: string;
    onClose: (refresh: boolean) => void;
}

export function PlatformModificationPanel(props: PlatformModificationPanelProps) {
    const [showStreetForm, setShowStreetForm] = useState(false);
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [technicalName, setTechnicalName] = useState('');
    const [street, setStreet] = useState<StreetView>(null);
    const [requestStop, setRequestStop] = useState<boolean>(null);
    const [gps, setGps] = useState<string[]>(['', '']);
    const [capacity, setCapacity] = useState<number>(1);
    const [settings, setSettings] = useState<NavPointSettings>({
        passengersFrom: true, passengersTo: true, depot: false, technicalStop: false
    });
    const [showSettingsRoller, setShowSettingsRoller] = useState(false);
    const [showConditionSelector, setShowConditionSelector] = useState(false);
    const [condition, setCondition] = useState<ConditionMetaDataView>()
    const currentUser = useContext(CurrentUserContext);
    const [adoptedNavPoint, setAdoptedNavPoint] = useState<AdoptedNavPoint>();
    const [navPointSpace, setNavPointSpace] = useState<DataSpacePermissionCompanyView>();
    const onSave = (e: FormEvent) => {
        e.preventDefault();
        if (verifyForm()) {
            new PlatformEndpointApi().modifyPlatform({
                platformModifyInputData: {
                    navPointMetaData: {
                        id: props.platformId,
                        name: nullIfBlank(name),
                        shortName: nullIfBlank(shortName),
                        technicalName: nullIfBlank(technicalName),
                        conditionId: condition ? condition.id : null,
                        streetId: street ? street.id : null,
                        gpsX: parseFloat(gps![0]),
                        gpsY: parseFloat(gps![1]),
                        settings: settings,
                        companyId: currentUser.user.defaultCompany.id
                    },

                    requestStop: requestStop,
                    capacity: capacity,
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Poprawnie zmodyfikowano peron');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, getErrorMap());
                });
        }
    }
    useEffect(() => {
        new PlatformEndpointApi().getPlatformDetails({
                platformId: props.platformId,
                companyId: currentUser.user.defaultCompany.id
            },
            {headers: prepareSecurityHeaders()})
            .then(s => {
                setName(s.metaData.name);
                setShortName(s.metaData.shortName);
                setTechnicalName(s.metaData.technicalName);
                setRequestStop(s.details.requestStop);
                setGps([s.metaData.gpsX ? s.metaData.gpsX + '' : '', s.metaData.gpsY ? s.metaData.gpsY + '' : '']);
                setSettings(s.metaData.settings);
                if (s.metaData.conditionId) {
                    new ConditionEndpointApi().getConditionMetaData({conditionId: s.metaData.conditionId},
                        {headers: prepareSecurityHeaders()})
                        .then(c => setCondition(c))
                        .catch(e => verifyClientResponse(e));
                }
                if (s.metaData.streetId) {
                    new StreetEndpointApi().getStreet({
                            companyId: currentUser.user.defaultCompany.id,
                            streetId: s.metaData.streetId
                        },
                        {headers: prepareSecurityHeaders()})
                        .then(s => setStreet(s))
                        .catch(e => verifyClientResponse(e));
                }
                setNavPointSpace(currentUser.user.defaultCompany.availableDataSpaces.filter(ds => ds.id == s.metaData.dataSpaceId)[0]);
            })
            .catch(e => verifyClientResponse(e));
        new NavpointEndpointApi().prepareAdoptedNavPoint({
            adoptedNavPointRequest: {
                id: props.platformId,
                companyId: currentUser.user.defaultCompany.id,
            }
        }, {headers: prepareSecurityHeaders()})
            .then(adoptedNavPoint => {
                setAdoptedNavPoint(adoptedNavPoint);
            }).catch(e => {
            verifyClientResponse(e);
        });
    }, [props.platformId]);
    const verifyForm = () => {
        const gpsX = gps![0];
        const gpsY = gps![1];
        if ((gpsX.length > 0 && gpsY.length === 0) || (gpsX.length === 0 && gpsY.length > 0)) {
            addToast('Wypelnij oba pola GPS');
            return false;
        } else if (gpsX.length > 0 && gpsY.length > 0 && (isNaN(parseFloat(gpsX)) || isNaN(parseFloat(gpsY)))) {
            addToast('Wypelnij oba pola GPS poprawnie');
            return false;
        } else if (!name && !shortName && !technicalName) {
            addToast('Nie wypełniono nazwy');
            return false;
        }else if(capacity < 1 || capacity > 10){
            addToast('Pojemność musi być w zakresie <1, 10>');
            return false;
        }
        return true;
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        </div>
        <h3>{adoptedNavPoint && prepareNameWithAncestor(adoptedNavPoint)} - modyfikacja</h3>
        <form>
            <label>Nazwa:</label>
            <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <label>Nazwa skrócona:</label>
            <input name='shortName' value={shortName}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setShortName(e.target.value)}
                   maxLength={50}/>
            <br/>
            <label>Nazwa techniczna:</label>
            <input name='technicalName' value={technicalName}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => setTechnicalName(e.target.value)} maxLength={50}/>
            <br/>
            <RequestStopInput value={requestStop} onChange={(v: boolean) => setRequestStop(v)}/>
            <br/>
            <span>Warunek: </span>
            {condition ? <>
                {condition.name}
                <Af onClick={() => setCondition(null)}>Usuń warunek</Af>
            </> : 'nie ustawiono'}
            <Af onClick={() => setShowConditionSelector(true)}>Warunek</Af>
            {showConditionSelector &&
                <ConditionPopupSelector
                    company={currentUser.user.defaultCompany}
                    visibilityRule={ConditionVisibilityRuleTypeView.NavPoint}
                    closePopup={() => setShowConditionSelector(false)}
                    selectConditionFun={(c) => setCondition(c)}
                />}
            <br/>
            <label>Ulica:</label>
            {street ?
                <span> {`${street.name}`} </span> :
                <span> nie wybrano ulicy </span>}
            <Af onClick={() => setShowStreetForm(true)}>Wybór ulicy</Af>
            {street && <Af onClick={() => setStreet(null)}>Usuń ulicę</Af>}
            {showStreetForm &&
                <StreetPopupSelector closePopup={() => setShowStreetForm(s => !s)}
                                     mzkWorkspace={{
                                         company: currentUser.user.defaultCompany,
                                         space: navPointSpace
                                     }}
                                     onSelectStreet={(s) => {
                                         setStreet(s.street);
                                         setShowStreetForm(false);
                                     }}/>}
            <br/>
            <GpsInput value={gps} onChange={(v: string[]) => setGps(v)}/>
            <br/>
            <label>Pojemność:</label>
            <input type='number' value={capacity} onChange={e=>setCapacity(parseInt(e.target.value))} min={1} max={10} step={1} />
            <Help>Mierzone w autobusach. Przyjęty przelicznik: 2 autobusy = 1 tramwaj</Help>
            <br/>
            <NavPointSettingsRoller onChangeVisibility={() => setShowSettingsRoller(s => !s)}
                                    isOpen={showSettingsRoller}
                                    settings={settings} onChangeSettings={(s) => setSettings(s)}/>
            <Button onClick={onSave}>Zapisz</Button>
        </form>
    </div>;
}

const getErrorMap = () => {
    const map = new Map();
    map.set("not_exist", (r: any) => {
        if (r.data.name === 'station') {
            addToast('Stacja nie istnieje');
        }
    });
    map.set("uniqueness", (r: any) => {
        if (r.data.name === 'technicalName') {
            addToast('Nazwa techniczna nie jest unikalna');
        } else if (r.data.name === 'name') {
            addToast('Nazwa nie jest unikalna');
        }
    });
    return map;
}
