/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InitSearchStationRequest,
  NavPointStationView,
  StationInputData,
  StationModifyInputData,
  StationPreviewView,
} from '../models';
import {
    InitSearchStationRequestFromJSON,
    InitSearchStationRequestToJSON,
    NavPointStationViewFromJSON,
    NavPointStationViewToJSON,
    StationInputDataFromJSON,
    StationInputDataToJSON,
    StationModifyInputDataFromJSON,
    StationModifyInputDataToJSON,
    StationPreviewViewFromJSON,
    StationPreviewViewToJSON,
} from '../models';

export interface GetStationDetailsRequest {
    stationId: string;
    companyId: string;
}

export interface InitSearchStationsRequest {
    initSearchStationRequest: InitSearchStationRequest;
}

export interface PreviewSearchRequest {
    spaceId: string;
    companyId: string;
    limit: number;
}

export interface SaveStationRequest {
    stationInputData: StationInputData;
}

export interface UpdateStationRequest {
    stationModifyInputData: StationModifyInputData;
}

/**
 * 
 */
export class StationEndpointApi extends runtime.BaseAPI {

    /**
     */
    async getStationDetailsRaw(requestParameters: GetStationDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NavPointStationView>> {
        if (requestParameters.stationId === null || requestParameters.stationId === undefined) {
            throw new runtime.RequiredError('stationId','Required parameter requestParameters.stationId was null or undefined when calling getStationDetails.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getStationDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.stationId !== undefined) {
            queryParameters['stationId'] = requestParameters.stationId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/stations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NavPointStationViewFromJSON(jsonValue));
    }

    /**
     */
    async getStationDetails(requestParameters: GetStationDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NavPointStationView> {
        const response = await this.getStationDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async initSearchStationsRaw(requestParameters: InitSearchStationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NavPointStationView>>> {
        if (requestParameters.initSearchStationRequest === null || requestParameters.initSearchStationRequest === undefined) {
            throw new runtime.RequiredError('initSearchStationRequest','Required parameter requestParameters.initSearchStationRequest was null or undefined when calling initSearchStations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/stations/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitSearchStationRequestToJSON(requestParameters.initSearchStationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NavPointStationViewFromJSON));
    }

    /**
     */
    async initSearchStations(requestParameters: InitSearchStationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NavPointStationView>> {
        const response = await this.initSearchStationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewSearchRaw(requestParameters: PreviewSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StationPreviewView>> {
        if (requestParameters.spaceId === null || requestParameters.spaceId === undefined) {
            throw new runtime.RequiredError('spaceId','Required parameter requestParameters.spaceId was null or undefined when calling previewSearch.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling previewSearch.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling previewSearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.spaceId !== undefined) {
            queryParameters['spaceId'] = requestParameters.spaceId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/stations/previewSearches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StationPreviewViewFromJSON(jsonValue));
    }

    /**
     */
    async previewSearch(requestParameters: PreviewSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StationPreviewView> {
        const response = await this.previewSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveStationRaw(requestParameters: SaveStationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.stationInputData === null || requestParameters.stationInputData === undefined) {
            throw new runtime.RequiredError('stationInputData','Required parameter requestParameters.stationInputData was null or undefined when calling saveStation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/stations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StationInputDataToJSON(requestParameters.stationInputData),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async saveStation(requestParameters: SaveStationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.saveStationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateStationRaw(requestParameters: UpdateStationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stationModifyInputData === null || requestParameters.stationModifyInputData === undefined) {
            throw new runtime.RequiredError('stationModifyInputData','Required parameter requestParameters.stationModifyInputData was null or undefined when calling updateStation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/stations`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StationModifyInputDataToJSON(requestParameters.stationModifyInputData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateStation(requestParameters: UpdateStationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateStationRaw(requestParameters, initOverrides);
    }

}
