import {prepareName} from "../../common/navpoint/navpointUtil";
import {stringCompare} from "../../common/string/stringUtil";
import PlatformRow from "./PlatformRow";
import {Fragment, ReactNode, useMemo} from "react";
import {StructuredNavPoints} from "../../../api";
import {NavPointMetaDataView} from "../../../api";

interface PlatformTableRowsProps {
    platformData: StructuredNavPoints[];
    maxDepth: number;
    actionProviderFun: (a0: NavPointMetaDataView) => ReactNode;
    query: string
}

function sort(navPoints: StructuredNavPoints[], query: string) {
    if (navPoints == null) {
        return null;
    }
    const p = [...navPoints];
    p.sort((a, b) => {
        const name1 = prepareName(a.metaData);
        const name2 = prepareName(b.metaData);
        if (query != null){
            const queryLowered = query.toLowerCase();
            let result = 0;
            if (name1.toLowerCase().startsWith(queryLowered)){
                result--;
            }else if(name2.toLowerCase().startsWith(queryLowered)){
                result++;
            }
            if (result != 0){
                return result;
            }
        }
        return stringCompare(name1, name2);
    });
    return p;
}

function PlatformTableRows(props: PlatformTableRowsProps) {
    const process = (structuredNavPoint: StructuredNavPoints, nodeLvl: number): ReactNode => {
        const sortedChildren = nodeLvl > 0 ? sort(structuredNavPoint.children, props.query) : structuredNavPoint.children;
        return <Fragment key={structuredNavPoint.metaData.id}>
            <PlatformRow
                nodeLvl={nodeLvl}
                structuredNavPoint={structuredNavPoint}
                maxDepth={props.maxDepth}
                actionProviderFun={props.actionProviderFun}
            />
            {sortedChildren.map((ch: StructuredNavPoints) => process(ch, nodeLvl + 1))}
        </Fragment>;
    }
    const sortedPlatforms = useMemo(() => {
        return sort(props.platformData, props.query);
    }, [props.platformData]);
    if (sortedPlatforms == null) {
        return <tr>
            <td colSpan={4}>Uzupełnij kryteria.</td>
        </tr>
    } else if (!sortedPlatforms.length) {
        return <tr>
            <td colSpan={4}>Brak danych. Zmień kryteria.</td>
        </tr>;
    }
    return sortedPlatforms.map((structuredNavPoint) => process(structuredNavPoint, 0))
}

export default PlatformTableRows;
