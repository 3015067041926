import {ReactNode} from 'react';
import {secondsToFrontDuration} from "../../common/input/duration/durationUtil";
import {SectionTimeView} from "../../../api";

interface SectionTimeTableProps {
    times: SectionTimeView[];
    actionProvider: (time: SectionTimeView, index: number) => ReactNode;
}

function SectionTimeTable(props: SectionTimeTableProps) {
    return (
        <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Od</th>
                <th>Do</th>
                <th>Czas</th>
                <th>Akcje</th>
            </tr>
            </thead>
            <tbody>
            {props.times.map((t, i) => (
                <tr key={i}>
                    <td>{i}</td>
                    <td>{t.from}</td>
                    <td>{t.to}</td>
                    <td>{secondsToFrontDuration(t.travelSec)}</td>
                    <td>{props.actionProvider(t, i)}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default SectionTimeTable;
