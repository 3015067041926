/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EditStreetInput,
  StreetInput,
  StreetPreviewView,
  StreetSearchInput,
  StreetSearchView,
  StreetView,
  UsageInput,
} from '../models';
import {
    EditStreetInputFromJSON,
    EditStreetInputToJSON,
    StreetInputFromJSON,
    StreetInputToJSON,
    StreetPreviewViewFromJSON,
    StreetPreviewViewToJSON,
    StreetSearchInputFromJSON,
    StreetSearchInputToJSON,
    StreetSearchViewFromJSON,
    StreetSearchViewToJSON,
    StreetViewFromJSON,
    StreetViewToJSON,
    UsageInputFromJSON,
    UsageInputToJSON,
} from '../models';

export interface DeleteStreetRequest {
    companyId: string;
    streetId?: string;
}

export interface EditStreetRequest {
    editStreetInput: EditStreetInput;
}

export interface GetStreetRequest {
    companyId: string;
    streetId?: string;
}

export interface LogStreetUsageRequest {
    usageInput?: UsageInput;
}

export interface PreviewStreetSearchRequest {
    spaceId: string;
    companyId: string;
}

export interface SaveStreetRequest {
    streetInput: StreetInput;
}

export interface SearchStreetsRequest {
    streetSearchInput: StreetSearchInput;
}

/**
 * 
 */
export class StreetEndpointApi extends runtime.BaseAPI {

    /**
     */
    async deleteStreetRaw(requestParameters: DeleteStreetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling deleteStreet.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.streetId !== undefined) {
            queryParameters['streetId'] = requestParameters.streetId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/streets`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteStreet(requestParameters: DeleteStreetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStreetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async editStreetRaw(requestParameters: EditStreetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.editStreetInput === null || requestParameters.editStreetInput === undefined) {
            throw new runtime.RequiredError('editStreetInput','Required parameter requestParameters.editStreetInput was null or undefined when calling editStreet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/streets`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditStreetInputToJSON(requestParameters.editStreetInput),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async editStreet(requestParameters: EditStreetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.editStreetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStreetRaw(requestParameters: GetStreetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StreetView>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getStreet.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.streetId !== undefined) {
            queryParameters['streetId'] = requestParameters.streetId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/streets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StreetViewFromJSON(jsonValue));
    }

    /**
     */
    async getStreet(requestParameters: GetStreetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StreetView> {
        const response = await this.getStreetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async logStreetUsageRaw(requestParameters: LogStreetUsageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/streets/usages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsageInputToJSON(requestParameters.usageInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logStreetUsage(requestParameters: LogStreetUsageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logStreetUsageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async previewStreetSearchRaw(requestParameters: PreviewStreetSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StreetPreviewView>> {
        if (requestParameters.spaceId === null || requestParameters.spaceId === undefined) {
            throw new runtime.RequiredError('spaceId','Required parameter requestParameters.spaceId was null or undefined when calling previewStreetSearch.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling previewStreetSearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.spaceId !== undefined) {
            queryParameters['spaceId'] = requestParameters.spaceId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/streets/previewSearches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StreetPreviewViewFromJSON(jsonValue));
    }

    /**
     */
    async previewStreetSearch(requestParameters: PreviewStreetSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StreetPreviewView> {
        const response = await this.previewStreetSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveStreetRaw(requestParameters: SaveStreetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.streetInput === null || requestParameters.streetInput === undefined) {
            throw new runtime.RequiredError('streetInput','Required parameter requestParameters.streetInput was null or undefined when calling saveStreet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/streets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreetInputToJSON(requestParameters.streetInput),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async saveStreet(requestParameters: SaveStreetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.saveStreetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchStreetsRaw(requestParameters: SearchStreetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StreetSearchView>>> {
        if (requestParameters.streetSearchInput === null || requestParameters.streetSearchInput === undefined) {
            throw new runtime.RequiredError('streetSearchInput','Required parameter requestParameters.streetSearchInput was null or undefined when calling searchStreets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/streets/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreetSearchInputToJSON(requestParameters.streetSearchInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StreetSearchViewFromJSON));
    }

    /**
     */
    async searchStreets(requestParameters: SearchStreetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StreetSearchView>> {
        const response = await this.searchStreetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
