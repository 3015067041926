/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NavPointPlatformView,
  PlatformInputData,
  PlatformModifyInputData,
} from '../models';
import {
    NavPointPlatformViewFromJSON,
    NavPointPlatformViewToJSON,
    PlatformInputDataFromJSON,
    PlatformInputDataToJSON,
    PlatformModifyInputDataFromJSON,
    PlatformModifyInputDataToJSON,
} from '../models';

export interface GetPlatformDetailsRequest {
    platformId: string;
    companyId: string;
}

export interface ModifyPlatformRequest {
    platformModifyInputData: PlatformModifyInputData;
}

export interface SavePlatformRequest {
    platformInputData: PlatformInputData;
}

/**
 * 
 */
export class PlatformEndpointApi extends runtime.BaseAPI {

    /**
     */
    async getPlatformDetailsRaw(requestParameters: GetPlatformDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NavPointPlatformView>> {
        if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
            throw new runtime.RequiredError('platformId','Required parameter requestParameters.platformId was null or undefined when calling getPlatformDetails.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getPlatformDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.platformId !== undefined) {
            queryParameters['platformId'] = requestParameters.platformId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/platforms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NavPointPlatformViewFromJSON(jsonValue));
    }

    /**
     */
    async getPlatformDetails(requestParameters: GetPlatformDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NavPointPlatformView> {
        const response = await this.getPlatformDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async modifyPlatformRaw(requestParameters: ModifyPlatformRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.platformModifyInputData === null || requestParameters.platformModifyInputData === undefined) {
            throw new runtime.RequiredError('platformModifyInputData','Required parameter requestParameters.platformModifyInputData was null or undefined when calling modifyPlatform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/platforms`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlatformModifyInputDataToJSON(requestParameters.platformModifyInputData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async modifyPlatform(requestParameters: ModifyPlatformRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.modifyPlatformRaw(requestParameters, initOverrides);
    }

    /**
     */
    async savePlatformRaw(requestParameters: SavePlatformRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.platformInputData === null || requestParameters.platformInputData === undefined) {
            throw new runtime.RequiredError('platformInputData','Required parameter requestParameters.platformInputData was null or undefined when calling savePlatform.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/platforms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlatformInputDataToJSON(requestParameters.platformInputData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async savePlatform(requestParameters: SavePlatformRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.savePlatformRaw(requestParameters, initOverrides);
    }

}
