import Close from "../../../common/ahreffun/Close";
import {useEffect, useState} from "react";
import {Company, prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {ConditionEndpointApi, ConditionTypeView, VariableEndpointApi, VariableTemplateView} from "../../../../api";
import {verifyClientResponse} from "../../../common/error/resolvers";
import Af from "../../../common/ahreffun/Af";
import {VariablePopup} from "../../../variable/popup/VariablePopup";
import {NewVariableConditionValueSelector} from "./NewVariableConditionValueSelector";
import Button from "../../../common/input/button/Button";
import {addToast} from "../../../common/toast/toastUtil";

interface NewVariableConditionFormProps {
    onClose: (refresh: boolean) => void;
    company: Company;
}

export interface VariableValueSelection {
    variable: VariableTemplateView;
    selectedValueIds: string[];
}

export function NewVariableConditionForm(props: NewVariableConditionFormProps) {
    const [name, setName] = useState('');
    const [variableSelection, setVariableSelection] = useState<VariableValueSelection>();
    const [allVariables, setAllVariables] = useState<VariableTemplateView[]>([]);
    const [showVariablePopup, setShowVariablePopup] = useState(false);
    useEffect(() => {
        new VariableEndpointApi().getVariables({companyId: props.company.id}, {headers: prepareSecurityHeaders()})
            .then(r => {
                setAllVariables(r);
            })
            .catch(e => verifyClientResponse(e));
    }, []);
    const onSelect = (id: string) => {
        if (variableSelection.selectedValueIds.includes(id)) {
            setVariableSelection({variable: variableSelection.variable, selectedValueIds: variableSelection.selectedValueIds.filter(i => id != i)});
        } else {
            setVariableSelection({variable: variableSelection.variable, selectedValueIds: [...variableSelection.selectedValueIds, id]});
        }
    }

    function validateForm() {
        if (!name) {
            addToast('Wypełnij nazwę');
            return false;
        }
        if (variableSelection.selectedValueIds.length < 1) {
            addToast('Musisz wybrać przynajmniej jedną wartość');
            return false;
        }
        if (variableSelection.variable.values.length === variableSelection.selectedValueIds.length) {
            addToast('Nie możesz wybrać wszystkich wartości');
            return false;
        }
        return true;
    }

    function onSave() {
        if (validateForm()) {
            new ConditionEndpointApi().saveVariableCondition({
                variableConditionInput: {
                    metaData: {
                        name: name,
                        companyId: props.company.id,
                        type: ConditionTypeView.Variable
                    }, details: {
                        templateId: variableSelection.variable.id,
                        permittedVariableValueIds: variableSelection.selectedValueIds
                    }
                }
            }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Pomyślnie dodano nowy warunek');
                    props.onClose(true);
                }).catch(e => {
                verifyClientResponse(e, new Map());
            });
        }
    }

    return <div>
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
            </div>
            <h3>Warunek ze zmiennej środowiskowej</h3>
            <label>Nazwa:</label>
            <input name='name' value={name} onChange={e => setName(e.target.value)}
                   maxLength={50} required={true}/>
            <br/>
            <label>Warunek: </label>
            {variableSelection == null ? 'nie ustawiono' : variableSelection.variable.name}
            <span> </span>
            <Af onClick={() => setShowVariablePopup(true)}>Wybór warunku</Af>
            {showVariablePopup && <VariablePopup onClose={() => setShowVariablePopup(false)} variables={allVariables}
                                                 actionProvider={v => <Af onClick={() => {
                                                     setVariableSelection({variable: v, selectedValueIds: []});
                                                     setShowVariablePopup(false)
                                                 }}>Wybierz</Af>}/>}
            <NewVariableConditionValueSelector
                value={variableSelection}
                onSelect={onSelect}
            />
            <br/>
            <Button onClick={onSave}>Zapisz</Button>
        </div>
    </div>;
}
