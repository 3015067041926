import {ReactNode, useMemo} from 'react';
import SectionGroupTable from "./SectionGroupTable";
import {AdoptedNavPoint, JammedSectionView} from "../../../api";

interface SectionTableProps {
    sections: JammedSectionView[];
    actionProvider: (s: JammedSectionView) => ReactNode;
    baseNavPoint: AdoptedNavPoint;
    navPointPosition: number;
}

export interface SectionStructure {
    elements: JammedSectionView[];
    id: string;
}

function SectionTable(props: SectionTableProps) {
    const groupedSections = useMemo<Map<string, SectionStructure>>(() => {
        return props.sections.reduce((result, obj) => {
            const section = obj.section;
            let id = props.baseNavPoint.navPointMetaData.id == section.part1.navPointMetaData.id ? section.part2.navPointMetaData.id : section.part1.navPointMetaData.id;
            if (!result.get(id)) {
                result.set(id, {id: id, elements: []});
            }
            result.get(id).elements.push(obj);
            return result;
        }, new Map());
    }, [props.sections]);

    return (
        <div>
            <table>
                <tbody>
                {Array.from(groupedSections.entries()).map((s) => {
                    return <SectionGroupTable sections={s[1]} key={s[0]}
                                              baseNavPoint={props.baseNavPoint}
                                              navPointPosition={props.navPointPosition}
                                              actionProvider={props.actionProvider}></SectionGroupTable>;
                })}
                </tbody>
            </table>
        </div>
    );
}

export default SectionTable;
