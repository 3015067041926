/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EditTownInput,
  TownInput,
  TownSearchInput,
  TownView,
} from '../models';
import {
    EditTownInputFromJSON,
    EditTownInputToJSON,
    TownInputFromJSON,
    TownInputToJSON,
    TownSearchInputFromJSON,
    TownSearchInputToJSON,
    TownViewFromJSON,
    TownViewToJSON,
} from '../models';

export interface DeleteTownRequest {
    townId: string;
    companyId: string;
}

export interface EditTownRequest {
    editTownInput: EditTownInput;
}

export interface GetTownDetailsRequest {
    townId: string;
    companyId: string;
}

export interface SaveTownRequest {
    townInput: TownInput;
}

export interface SearchTownRequest {
    townSearchInput: TownSearchInput;
}

/**
 * 
 */
export class TownEndpointApi extends runtime.BaseAPI {

    /**
     */
    async deleteTownRaw(requestParameters: DeleteTownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.townId === null || requestParameters.townId === undefined) {
            throw new runtime.RequiredError('townId','Required parameter requestParameters.townId was null or undefined when calling deleteTown.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling deleteTown.');
        }

        const queryParameters: any = {};

        if (requestParameters.townId !== undefined) {
            queryParameters['townId'] = requestParameters.townId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/towns`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTown(requestParameters: DeleteTownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTownRaw(requestParameters, initOverrides);
    }

    /**
     */
    async editTownRaw(requestParameters: EditTownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.editTownInput === null || requestParameters.editTownInput === undefined) {
            throw new runtime.RequiredError('editTownInput','Required parameter requestParameters.editTownInput was null or undefined when calling editTown.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/towns`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditTownInputToJSON(requestParameters.editTownInput),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async editTown(requestParameters: EditTownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.editTownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTownDetailsRaw(requestParameters: GetTownDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TownView>> {
        if (requestParameters.townId === null || requestParameters.townId === undefined) {
            throw new runtime.RequiredError('townId','Required parameter requestParameters.townId was null or undefined when calling getTownDetails.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getTownDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.townId !== undefined) {
            queryParameters['townId'] = requestParameters.townId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/towns/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TownViewFromJSON(jsonValue));
    }

    /**
     */
    async getTownDetails(requestParameters: GetTownDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TownView> {
        const response = await this.getTownDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveTownRaw(requestParameters: SaveTownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.townInput === null || requestParameters.townInput === undefined) {
            throw new runtime.RequiredError('townInput','Required parameter requestParameters.townInput was null or undefined when calling saveTown.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/towns`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TownInputToJSON(requestParameters.townInput),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async saveTown(requestParameters: SaveTownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.saveTownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchTownRaw(requestParameters: SearchTownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TownView>>> {
        if (requestParameters.townSearchInput === null || requestParameters.townSearchInput === undefined) {
            throw new runtime.RequiredError('townSearchInput','Required parameter requestParameters.townSearchInput was null or undefined when calling searchTown.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/towns/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TownSearchInputToJSON(requestParameters.townSearchInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TownViewFromJSON));
    }

    /**
     */
    async searchTown(requestParameters: SearchTownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TownView>> {
        const response = await this.searchTownRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
