import Roller from "../common/roller/Roller";
import {useState} from "react";

export const InitTab = () => {
    const [showRealised, setShowRealised] = useState(false);
    return <>
        <h1>Start</h1>
        <p>Witaj w MZK Plannerze!</p>
        <Roller title={'Zrealizowane'} open={showRealised} onChangeVisibility={() => setShowRealised(s => !s)}>
            <h3>Zrealizowane</h3>
            <ol>
                <li>dodanie nowego odcinka na trasie/czasu w sekcji/kursu powoduje wrócenie refa do szukania</li>
                <li>ulice: preview, ostatnio użyte, modyfikacja, usuwanie</li>
                <li>strefy: modyfikacja, usuwanie</li>
                <li>brygady: zastępowanie użyć, usuwanie</li>
                <li>rozkłady (grupy, linie): modyfikacja, usuwanie</li>
                <li><s>podgląd odcinków na liście navpoint</s></li>
                <li><s>podgląd odcinków na schemacie linii</s></li>
                <li><s>opcja odcinka: odcinek przez navpoint pośredni</s></li>
                <li><s>podział odcinka na odcinek bazowy oraz informacje o pomiarach</s></li>
                <li><s>platformy: preview, ostatnio użyte</s></li>
                <li><s>stacje: ostatnio użyte</s></li>
                <li><s>warunki składowe - widoczne tylko na głównej liście warunków & ostatnio użyte</s></li>
                <li><s>pojemność peronów</s></li>
                <li><s>opcjonalne ulice dla stacji</s></li>
                <li><s>modyfikacja navpoint</s></li>
                <li><s>wyszukiwanie składowych odcinków zamiast jednego</s></li>
                <li><s>optymalizacja procesu wyszukiwania spełnionych warunków i obliczania wartości zmiennej</s></li>
                <li><s>sprawdzanie warunku dla parentNavPoint</s></li>
                <li><s>stacje postojowe</s></li>
                <li><s>stacje wyjazdowe</s></li>
                <li><s>navpoint - przypisywanie warunków</s></li>
                <li><s>tworzenie kursów</s></li>
                <li><s>brygady</s></li>
                <li><s>brygady osobowe - przypisywanie kursów konkretnym pracownikom</s></li>
                <li><s>zmienne środowiskowe - przypisywanie rozkładom</s></li>
                <li><s>odwrócone odcinki</s></li>
                <li><s>zmienne środowiskowe - dodawanie nowych</s></li>
                <li><s>podgląd czasów na linii w zależności od wybranych odcinków</s></li>
                <li><s>panel odcinków</s></li>
                <li><s>openapi</s></li>
                <li><s>konteneryzacja</s></li>
                <li><s>używać tagu html thead w tabelkach</s></li>
                <li><s>odcinki dwukierunkowe powinny się pojawiać na odwróconym kierunku</s></li>
                <li><s>sugestie kolejnych elementów trasy</s></li>
                <li><s>odświeżanie list po dodaniu nowych elementów</s></li>
            </ol>
        </Roller>
    </>;
}
