import Button from "../../common/input/button/Button";
import {RequestStopInput} from "../../requeststop/RequestStopInput";
import GpsInput from "../../gps/GpsInput";
import {addToast} from "../../common/toast/toastUtil";
import {nullIfBlank} from "../../common/string/stringUtil";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import Close from "../../common/ahreffun/Close";
import ZonePopupSelector from "../../zone/popup/ZonePopupSelector";
import Af from "../../common/ahreffun/Af";
import {assignStationIdsToZone} from "../../zone/ajax/zoneAjax";
import {
    ConditionMetaDataView,
    ConditionVisibilityRuleTypeView,
    NavPointSettings,
    StationEndpointApi,
    StreetSearchView,
    ZoneView
} from "../../../api";
import {useState} from "react";
import {NavPointSettingsRoller} from "../../common/navpoint/NavPointSettingsRoller";
import ConditionPopupSelector from "../../condition/popup/ConditionPopupSelector";
import StreetPopupSelector from "../../street/popup/StreetPopupSelector";

interface AddNewStationFormProps {
    mzkWorkspace: MzkWorkspace
    onClose: (refresh: boolean) => void;
}

export function AddNewStationForm(props: AddNewStationFormProps) {
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [technicalName, setTechnicalName] = useState('');

    const [requestStop, setRequestStop] = useState<boolean>(null);
    const [street, setStreet] = useState<StreetSearchView>(null);
    const [showStreetForm, setShowStreetForm] = useState(false);
    const [gps, setGps] = useState<string[]>(['', '']);
    const [zone, setZone] = useState<ZoneView>(null);
    const [showZonePopup, setShowZonePopup] = useState(false);
    const [settings, setSettings] = useState<NavPointSettings>({
        passengersFrom: true, passengersTo: true, depot: false, technicalStop: false
    });
    const [showSettingsRoller, setShowSettingsRoller] = useState(false);
    const [showConditionSelector, setShowConditionSelector] = useState(false);
    const [condition, setCondition] = useState<ConditionMetaDataView>()
    const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (verifyForm()) {
            new StationEndpointApi()
                .saveStation(
                    {
                        stationInputData: {
                            navPointMetaData: {
                                name: name,
                                shortName: nullIfBlank(shortName),
                                technicalName: nullIfBlank(technicalName),
                                companyId: props.mzkWorkspace.company.id,
                                dataSpaceId: props.mzkWorkspace.space.id,
                                parentId: null,
                                conditionId: condition ? condition.id : null,
                                streetId: street?.street.id,
                                gpsX: parseFloat(gps[0]),
                                gpsY: parseFloat(gps[1]),
                                settings: settings
                            },
                            requestStop: requestStop,
                        },
                    },
                    {headers: prepareSecurityHeaders()}
                )
                .then((r) => {
                    if (zone) {
                        assignStationIdsToZone(
                            [r],
                            props.mzkWorkspace.company,
                            zone,
                            getErrorMapForAssigningZone(),
                            () => {
                                addToast("Pomyślnie dodano nową stację z przypisaną strefą");
                                props.onClose(true);
                            }
                        );
                    } else {
                        addToast("Pomyślnie dodano nową stację");
                        props.onClose(true);
                    }
                })
                .catch((e) => {
                    verifyClientResponse(e, getErrorMapForAddingStation());
                });
        }
    }
    const verifyForm = () => {
        if (nullIfBlank(name) == null) {
            addToast("Nazwa stacji nie może być pusta");
            return false;
        }
        const gpsX = gps[0];
        const gpsY = gps[1];
        if (
            (gpsX.length > 0 && gpsY.length === 0) ||
            (gpsX.length === 0 && gpsY.length > 0)
        ) {
            addToast("Wypełnij oba pola GPS");
            return false;
        } else if (
            gpsX.length > 0 &&
            gpsY.length > 0 &&
            (isNaN(parseFloat(gpsX)) || isNaN(parseFloat(gpsY)))
        ) {
            addToast("Wypełnij oba pola GPS poprawnie");
            return false;
        }
        return true;
    }

    return <div className="diffArea">
        <div>
      <span className="windowButton">
        <Close onClick={() => props.onClose(true)}/>
      </span>
        </div>
        <h3>Dodawanie nowej stacji {name}</h3>
        <form>
            <label>Nazwa*:</label>
            <input
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={50}
            />
            <br/>
            <label>Nazwa skrócona:</label>
            <input
                name="shortName"
                value={shortName}
                onChange={(e) => setShortName(e.target.value)}
                maxLength={50}
            />
            <br/>
            <label>Nazwa techniczna:</label>
            <input
                name="technicalName"
                value={technicalName}
                onChange={(e) => setTechnicalName(e.target.value)}
                maxLength={50}
            />
            <br/>
            <span>Warunek: </span>
            {condition ? <>
                {condition.name}
                <Af onClick={() => setCondition(null)}>Usuń warunek</Af>
            </> : 'nie ustawiono'}
            <Af onClick={() => setShowConditionSelector(true)}>Warunek</Af>
            {showConditionSelector &&
                <ConditionPopupSelector
                    company={props.mzkWorkspace.company}
                    visibilityRule={ConditionVisibilityRuleTypeView.NavPoint}
                    closePopup={() => setShowConditionSelector(false)}
                    selectConditionFun={(c) => setCondition(c)}
                />}
            <br/>
            <label>Ulica:</label>
            {street ?
                <span> {`${street.street.name}(${street.town.name})`} </span> :
                <span> nie wybrano ulicy </span>}
            <Af onClick={() => setShowStreetForm(true)}>Wybór ulicy</Af>
            {street && <Af onClick={() => setStreet(null)}>Usuń ulicę</Af>}
            {showStreetForm &&
                <StreetPopupSelector closePopup={() => setShowStreetForm(s => !s)}
                                     mzkWorkspace={props.mzkWorkspace}
                                     onSelectStreet={(s: StreetSearchView) => {
                                         setStreet(s);
                                         setShowStreetForm(false);
                                     }}/>}
            <br/>
            <label>Strefa: </label>
            {zone ? <span>{zone.name}</span> : <>
                <span>nie wybrano strefy</span>
                <Af onClick={() => setShowZonePopup(true)}>Wybór strefy</Af>
            </>}
            {showZonePopup && <ZonePopupSelector
                mzkWorkspace={props.mzkWorkspace}
                closePopup={() => setShowZonePopup(false)}
                onSelectZone={(z: ZoneView) => {
                    setZone(z);
                    setShowZonePopup(false);
                }}
            />}
            {zone && <Af onClick={() => {
                setZone(null)
            }}>Usuń strefę</Af>}
            <br/>
            <RequestStopInput value={requestStop} onChange={(v) => setRequestStop(v)}/>
            <br/>
            <GpsInput value={gps} onChange={(v) => setGps(v)}/>
            <br/>
            <NavPointSettingsRoller onChangeVisibility={() => setShowSettingsRoller(s => !s)}
                                    isOpen={showSettingsRoller}
                                    settings={settings} onChangeSettings={(s) => setSettings(s)}/>
            <Button onClick={onSubmit}>Dodaj</Button>
        </form>
    </div>;
}


const getErrorMapForAssigningZone = () => {
    const map = new Map();
    map.set("not_exist", (r: any) => {
        if (r.data.name === "station") {
            addToast("Stacja nie istnieje");
        } else if (r.data.name === "zone") {
            addToast("Strefa nie istnieje. Odśwież stronę");
        }
    });
    return map;
}
const getErrorMapForAddingStation = () => {
    const map = new Map();
    map.set("uniqueness", (r: any) => {
        if (r.data.name === "technicalName") {
            addToast("Nazwa techniczna nie jest unikalna");
        } else if (r.data.name === "name") {
            addToast("Nazwa nie jest unikalna");
        }
    });
    return map;
}
