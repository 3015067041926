import {ReactNode, useState} from "react";
import StreetTabSectionStreet from "./StreetTabSectionStreet";
import StreetTabSectionTown from "./StreetTabSectionTown";
import SpaceForCurrentCompanySwitcher from "../../space/switch/SpaceForCurrentCompanySwitcher";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {TownView} from "../../../api";
import StreetSearchTypeSwitcher from "./StreetSearchTypeSwitcher";

type StreetTabSearchType =
    { type: 'street', townToSearch: TownView } |
    { type: 'town' };

export function StreetTab() {
    const [mzkWorkspace, setMzkWorkspace] = useState<MzkWorkspace>();
    const [searchType, setSearchType] = useState<StreetTabSearchType>({type: "street", townToSearch: null});
    const showStreetTableForTownFun = (t: TownView) => setSearchType({type: 'street', townToSearch: t});
    const prepareMainContent = (): ReactNode => {
        return searchType.type === 'street' ?
            <StreetTabSectionStreet mzkWorkspace={mzkWorkspace} townForStreetSearch={searchType.townToSearch}
                                    showStreetTableForTownFun={showStreetTableForTownFun}/> :
            <StreetTabSectionTown mzkWorkspace={mzkWorkspace} showStreetTableForTownFun={showStreetTableForTownFun}/>;
    }
    return (
        <>
            <SpaceForCurrentCompanySwitcher mzkWorkspace={mzkWorkspace}
                                            onChangeCompanyAndSpaceFun={(c, s) => setMzkWorkspace({company: c, space: s})}/>
            <br/>
            <StreetSearchTypeSwitcher searchStreet={searchType.type === 'street'}
                                      onChangeSearchType={() => setSearchType(searchType.type === "street" ? {type: 'town'} : {type: 'street', townToSearch: null})}/>
            {mzkWorkspace && prepareMainContent()}
        </>
    );
}
