import Button from "../../common/input/button/Button";
import Checkbox from "../../common/input/checkbox/Checkbox";
import {Company} from "../../user/current/CurrentUserContext";
import {ZoneView} from "../../../api";

interface ZoneAssigningZonesTableCriteriaProps {
    zones: ZoneView[];
    selectedZone: ZoneView | null;
    onChangeZone: (zone: ZoneView) => void;
    showOnlyStationWithoutZones: boolean;
    onChangeShowOnlyStationWithoutZones: (value: boolean) => void;
    assignAllStationWithoutZone: () => void;
    company: Company;
    query: string;
    onQueryChanged: (query: string) => void;
}

const ZoneAssigningZonesTableCriteria = (props: ZoneAssigningZonesTableCriteriaProps) => {
    return (
        <div>
            <form>
                <label>Nazwa*:</label>
                <input onChange={(e) => props.onQueryChanged(e.target.value)} value={props.query} placeholder="nazwa"/>
                <Checkbox value={props.showOnlyStationWithoutZones}
                          onChange={props.onChangeShowOnlyStationWithoutZones}>Tylko stacje bez ustalonej
                    strefy</Checkbox>
            </form>
            <span>Wybierz strefę do przypisania:</span>
            {props.zones.map(z => <Button
                backgroundColor={props.selectedZone && props.selectedZone.id === z.id ? 'Secondary' : null}
                onClick={() => props.onChangeZone(z)} key={z.id}>{z.name}</Button>)}
            <br/>
            {props.selectedZone && props.company.superUser &&
                <Button onClick={props.assignAllStationWithoutZone}>Przypisz bieżącą strefę widocznym stacją z
                    brakującymi strefami</Button>}
        </div>
    );
}
export default ZoneAssigningZonesTableCriteria;
