import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {NavPointStationView, StationEndpointApi} from "../../../api";

export function initStationSearch(query: string, spaceIds: Array<string>, company: Company,
                                  showOnlyStationsWithoutZone: boolean,
                                  callback: (a0: NavPointStationView[]) => void) {
    new StationEndpointApi().initSearchStations({
            initSearchStationRequest: {
                query: query,
                spaceIds: spaceIds,
                companyId: company.id,
                showOnlyStationsWithoutZone: showOnlyStationsWithoutZone
            }
        },
        {headers: prepareSecurityHeaders()}
    )
        .then(r => callback(r))
        .catch(e => verifyClientResponse(e));
}
