/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StructuredNavPoints } from './StructuredNavPoints';
import {
    StructuredNavPointsFromJSON,
    StructuredNavPointsFromJSONTyped,
    StructuredNavPointsToJSON,
} from './StructuredNavPoints';

/**
 * 
 * @export
 * @interface StructuredNavPointsPreviewResult
 */
export interface StructuredNavPointsPreviewResult {
    /**
     * 
     * @type {boolean}
     * @memberof StructuredNavPointsPreviewResult
     */
    appliedLimit?: boolean;
    /**
     * 
     * @type {Array<StructuredNavPoints>}
     * @memberof StructuredNavPointsPreviewResult
     */
    navPoints?: Array<StructuredNavPoints>;
}

/**
 * Check if a given object implements the StructuredNavPointsPreviewResult interface.
 */
export function instanceOfStructuredNavPointsPreviewResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StructuredNavPointsPreviewResultFromJSON(json: any): StructuredNavPointsPreviewResult {
    return StructuredNavPointsPreviewResultFromJSONTyped(json, false);
}

export function StructuredNavPointsPreviewResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): StructuredNavPointsPreviewResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appliedLimit': !exists(json, 'appliedLimit') ? undefined : json['appliedLimit'],
        'navPoints': !exists(json, 'navPoints') ? undefined : ((json['navPoints'] as Array<any>).map(StructuredNavPointsFromJSON)),
    };
}

export function StructuredNavPointsPreviewResultToJSON(value?: StructuredNavPointsPreviewResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appliedLimit': value.appliedLimit,
        'navPoints': value.navPoints === undefined ? undefined : ((value.navPoints as Array<any>).map(StructuredNavPointsToJSON)),
    };
}

