import {TimetableView, VariableSelectionsEndpointApi, VariableSelectionView, VariableTemplateValueView} from "../../../api";
import Af from "../../common/ahreffun/Af";
import {verifyClientResponse} from "../../common/error/resolvers";
import {uxNull} from "../../common/string/stringUtil";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";

interface OverriddenVariableTableRowProps {
    variable: VariableSelectionView;
    index: number;
    onUpdated: ()=>void;
    timetable: TimetableView;
    shouldHaveAction: boolean;
}

export const OverriddenVariableTableRow = (props: OverriddenVariableTableRowProps) => {
    return (
        <tr key={props.variable.template.id}>
            <td>{props.index}</td>
            <td>{props.variable.template.name}</td>
            <td>
                <ul>
                    {props.variable.template.values.map(vv => variableValueLi(vv, props.variable, props.timetable, props.shouldHaveAction, props.onUpdated))}
                </ul>
            </td>
        </tr>
    );
};

function variableValueLi(vv: VariableTemplateValueView, variable: VariableSelectionView, timetable: TimetableView, shouldHaveAction: boolean, onUpdated: () => void) {
    const isSelected = vv.id == variable.currentValueId;
    let action = uxNull();
    if (shouldHaveAction){
        if (isSelected){
            action = actionForSelected(variable, timetable, onUpdated);
        }else{
            action = actionForNotSelected(variable, vv, timetable, onUpdated);
        }
    }
    return <li key={vv.id} className={isSelected ?'diffArea': ''}>
        {isSelected ? <b>{vv.description}</b> : vv.description}
        {vv._default && <span> (domyślna)</span>}
        {action}
    </li>;
}

function actionForSelected(variable: VariableSelectionView, timetable: TimetableView, onUpdated: () => void) {
    return <>
        <span>(bieżący wybór)</span>
        <Af onClick={()=>addSelection(timetable.id, variable.template.id, null, onUpdated)}>Usuń</Af>
    </>;
}

function actionForNotSelected(variable: VariableSelectionView, vv: VariableTemplateValueView, timetable: TimetableView, onUpdated: () => void) {
    return <Af onClick={()=>addSelection(timetable.id, variable.template.id, vv.id, onUpdated)}>Wybierz</Af>;
}

function addSelection(timetableId: string, variableTemplateId: string, valueId: string, callback: ()=>void){
    new VariableSelectionsEndpointApi().saveSelection({createVariableSelectionInput: {
        timetableId: timetableId,
            variableTemplateId: variableTemplateId,
            valueId: valueId
        }}, {headers: prepareSecurityHeaders()})
        .then(()=>callback())
        .catch(e => verifyClientResponse(e));
}
