/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CourseInput,
  CourseSearchInput,
  CourseSearchTimeInput,
  CourseTimeView,
  CourseView,
} from '../models';
import {
    CourseInputFromJSON,
    CourseInputToJSON,
    CourseSearchInputFromJSON,
    CourseSearchInputToJSON,
    CourseSearchTimeInputFromJSON,
    CourseSearchTimeInputToJSON,
    CourseTimeViewFromJSON,
    CourseTimeViewToJSON,
    CourseViewFromJSON,
    CourseViewToJSON,
} from '../models';

export interface CalculateTimesForCourseRequest {
    courseSearchTimeInput?: CourseSearchTimeInput;
}

export interface CreateNewCourseRequest {
    courseInput?: CourseInput;
}

export interface DeleteCourseRequest {
    courseId: string;
}

export interface SearchCourseInitRequest {
    courseSearchInput?: CourseSearchInput;
}

/**
 * 
 */
export class CoursesEndpointApi extends runtime.BaseAPI {

    /**
     */
    async calculateTimesForCourseRaw(requestParameters: CalculateTimesForCourseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CourseTimeView>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/courses/times`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CourseSearchTimeInputToJSON(requestParameters.courseSearchTimeInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CourseTimeViewFromJSON));
    }

    /**
     */
    async calculateTimesForCourse(requestParameters: CalculateTimesForCourseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CourseTimeView>> {
        const response = await this.calculateTimesForCourseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createNewCourseRaw(requestParameters: CreateNewCourseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/courses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CourseInputToJSON(requestParameters.courseInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createNewCourse(requestParameters: CreateNewCourseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNewCourseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteCourseRaw(requestParameters: DeleteCourseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.courseId === null || requestParameters.courseId === undefined) {
            throw new runtime.RequiredError('courseId','Required parameter requestParameters.courseId was null or undefined when calling deleteCourse.');
        }

        const queryParameters: any = {};

        if (requestParameters.courseId !== undefined) {
            queryParameters['courseId'] = requestParameters.courseId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/courses`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCourse(requestParameters: DeleteCourseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCourseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async searchCourseInitRaw(requestParameters: SearchCourseInitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CourseView>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/courses/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CourseSearchInputToJSON(requestParameters.courseSearchInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CourseViewFromJSON));
    }

    /**
     */
    async searchCourseInit(requestParameters: SearchCourseInitRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CourseView>> {
        const response = await this.searchCourseInitRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
