/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdoptedNavPoint,
  LineSchemaPlanInput,
  LineSchemaPlanValidationInput,
  LineSchemaPlanValidationResult,
  LineSchemaPlanView,
} from '../models';
import {
    AdoptedNavPointFromJSON,
    AdoptedNavPointToJSON,
    LineSchemaPlanInputFromJSON,
    LineSchemaPlanInputToJSON,
    LineSchemaPlanValidationInputFromJSON,
    LineSchemaPlanValidationInputToJSON,
    LineSchemaPlanValidationResultFromJSON,
    LineSchemaPlanValidationResultToJSON,
    LineSchemaPlanViewFromJSON,
    LineSchemaPlanViewToJSON,
} from '../models';

export interface FindNavPointRecommendationsRequest {
    lastNavPointId: string;
    companyId: string;
    limit: number;
}

export interface FindSchemaPlanRequest {
    lineId: string;
}

export interface SaveLineSchemaPlanRequest {
    lineSchemaPlanInput: LineSchemaPlanInput;
}

export interface ValidatePlanRequest {
    lineSchemaPlanValidationInput?: LineSchemaPlanValidationInput;
}

/**
 * 
 */
export class LineSchemaPlanEndpointApi extends runtime.BaseAPI {

    /**
     */
    async findNavPointRecommendationsRaw(requestParameters: FindNavPointRecommendationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdoptedNavPoint>>> {
        if (requestParameters.lastNavPointId === null || requestParameters.lastNavPointId === undefined) {
            throw new runtime.RequiredError('lastNavPointId','Required parameter requestParameters.lastNavPointId was null or undefined when calling findNavPointRecommendations.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling findNavPointRecommendations.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling findNavPointRecommendations.');
        }

        const queryParameters: any = {};

        if (requestParameters.lastNavPointId !== undefined) {
            queryParameters['lastNavPointId'] = requestParameters.lastNavPointId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lines/schemas/plans/navPoints/recommendations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdoptedNavPointFromJSON));
    }

    /**
     */
    async findNavPointRecommendations(requestParameters: FindNavPointRecommendationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdoptedNavPoint>> {
        const response = await this.findNavPointRecommendationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findSchemaPlanRaw(requestParameters: FindSchemaPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LineSchemaPlanView>> {
        if (requestParameters.lineId === null || requestParameters.lineId === undefined) {
            throw new runtime.RequiredError('lineId','Required parameter requestParameters.lineId was null or undefined when calling findSchemaPlan.');
        }

        const queryParameters: any = {};

        if (requestParameters.lineId !== undefined) {
            queryParameters['lineId'] = requestParameters.lineId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lines/schemas/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LineSchemaPlanViewFromJSON(jsonValue));
    }

    /**
     */
    async findSchemaPlan(requestParameters: FindSchemaPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LineSchemaPlanView> {
        const response = await this.findSchemaPlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveLineSchemaPlanRaw(requestParameters: SaveLineSchemaPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.lineSchemaPlanInput === null || requestParameters.lineSchemaPlanInput === undefined) {
            throw new runtime.RequiredError('lineSchemaPlanInput','Required parameter requestParameters.lineSchemaPlanInput was null or undefined when calling saveLineSchemaPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lines/schemas/plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LineSchemaPlanInputToJSON(requestParameters.lineSchemaPlanInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveLineSchemaPlan(requestParameters: SaveLineSchemaPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveLineSchemaPlanRaw(requestParameters, initOverrides);
    }

    /**
     */
    async validatePlanRaw(requestParameters: ValidatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LineSchemaPlanValidationResult>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lines/schemas/plans/validation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LineSchemaPlanValidationInputToJSON(requestParameters.lineSchemaPlanValidationInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LineSchemaPlanValidationResultFromJSON));
    }

    /**
     */
    async validatePlan(requestParameters: ValidatePlanRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LineSchemaPlanValidationResult>> {
        const response = await this.validatePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
