/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdoptedNavPoint } from './AdoptedNavPoint';
import {
    AdoptedNavPointFromJSON,
    AdoptedNavPointFromJSONTyped,
    AdoptedNavPointToJSON,
} from './AdoptedNavPoint';

/**
 * 
 * @export
 * @interface LineSchemaPlanPartView
 */
export interface LineSchemaPlanPartView {
    /**
     * 
     * @type {string}
     * @memberof LineSchemaPlanPartView
     */
    id: string;
    /**
     * 
     * @type {AdoptedNavPoint}
     * @memberof LineSchemaPlanPartView
     */
    adoptedNavPoint: AdoptedNavPoint;
}

/**
 * Check if a given object implements the LineSchemaPlanPartView interface.
 */
export function instanceOfLineSchemaPlanPartView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "adoptedNavPoint" in value;

    return isInstance;
}

export function LineSchemaPlanPartViewFromJSON(json: any): LineSchemaPlanPartView {
    return LineSchemaPlanPartViewFromJSONTyped(json, false);
}

export function LineSchemaPlanPartViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineSchemaPlanPartView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'adoptedNavPoint': AdoptedNavPointFromJSON(json['adoptedNavPoint']),
    };
}

export function LineSchemaPlanPartViewToJSON(value?: LineSchemaPlanPartView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'adoptedNavPoint': AdoptedNavPointToJSON(value.adoptedNavPoint),
    };
}

