import * as React from "react";

export function nullIfBlank(string: string){
    if(string == null){
        return null;
    }else if(string.length === 0){
        return null;
    }else{
        return string;
    }
}

export function uxNull(nullable: React.ReactNode = null){
    if (nullable == null){
        return '-';
    }else{
        return nullable;
    }
}

export function stringCompare(str1: string, str2: string){
    if (str1 < str2) {
        return -1;
    } else if (str1 > str2) {
        return 1;
    } else {
        return 0;
    }
}
