/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LineSchemaPlanValidationInputSample
 */
export interface LineSchemaPlanValidationInputSample {
    /**
     * 
     * @type {string}
     * @memberof LineSchemaPlanValidationInputSample
     */
    sampleId?: string;
    /**
     * 
     * @type {string}
     * @memberof LineSchemaPlanValidationInputSample
     */
    navPointId?: string;
    /**
     * 
     * @type {string}
     * @memberof LineSchemaPlanValidationInputSample
     */
    nextNavPointId?: string;
}

/**
 * Check if a given object implements the LineSchemaPlanValidationInputSample interface.
 */
export function instanceOfLineSchemaPlanValidationInputSample(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LineSchemaPlanValidationInputSampleFromJSON(json: any): LineSchemaPlanValidationInputSample {
    return LineSchemaPlanValidationInputSampleFromJSONTyped(json, false);
}

export function LineSchemaPlanValidationInputSampleFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineSchemaPlanValidationInputSample {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sampleId': !exists(json, 'sampleId') ? undefined : json['sampleId'],
        'navPointId': !exists(json, 'navPointId') ? undefined : json['navPointId'],
        'nextNavPointId': !exists(json, 'nextNavPointId') ? undefined : json['nextNavPointId'],
    };
}

export function LineSchemaPlanValidationInputSampleToJSON(value?: LineSchemaPlanValidationInputSample | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sampleId': value.sampleId,
        'navPointId': value.navPointId,
        'nextNavPointId': value.nextNavPointId,
    };
}

