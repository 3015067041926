import NavbarLogo from "../navbar/NavbarLogo";
import LoginForm from "../login/LoginForm";

export function UserNotLoggedApp() {
    return <>
        <NavbarLogo/>
        <h3>Zaloguj się aby kontynuować</h3>
        <LoginForm/>
    </>;
}
