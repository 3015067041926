/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LineSchemaMetaDataView
 */
export interface LineSchemaMetaDataView {
    /**
     * 
     * @type {string}
     * @memberof LineSchemaMetaDataView
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LineSchemaMetaDataView
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LineSchemaMetaDataView
     */
    technicalName?: string;
    /**
     * 
     * @type {string}
     * @memberof LineSchemaMetaDataView
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof LineSchemaMetaDataView
     */
    technicalDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof LineSchemaMetaDataView
     */
    groupId?: string;
    /**
     * 
     * @type {string}
     * @memberof LineSchemaMetaDataView
     */
    timeTableId?: string;
}

/**
 * Check if a given object implements the LineSchemaMetaDataView interface.
 */
export function instanceOfLineSchemaMetaDataView(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LineSchemaMetaDataViewFromJSON(json: any): LineSchemaMetaDataView {
    return LineSchemaMetaDataViewFromJSONTyped(json, false);
}

export function LineSchemaMetaDataViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineSchemaMetaDataView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'technicalName': !exists(json, 'technicalName') ? undefined : json['technicalName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'technicalDescription': !exists(json, 'technicalDescription') ? undefined : json['technicalDescription'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'timeTableId': !exists(json, 'timeTableId') ? undefined : json['timeTableId'],
    };
}

export function LineSchemaMetaDataViewToJSON(value?: LineSchemaMetaDataView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'technicalName': value.technicalName,
        'description': value.description,
        'technicalDescription': value.technicalDescription,
        'groupId': value.groupId,
        'timeTableId': value.timeTableId,
    };
}

