import {useContext} from 'react';
import SectionSearchTab from "../search/SectionSearchTab";
import {CurrentUserContext} from "../../CurrentUserContext";

const SectionTab = () => {
    const currentUser = useContext(CurrentUserContext);
    const company = currentUser.user.defaultCompany;
    return <SectionSearchTab company={company}/>;
}

export default SectionTab;
