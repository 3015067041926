/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateSectionInput,
  SectionSearchInput,
  SectionSearchResult,
  SectionTimeView,
  UpdateSectionInput,
} from '../models';
import {
    CreateSectionInputFromJSON,
    CreateSectionInputToJSON,
    SectionSearchInputFromJSON,
    SectionSearchInputToJSON,
    SectionSearchResultFromJSON,
    SectionSearchResultToJSON,
    SectionTimeViewFromJSON,
    SectionTimeViewToJSON,
    UpdateSectionInputFromJSON,
    UpdateSectionInputToJSON,
} from '../models';

export interface GetSectionTimesRequest {
    sectionId: string;
}

export interface InitSearchSectionsRequest {
    sectionSearchInput: SectionSearchInput;
}

export interface RemoveSectionRequest {
    sectionId: string;
}

export interface SaveSectionRequest {
    createSectionInput?: CreateSectionInput;
}

export interface UpdateSectionRequest {
    updateSectionInput?: UpdateSectionInput;
}

/**
 * 
 */
export class SectionEndpointApi extends runtime.BaseAPI {

    /**
     */
    async getSectionTimesRaw(requestParameters: GetSectionTimesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SectionTimeView>>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling getSectionTimes.');
        }

        const queryParameters: any = {};

        if (requestParameters.sectionId !== undefined) {
            queryParameters['sectionId'] = requestParameters.sectionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sections/times`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SectionTimeViewFromJSON));
    }

    /**
     */
    async getSectionTimes(requestParameters: GetSectionTimesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SectionTimeView>> {
        const response = await this.getSectionTimesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async initSearchSectionsRaw(requestParameters: InitSearchSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionSearchResult>> {
        if (requestParameters.sectionSearchInput === null || requestParameters.sectionSearchInput === undefined) {
            throw new runtime.RequiredError('sectionSearchInput','Required parameter requestParameters.sectionSearchInput was null or undefined when calling initSearchSections.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sections/searches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SectionSearchInputToJSON(requestParameters.sectionSearchInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionSearchResultFromJSON(jsonValue));
    }

    /**
     */
    async initSearchSections(requestParameters: InitSearchSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionSearchResult> {
        const response = await this.initSearchSectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeSectionRaw(requestParameters: RemoveSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sectionId === null || requestParameters.sectionId === undefined) {
            throw new runtime.RequiredError('sectionId','Required parameter requestParameters.sectionId was null or undefined when calling removeSection.');
        }

        const queryParameters: any = {};

        if (requestParameters.sectionId !== undefined) {
            queryParameters['sectionId'] = requestParameters.sectionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/sections`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeSection(requestParameters: RemoveSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeSectionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async saveSectionRaw(requestParameters: SaveSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSectionInputToJSON(requestParameters.createSectionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveSection(requestParameters: SaveSectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveSectionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateSectionRaw(requestParameters: UpdateSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sections`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSectionInputToJSON(requestParameters.updateSectionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateSection(requestParameters: UpdateSectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSectionRaw(requestParameters, initOverrides);
    }

}
