import {useContext, useEffect, useState} from "react";
import {CurrentUserContext} from "../../CurrentUserContext";
import {
    AsyncTaskEndpointApi,
    AsyncTaskView,
    AsyncTaskViewTaskStatusEnum,
    AsyncTaskViewTaskTypeEnum
} from "../../../api";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {uxNull} from "../../common/string/stringUtil";
import {sortByPropertyValue} from "../../common/sort/sortUtil";

interface AsyncTaskTabProps {

}

export function AsyncTaskTab(props: AsyncTaskTabProps) {
    const currentUser = useContext(CurrentUserContext);
    const [asyncTasks, setAsyncTasks] = useState<Array<AsyncTaskView>>()
    useEffect(() => {
        const intervalId = setInterval(() => {
            new AsyncTaskEndpointApi()
                .initSearchAsyncTask({
                    asyncTaskSearchInput: {companyId: currentUser.user.defaultCompany.id},
                }, {headers: prepareSecurityHeaders()})
                .then(at => setAsyncTasks(at))
                .catch(e => {
                    verifyClientResponse(e)
                });
        }, 5000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    return <>
        <h1>Zadania asynchroniczne</h1>
        {asyncTasks ? <table>
            <thead>
            <tr className='tableHeader'>
                <th>Lp</th>
                <th>Typ</th>
                <th>Status</th>
                <th>Progres</th>
                <th>Błąd</th>
            </tr>
            </thead>
            <tbody>
            {asyncTasks.sort(sortByPropertyValue('addTime')).map((as, index) => <tr key={as.id}>
                <td>{index}</td>
                <td>{getTaskTypeDescription(as.taskType)}</td>
                <td>{getTaskStatusDescription(as.taskStatus)}</td>
                <td>{prepareProgressionRow(as)}</td>
                <td>{uxNull(as.errorDescription)}</td>
            </tr>)}
            </tbody>
        </table> : <p>Ładowanie danych...</p>}

        Strona odświeża się co 5 sekund.
    </>;
}

function prepareProgressionRow(as: AsyncTaskView) {
    if (AsyncTaskViewTaskStatusEnum.Processing == as.taskStatus) {
        if (as.progression) {
            return <>
                {as.progression.description && <>{as.progression.description}<br/></>}
                <progress value={as.progression.current} max={as.progression.maxAmount}/>
            </>;
        } else {
            return <progress/>;
        }
    } else {
        return uxNull();
    }
}
function getTaskStatusDescription(status: AsyncTaskViewTaskStatusEnum){
    switch (status){
        case "READY":
            return 'Oczekujący';
        case "PROCESSING":
            return 'W toku';
        case "FINISHED":
            return 'Zakończony';
        case "ERROR":
            return 'Błąd';
    }
}
function getTaskTypeDescription(type: AsyncTaskViewTaskTypeEnum){
    switch (type){
        case "TODO":
            return 'Testowy';
        default:
            return type;
    }
}
