import ZoneTable from "../table/ZoneTable";
import Af from "../../common/ahreffun/Af";
import Popup from "../../common/popup/Popup";
import {ZoneEndpointApi, ZoneView} from "../../../api";
import {MzkWorkspace, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";

interface ZonePopupSelectorProps {
    closePopup: () => void;
    mzkWorkspace: MzkWorkspace;
    onSelectZone: (z: ZoneView) => void;
}

function ZonePopupSelector(props: ZonePopupSelectorProps) {
    return (
        <Popup closePopup={props.closePopup}>
            <h1>Wybór strefy</h1>
            <ZoneTable mzkWorkspace={props.mzkWorkspace}
                       actionProvider={(z: ZoneView) => <Af onClick={() => {
                           props.onSelectZone(z);
                           new ZoneEndpointApi()
                               .logZoneUsage({usageInput: {id: z.id}},
                                   {headers: prepareSecurityHeaders()})
                               .catch(e => verifyClientResponse(e));
                       }}>Wybierz</Af>}/>
        </Popup>
    );
}

export default ZonePopupSelector;
