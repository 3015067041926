import Af from "./Af";

interface CloseProps {
    onClick: () => void;
}

const Close = (props: CloseProps) => {
    return <Af onClick={props.onClick}>x</Af>;
}

export default Close;
