import {ConditionEndpointApi, ConditionMetaDataView, ConditionVisibilityRuleTypeView} from "../../../api";
import {Company, prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {ReactNode} from "react";
import Tooltip from "../../common/tooltip/Tooltip";
import {verifyClientResponse} from "../../common/error/resolvers";
import Af from "../../common/ahreffun/Af";
import {mapBooleanToDescription} from "../../common/boolean/booleanUtil";

interface TabConditionTableProps {
    conditions: ConditionMetaDataView[];
    company: Company;
    provideActionFun: (c: ConditionMetaDataView) => ReactNode;
    onConditionChange: () => void;
}

export function TabConditionTable(props: TabConditionTableProps) {
    return <table>
        <thead>
        <tr className='tableHeader'>
            <th rowSpan={2}>Lp</th>
            <th rowSpan={2}>Nazwa</th>
            <th colSpan={3}>Widoczność</th>
            <th rowSpan={2}>Akcje</th>
        </tr>
        <tr className='tableHeader'>
            <th><Tooltip content={'odcinki'}>📐</Tooltip></th>
            <th><Tooltip content={'załogi'}>👨‍🍳</Tooltip></th>
            <th><Tooltip content={'navpoint'}>🚉</Tooltip></th>
        </tr>
        </thead>
        <tbody>
        {props.conditions.map((c, i) => <Row key={c.id}
                                             index={i}
                                             condition={c}
                                             company={props.company}
                                             onConditionChange={props.onConditionChange}
                                             provideActionFun={props.provideActionFun}/>)}
        </tbody>
    </table>;
}

interface RowProps {
    index: number;
    condition: ConditionMetaDataView;
    company: Company;
    onConditionChange: () => void;
    provideActionFun: (c: ConditionMetaDataView) => ReactNode;
}

function Row(props: RowProps) {
    const visibleInSection = props.condition.visibilityRule.rules.has(ConditionVisibilityRuleTypeView.Section);
    const visibleInCrew = props.condition.visibilityRule.rules.has(ConditionVisibilityRuleTypeView.Crew);
    const visibleInNavPoint = props.condition.visibilityRule.rules.has(ConditionVisibilityRuleTypeView.NavPoint);
    const editIsPossible = props.condition.companyId && props.company.superUser;
    const onChangeVisibility = (visibilityType: ConditionVisibilityRuleTypeView, value: boolean) => {
        new ConditionEndpointApi().changeVisibilityRule({
                conditionChangeVisibilityInput: {
                    conditionId: props.condition.id,
                    visibilityType: visibilityType,
                    visibilityValue: value
                }
            },
            {headers: prepareSecurityHeaders()})
            .then(() => props.onConditionChange())
            .catch(e => verifyClientResponse(e));
    };
    return <tr>
        <td>{props.index}</td>
        <td>{props.condition.name}</td>
        <td>
            {editIsPossible ?
                <Af onClick={() => onChangeVisibility(ConditionVisibilityRuleTypeView.Section, !visibleInSection)}>
                    {mapBooleanToDescription(visibleInSection)}
                </Af> :
                mapBooleanToDescription(visibleInSection)}
        </td>
        <td>
            {editIsPossible ?
                <Af onClick={() => onChangeVisibility(ConditionVisibilityRuleTypeView.Crew, !visibleInCrew)}>
                    {mapBooleanToDescription(visibleInCrew)}
                </Af> :
                mapBooleanToDescription(visibleInCrew)}
        </td>
        <td>
            {editIsPossible ?
                <Af onClick={() => onChangeVisibility(ConditionVisibilityRuleTypeView.NavPoint, !visibleInNavPoint)}>
                    {mapBooleanToDescription(visibleInNavPoint)}
                </Af> :
                mapBooleanToDescription(visibleInNavPoint)}
        </td>
        <td>{props.provideActionFun(props.condition)}</td>
    </tr>
}
