import {ReactNode, useEffect, useState} from "react";
import {StationSearchTypeSwitcher} from "../list/StationSearchTypeSwitcher";
import {StationTabSectionStation} from "./StationTabSectionStation";
import {StationTabSectionPlatform} from "./StationTabSectionPlatform";
import {getCookie, setCookie} from "../../common/cookie/cookieUtil";
import SpaceForCurrentCompanySwitcher from "../../space/switch/SpaceForCurrentCompanySwitcher";
import {MzkWorkspace} from "../../user/current/CurrentUserContext";
import {NavPointUsagePanel} from "../../navpoint/usage/NavPointUsagePanel";
import {NavPointMetaDataView} from "../../../api";
import {StationDetailsPanel} from "../details/StationDetailsPanel";
import {StationModificationPanel} from "../modification/StationModificationPanel";
import {PlatformDetailsPanel} from "../../platform/details/PlatformDetailsPanel";
import {PlatformModificationPanel} from "../../platform/modification/PlatformModificationPanel";
import {SectionListPanel} from "../../section/list/SectionListPanel";
import {logNavPointUsage} from "../../navpoint/ajax/ajaxUtils";

export enum StationTabContentType {
    STATION,
    PLATFORM
}

type StationTabContent =
    { type: StationTabContentType.STATION }
    | { type: StationTabContentType.PLATFORM, stationForSearch: any };

export function StationTab() {
    const [stationTabContent, setStationTabContent] = useState<StationTabContent>({type: StationTabContentType.STATION});
    const [mzkWorkspace, setMzkWorkspace] = useState<MzkWorkspace>();
    const [query, setQuery] = useState('');
    const [showNewStationForm, setShowNewStationForm] = useState(false);
    const [navPointForUsagePanel, setNavPointForUsagePanel] = useState<NavPointMetaDataView>();
    const [greedyVersion, setGreedyVersion] = useState(0);
    const [navPointIdForStationDetails, setNavPointIdForStationDetails] = useState<string>();
    const [navPointIdForStationModification, setNavPointIdForStationModification] = useState<string>();
    const [navPointIdForPlatformDetails, setNavPointIdForPlatformDetails] = useState<string>();
    const [navPointIdForPlatformModification, setNavPointIdForPlatformModification] = useState<string>();
    const [navPointIdForSectionList, setNavPointIdForSectionList] = useState<string>();
    useEffect(() => {
        if (getCookie('TRAIN_PLATFORM_TAB') === 'PLATFORM') {
            setStationTabContent({type: StationTabContentType.PLATFORM, stationForSearch: null});
        }
    }, []);
    const onChangeSearchType = (newType: StationTabContentType) => {
        if (newType === StationTabContentType.STATION) {
            setStationTabContent({type: StationTabContentType.STATION})
            setCookie('TRAIN_PLATFORM_TAB', 'STATION', 10);
        } else {
            setStationTabContent({type: StationTabContentType.PLATFORM, stationForSearch: null})
            setCookie('TRAIN_PLATFORM_TAB', 'PLATFORM', 10);
        }
    }
    const prepareMainContent = (stationTabContent: StationTabContent): ReactNode => {
        return stationTabContent.type === StationTabContentType.STATION ? (
            <StationTabSectionStation
                mzkWorkspace={mzkWorkspace}
                query={query}
                onChangeQuery={(q: string) => setQuery(q)}
                showNewStationForm={showNewStationForm}
                toggleShowNewStationFormFun={() => setShowNewStationForm(!showNewStationForm)}
                greedyStationVersion={greedyVersion}
                increaseGreedyStationVersion={() => setGreedyVersion(v => v + 1)}
                onShowNavPointUsage={(n) => setNavPointForUsagePanel(n)}
                onChangeNavPointIdForStationDetails={(i) => {
                    setNavPointIdForStationDetails(i);
                    logNavPointUsage(i);
                }}
                onShowSection={n => setNavPointIdForSectionList(n)}
            />
        ) : (
            <StationTabSectionPlatform
                mzkWorkspace={mzkWorkspace}
                query={query}
                onChangeQuery={(q: string) => setQuery(q)}
                showNewStationForm={showNewStationForm}
                toggleShowNewStationFormFun={() => setShowNewStationForm(!showNewStationForm)}
                greedyPlatformVersion={greedyVersion}
                increaseGreedyPlatformVersion={() => setGreedyVersion(v => v + 1)}
                onShowNavPointUsage={(n) => setNavPointForUsagePanel(n)}
                onChangeNavPointIdForStationDetails={(i) => {
                    setNavPointIdForStationDetails(i);
                    logNavPointUsage(i);
                }}
                onChangeNavPointIdForPlatformDetails={i => {
                    setNavPointIdForPlatformDetails(i);
                    logNavPointUsage(i);
                }}
                onShowSection={n => setNavPointIdForSectionList(n)}
            />
        );
    }
    return (
        <>
            <SpaceForCurrentCompanySwitcher
                mzkWorkspace={mzkWorkspace}
                onChangeCompanyAndSpaceFun={(c, s) => setMzkWorkspace({company: c, space: s})}
            />
            <br/>
            <StationSearchTypeSwitcher
                stationTabContentType={stationTabContent.type}
                onChangeSearchType={onChangeSearchType}
            />
            <div id="simpleMainContentSplit">
                {mzkWorkspace && prepareMainContent(stationTabContent)}
                {navPointForUsagePanel &&
                    <NavPointUsagePanel navPoint={navPointForUsagePanel} onClose={(refresh) => {
                        setNavPointForUsagePanel(null);
                        refresh && setGreedyVersion(v => v + 1);
                    }}/>}
                {navPointIdForStationDetails && <StationDetailsPanel stationId={navPointIdForStationDetails}
                                                                     onClose={() => setNavPointIdForStationDetails(null)}
                                                                     onModify={(id) => {
                                                                         setNavPointIdForStationDetails(null);
                                                                         setNavPointIdForStationModification(id);
                                                                     }}/>}
                {navPointIdForStationModification && <StationModificationPanel
                    stationId={navPointIdForStationModification}
                    onClose={(refresh) => {
                        setNavPointIdForStationModification(null);
                        refresh && setGreedyVersion(v => v + 1);
                    }}
                />}
                {navPointIdForPlatformDetails && <PlatformDetailsPanel platformId={navPointIdForPlatformDetails}
                                                                       onClose={() => setNavPointIdForPlatformDetails(null)}
                                                                       onModify={i => {
                                                                           setNavPointIdForPlatformDetails(null);
                                                                           setNavPointIdForPlatformModification(i);
                                                                       }}
                />
                }
                {navPointIdForPlatformModification &&
                    <PlatformModificationPanel
                        platformId={navPointIdForPlatformModification} onClose={refresh => {
                        setNavPointIdForPlatformModification(null);
                        refresh && setGreedyVersion(v => v + 1);
                    }}/>}
                {navPointIdForSectionList && <SectionListPanel navPointId={navPointIdForSectionList}
                                                               onClose={() => setNavPointIdForSectionList(null)}/>}
            </div>
        </>
    );
}
