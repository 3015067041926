/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DayOfMonth } from './DayOfMonth';
import {
    DayOfMonthFromJSON,
    DayOfMonthFromJSONTyped,
    DayOfMonthToJSON,
} from './DayOfMonth';

/**
 * 
 * @export
 * @interface DayOfMonthConditionDetails
 */
export interface DayOfMonthConditionDetails {
    /**
     * 
     * @type {Array<DayOfMonth>}
     * @memberof DayOfMonthConditionDetails
     */
    days?: Array<DayOfMonth>;
}

/**
 * Check if a given object implements the DayOfMonthConditionDetails interface.
 */
export function instanceOfDayOfMonthConditionDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DayOfMonthConditionDetailsFromJSON(json: any): DayOfMonthConditionDetails {
    return DayOfMonthConditionDetailsFromJSONTyped(json, false);
}

export function DayOfMonthConditionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayOfMonthConditionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'days': !exists(json, 'days') ? undefined : ((json['days'] as Array<any>).map(DayOfMonthFromJSON)),
    };
}

export function DayOfMonthConditionDetailsToJSON(value?: DayOfMonthConditionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'days': value.days === undefined ? undefined : ((value.days as Array<any>).map(DayOfMonthToJSON)),
    };
}

