/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditLineSchemaInput
 */
export interface EditLineSchemaInput {
    /**
     * 
     * @type {string}
     * @memberof EditLineSchemaInput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EditLineSchemaInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLineSchemaInput
     */
    technicalName: string;
    /**
     * 
     * @type {string}
     * @memberof EditLineSchemaInput
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EditLineSchemaInput
     */
    technicalDescription?: string;
}

/**
 * Check if a given object implements the EditLineSchemaInput interface.
 */
export function instanceOfEditLineSchemaInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "technicalName" in value;

    return isInstance;
}

export function EditLineSchemaInputFromJSON(json: any): EditLineSchemaInput {
    return EditLineSchemaInputFromJSONTyped(json, false);
}

export function EditLineSchemaInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditLineSchemaInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'technicalName': json['technicalName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'technicalDescription': !exists(json, 'technicalDescription') ? undefined : json['technicalDescription'],
    };
}

export function EditLineSchemaInputToJSON(value?: EditLineSchemaInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'technicalName': value.technicalName,
        'description': value.description,
        'technicalDescription': value.technicalDescription,
    };
}

