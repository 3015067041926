/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NavPointMetaDataModifyInput } from './NavPointMetaDataModifyInput';
import {
    NavPointMetaDataModifyInputFromJSON,
    NavPointMetaDataModifyInputFromJSONTyped,
    NavPointMetaDataModifyInputToJSON,
} from './NavPointMetaDataModifyInput';

/**
 * 
 * @export
 * @interface PlatformModifyInputData
 */
export interface PlatformModifyInputData {
    /**
     * 
     * @type {NavPointMetaDataModifyInput}
     * @memberof PlatformModifyInputData
     */
    navPointMetaData: NavPointMetaDataModifyInput;
    /**
     * 
     * @type {boolean}
     * @memberof PlatformModifyInputData
     */
    requestStop?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlatformModifyInputData
     */
    capacity: number;
}

/**
 * Check if a given object implements the PlatformModifyInputData interface.
 */
export function instanceOfPlatformModifyInputData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "navPointMetaData" in value;
    isInstance = isInstance && "capacity" in value;

    return isInstance;
}

export function PlatformModifyInputDataFromJSON(json: any): PlatformModifyInputData {
    return PlatformModifyInputDataFromJSONTyped(json, false);
}

export function PlatformModifyInputDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformModifyInputData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'navPointMetaData': NavPointMetaDataModifyInputFromJSON(json['navPointMetaData']),
        'requestStop': !exists(json, 'requestStop') ? undefined : json['requestStop'],
        'capacity': json['capacity'],
    };
}

export function PlatformModifyInputDataToJSON(value?: PlatformModifyInputData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'navPointMetaData': NavPointMetaDataModifyInputToJSON(value.navPointMetaData),
        'requestStop': value.requestStop,
        'capacity': value.capacity,
    };
}

