export function secondsToFrontDuration(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    let result = '';
    if (hours > 0) {
        result += hours + ' godz. ';
    }
    if (minutes > 0) {
        result += minutes + ' min. ';
    }
    if (seconds > 0) {
        result += seconds + ' sek. ';
    }
    return result;
}

export function timeStringAsDate(time: string): Date {
    return new Date(`1970-01-01T${time}`);
}

export function minutesFromNearestHours(secondsFromMidnight: number) {
    // Oblicz pełną godzinę najbliższą do ilości sekund
    const nearestHour = Math.floor(secondsFromMidnight / 3600) * 3600;

// Oblicz ilość minut od pełnej godziny
    return Math.floor((secondsFromMidnight - nearestHour) / 60);
}

export function secondsToTime(seconds: number): string {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedDays = days > 0 ? `(+${days})` : '';
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${formattedDays}`;
}
