import Close from "../../../common/ahreffun/Close";
import {ChangeEvent, useContext, useState} from "react";
import {
    CREW_GROUP_OPEN_SELECTOR_DESCRIPTION,
    CREW_GROUP_OPEN_SELECTOR_NAMES,
    CREW_GROUP_SELECTOR_DESCRIPTION,
    CREW_GROUP_SELECTOR_NAMES
} from "../../crewUtil";
import {
    ConditionMetaDataView,
    ConditionVisibilityRuleTypeView,
    CrewEndpointApi,
    CrewGroupMetaDataViewSelectorTypeEnum
} from "../../../../api";
import Af from "../../../common/ahreffun/Af";
import ConditionPopupSelector from "../../../condition/popup/ConditionPopupSelector";
import {CurrentUserContext} from "../../../CurrentUserContext";
import DateInput from "../../../common/input/date/DateInput";
import {addToast} from "../../../common/toast/toastUtil";
import Button from "../../../common/input/button/Button";
import {stringToDate} from "../../../common/date/dateUtil";
import {prepareSecurityHeaders} from "../../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../../common/error/resolvers";

interface NewCrewGroupPanelProps {
    onClose: (refresh: boolean) => void;
}

type NewCrewGroupPanelSelector =
    | { type: 'NONE', apiEnum: CrewGroupMetaDataViewSelectorTypeEnum }
    | { type: 'CONDITION', condition: ConditionMetaDataView, apiEnum: CrewGroupMetaDataViewSelectorTypeEnum }
    | { type: 'DATE', dates: string[], apiEnum: CrewGroupMetaDataViewSelectorTypeEnum }
type NewCrewGroupPanelOpenness =
    | { type: 'open', crewAmount: number }
    | { type: 'closed' };

export function NewCrewGroupPanel(props: NewCrewGroupPanelProps) {
    const [name, setName] = useState('');
    const [selector, setSelector] = useState(createConditionCrewGroupSelector());
    const [openness, setOpenness] = useState(createClosedGroupOpenness());
    const currentUser = useContext(CurrentUserContext);
    const onSave = () => {
        if (validate()) {
            new CrewEndpointApi()
                .saveCrewGroups({
                    crewGroupInput: {
                        name: name,
                        selectorConditionId: selector.type == 'CONDITION' ? selector.condition.id : null,
                        selectorDays: selector.type == 'DATE' ? selector.dates.map(d => stringToDate(d)) : null,
                        crewAmount: openness.type == 'open' ? openness.crewAmount : null,
                        companyId: currentUser.user.defaultCompany.id
                    }
                }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Poprawnie dodano nową brygadę');
                    props.onClose(true);
                })
                .catch(e => verifyClientResponse(e));
        }
    }
    const validate = () => {
        if (!name) {
            addToast('Uzupełnij nazwę');
            return false;
        }
        if (selector.type == 'CONDITION') {
            if (selector.condition == null) {
                addToast('Uzupełnij warunek');
                return false;
            }
        } else if (selector.type == 'DATE') {
            if (!selector.dates.length) {
                addToast('Dodaj przynajmniej jedną datę');
                return false;
            }
        }
        if (openness.type == 'open') {
            if (openness.crewAmount < 1) {
                addToast('Ilość członków w brygoadzie musi być większa niż 1');
                return false;
            }
        }
        return true;
    }
    return <div className='diffArea'>
        <div>
            <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
        </div>
        <h3>Nowa grupa brygad</h3>
        <label>Nazwa*:</label>
        <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
               maxLength={50} required={true}/><br/>
        <fieldset>
            <legend>Selektor</legend>
            <NewCrewGroupPanelSelectorSelect currentSelector={selector} onSelectSelector={setSelector}/><br/>
            {CREW_GROUP_SELECTOR_DESCRIPTION.has(selector.type) && <div>{CREW_GROUP_SELECTOR_DESCRIPTION.get(selector.type)}</div>}
            <NewCrewGroupConditionSelectorChanger selector={selector} onChangeSelector={setSelector}/>
            <NewCrewGroupDateSelectorChanger selector={selector} onChangeSelector={setSelector}/>
        </fieldset>
        <fieldset>
            <legend>Otwartość</legend>
            <NewCrewGroupPanelOpennessSelect currentOpenness={openness} onChangeOpenness={setOpenness}/><br/>
            <div>{CREW_GROUP_OPEN_SELECTOR_DESCRIPTION.get(openness.type=='open')}</div>
            <NewCrewGroupPanelOpenOpennessChanger currentOpenness={openness} onChangeOpenness={setOpenness}/>
        </fieldset>
        <Button onClick={onSave}>Dodaj</Button>
    </div>
}

function createNoneCrewGroupSelector(): NewCrewGroupPanelSelector {
    return {type: 'NONE', apiEnum: CrewGroupMetaDataViewSelectorTypeEnum.None};
}
function createConditionCrewGroupSelector(): NewCrewGroupPanelSelector{
    return {type: "CONDITION", apiEnum: CrewGroupMetaDataViewSelectorTypeEnum.Condition, condition: null};
}

function createClosedGroupOpenness(): NewCrewGroupPanelOpenness {
    return {type: 'closed'};
}

interface NewCrewGroupPanelSelectorSelectProps {
    currentSelector: NewCrewGroupPanelSelector;
    onSelectSelector: (selector: NewCrewGroupPanelSelector) => void;
}

function NewCrewGroupPanelSelectorSelect(props: NewCrewGroupPanelSelectorSelectProps) {
    return <select value={props.currentSelector.apiEnum} onChange={e => {
        let selector = createNoneCrewGroupSelector();
        if (e.target.value === CrewGroupMetaDataViewSelectorTypeEnum.Condition) {
            selector = createConditionCrewGroupSelector();
        } else if (e.target.value === CrewGroupMetaDataViewSelectorTypeEnum.Date) {
            selector = {type: 'DATE', dates: [], apiEnum: CrewGroupMetaDataViewSelectorTypeEnum.Date};
        }
        props.onSelectSelector(selector);
    }}>
        <option
            value={CrewGroupMetaDataViewSelectorTypeEnum.None}>{CREW_GROUP_SELECTOR_NAMES.get(CrewGroupMetaDataViewSelectorTypeEnum.None)}</option>
        <option
            value={CrewGroupMetaDataViewSelectorTypeEnum.Condition}>{CREW_GROUP_SELECTOR_NAMES.get(CrewGroupMetaDataViewSelectorTypeEnum.Condition)}</option>
        <option
            value={CrewGroupMetaDataViewSelectorTypeEnum.Date}>{CREW_GROUP_SELECTOR_NAMES.get(CrewGroupMetaDataViewSelectorTypeEnum.Date)}</option>
    </select>;
}

interface NewCrewGroupSelectorChangerProps {
    selector: NewCrewGroupPanelSelector
    onChangeSelector: (s: NewCrewGroupPanelSelector) => void;
}

function NewCrewGroupConditionSelectorChanger(props: NewCrewGroupSelectorChangerProps) {
    const currentUser = useContext(CurrentUserContext);
    const [showConditionPopup, setShowConditionPopup] = useState(false);
    if (props.selector.type != 'CONDITION') {
        return;
    }
    return <>
        <label>Warunek: {props.selector.condition ? props.selector.condition.name : 'nie wybrano'}</label>
        <Af onClick={() => {
            setShowConditionPopup(true)
        }}>Wybór warunku</Af>
        {showConditionPopup && <ConditionPopupSelector company={currentUser.user.defaultCompany}
                                                       visibilityRule={ConditionVisibilityRuleTypeView.Crew}
                                                       closePopup={() => setShowConditionPopup(false)}
                                                       selectConditionFun={(c) => props.onChangeSelector({
                                                           type: "CONDITION",
                                                           apiEnum: CrewGroupMetaDataViewSelectorTypeEnum.Condition,
                                                           condition: c
                                                       })}/>}
    </>;
}

function NewCrewGroupDateSelectorChanger(props: NewCrewGroupSelectorChangerProps) {
    const [addingDate, setAddingDate] = useState('');
    if (props.selector.type != 'DATE') {
        return;
    }
    const dates = props.selector.dates;
    const addDate = (d: string) => {
        props.onChangeSelector({type: 'DATE', apiEnum: CrewGroupMetaDataViewSelectorTypeEnum.Date, dates: [...dates, d]})
    };
    const removeDate = (d: string) => {
        props.onChangeSelector({type: "DATE", apiEnum: CrewGroupMetaDataViewSelectorTypeEnum.Date, dates: dates.filter((da: string) => da != d)})
    };
    return <>
        <label>Wybrane daty:</label><br/>
        {props.selector.dates.map((d) => <Af key={d} onClick={() => removeDate(d)}>{d}</Af>)}<br/>
        <DateInput onChange={setAddingDate} value={addingDate}/><Button onClick={() => {
        if (!addingDate) {
            addToast('Wpisz datę');
        } else if (dates.includes(addingDate)) {
            addToast('Selektor zawiera już wskazaną datę');
        } else {
            addDate(addingDate);
            setAddingDate('');
        }
    }}>Dodaj</Button>
    </>;
}

interface NewCrewGroupPanelOpennessProps {
    currentOpenness: NewCrewGroupPanelOpenness,
    onChangeOpenness: (o: NewCrewGroupPanelOpenness) => void;
}

function NewCrewGroupPanelOpennessSelect(props: NewCrewGroupPanelOpennessProps) {
    return <select value={props.currentOpenness.type} onChange={(e) => {
        if (e.target.value == 'closed') {
            props.onChangeOpenness({type: "closed"});
        } else {
            props.onChangeOpenness({type: 'open', crewAmount: 10});
        }
    }}>
        <option value='closed'>{CREW_GROUP_OPEN_SELECTOR_NAMES.get(false)}</option>
        <option value='open'>{CREW_GROUP_OPEN_SELECTOR_NAMES.get(true)}</option>
    </select>
}

function NewCrewGroupPanelOpenOpennessChanger(props: NewCrewGroupPanelOpennessProps) {
    if (props.currentOpenness.type != 'open') {
        return;
    }
    return <>
        <label>Ilość członków brygady: </label>
        <input type='number' value={props.currentOpenness.crewAmount} step={1} min={1}
               onChange={(v) => {
                   props.onChangeOpenness({type: 'open', crewAmount: Number(v.target.value)});
               }}/>
    </>
}
