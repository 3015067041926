/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CrewGroupInput
 */
export interface CrewGroupInput {
    /**
     * 
     * @type {string}
     * @memberof CrewGroupInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CrewGroupInput
     */
    selectorConditionId?: string;
    /**
     * 
     * @type {Array<Date>}
     * @memberof CrewGroupInput
     */
    selectorDays?: Array<Date>;
    /**
     * 
     * @type {number}
     * @memberof CrewGroupInput
     */
    crewAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CrewGroupInput
     */
    companyId: string;
}

/**
 * Check if a given object implements the CrewGroupInput interface.
 */
export function instanceOfCrewGroupInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyId" in value;

    return isInstance;
}

export function CrewGroupInputFromJSON(json: any): CrewGroupInput {
    return CrewGroupInputFromJSONTyped(json, false);
}

export function CrewGroupInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrewGroupInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'selectorConditionId': !exists(json, 'selectorConditionId') ? undefined : json['selectorConditionId'],
        'selectorDays': !exists(json, 'selectorDays') ? undefined : json['selectorDays'],
        'crewAmount': !exists(json, 'crewAmount') ? undefined : json['crewAmount'],
        'companyId': json['companyId'],
    };
}

export function CrewGroupInputToJSON(value?: CrewGroupInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'selectorConditionId': value.selectorConditionId,
        'selectorDays': value.selectorDays,
        'crewAmount': value.crewAmount,
        'companyId': value.companyId,
    };
}

