/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EditGroupInput,
  GroupInput,
  TimetableGroupView,
} from '../models';
import {
    EditGroupInputFromJSON,
    EditGroupInputToJSON,
    GroupInputFromJSON,
    GroupInputToJSON,
    TimetableGroupViewFromJSON,
    TimetableGroupViewToJSON,
} from '../models';

export interface AddTimetableGroupRequest {
    groupInput: GroupInput;
}

export interface DeleteTimetableGroupRequest {
    groupId: string;
}

export interface FindGroupsForParentRequest {
    timetableId: string;
    groupId?: string;
}

export interface GetTimetableGroupDetailsRequest {
    groupId?: string;
}

export interface UpdateTimetableGroupRequest {
    editGroupInput: EditGroupInput;
}

/**
 * 
 */
export class TimetableGroupEndpointApi extends runtime.BaseAPI {

    /**
     */
    async addTimetableGroupRaw(requestParameters: AddTimetableGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupInput === null || requestParameters.groupInput === undefined) {
            throw new runtime.RequiredError('groupInput','Required parameter requestParameters.groupInput was null or undefined when calling addTimetableGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timetables/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupInputToJSON(requestParameters.groupInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addTimetableGroup(requestParameters: AddTimetableGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addTimetableGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteTimetableGroupRaw(requestParameters: DeleteTimetableGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling deleteTimetableGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/timetables/groups`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTimetableGroup(requestParameters: DeleteTimetableGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTimetableGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findGroupsForParentRaw(requestParameters: FindGroupsForParentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TimetableGroupView>>> {
        if (requestParameters.timetableId === null || requestParameters.timetableId === undefined) {
            throw new runtime.RequiredError('timetableId','Required parameter requestParameters.timetableId was null or undefined when calling findGroupsForParent.');
        }

        const queryParameters: any = {};

        if (requestParameters.timetableId !== undefined) {
            queryParameters['timetableId'] = requestParameters.timetableId;
        }

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/timetables/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimetableGroupViewFromJSON));
    }

    /**
     */
    async findGroupsForParent(requestParameters: FindGroupsForParentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TimetableGroupView>> {
        const response = await this.findGroupsForParentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTimetableGroupDetailsRaw(requestParameters: GetTimetableGroupDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TimetableGroupView>> {
        const queryParameters: any = {};

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/timetables/groups/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TimetableGroupViewFromJSON(jsonValue));
    }

    /**
     */
    async getTimetableGroupDetails(requestParameters: GetTimetableGroupDetailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TimetableGroupView> {
        const response = await this.getTimetableGroupDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTimetableGroupRaw(requestParameters: UpdateTimetableGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.editGroupInput === null || requestParameters.editGroupInput === undefined) {
            throw new runtime.RequiredError('editGroupInput','Required parameter requestParameters.editGroupInput was null or undefined when calling updateTimetableGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/timetables/groups`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditGroupInputToJSON(requestParameters.editGroupInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateTimetableGroup(requestParameters: UpdateTimetableGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTimetableGroupRaw(requestParameters, initOverrides);
    }

}
