import {ReactNode, useEffect, useState} from 'react';
import ConditionTable from "../table/ConditionTable";
import Button from "../../common/input/button/Button";
import NewLogicConditionForm from "../new/NewLogicConditionForm";
import {searchConditions} from "../ajax/ajaxUtils";
import VariablePanel from "../../variable/panel/VariablePanel";
import VariableValuePopup from "../../variable/value/VariableValuePopup";
import NewVariableForm from "../../variable/new/NewVariableForm";
import Af from "../../common/ahreffun/Af";
import {ConditionMetaDataView, ConditionTypeView, StaticConditionDetailsSubtypeEnum, VariableTemplateView} from "../../../api";
import {uxNull} from "../../common/string/stringUtil";
import NewWinterHolidaysConditionForm from "../definition/winterholidays/NewWinterHolidaysConditionForm";
import NewStaticConditionForm from "../definition/static/NewStaticConditionForm";
import NewDayOfWeekConditionForm from "../definition/dayofweek/NewDayOfWeekConditionForm";
import NewDayOfMonthConditionInput from "../definition/dayofmonth/NewDayOfMonthConditionInput";
import {NewVariableConditionForm} from "../definition/variable/NewVariableConditionForm";
import {Company} from "../../user/current/CurrentUserContext";
import NewConditionListPanel from "../new/NewConditionListPanel";
import {TabConditionTable} from "./TabConditionTable";

interface ConditionTabSectionConditionManagementProps {
    company: Company;
}

export type NewConditionPanelInConditionTabType =
    | { type: 'NEW' }
    | { type: 'WINTER_HOLIDAYS' }
    | { type: 'DAY_OF_WEEK' }
    | { type: 'DAY_OF_MONTH' }
    | { type: 'VARIABLE' }
    | { type: 'LOGIC', childrenConditions: ConditionMetaDataView[], subtype: ConditionTypeView }
    | { type: 'STATIC', subtype: StaticConditionDetailsSubtypeEnum };

function ConditionTabSectionConditionManagement(props: ConditionTabSectionConditionManagementProps) {
    const [conditions, setConditions] = useState<ConditionMetaDataView[]>([]);
    const [showVariablePopup, setShowVariablePopup] = useState(false);
    const [variableToViewDetails, setVariableToViewDetails] = useState<VariableTemplateView>();
    const [showNewVariableTemplateForm, setShowNewVariableTemplateForm] = useState(false);
    const [newConditionPanelType, setNewConditionPanelType] = useState<NewConditionPanelInConditionTabType>();
    const [greedyConditionVersion, setGreedyConditionVersion] = useState(0);
    useEffect(() => {
        refreshConditions();
    }, [greedyConditionVersion]);
    const removeSelectedLogicalCondition = (index: number): void => {
        setNewConditionPanelType((t: NewConditionPanelInConditionTabType) => {
            if (t.type === 'LOGIC') {
                const c = [...t.childrenConditions];
                c.splice(index, 1);
                return {...t, childrenConditions: c};
            }
            return t;
        });
    };
    const selectLogicalConditionInNewConditionForm = (subtype: ConditionTypeView, condition: ConditionMetaDataView): void => {
        setNewConditionPanelType(t => {
            if (t && t.type === 'LOGIC' && t.subtype === subtype) {
                if (!t.childrenConditions.includes(condition)) {
                    return {...t, childrenConditions: [...t.childrenConditions, condition]};
                }
            }
            return {type: "LOGIC", childrenConditions: [condition], subtype: subtype}
        })
    };

    const refreshConditions = (): void => {
        searchConditions(props.company.id, null).then((r: any) => {
            setConditions(r);
        });
    };
    const conditionActionProvider = function () {
        const showSuperUserAction = props.company.superUser;
        if (showSuperUserAction) {
            return (c: ConditionMetaDataView): ReactNode => (
                <>
                    <Af onClick={() => selectLogicalConditionInNewConditionForm(ConditionTypeView.And, c)}>Oraz</Af>
                    <Af onClick={() => selectLogicalConditionInNewConditionForm(ConditionTypeView.Or, c)}>Lub</Af>
                    <Af onClick={() => selectLogicalConditionInNewConditionForm(ConditionTypeView.Not, c)}>Nie</Af>
                </>
            );
        } else {
            return (): ReactNode => uxNull();
        }
    };
    const resolveNewConditionPopup = (): ReactNode => {
        const closeFun = (refresh: boolean): void => {
            setNewConditionPanelType(null);
            refresh && refreshConditions();
        };
        if (!newConditionPanelType) {
            return null;
        }
        switch (newConditionPanelType.type) {
            case 'NEW':
                return <NewConditionListPanel
                    changeNewConditionTypeFun={(t: NewConditionPanelInConditionTabType) => setNewConditionPanelType(t)}
                    closeFun={closeFun}/>;
            case 'WINTER_HOLIDAYS':
                return <NewWinterHolidaysConditionForm company={props.company} closeFun={closeFun}/>;
            case 'STATIC':
                return <NewStaticConditionForm company={props.company} closeFun={closeFun}
                                               subtype={newConditionPanelType.subtype}/>;
            case 'DAY_OF_WEEK':
                return <NewDayOfWeekConditionForm company={props.company} closeFun={closeFun}/>;
            case 'DAY_OF_MONTH':
                return <NewDayOfMonthConditionInput company={props.company} onClose={closeFun}/>;
            case 'VARIABLE':
                return <NewVariableConditionForm company={props.company} onClose={closeFun}/>;
            case "LOGIC":
                return <NewLogicConditionForm company={props.company} close={closeFun}
                                              type={newConditionPanelType.subtype}
                                              proposedConditions={newConditionPanelType.childrenConditions}
                                              removeSelectedLogicalConditionFun={removeSelectedLogicalCondition}
                />
        }
    };
    return (
        <div id="simpleMainContentSplit">
            <div>
                <h3>Warunki</h3>
                <TabConditionTable
                    company={props.company}
                    conditions={conditions}
                    provideActionFun={conditionActionProvider()}
                    onConditionChange={()=>setGreedyConditionVersion(v=>v+1)}
                />
                <Button onClick={() => setShowVariablePopup(!showVariablePopup)}>Zmienne środowiskowe</Button>
                {props.company.superUser && (
                    <Button onClick={() => setNewConditionPanelType({type: 'NEW'})}>Nowy warunek</Button>
                )}
            </div>
            {showVariablePopup && (
                <VariablePanel
                    closeFun={() => setShowVariablePopup(!showVariablePopup)}
                    company={props.company}
                    changeVariableToViewValueFun={(v) => setVariableToViewDetails(v)}
                    toggleShowNewVariableFormFun={() => setShowNewVariableTemplateForm(!showNewVariableTemplateForm)}
                />
            )}
            {variableToViewDetails && (
                <VariableValuePopup
                    variable={variableToViewDetails}
                    close={() => setVariableToViewDetails(null)}
                />
            )}
            {showNewVariableTemplateForm && (
                <NewVariableForm company={props.company}
                                 closeFun={() => setShowNewVariableTemplateForm(!showNewVariableTemplateForm)}/>
            )}
            {resolveNewConditionPopup()}
        </div>
    );
}

export default ConditionTabSectionConditionManagement;
