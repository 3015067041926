/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreetSearchInput
 */
export interface StreetSearchInput {
    /**
     * 
     * @type {string}
     * @memberof StreetSearchInput
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof StreetSearchInput
     */
    townId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreetSearchInput
     */
    spaceId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreetSearchInput
     */
    companyId?: string;
}

/**
 * Check if a given object implements the StreetSearchInput interface.
 */
export function instanceOfStreetSearchInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StreetSearchInputFromJSON(json: any): StreetSearchInput {
    return StreetSearchInputFromJSONTyped(json, false);
}

export function StreetSearchInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreetSearchInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': !exists(json, 'query') ? undefined : json['query'],
        'townId': !exists(json, 'townId') ? undefined : json['townId'],
        'spaceId': !exists(json, 'spaceId') ? undefined : json['spaceId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
    };
}

export function StreetSearchInputToJSON(value?: StreetSearchInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': value.query,
        'townId': value.townId,
        'spaceId': value.spaceId,
        'companyId': value.companyId,
    };
}

