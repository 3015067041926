import Close from "../../common/ahreffun/Close";
import Button from "../../common/input/button/Button";
import Help from "../../common/tooltip/Help";
import {addToast} from "../../common/toast/toastUtil";
import {prepareSecurityHeaders} from "../../user/current/CurrentUserContext";
import {verifyClientResponse} from "../../common/error/resolvers";
import {LineSchemaEndpointApi} from "../../../api";
import Checkbox from "../../common/input/checkbox/Checkbox";
import {ChangeEvent, FormEvent, useEffect, useState} from "react";

interface EditTimetableLineFormProps {
    onClose: (refresh: boolean) => void;
    lineId: string;
}

function EditTimetableLineForm(props: EditTimetableLineFormProps) {

    const [name, setName] = useState<string>(null);
    const [description, setDescription] = useState<string>(null);
    const [technicalName, setTechnicalName] = useState('');
    const [originalTechnicalName, setOriginalTechnicalName] = useState<string>();
    const [technicalDescription, setTechnicalDescription] = useState('');
    useEffect(() => {
        new LineSchemaEndpointApi()
            .getLineSchemaDetails({
                lineId: props.lineId
            }, {headers: prepareSecurityHeaders()})
            .then(r => {
                setName(r.name);
                setDescription(r.description);
                setTechnicalName(r.technicalName);
                setOriginalTechnicalName(r.technicalName);
                setTechnicalDescription(r.technicalDescription);
            })
            .catch(e => {
                verifyClientResponse(e);
            })
    }, [props.lineId]);
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (verifyForm()) {
            new LineSchemaEndpointApi()
                .updateLineSchema({
                    editLineSchemaInput: {
                        id: props.lineId,
                        name: name,
                        technicalName: technicalName,
                        description: description,
                        technicalDescription: technicalDescription,
                    }
                }, {headers: prepareSecurityHeaders()})
                .then(() => {
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e);
                });
        }
    }
    const verifyForm = () => {
        if (technicalName === '') {
            addToast('Nazwa techniczna nie może być pusta');
            return false;
        }
        return true;
    }
    const onRemove = () => {
        if (confirm(`Czy na pewno chcesz usunąć linię ${originalTechnicalName}?`)) {
            new LineSchemaEndpointApi().deleteLineSchema({lineId: props.lineId}, {headers: prepareSecurityHeaders()})
                .then(() => {
                    addToast('Poprawnie usunięto linię');
                    props.onClose(true);
                })
                .catch(e => {
                    verifyClientResponse(e, new Map());
                });
        }
    }
    return (
        <div className='diffArea'>
            <div>
                <span className='windowButton'><Close onClick={() => props.onClose(false)}/></span>
            </div>
            <h2>Edycja {originalTechnicalName}</h2>
            <form>
                <label>Id techniczne*:</label>
                <Help>Główny identyfikator</Help>
                <input name='technicalName' value={technicalName}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setTechnicalName(e.target.value)}
                       maxLength={50}/>
                <br/>
                <label>Opis techniczny:</label>
                <Help>Zalecany format: Cel(Początek) /Wariant</Help>
                <input name='technicalDescription' value={technicalDescription}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setTechnicalDescription(e.target.value)}
                       maxLength={250}/>
                <br/>
                <h4>Nadpisane nazwy dla pasażera:</h4>
                <Checkbox value={name != null} onChange={() => setName(name == null ? '' : null)}>Id:</Checkbox>
                <Help>Nazwa dla pasażerów. Zazwyczaj reprezentowana jako numer linii</Help>
                <input name='name' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                       maxLength={50} disabled={name == null}/>
                <br/>
                <Checkbox value={description != null}
                          onChange={() => setDescription(description == null ? '' : null)}>Opis:</Checkbox>
                <Help>Opis dla pasażerów. Zazwyczaj przystanek docelowy</Help>
                <input name='description' value={description}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                       maxLength={250} disabled={description == null}/>
                <br/>
                <Button onClick={onSubmit}>Zapisz</Button>
            </form>
            <h3>Inne opcje</h3>
            <Button onClick={onRemove}>Usuń linię</Button>
        </div>
    );
}

export default EditTimetableLineForm;
