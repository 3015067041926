import {Component, ReactNode} from 'react';
import UserAssociatedCompanySwitcher from "../../company/switch/UserAssociatedCompanySwitcher";
import SpaceSwitcher from "./SpaceSwitcher";
import {Company} from "../../user/current/CurrentUserContext";
import {DataSpacePermissionCompanyView} from "../../../api";

interface CompanyContextAssociatedSpaceSwitcherProps {
    company: Company;
    space: DataSpacePermissionCompanyView;
    onChangeCompanyAndSpaceFun: (company: Company, space: DataSpacePermissionCompanyView) => void;
}

interface CompanyContextAssociatedSpaceSwitcherState {
    availableSpaces: DataSpacePermissionCompanyView[];
    initSpace: DataSpacePermissionCompanyView | null;
}

class CompanyContextAssociatedSpaceSwitcher extends Component<CompanyContextAssociatedSpaceSwitcherProps, CompanyContextAssociatedSpaceSwitcherState> {

    constructor(props: CompanyContextAssociatedSpaceSwitcherProps) {
        super(props);
        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangeSpace = this.onChangeSpace.bind(this);

        this.state = {availableSpaces: [], initSpace: null};
    }

    render(): ReactNode {
        return <>
            <label>Firma: </label>
            <UserAssociatedCompanySwitcher
                company={this.props.company} onChangeCompanyFun={this.onChangeCompany}
            />
            <br/>
            <label>Space: </label>
            <SpaceSwitcher space={this.props.space} spaces={this.state.availableSpaces} initSpace={this.state.initSpace}
                           onChangeSpace={this.onChangeSpace}/>
        </>;
    }

    onChangeCompany(company: Company): void {
        let newSpace: DataSpacePermissionCompanyView | null = null;
        const currentSpace = this.props.space;
        if (currentSpace && company.availableDataSpaces.some(s => s.id === this.props.space.id)) {
            newSpace = currentSpace;
        } else {
            const defaultSpaces = company.availableDataSpaces.filter(c => c.defaultSpace);
            if (defaultSpaces.length) {
                newSpace = defaultSpaces[0];
            }
        }
        this.setState({availableSpaces: company ? company.availableDataSpaces : []});
        this.props.onChangeCompanyAndSpaceFun(company, newSpace);
    }

    onChangeSpace(space: DataSpacePermissionCompanyView): void {
        this.props.onChangeCompanyAndSpaceFun(this.props.company, space);
    }
}

export default CompanyContextAssociatedSpaceSwitcher;
