import {ReactNode} from "react";

export interface NavbarMenuPositionProps {
    disabled: boolean;
    onClick: () => void;
    children: ReactNode;
}

export function NavbarMenuPosition(props: NavbarMenuPositionProps): JSX.Element {
    let classNames = 'navbarMenuPosition ';
    classNames += props.disabled ? 'bgColorDisabled' : 'bgColorPrimary';
    return <div onClick={props.onClick} className={classNames}>
        {props.children}
    </div>;
}