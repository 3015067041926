/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SectionTimeView } from './SectionTimeView';
import {
    SectionTimeViewFromJSON,
    SectionTimeViewFromJSONTyped,
    SectionTimeViewToJSON,
} from './SectionTimeView';

/**
 * 
 * @export
 * @interface CreateSectionSampleInput
 */
export interface CreateSectionSampleInput {
    /**
     * used for modification only
     * @type {string}
     * @memberof CreateSectionSampleInput
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionSampleInput
     */
    sectionId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSectionSampleInput
     */
    sampleDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof CreateSectionSampleInput
     */
    defaultTravelSec?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSectionSampleInput
     */
    defaultWaitingSec?: number;
    /**
     * 
     * @type {Array<SectionTimeView>}
     * @memberof CreateSectionSampleInput
     */
    times?: Array<SectionTimeView>;
}

/**
 * Check if a given object implements the CreateSectionSampleInput interface.
 */
export function instanceOfCreateSectionSampleInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateSectionSampleInputFromJSON(json: any): CreateSectionSampleInput {
    return CreateSectionSampleInputFromJSONTyped(json, false);
}

export function CreateSectionSampleInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSectionSampleInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sectionId': !exists(json, 'sectionId') ? undefined : json['sectionId'],
        'sampleDate': !exists(json, 'sampleDate') ? undefined : (new Date(json['sampleDate'])),
        'defaultTravelSec': !exists(json, 'defaultTravelSec') ? undefined : json['defaultTravelSec'],
        'defaultWaitingSec': !exists(json, 'defaultWaitingSec') ? undefined : json['defaultWaitingSec'],
        'times': !exists(json, 'times') ? undefined : ((json['times'] as Array<any>).map(SectionTimeViewFromJSON)),
    };
}

export function CreateSectionSampleInputToJSON(value?: CreateSectionSampleInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sectionId': value.sectionId,
        'sampleDate': value.sampleDate === undefined ? undefined : (value.sampleDate.toISOString().substr(0,10)),
        'defaultTravelSec': value.defaultTravelSec,
        'defaultWaitingSec': value.defaultWaitingSec,
        'times': value.times === undefined ? undefined : ((value.times as Array<any>).map(SectionTimeViewToJSON)),
    };
}

